/**
   * Formats clock values in 0:00 format.
   *
   * @param {Object} clockValues values from the countdown timer
   * @param {number} clockValues.minutes minutes left in countdown
   * @param {number} clockValues.seconds seconds in current minute
   *
   * @return {string} formatted clock values as 0:00
 */
export default function formatCountdown({minutes, seconds}) {
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${minutes}:${formattedSeconds}`;
}
