import { Controller } from "stimulus";
/**
 * Remove documents from User Merchant interface.
 */
export default class extends Controller {
  /**
   * Submit to request Users::DocumentsController#destroy
   * @param {event} event -> click
   */
  static targets = [
    "name",
    "nameStatus",
    "destroyBtn",
    "submitBtn"
  ];

  validatePartnerName = () => {
    if (this.validApiPartnerName) {
      this.submitBtnTarget.disabled = false;
      this.addSuccess(this.nameStatusTarget);
    } else {
      this.submitBtnTarget.disabled = true;
      this.addError(this.nameStatusTarget);
    }
  }

  get validApiPartnerName() {
    return this.nameTarget.value !== "";
  }

  get params() {
    const guid = this.data.get("guid");
    const method = guid ? "PUT" : "POST";
    const payLoad = {
      api_partner: {
        name: this.nameTarget.value
      }
    };

    if (guid) {
      payLoad["api_partner"]["guid"] = guid;
    }

    const headers = {
      "credentials": "same-origin",
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token":
        document.head.querySelector("meta[name=csrf-token]").content,
      "X-Fetch-Request": true,
      "Accept": "application/json"
    };

    return {
      "body": JSON.stringify(payLoad),
      method,
      "headers": new Headers(headers)
    };
  }

  submitForm = () => {
    const url = this.data.get("url");

    fetch(url, this.params).then(response => {
      response.json();
    }).then(response => {
      const progressUrl = this.data.get("progressUrl");
      window.location.href = progressUrl;
    }).catch(err => {
      console.log(err);
    });
  }

  /**
   * Adds has-success class around text field (green)
   */

  addSuccess(target) {
    target.classList.remove("has-error");
    target.classList.add("has-success");
  }

  /**
   * Adds has-success class around text field (red)
   */

  addError(target) {
    target.classList.remove("has-success");
    target.classList.add("has-error");
  }
}
