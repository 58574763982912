import { Controller } from "stimulus";
import {
  showConfirmDialog
} from "../../../shared/common/dialogs/confirm_dialog_controller";
import {
  showErrorDialog
} from "../../../shared/common/dialogs/error_dialog_controller";
import {
  flash
} from "../../../shared/common/notices/flash_alerts_controller";


export default class extends Controller {
  connect() {
    this.message = this.data.get("message");
    this.url = this.data.get("url");
    this.lease = this.data.get("lease");
  }

  /**
   * Makes request to send a text message
   */
  createMessage = () => {
    const params = JSON.stringify({
      lease_guid: this.lease
    });

    const request = window.acima.fetchInit( { method: "POST", body: params } );

    fetch(this.url, request)
      .then(response => response.json())
      .then(jsonResponse => this.handleResponse(jsonResponse))
      .catch(err => this.handleError(err));
  }

  /**
   * handles Json response
   *
   * @param {Object} err caught error object
   */

  handleResponse(jsonResponse) {
    if (jsonResponse.success) {
      this.showSuccessMessage(jsonResponse.successMessage);
    } else {
      this.handleError(new Error(jsonResponse.message));
    }
  }

  /**
   * handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    showErrorDialog(err);
  }

  /**
   * Display flash success to user
   *
   * @param {Object} successMessage display the sucessfull message
   */
  showSuccessMessage(successMessage) {
    flash.clear();
    flash.success(`${successMessage}`);
  }

  /*
   * Show the confirm create message dialog
   *
   */
  showDialog() {
    showConfirmDialog({
      message: this.message,
      action: this.createMessage
    });
  }
}
