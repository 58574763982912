import { Controller } from "stimulus";

const anyDigitRegex = /^\d+(\.\d+)?$/;
const maximumAmountFactor = 0.4;
const minimumAmountFactor = 0.1;
const optionalPaymentElectionValue = "optional_payment_election";
const emptyValues = [null, ""];

/**
 * Handle additional payments on merchants (Lease Details tab)
 */
export default class extends Controller {
  static targets = [
    "additionalPaymentsForm",
    "modalTitle",
    "modalText",
    "amountLabel",
    "amount",
    "amountStatus",
    "selectedPaymentType",
    "amountInvalid",
    "confirmBtn",
    "selectedPaymentTypeLabel",
    "editLinkLabel",
    "editBox",
    "buttonsBox",
    "opeEnabled",
    "selectedRecurringPaymentAmount",
    "optionalPaymentThresholdAmounts",
    "renewalPaymentAmount",
    "proratedOptionalPaymentAmount",
    "effectiveRenewalPaymentAmount",
  ];

  /** Opens modal and populate its content */
  openModal(e) {
    this.additionalPaymentsFormTarget.classList.remove("hidden");
    this.populateDynamicContentOnModal(e);
    this.toggleStateConfirmButton();
  }

  /** Closes modal */
  closeModal() {
    const forceMerchandiseTotalValidationWithOPE = this.hasOpeEnabledTarget;
    this.additionalPaymentsFormTarget.classList.add("hidden");
    this.clearAmountStatus();
    this.clearFieldValues();
    this.invoicesController.setsCurrentOpeAction("close");
    this.invoicesController.validateMerchandiseTotal(forceMerchandiseTotalValidationWithOPE);
  }

  /** Makes sure to clear form field values if the optional payment modal get closed without confirming the amount
   *  or without selecting any option previously, this means by clicking either the X or Cancel buttons. */
  clearFieldValues() {
    if (emptyValues.includes(this.editLinkLabelTarget.getAttribute("data-option-selected"))) {
      this.amountTarget.value = "";
      this.selectedPaymentTypeTarget.value = "";
    }
  }

  /** Populates dynamic content on the modal based on user's selection */
  populateDynamicContentOnModal(e) {
    const currentTarget = e.target.closest("label");
    this.populateModalTitle(currentTarget);
    this.renderOptionalPaymentElectionModalContent();
    this.selectedPaymentTypeTarget.value = optionalPaymentElectionValue;
  }

  /** Renders dynamic content on the Optional Payment Election modal */
  renderOptionalPaymentElectionModalContent() {
    this.amountLabelTarget.textContent = window.acima.i18n.t("invoices.edit.optional_payment_election_amount_label");
    this.populateOptionalPaymentElectionModalText();
    this.populateOptionalPaymentElectionModalThresholds();
    this.populateOptionalPaymentElectionSummaryBreakdown();
  }

  /** Populates modal title based on user's selection */
  populateModalTitle(target) {
    const modalTitle = target.getAttribute("data-modal-title");
    this.modalTitleTarget.textContent = modalTitle;
  }

  /** Populates content for the Optional Payment Election modal text */
  populateOptionalPaymentElectionModalText() {
    const content = window.acima.i18n.t("invoices.edit.merchant_optional_payment_election_modal_text");
    this.modalTextTarget.innerHTML = content;
  }

  /** Populates content for the Optional Payment Election thresholds */
  populateOptionalPaymentElectionModalThresholds() {
    this.optionalPaymentThresholdAmountsTarget.innerHTML = window.acima.i18n.t("invoices.edit.merchant_optional_payment_threshold_amounts", { min: `${accounting.formatMoney(this.minOptionalPaymentAmount, "$", 2)}`, max: `${accounting.formatMoney(this.maxOptionalPaymentAmount, "$", 2)}` });
  }

  /** Clears displayed feedback on the page */
  clearAmountStatus() {
    this.amountStatusTarget.classList.remove("has-success");
    this.amountStatusTarget.classList.remove("has-error");
    this.amountInvalidTarget.classList.add("hidden");
  }

  /** Runs general validations for amount field and then runs specific based on user's selection */
  validateAmount(e) {
    if (this.amountReady) {
      this.validateOptionalPaymentElectionAmount(e);
    } else {
      const message = window.acima.i18n.t("invoices.validations.only_digits");
      this.addError(message);
      e.stopImmediatePropagation();
    }
  }

  /** Populates content for the Optional Payment Election Summary if values are not already set */
  /** Clears content for the Optional Payment Election Summary if OPE amount is not valid */
  populateOptionalPaymentElectionSummaryBreakdown() {
    if(this.amountReady) {
      if(this.canUpdateSummaryBreakdown) {
        this.fetchAmounts();
      }
    } else {
      this.clearSummaryBreakdown();
    }
  }

  /** Fetches amount for the unsaved invoice and populates the summary breakdown */
  fetchAmounts(e) {
    const leaseId = this.invoiceLeaseTermsController.data.get("leaseId");
    const params = this.buildParams;

    this.invoiceLeaseTermsController.fetchAmounts(leaseId, params)
      .then(function(data) {
        if (data.success) {
          const amounts = this.filterAmounts(data);
          this.renewalPaymentAmountTarget.textContent = accounting.formatMoney(amounts.renewal_payment_amount, "$", 2);
          this.proratedOptionalPaymentAmountTarget.textContent = accounting.formatMoney(amounts.pro_rate_payment_amount, "$", 2);
          this.effectiveRenewalPaymentAmountTarget.textContent = accounting.formatMoney(amounts.effective_payment_amount, "$", 2);
        }
      }.bind(this));
  }

  /** Filters data based on selected term, defaulted to 1 year term if none is selected */
  filterAmounts(data) {
    if (this.selectedTerm === undefined) {
      return data.details.filter((amounts) => amounts.lease_term_months == 12)[0];
    }

    const programId = parseInt(this.selectedTerm.dataset.id);
    return data.details.filter((amounts) => amounts.underwriting_program_id == programId)[0];
  }

  /** Clears Summary Breakdown content */
  clearSummaryBreakdown() {
    this.renewalPaymentAmountTarget.textContent = "";
    this.proratedOptionalPaymentAmountTarget.textContent = "";
    this.effectiveRenewalPaymentAmountTarget.textContent = "";
  }

  /** Validates a value is within a range and displays error message if any */
  validateValueInRange(e, min, max) {
    const value = this.amountTarget.value;

    if (value < min || value > max) {
      const message = window.acima.i18n.t("invoices.validations.amount_within_range", { min: `${accounting.toFixed(min, 2)}`, max: `${accounting.toFixed(max, 2)}` });
      this.addError(message);
      e.stopImmediatePropagation();
    } else {
      this.addSuccess();
    }
  }

  /** Runs specific validations for Optional Payment Election amount */
  validateOptionalPaymentElectionAmount(e) {
    const min = this.minOptionalPaymentAmount;
    const max = this.maxOptionalPaymentAmount;
    this.validateValueInRange(e, min, max);
  }

  /** Swaps Additional Payments section and populates content based on user's confirmation */
  swapAdditionalPaymentsSectionState() {
    const editBox = this.editBoxTarget;
    const buttonsBox = this.buttonsBoxTarget;

    editBox.classList.remove("hidden");
    buttonsBox.classList.add("hidden");

    this.editLinkLabelTarget.setAttribute("data-option-selected", optionalPaymentElectionValue);

    this.editLinkLabelTarget.setAttribute("data-modal-title", window.acima.i18n.t(`invoices.edit.${this.selectedPaymentTypeTarget.value}_modal_title`));
    this.selectedPaymentTypeLabelTarget.textContent = window.acima.i18n.t(`invoices.edit.merchant_optional_payment_amount_label`, { amount: `${accounting.formatMoney(this.amountTarget.value, "$", 2)}` });
  }

  /** Hides error message if any and green highlight amount field */
  addSuccess(message = "") {
    this.amountStatusTarget.classList.remove("has-error");
    this.amountStatusTarget.classList.add("has-success");

    this.amountInvalidTarget.textContent = message;
    this.amountInvalidTarget.classList.add("hidden");
    this.toggleStateConfirmButton();

    this.invoicesController.setsCurrentOpeAction(null);
  }

  /** Displays error message if any and red highlight amount field */
  addError(message) {
    this.amountStatusTarget.classList.remove("has-success");
    this.amountStatusTarget.classList.add("has-error");

    this.amountInvalidTarget.textContent = message;
    this.amountInvalidTarget.classList.remove("hidden");
    this.toggleStateConfirmButton();
  }

  /** Enables/Disables confirm button if modal amount is valid */
  toggleStateConfirmButton() {
    const isInvalid = this.amountStatusTarget.classList.contains("has-error") || this.amountTarget.value === "";
    const confirmButton = this.confirmBtnTarget;

    if (isInvalid) {
      confirmButton.disabled = true;
    } else {
      confirmButton.disabled = false;
    }
  }

  /**
   * Allows to opt-out selected payment type
   * Validates retailer invoice total to check whether is still valid or not
   * Runs validations to enable/disabled submit btn
  */
  optOut() {
    const editBox = this.editBoxTarget;
    const buttonsBox = this.buttonsBoxTarget;
    const forceMerchandiseTotalValidationWithOPE = this.hasOpeEnabledTarget;

    buttonsBox.classList.remove("hidden");
    editBox.classList.add("hidden");

    this.editLinkLabelTarget.setAttribute("data-option-selected", "");
    this.amountTarget.value = "";
    this.selectedPaymentTypeTarget.value = "";

    this.invoicesController.invalidOptionalPaymentLabelTarget.classList.add("hidden");
    this.invoicesController.setsCurrentOpeAction("optOut");
    this.invoicesController.validateMerchandiseTotal(forceMerchandiseTotalValidationWithOPE);

    this.invoiceLeaseTermsController.validateAndCalculate();
    this.invoiceLeaseTermsController.updateInvoiceValidation();
  }

  /**
   * Updates Retailer Invoice total value with the current approval amount + the OPE amount
   * Runs validations to enable/disabled submit btn
   * Hides Retailer Invoice total error if displayed
  */
  updateRetailerInvoiceTotal() {
    this.invoiceLeaseTermsController.merchandiseTotalInputTarget.value = accounting.toFixed(this.retailerInvoiceTotal, 2);
    this.invoiceLeaseTermsController.validateAndCalculate();
    this.invoiceLeaseTermsController.updateInvoiceValidation();
    this.invoicesController.merchandiseTotalInputErrorTarget.textContent = "";
  }

  /**
   * Returns InvoicesController reference
   * @return {Object} InvoicesController reference.
  */
  get invoicesController() {
    const selector = document.getElementById("inv_form");
    const controllerName = "invoices";

    return this.application.getControllerForElementAndIdentifier(selector, controllerName);
  }

  /**
   * Returns lease-terms-controller reference
   * @return {Object} lease-terms-controller reference.
  */
    get invoiceLeaseTermsController() {
      const selector = document.querySelector("[data-controller='invoice-lease-terms']");
      const controllerName = "invoice-lease-terms";

      return this.application.getControllerForElementAndIdentifier(selector, controllerName);
    }

  /**
   * Returns whether amount is valid or not (valid means is not empty and is a digit)
   * @return {Boolean} true/false
  */
  get amountReady() {
    return this.amountTarget.value.trim().length > 0 && anyDigitRegex.test(this.amountTarget.value);
  }

  /**
   * Returns current approval amount
   * @return {Float}
  */
  get currentApprovalAmount() {
    return parseFloat(this.element.getAttribute("data-lease-approved-amount"));
  }

  /**
   * Returns 10% of current approval amount
   * @return {Float}
  */
  get minOptionalPaymentAmount() {
    return parseFloat(this.currentApprovalAmount) * minimumAmountFactor;
  }

  /**
   * Returns 40% of current approval amount
   * @return {Float}
  */
  get maxOptionalPaymentAmount() {
    return parseFloat(this.currentApprovalAmount) * maximumAmountFactor;
  }

  /**
   * Returns calculated value for retailerInvoiceTotal AKA merchandiseTotal
   * based on current approval amount and optional payment amount
   * @return {Float}
  */
  get retailerInvoiceTotal() {
    return parseFloat(this.currentApprovalAmount) + parseFloat(this.amountTarget.value);
  }

  /**
   * Returns params to be sent to calculate amounts for the unsaved invoice
   * @return {String}
  */
  get buildParams() {
    return new URLSearchParams({
      merchandise_total: this.retailerInvoiceTotal,
      underwriting_program_ids: this.invoiceLeaseTermsController.programIdsToSend,
      optional_payment_amount: this.amountTarget.value,
      selected_payment_type: this.selectedPaymentTypeTarget.value,
    }).toString();
  }

  /**
   * Returns selectedTerm element
   * @return {HTMLElement|undefined}
  */
  get selectedTerm() {
    return this.invoicesController.selectedTerm;
  }

  /**
   * Returns whether the summary breakdown has to be updated or not based on the selected Term
   * @return {Boolean} true/false
  */
  get canUpdateSummaryBreakdown() {
    if (this.selectedTerm === undefined) {
      return true;
    } else {
      return !this.renewalPaymentAmountTarget.textContent.includes(this.selectedTerm.dataset.renewalPaymentAmount);
    }
  }
}
