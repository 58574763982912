import {Controller} from "stimulus";

/**
 * Formats SSNs.
 */
export default class extends Controller {
  /**
   * Dynamically reformats the value of a subject element
   * as a hyphenated SSN.
   * @param {event} event Keyup
   */
  format(event) {
    if (event.which == 189) {
      event.preventDefault();
    }

    this.element.value = this.element.value.replace(/[^0-9\-]/g, "");
    this.isCanadianLease() ? this.canadaFormat() : this.usFormat();
  }

  /**
   * Checks if current Lease is Canadian.
   * @return {boolean} true/false value
   */
  isCanadianLease() {
    const boolString = this.data.get("canadian-lease");

    return boolString.toLowerCase() === "true";
  }

  canadaFormat() {
    // Format: XXX-XXX-XXX
    // Sample: 124-936-956

    if (
      this.element.value.length === 4 && this.element.value.indexOf("-") === -1
    ) {
      this.element.value =
        this.element.value.substring(0, 3) + "-" +
        this.element.value.substring(3, this.element.value.length);
    } else if (
      this.element.value.length === 8 && this.element.value.indexOf("-") === -1
    ) {
      this.element.value =
        this.element.value.substring(0, 3) + "-" +
        this.element.value.substring(3, 6) + "-" +
        this.element.value.substring(6, this.element.value.length);
    } else if (
      this.element.value.length === 8 && this.element.value.match((/-/g) || []).length === 1
    ) {
      this.element.value =
        this.element.value.substring(0, 7) + "-" +
        this.element.value.substring(7, this.element.value.length);
    } else if (
      this.element.value.length === 9 && this.element.value.indexOf("-") === -1
    ) {
      this.element.value =
        this.element.value.substring(0, 3) + "-" +
        this.element.value.substring(3, 6) + "-" +
        this.element.value.substring(6, this.element.value.length);
    }
  }

  usFormat() {
    // Format: XXX-XX-XXXX
    // Sample: 124-93-6956

    if (
      this.element.value.length === 4 && this.element.value.indexOf("-") == -1
    ) {
      this.element.value =
        this.element.value.substring(0, 3) + "-" +
        this.element.value.substring(3, this.element.value.length);
    } else if (
      this.element.value.length === 7 && this.element.value.indexOf("-") === -1
    ) {
      this.element.value =
        this.element.value.substring(0, 3) + "-" +
        this.element.value.substring(3, 5) + "-" +
        this.element.value.substring(5, this.element.value.length);
    } else if (
      this.element.value.length === 7 && this.element.value.match((/-/g) || []).length === 1
    ) {
      this.element.value =
        this.element.value.substring(0, 6) + "-" +
        this.element.value.substring(6, this.element.value.length);
    } else if (
      this.element.value.length === 9 && this.element.value.indexOf("-") === -1
    ) {
      this.element.value =
        this.element.value.substring(0, 3) + "-" +
        this.element.value.substring(3, 5) + "-" +
        this.element.value.substring(5, this.element.value.length);
    }
  }
}
