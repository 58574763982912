import {Controller} from "stimulus";

/** Handle Edit Account modal functionality */
export default class extends Controller {
    static targets = [
      "fundingMethodTitle",
      "accountId",
      "cardId",
      "locationDba",
      "locationAddress",
      "locationId",
      "errors",
      "updateAccountBtn",
      "modal",
    ];

    /** Register dialog */
    connect() {
      dialogPolyfill.registerDialog(this.element);
    }

    /**
     * Get account from launch controller, open modal & fill with data
     * @param  {object} fundingAccount The funding account instance
     * @param  {object} locationData The location instance
     * @param {cardData} cardData the instant funding card instance
     */
    openDialog(fundingAccount, locationData, cardData) {
      this.clearForm();
      this.fundingAccount = fundingAccount;
      this.locationData = locationData;
      if (this.acimaDirectEnabled) {
        this.instantFundingCardData = cardData;
      }
      this.element.showModal();
      this.fillForm();
    }

    /** Close the dialog */
    closeDialog() {
      this.clearForm();
      this.element.close();
    }

    /** is ACH enabled? */
    get achEnabled() {
      return this.modalTarget.dataset.achEnabled === "true";
    }

    /** is acima direct enabled? */
    get acimaDirectEnabled() {
      return this.modalTarget.dataset.acimaDirectEnabled === "true";
    }

    /** Fill form with selected account current data */
    fillForm() {
      this.accountIdTarget.value = this.fundingAccount.id || "";
      if (this.acimaDirectEnabled) {
        this.cardIdTarget.value = this.instantFundingCardData.id || "";
      }
      this.locationIdTarget.value = this.locationData.location_id;
      this.locationDbaTarget.textContent = this.locationData.location_dba;
      this.locationAddressTarget.textContent =
          this.locationData.location_address;
      this.setFundingTitle();
    }

    /** Set the title of the funding method based on selected bank
     * account/instant funding card */
    setFundingTitle() {
      if (this.achEnabled && this.accountIdTarget.value) {
        this.fundingMethodTitleTarget.textContent =
            this.fundingAccount.bank_name;
      } else if (this.acimaDirectEnabled && this.cardIdTarget.value != "") {
        this.fundingMethodTitleTarget.textContent = "XXXXXXXXX" +
                this.cardIdTarget.options[this.cardIdTarget.selectedIndex].text;
      } else {
        this.fundingMethodTitleTarget.textContent = "None Selected";
      }
    }

    /** Clear input fields when modal closed */
    clearForm() {
      this.fundingMethodTitleTarget.textContent = "";
      if (this.achEnabled) {
        this.accountIdTarget.value = "";
      }
      if (this.acimaDirectEnabled) {
        this.cardIdTarget.value = "";
      }
      this.locationDbaTarget.textContent = "";
      this.locationAddressTarget.textContent = "";
      this.clearErrors();
      this.enableSubmitButton();
    }

    /** Clear error messages */
    clearErrors() {
      this.errorsTarget.classList.add("hide");
      this.errorsTarget.textContent = "";
    }

    /**
     * Disable submit button after click
     * @param  {event} event JS Event
     * @return {null} null Early return if already disabled
     */
    disableSubmitButton(event) {
      if (event.currentTarget.disabled == true) return null;
      event.currentTarget.disabled = true;
      event.currentTarget.classList.add("disabled");
    }

    /** Enable submit button */
    enableSubmitButton() {
      this.updateAccountBtnTarget.disabled = false;
      this.updateAccountBtnTarget.classList.remove("disabled");
    }

    /** List errors at top of form
     * @param {string} message Error message from invalid form.
     */
    addError(message) {
      const item = document.createElement("LI");
      item.textContent = message;
      this.errorsTarget.classList.remove("hide");
      this.errorsTarget.appendChild(item);
    }

    /** Handle form submission
     * @param {event} event Action that submits form.
     */
    submitForm(event) {
      const controller = this;
      const url = "/owners/locations/" + this.locationIdTarget.value;
      const requestHash = { method: "PATCH", body: this.params() };
      const fetchWith = window.acima.fetchInit(requestHash);

      controller.disableSubmitButton(event);

      fetch(url, fetchWith).then((response) => {
        return response.json();
      }).then((data) => {
        if (data.success == false && data.redirect) {
          window.location = data.redirect;
          return false;
        }

        if (data.success == true) {
          controller.clearForm();
          window.location.reload();
        } else {
          controller.clearErrors();
          data.message.forEach((message) => {
            controller.addError(message);
          });
          controller.enableSubmitButton();
        }
      });
    }

    /**
     * Define params for Post request
     * @return {JSON} params JSON string
     */
    params() {
      let cardId = null;

      if (this.acimaDirectEnabled) {
        cardId = this.cardIdTarget.value || null;
      }

      return JSON.stringify({
        bank_account_id: this.accountIdTarget.value,
        instant_funding_card_id: cardId,
      });
    }
}
