import {Controller} from "stimulus";

/**
* Applicant info language preference setting
 */
export default class extends Controller {
  static targets = [
    "spanishToggleInput",
    "spanishToggleParent",
  ]

  connect() {
    if (this.hasSpanishToggleInputTarget) {
      this.currentStyles();
    }
  }

  /**
  * Set styles based on current language preference setting
  */
  currentStyles() {
    if (this.spanishToggleInputTarget.checked) {
      this.activeStyles();
    } else {
      this.inactiveStyles();
    }
  }

  /**
  * Set styles for Spanish language preference
  */
  activeStyles() {
    this.spanishToggleParentTarget.classList.remove("tongue_parent_inactive");
    this.spanishToggleParentTarget.classList.add("tongue_parent");
  }

  /**
  * Set styles for English language preference
  */
  inactiveStyles() {
    this.spanishToggleParentTarget.classList.add("tongue_parent_inactive");
    this.spanishToggleParentTarget.classList.remove("tongue_parent");
  }

  /**
  * Update language preference setting
  */
  toggleSpanishPreference() {
    if (this.spanishToggleInputTarget.checked) {
      this.activeStyles();
      this.spanishPreference();
    } else {
      this.inactiveStyles();
      this.englishPreference();
    }
  }

  /**
  * Set language preference to Spanish
  */
  spanishPreference() {
    this.updateLanguagePreference("es");
  }

  /**
  * Set language preference to English
  */
  englishPreference() {
    this.updateLanguagePreference("en");
  }

  /**
  * Submit language preference update
  * @param {str} tongueCode language preference abbreviation
  */
  updateLanguagePreference(tongueCode) {
    const url = this.data.get("update-url");
    const body = JSON.stringify({tongue_code: tongueCode});
    const fetchWith = window.acima.fetchInit({method: "PUT", body: body});
    const errorMessage = this.data.get("error-message");

    fetch(url, fetchWith).then(function(response) {
      if (!response.ok) {
        show_common_error_dialog(
            errorMessage.replace(/%{error}/, response.statusText)
        );
      }
    });
  }
}
