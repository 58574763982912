// selector for buttons within a section that should be locked/unlocked
const LOCKABLE_BUTTONS_SELECTOR = ".btn:not(.contract-lock-free)";

/**
 * Toggles lockable interface elements on/off, delegates to specific lockable
 * handlers
 *
 * @param {Object} data all data required to render the lockable UI
 * @param {boolean} data.lockedByCurrentViewer
 * @param {element[]} data.lockableSections
 * @param {element[]} data.lockableElements
 * @param {element} data.conditionsTab
 */
export function toggleLockables(data) {
  toggleLockableSections(data.lockableSections, data.lockedByCurrentViewer);
  toggleLockableElements(data.lockableElements, data.lockedByCurrentViewer);
  if (data.conditionsTab) {
    toggleConditionsTabLock(data.conditionsTab, data.lockedByCurrentViewer);
  }
}

/**
 * Toggles lock on an individual lockable element based on whether
 * the current viewer owns the lock or not.
 *
 * @param {element} lockableElement lockable element
 * @param {boolean} lockedByCurrentViewer if the current viewer owns the lock
 */
function toggleLockableElement(lockableElement, lockedByCurrentViewer) {
  if (lockedByCurrentViewer) {
    // show and enable lockable element
    lockableElement.classList.remove("super-hide", "hide", "ng-hide");
    !lockableElement.hasAttribute("data-prevent-enable-toggle") && lockableElement.removeAttribute("disabled");
  } else {
    // hide and disable lockable element
    lockableElement.classList.add("super-hide", "hide");
    lockableElement.setAttribute("disabled", true);
    // If a locked dropdown is open when the lock is released, close it
    // so it doesn't stick around as a phantom dropdown
    if (lockableElement.classList.contains("dropdown-toggle")) {
      if (lockableElement.closest(".btn-group").classList.contains("open")) {
        // bootstrap dropdowns use jquery, sorry
        $(lockableElement).dropdown("toggle");
      }
    }
  }
}

/**
 * Toggles lock on all elements that are marked lockable, yields each
 * element to toggleLockableElement
 *
 * @param {element[]} lockableElements list of lockable elements
 * @param {boolean} lockedByCurrentViewer if the current viewer owns the lock
 */
function toggleLockableElements(lockableElements, lockedByCurrentViewer) {
  lockableElements.forEach((element) => {
    toggleLockableElement(element, lockedByCurrentViewer);
  });
}

/**
 * Toggles lock on all buttons, except those marked with the class
 * "contract-lock-free", within a lockable section, yields each
 * button to toggleLockableElement
 *
 * @param {element[]} lockableSections list of lockable sections
 * @param {boolean} lockedByCurrentViewer if the current viewer owns the lock
 */
function toggleLockableSections(lockableSections, lockedByCurrentViewer) {
  lockableSections.forEach((section) => {
    section.querySelectorAll(LOCKABLE_BUTTONS_SELECTOR).forEach(
        (button) => toggleLockableElement(button, lockedByCurrentViewer)
    );
  });
}

/**
 * Toggle lock on conditions tab with CSS since it is loaded
 * @param {element} conditionsTab conditions tab is loaded async
 * @param {boolean} lockedByCurrentViewer if the current viewer owns the lock
 */
function toggleConditionsTabLock(conditionsTab, lockedByCurrentViewer) {
  if (lockedByCurrentViewer) {
    conditionsTab.dataset.lockedBy = "current";
  } else {
    conditionsTab.dataset.lockedBy = "other";
  }
}
