import {Controller} from "stimulus";
import {alertHelper} from "../../helpers/alert_helpers";

const NOTES_DRAWER_COLLAPSE_CLASS = "notes-drawer-collapsed";
/**
 * Controller used to filter the group contracts list (datatables)
 */
export default class extends Controller {
  static targets = [
    "alertNotice",
    "messageBody",
    "addButton",
  ]

  connect() {
    this.commentableType = this.data.get("commentableType");
    this.commentableId = this.data.get("commentableId");
    this.resourceUrl = this.data.get("resourceUrl");
    this.failureMessage = this.data.get("failureMessage");
    this.emptyNoteMessage = this.data.get("emptyNote");
    this.mainContainer = document.getElementById("main-container");
  }

  toggle() {
    this.messageBodyTarget.value = "";
    this.element.classList.toggle(NOTES_DRAWER_COLLAPSE_CLASS);
    this.mainContainer.classList.toggle("container-lg");
    this.mainContainer.classList.toggle("container-condensed");
  }

  /**
   * Handles error responses and sets notification alert
   *
   * @param {Error} err caught error
   */
  handleError(err) {
    console.error(err);
    alertHelper.error(this.alertNoticeTarget, this.failureMessage);
  }

  /**
   * Handles successful responses and refreshes the page.
   *
   * @param {object} responseJson parsed response json
   */
  handleResponse(responseJson) {
    if (!responseJson.success) {
      throw new Error(responseJson.message);
    }

    this.toggle();
    window.location.reload();
  }

  /**
   * Checks if the message body field has a value
   *
   * @return {boolean} form invalid or not
   */
  invalid() {
    if (this.messageBodyTarget.value.length === 0) {
      alertHelper.error(this.alertNoticeTarget, this.emptyNoteMessage);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Formats the note request body
   *
   * @return {string} request body json string
   */
  requestBody() {
    return JSON.stringify({
      comment: {
        commentable_type: this.commentableType,
        commentable_id: this.commentableId,
        comment: this.messageBodyTarget.value,
      },
    });
  }

  toggleAddNote() {
    if (this.addButtonTarget.hasAttribute("disabled")) {
      this.addButtonTarget.removeAttribute("disabled");
    } else {
      this.addButtonTarget.setAttribute("disabled", "");
    }
  }
  /**
   * Creates a new note
   */
  addNote() {
    if (this.invalid()) {
      return;
    }

    const url = `${this.resourceUrl}.json`;
    const request = {method: "POST", body: this.requestBody()};

    this.toggleAddNote();
    fetch(url, window.acima.fetchInit(request))
        .then((res) => res.json())
        .then((json) => this.handleResponse(json))
        .catch((err) => this.handleError(err))
        .finally(() => this.toggleAddNote());
  }
}
