import {Controller} from "stimulus";
import {
  showSpinnerDialog,
} from "../shared/common/dialogs/spinner_dialog_controller";

/** Update Contract Language handling */
export default class extends Controller {
  static targets = [
    "updateLanguageLink",
  ]

  /**
    * Register the controller and set initial state
    */
  connect() {
    // starting data from view
    this.busyMessage = this.data.get("busyMessage");
    this.contractId = this.data.get("contractId");
    this.languageCode = this.data.get("languageCode");
    this.languageUrl = this.data.get("languageUrl");
  }

  /**
   * Makes the call to update the language and refreshes the page on complete
   */
  updateLanguage = () => {
    const requestBody = {
      lease_id: this.contractId,
      language_preference_code: this.languageCode,
    };
    const request = {method: "PUT", body: JSON.stringify(requestBody)};
    showSpinnerDialog(this.busyMessage);

    fetch(this.languageUrl, window.acima.fetchInit(request))
        .then(location.reload);
  }
}
