import {Controller} from "stimulus";
import {timeHelper} from "../../helpers/time_helper";
import {flash} from "../../shared/common/notices/flash_alerts_controller";
import {toggleLockables} from "./locking/toggle_lockables";
import {renderViewers} from "./locking/render_viewers";
import {renderControls} from "./locking/render_controls";
import {
  showLockRequesterDialog,
  closeLockRequesterDialog,
} from "./locking/lock_requester_dialog_controller";
import {
  showLockOwnerDialog,
  lockOwnerRequestCanceled,
} from "./locking/lock_owner_dialog_controller";
import {
  initLockTimeout,
  clearLockTimeout,
} from "./locking/lock_timeout_controller";
import cable from "../../../channels/helpers/cable";
import
  ContractPresenceChannel
from "../../../channels/contract_presence_channel";
import LockToggleChannel from "../../../channels/lock_toggle_channel";
import LockRequestChannel from "../../../channels/lock_request_channel";

// Viewer events for contract channel
const CURRENT_VIEWER_SUBSCRIBED_EVENT = "subscription_succeeded";
// Lock Request events for contract channel
const LOCK_REQUEST_SUBMITTED_EVENT = "client-editing-request";
const LOCK_REQUEST_RESPONDED_EVENT = "client-editing-response";
const LOCK_REQUEST_CLEARED_EVENT = "client-request-clear";
const LOCK_REQUEST_CHECK_EVENT = "client-any-editing-requests";
// Lock events for lock channel
const LOCK_RELEASED_EVENT = "unlocked";
const LOCK_TAKEN_EVENT = "locked";
// Events for populating viewers
const POPULATE_SUBSCRIBERS_EVENT = "populate_subscribers";
// Represents the default lock as held by the system
const SYSTEM_LOCK = {
  created_at: null, // lock creation timestamp
  expires_at: null, // lock expiration timestamp
  id: null, // id of lock itself
  lease_id: null, // id of contract that lock is tied to
  locked_by_name: "System", // display name of lock owner
  updated_at: null, // lock update timestamp
  user_id: 0, // database id of lock owner
  lockedByCurrentViewer: false, // locked to current viewer
  lockedByOtherViewer: false, // locked by another viewer
};
const EMPTY_REQUEST = {
  requester: {
    info: {
      id: 0,
    },
  },
};
// Number of minutes a lock should expire in
const SESSION_LENGTH_MINUTES = 5;
const GRANTED_DECISION_RESPONSE = "granted";
const DENIED_DECISION_RESPONSE = "denied";

/**
 * Controller used to manage user contract locks and viewer presence
 */
export default class extends Controller {
  static targets = [
    "viewersList",
    "lockableSection",
    "lockableElement",
    "takeLockButton",
    "requestLockButton",
    "releaseLockButton",
    "conditionsTab",
  ]

  /**
   * Sets up data and web socket connections
   */
  connect() {
    // configure instance
    this.contractId = this.data.get("contractId");
    this.currentViewerId = parseInt(this.data.get("currentViewerId"), 10);
    this.contractLockUrl = this.data.get("contractLockUrl");
    this.lock = {...SYSTEM_LOCK, lease_id: this.contractId};
    this.activeRequest = EMPTY_REQUEST;
    this.currentViewers = [];

    this.configureChannel();
    this.bindSubscriberEvents();
  }

  disconnect() {
    this.contractPresenceChannel.perform('unsubscribed');
  }
  /**
   * Configure anycable channel
  */
  configureChannel() {
    this.contractPresenceChannel = new ContractPresenceChannel({
      currentView: "user",
      leaseId: this.contractId,
      userId: this.currentViewerId,
    });
    this.lockToggleChannel = new LockToggleChannel({
      leaseId: this.contractId,
      userId: this.currentViewerId,
    });
    this.lockRequestChannel = new LockRequestChannel({
      leaseId: this.contractId
    });
    [
      this.contractPresenceChannel,
      this.lockToggleChannel,
      this.lockRequestChannel,
    ].map((channel) => cable.subscribe(channel));
  }
  /**
   * Bind web socket channel events
   */
  bindSubscriberEvents() {
    // lock request channel events
    this.lockRequestChannel.on(LOCK_REQUEST_SUBMITTED_EVENT, (data) => {
      this.onLockRequestSubmitted(data);
    });
    this.lockRequestChannel.on(LOCK_REQUEST_RESPONDED_EVENT, (data) =>{
      this.onLockRequestResponded(data)
    });
    this.lockRequestChannel.on(LOCK_REQUEST_CLEARED_EVENT, (data) =>{
      this.onLockRequestCleared(data)
    });
    this.lockRequestChannel.on(LOCK_REQUEST_CHECK_EVENT, () =>{
      this.onLockRequestCheck()
    });
    this.lockToggleChannel.on(CURRENT_VIEWER_SUBSCRIBED_EVENT, (data) => {
      this.onCurrentViewerSubscribed(data);
    });
    // lock channel events
    this.lockToggleChannel.on(LOCK_TAKEN_EVENT, (data) => {
      this.onLockTaken(data);
    });
    this.lockToggleChannel.on(LOCK_RELEASED_EVENT, (data) => {
      this.onLockReleased(data);
    });
    this.contractPresenceChannel.on(POPULATE_SUBSCRIBERS_EVENT, (data) => {
      if (data["users"]) {
        this.currentViewers = data["users"].map((user) => user["user_info"]);
        this.render();
      }
    });
  }

  /**
   * SERVER RESPONSE HANDLERS
   * --------------------------------------------------------------------------
   *
   * These functions handle the responses from the server
   */

  /**
   * Determines whether a lock is present and delegates to lock event handlers
   * accordingly
   *
   * The responseJson may represent a full server lock or may just expose the
   * lock is not present.
   *
   * @param {Object} responseJson response from server after lock check
   * @param {boolean} responseJson.locked whether a lock is present or not
   * @param {number} responseJson.lease_id contract database id for lock
   * @param {?string} responseJson.created_at lock creation timestamp
   * @param {?string} responseJson.expires_at lock expiration timestamp
   * @param {?string} responseJson.id lock database id
   * @param {?string} responseJson.locked_by_name lock owner display name
   * @param {?string} responseJson.updated_at lock updated timestamp
   * @param {?string} responseJson.user_id lock owner user id
   */
  handleCheckLockResponse(responseJson) {
    if (responseJson.locked) {
      this.onLockTaken(responseJson);
    } else {
      this.onLockReleased(responseJson);
    }
  }
  lockButtonPresent() {
    // eslint-disable-next-line max-len
    !!document.querySelector("[data-target='users--contracts--locking.takeLockButton']");
  }

  /**
   * Handles errors that occur during fetch and renders them as a flash message
   *
   * @param {Error} error error thrown and caught by this handler
   */
  handleError(error) {
    if (this.lockButtonPresent()) {
      flash.error(error.message);
    }
  }


  /**
   * EVENT HANDLERS
   * --------------------------------------------------------------------------
   *
   * These functions handle events from the subscribed channels and actions.
   */

  /**
   * Checks for an existing lock and handles the response
   *
   * Delegates to handleCheckLockRequest to determine what to do with the
   * fetch results.
   *
   * This will also check for any active requests so that the new viewer or
   * returning owner can update their active request state.
   */
  onCurrentViewerSubscribed(data) {
    try {
      this.handleCheckLockResponse(data);
      this.lockRequestChannel.perform("trigger", {
        eventName: LOCK_REQUEST_CHECK_EVENT,
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  /**
   * Checks for existing lock requests and retriggers their submission if
   * the current viewer has an outstanding request.
   *
   * Triggered when an absentee owner returns and asks the rest of the viewers
   * if they have any active requests to respond to.
   */
  onLockRequestCheck() {
    const requesterId = this.activeRequest.requester.info.id;

    if (requesterId === this.currentViewerId) {
      this.lockRequestChannel.perform("trigger", {
        eventName: LOCK_REQUEST_SUBMITTED_EVENT,
        request: this.activeRequest,
      });
    }
  }

  /**
   * Clears the active request and clears out the request in the owner dialog.
   *
   * Triggered when a request is cleared via anycable events.
   */
  onLockRequestCleared() {
    this.activeRequest = EMPTY_REQUEST;
    lockOwnerRequestCanceled();
  }

  /**
   * Handles contract lock "unlocked" events
   *
   * This function is called whenever the lock channel alerts us that a lock
   * has been released or when lockCheck() has NOT found an existing lock on
   * page load
   *
   * Sets the current lock object to the lease_id from the server and the
   * system lock default. Then delegates to render() to render the lock
   * interface.
   *
   * @param {Object} data server side representation of the absence of a lock
   * @param {number} data.lease_id contract database id this lock is tied to
   */
  onLockReleased(data) {
    // these guards are necessary until we can convert from global channel
    if (data.lease_id != this.contractId) {
      return;
    }

    this.lock = {
      ...data,
      ...SYSTEM_LOCK,
    };

    closeLockRequesterDialog();
    clearLockTimeout();
    this.render();
  }

  /**
   * Handles contract lock "locked" events
   *
   * This function is called whenever the lock channel alerts us that a lock
   * has been taken or when lockCheck() has found an existing lock on page load
   *
   * Sets the current lock object to the values from the server and this file.
   * Then delegates to render() to render the lock interface.
   *
   * @param {Object} data server side representation of a lock
   * @param {string} data.created_at lock creation timestamp
   * @param {string} data.expires_at lock expiration timestamp
   * @param {string} data.id lock database id
   * @param {string} data.lease_id contract database id this lock is tied to
   * @param {string} data.locked_by_name lock owner display name
   * @param {string} data.updated_at lock updated timestamp
   * @param {string} data.user_id lock owner user id
   */
  onLockTaken(data) {
    // these guards are necessary until we can convert from global channel
    if (data.lease_id != this.contractId) {
      return;
    }

    const lockedByCurrentViewer = data.user_id === this.currentViewerId;

    this.lock = {
      ...data,
      lockedByCurrentViewer: lockedByCurrentViewer,
      lockedByOtherViewer: !lockedByCurrentViewer,
    };
    this.render();

    const endTimeElapsed = timeHelper.convertDateTimeElapsed(
        this.lock.expires_at
    );
    const onExpired = this.expireLock;

    initLockTimeout({
      endTimeElapsed,
      onExpired,
      durationMinutes: SESSION_LENGTH_MINUTES,
    });
  }

  /**
   * Handles contract request response events
   *
   * This function is called whenever the contract channel alerts us that an
   * owner has responded to a lock request. If the request was not made by the
   * current viewer or the request was denied, this function is returned early.
   *
   * If the request does belong to the current viewer and the request was
   * granted this function releases the current lock and takes the lock for
   * the current viewer.
   *
   * @param {Object} data represents an overall request
   * @param {string} data.requester original requester
   * @param {string} data.response response from lock owner
   */
  onLockRequestResponded(data) {
    const otherRequester = data.requester.info.id !== this.currentViewerId;
    const requestDenied = data.response.decision == "denied";
    this.activeRequest = EMPTY_REQUEST;

    if (otherRequester) return;
    if (requestDenied) {
      // Use flash temporarily to alert viewer of request changes
      // This is done in the modals in the legacy lock
      flash.clear();
      flash.error("Your edit request was denied");
      return;
    }
    this.lockToggleChannel.perform("release_lock");
    this.lockToggleChannel.perform("lock");


    // Use flash temporarily to alert viewer of request changes
    // This is done in the modals in the legacy lock
    flash.clear();
    flash.success("Your edit request was granted");
  }

  /**
   * Handles contract request submitted events
   *
   * This function is called whenever the contract channel alerts us that a
   * viewer has requested the lock from the owner. If the current viewer is not
   * the owner, the lock request is set and no further action is taken.
   *
   * If the current viewer is the owner, launches the owner dialog to respond
   * to the request.
   *
   * @param {Object} request represents an overall request
   */
  onLockRequestSubmitted(request) {
    const requestInProgress = this.activeRequest.requester.info.id !== 0;

    if (requestInProgress) return;
    this.activeRequest = request;

    if (this.lock.lockedByCurrentViewer) {
      this.showOwnerDialog(request);
    }
  }

  /**
   * ACTIONS
   * --------------------------------------------------------------------------
   *
   * These functions are triggered by the UI and internally in order to update
   * and mutate the state of the lock controller.
   */


  /**
   * Clears the current active request and alerts other viewers to do the same.
   */
  clearRequest = () => {
    this.activeRequest = EMPTY_REQUEST;
    this.lockRequestChannel.perform("trigger", {
      eventName: LOCK_REQUEST_CLEARED_EVENT,
    });
  }

  /**
   * Denies the current request for the lock. Triggered by the lock owner
   * dialog.
   *
   * Allows the owner to respond to a request with a denial by publishing
   * a response with denial decision. The requester will be notified when
   * the onLockRequestResponded event handler is triggered.
   *
   * @param {Object} data payload
   * @param {string} data.message message submitted by owner
   */
  denyRequest = ({message}) => {
    this.activeRequest = {
      ...this.activeRequest,
      response: {
        decision: DENIED_DECISION_RESPONSE,
        message: message,
        responder: this.viewer(),
      },
    };

    this.lockRequestChannel.perform("trigger", {
      eventName: LOCK_REQUEST_RESPONDED_EVENT,
      request: this.activeRequest,
    });
    this.clearRequest();
    // Use flash temporarily to alert viewer of request changes
    // This is done in the modals in the legacy lock
    flash.clear();
    flash.error("You denied the edit request.");
  }

  /**
   * Automatically grants the request when the timer reaches zero.
   *
   * Sets a default granted response and triggers the request responded event
   * to update all other viewers.
   *
   * If the owner is absent, the current viewer is the requester and has to
   * force their own update handling because clients don't listen to their
   * own triggered events.
   *
   * @param {boolean} absentOwner to indicate the owner is no longer a viewer
   */
  expireRequest = (absentOwner = false) => {
    this.activeRequest = {
      ...this.activeRequest,
      response: {
        decision: GRANTED_DECISION_RESPONSE,
        message: "",
        responder: this.viewer(),
      },
    };

    this.lockRequestChannel.perform("trigger", {
      eventName: LOCK_REQUEST_RESPONDED_EVENT,
      request: this.activeRequest,
    });

    // If the owner is absent this is being triggered by the requester and they
    // need to manually handle the event to be updated
    if (absentOwner) {
      this.onLockRequestResponded(this.activeRequest);
    }

    this.clearRequest();
    // Use flash temporarily to alert viewer of request changes
    // This is done in the modals in the legacy lock
    flash.clear();
    flash.success("The request was granted when the timer reached zero.");
  }

  /**
   * This guards request expiration in the event that the owner of the lock
   * is currently absent. If so, the requester will trigger expiration.
   *
   */
  absentOwnerExpireRequest = () => {
    const requesterId = this.activeRequest.requester.info.id;
    const otherRequester = requesterId !== this.currentViewerId;

    if (this.ownerIsPresent() || otherRequester) {
      return;
    }

    this.expireRequest(true);
  }

  /**
   * Automatically releases the lock when it expires.
   *
   * This will make a call to destroy the lock when the ownership has expired
   * which will trigger the lock released event.
   */
  expireLock = () => {
    const url = `${this.contractLockUrl}.json`;
    const request = {method: "DELETE"};

    if (this.lock.lockedByCurrentViewer) {
      // Use flash temporarily to alert viewer of lock changes
      // This is done in the modals in the legacy lock
      flash.clear();
      flash.error("Your contract lock has expired.");
    }

    this.lockToggleChannel.perform("release_lock");
  }

  /**
   * Grants the current request for the lock. Triggered by the lock owner
   * dialog.
   *
   * Allows the owner to respond to a request with a grant by publishing
   * a response with granted decision. The requester will be notified when
   * the onLockRequestResponded event handler is triggered.
   *
   * @param {Object} data payload
   * @param {string} data.message message submitted by owner
   */
  grantRequest = ({message}) => {
    this.activeRequest = {
      ...this.activeRequest,
      response: {
        decision: GRANTED_DECISION_RESPONSE,
        message: message,
        responder: this.viewer(),
      },
    };
    this.lockRequestChannel.perform("trigger", {
      eventName: LOCK_REQUEST_RESPONDED_EVENT,
      request: this.activeRequest
    });
    this.clearRequest();

    if (this.lock.lockedByCurrentViewer) {
      // Use flash temporarily to alert viewer of request changes
      // This is done in the modals in the legacy lock
      flash.clear();
      flash.success("You granted the edit request.");
    }
  }

  /**
   * Allows current viewer to release the lock they hold
   *
   * If the lock is not held by the current viewer, this will return as a no-op.
   *
   */
  releaseLock() {
    if (!this.lock.lockedByCurrentViewer) {
      return;
    }

    this.lockToggleChannel.perform("release_lock");
  }

  /**
   * Allows the current viewer to request the lock from the current owner by
   * submitting a lock request event which will trigger a dialogue for the
   * owner.
   *
   * This is triggered as a callback from the lock requester dialog.
   *
   * @param {Object} newRequest base request data from dialog
   */
  requestLock = (newRequest) => {
    this.activeRequest = {
      ...newRequest,
      response: {
        responder: {},
        message: "",
        decision: "",
      },
    };

    this.lockRequestChannel.perform("trigger", {
      eventName: LOCK_REQUEST_SUBMITTED_EVENT,
      request: this.activeRequest,
    });
  }

  /**
   * Allows current viewer to take the lock from the system
   *
   * If the current contract is already locked by the current viewer or another
   * viewer this will return as a no-op.
   *
   */
  takeLock() {
    if (this.lock.lockedByCurrentViewer || this.lock.lockedByOtherViewer) {
      return;
    }
    this.lockToggleChannel.perform("lock");
  }

  /**
   * Shows the lock owner dialog and passes it the request and actions to
   * perform when the owner triggers a response.
   *
   * @param {Object} request request for lock made by another viewer
   */
  showOwnerDialog(request) {
    showLockOwnerDialog(
        {
          request: request,
          onRequestExpired: this.expireRequest,
          onDenyRequest: this.denyRequest,
          onGrantRequest: this.grantRequest,
        }
    );
  }

  /**
   * Launches the lock requester dialog which allows current viewer to request
   * the lock if it is owned by another viewer
   */
  showRequestDialog() {
    if (!this.lock.lockedByOtherViewer) {
      return;
    }

    showLockRequesterDialog({
      ownerName: this.lock.locked_by_name,
      requester: this.viewer(),
      onRequestExpired: this.absentOwnerExpireRequest,
      onRequestLock: this.requestLock,
      onCancelRequest: this.clearRequest,
      activeRequest: this.activeRequest,
      currentViewerId: this.currentViewerId,
    });
  }

  /**
   * RENDERERS
   * --------------------------------------------------------------------------
   *
   * These functions render the lock interface
   */

  /**
   * Render the contract locking interface
   *
   * Delegates to external rendering services in order to update the UI based
   * on lock ownership state.
   */
  render() {
    if (!this.lockButtonPresent()) {
      renderViewers(
        {
          viewers: this.viewers(),
          viewersList: this.viewersListTarget,
          lockOwnerId: this.lock.user_id,
        }
      );
      toggleLockables(
        {
          lockedByCurrentViewer: this.lock.lockedByCurrentViewer,
          lockableElements: this.lockableElementTargets,
          lockableSections: this.lockableSectionTargets,
          conditionsTab:
            this.hasConditionsTabTarget ? this.conditionsTabTarget : null,
        }
      );
      renderControls(
        {
          lockedByCurrentViewer: this.lock.lockedByCurrentViewer,
          lockedByOtherViewer: this.lock.lockedByOtherViewer,
          takeLockButton: this.takeLockButtonTarget,
          releaseLockButton: this.releaseLockButtonTarget,
          requestLockButton: this.requestLockButtonTarget,
          hasEditPermissions: this.data.get("hasEditPermissions"),
        }
      );
    }
  }
  /**
   * Returns a list of viewers
   *
   * If the lock owner is absent, this will append the absent owner to the list
   * of viewers.
   *
   * @return {Object[]} list of current viewers, possibly including absent owner
   */
  viewers() {
    if (this.ownerIsPresent()) {
      return this.currentViewers;
    } else {
      return [
        ...this.currentViewers,
        this.fakeOwner(),
      ];
    }
  }

  /**
   * Returns true if either the owner is present or the owner is the system.
   *
   * @return {boolean} whether the lock owner is present
   */
  ownerIsPresent() {
    const viewerIds = this.currentViewers.map((viewer) => viewer.id);
    const ownerId = this.lock.user_id;

    return viewerIds.includes(ownerId) || ownerId === 0;
  }

  /**
   * Builds a fake owner from data in the lock so that an absent owner can
   * be rendered for other viewers.
   *
   * @return {Object} representation of an absent owner
   */
  fakeOwner() {
    return {
      absent: true,
      chat_url: this.lock.owner_chat_url,
      department: this.lock.owner_department,
      id: this.lock.user_id,
      image: this.lock.owner_image,
      name: this.lock.locked_by_name,
      type: "user",
    };
  }

  viewer(){
    if (this.currentViewers.length > 0){
      return {
        info: this.currentViewers.filter((viewer) => {
          return viewer.id === this.currentViewerId;
        })[0],
      };
    } else {
      return {}
    }
  }
}
