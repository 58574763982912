import {Controller} from "stimulus";
import {publish, subscribe} from "../../helpers/pub_sub";

const EVENT_NAME = "showAssignedContractsDialogEvent";

/**
 * Publishes the show dialog event
 *
 * @param {number} textMessageId mp ID for text message to assign to
 */
export function showAssignedContractsDialog(textMessageId) {
  publish(EVENT_NAME, {textMessageId});
}

/** Email Details dialog */
export default class extends Controller {
  static targets = [
    "contractNumber",
  ]

  /**
   * Register the HTML dialog
   */
  connect() {
    this.resourceId = null;
    this.baseResourceUrl = this.data.get("baseResourceUrl");
    this.subResourcePath = this.data.get("subResourcePath");
    dialogPolyfill.registerDialog(this.element);
    subscribe(EVENT_NAME, this.showDialog);
  }

  /**
   * Handles hard errors in fetch
   *
   * @param {Error} err
   */
  handleError(err) {
    console.error("Error: ", err);
    this.closeDialog();
    location.reload();
  }

  /**
   * Handles success and failure response in fetch
   *
   * @param {Object} responseJson parsed response body json containing markup
   */
  handleResponse(responseJson) {
    this.closeDialog();
    location.reload();
  }

  /**
   * Assign the contract number to the text message
   */
  assignContract() {
    const url = [
      this.baseResourceUrl,
      this.resourceId,
      this.subResourcePath,
    ].join("/");
    const requestBody = {contract_number: this.contractNumberTarget.value};
    const request = {method: "PATCH", body: JSON.stringify(requestBody)};

    fetch(url, window.acima.fetchInit(request))
        .then((res) => res.json())
        .then((json) => this.handleResponse(json))
        .catch((err) => this.handleError(err));
  }

  /**
   * Close the dialog and reset body markup to spinner
   */
  closeDialog() {
    this.element.close();
  }

  /**
   * Trigger data fetch and show dialog
   *
   * @param {Object} data
   * @param {number} data.textMessageId text message ID to assign
   */
  showDialog = ({textMessageId}) => {
    this.resourceId = textMessageId;
    this.element.showModal();
  }
}
