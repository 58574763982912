import { Controller } from "stimulus";

/** Alternate Conditions dialog launcher */
export default class extends Controller {

  /**
   * Launch the dialog
   */
  launchDialog() {
    const modalController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("#alternate-conditions-dialog"),
      "merchants--alternate-condition"
    );
    modalController.launchDialog();
  }
}
