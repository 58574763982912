import { Controller } from "stimulus";

/**
 * Handles the edit and verify applicant views in the lease redesign.
 */

export default class extends Controller {
  static targets = [
    "applicantContainer",
    "bundleSelect",
    "saveButton",
    "applicantForm",
    "addressForm",
    "payFrequency",
    "altPhone",
    "mainPhone",
  ]

  launchAdditionalVerificationModals() {
    const bundled = this.bundleChanged();

    if (bundled) {
      this.launchModal("bundle-dialog");
    } else if (this.mainPhoneChanged()) {
      this.launchModal("phone-number-verification-dialog");
    } else {
      this.requestFormSubmit();
    }
  }

  launchModal(elementID) {
    const element = document.querySelector(`#${elementID}`);
    const modalController = this.application.getControllerForElementAndIdentifier(
      element,
      `users--contracts--actions--${elementID}`
    );
    modalController.launchDialog();
  }

  mainPhoneChanged() {
    if (!this.hasMainPhoneTarget) {
      return false;
    }

    const currentNumber = this.mainPhoneTarget.dataset["currentNumber"];
    const mainPhoneNumber = this.mainPhoneTarget.value.replace(/\D/g, "").slice(0,10);

    if (currentNumber == mainPhoneNumber) {
      return false;
    } else {
      return true;
    }
  }

  bundleChanged() {
    const bundleSelect = this.bundleSelectTarget;
    const validPayFrequency = this.validatePayFrequency();

    if (!validPayFrequency) {
      return;
    }

    if (bundleSelect.options[bundleSelect.options.selectedIndex].text == "Yes") {
      return true;
    } else {
      return false;
    }
  }

  async requestFormSubmit(caller) {
    if (caller == "bundle-dialog" && this.mainPhoneChanged()) {
      this.launchModal("phone-number-verification-dialog");
    } else {
      let addressForm = this.hasAddressFormTarget
      let response = await this.submitForm(this.applicantFormTarget);
      if (response.status == 'success' && addressForm) {
        await this.submitForm(this.addressFormTarget);
      }
     window.location.reload();
    }
  }

  async submitForm(target) {
    try {
      const response = await fetch(target.action, {
        method: target.method,
        body: new FormData(target)
      });
      if (response.ok) {
        return await response.json();
      }
    } catch(error) {
      console.error(`Failed to update applicant: ${error}`);
      return null;
    }
  }

  applicantDetailsFormHtml = "";

  requestApplicantEdit() {
    this.applicantDetailsFormHtml = this.applicantContainerTarget.innerHTML;

    const url = this.applicantContainerTarget.dataset["applicantEditUrl"];
    const container = this.applicantContainerTarget;

    this.getThenRenderForm(url, container);
  }

  requestApplicantVerify() {
    this.applicantDetailsFormHtml = this.applicantContainerTarget.innerHTML;

    const url = this.applicantContainerTarget.dataset["applicantVerifyUrl"];
    const container = this.applicantContainerTarget;

    this.getThenRenderForm(url, container);
  }

  getThenRenderForm(url, container) {
    const request = { method: "GET" };

    fetch(url, window.acima.fetchInit(request))
      .then(response => response.text())
      .then(text => container.innerHTML = text)
      .catch(err => console.error(err));
  }

  cancel() {
    this.applicantContainerTarget.innerHTML = this.applicantDetailsFormHtml;
  }

  setPayFieldInlineValidation(valid) {
    if (valid) {
      this.payFrequencyTarget.closest("tr").classList.remove("has-error");
      this.saveButtonTarget.removeAttribute("disabled");
    } else {
      this.payFrequencyTarget.closest("tr").classList.add("has-error");
      this.saveButtonTarget.setAttribute("disabled", "");
    }

    return valid;
  }

  validatePayFrequency() {
    const payFrequency = this.payFrequencyTarget.value;

    if (payFrequency == "") {
      return;
    }

    const valid = this.frequencyRange(payFrequency);

    return this.setPayFieldInlineValidation(valid);
  }

  formatMainPhone() {
    const number = this.mainPhoneTarget.value;
    this.mainPhoneTarget.value = this.formatNumber(number);
  }

  formatAltPhone() {
    const number = this.altPhoneTarget.value;
    this.altPhoneTarget.value = this.formatNumber(number);
  }

  formatNumber(number) {
    number = number.replace(/\D/g, "").slice(0, 10);
    return number = number.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }

  frequencyRange(payFrequency) {
    const rangeData = JSON.parse(
      this.payFrequencyTarget.getAttribute("data-separation-range")
    );

    return rangeData[payFrequency];
  };
}
