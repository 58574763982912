import {Controller} from "stimulus";

export default class extends Controller {
  show(e) {
    e.preventDefault();
    const personId = this.data.get("person-id");
    const phoneNumber = this.data.get("phone-number");
    const textMessages = JSON.parse(this.data.get("text-messages"));
    TextMessageModal.show(phoneNumber, personId, textMessages);
  }
}
