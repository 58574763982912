import { Controller } from "stimulus";
import WorkflowUpdatedChannel
  from "~/channels/invoice_adjustments/workflow_updated_channel";
import cable from "~/channels/helpers/cable";

export default class extends Controller {
  connect() {
    const dataset = this.element.dataset;
    this.channel = new WorkflowUpdatedChannel({
      contract_guid: dataset.contractGuid,
      adjustment_request_guid: dataset.adjustmentRequestGuid
    });
    cable.subscribe(this.channel).ensureSubscribed();
    this.channel.on("message", msg => window.location.reload());
  }

  disconnect() {
    if (this.channel) {
      cable.unsubscribe(this.channel);
    }
  }
}

