import { Controller } from "stimulus";

/**
 * Handle Refund Payment breakdown modal
 */
export default class extends Controller {
  static targets = [
    "modal",
  ];

  initialize(){
    const existingFailedTransactions = this.data.element.getAttribute("data-existing-failed-transactions");
    const isPuertoRicanLease = this.data.element.getAttribute("data-puerto-rican-lease");

    if (existingFailedTransactions >  0 && isPuertoRicanLease == "true"){
      this.openModal();
    }
  }

  /** Opens modal */
  openModal() {
    this.modalTarget.classList.remove("hidden");
  }

  /** Closes modal */
  closeModal() {
    this.modalTarget.classList.add("hidden");
  }
}
