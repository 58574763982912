import {Controller} from "stimulus";
import {publish, subscribe} from "../../../helpers/pub_sub";
import {timeHelper} from "../../../helpers/time_helper";
import {
  initRequestTimeout,
  clearRequestTimeout,
} from "./request_timeout_controller";

const SHOW_EVENT_NAME = "showLockRequesterDialogEvent";
const CLOSE_EVENT_NAME = "closeLockRequesterDialogEvent";
// Number of minutes a request should expire in
export const REQUEST_TIMEOUT_MINUTES = 3;


/**
 * Dispatches the showLockRequesterDialogEvent event
 *
 * This is the public interface into showing the lock request dialog
 *
 * @param {Object} dialogData - Payload object for dialog
 */
export function showLockRequesterDialog(dialogData) {
  publish(SHOW_EVENT_NAME, dialogData);
}

/**
 * Dispatches the closeLockRequesterDialogEvent event
 *
 * This is the public interface into closing the lock request dialog
 */
export function closeLockRequesterDialog() {
  publish(CLOSE_EVENT_NAME, {});
}

/**
 * Manages request lock dialog
 * */
export default class extends Controller {
  static targets = [
    "requestMessage",
    "requestSummary",
    "submitButton",
    "cancelButton",
    "requestTimeoutDisplay",
  ];

  /**
   * connect controller,register dialog and subscribe to publishing
   */
  connect() {
    dialogPolyfill.registerDialog(this.element);
    subscribe(SHOW_EVENT_NAME, this.showDialog);
    subscribe(CLOSE_EVENT_NAME, this.closeDialog);
    this.onRequestLock = null;
    this.onRequestExpired = null;
  }

  /**
   * Submits the onRequestLock callback with the message value. The locking
   * controller handles the actual request for the lock.
   *
   * This triggers when the viewer submits the request dialog.
   */
  requestLock() {
    const newRequest = {
      requester: this.requester,
      message: this.requestMessageTarget.value,
      timeout: timeHelper.calculateTimeoutElapsed(REQUEST_TIMEOUT_MINUTES),
    };
    this.activeRequest = {
      ...this.activeRequest,
      ...newRequest,
    };

    this.onRequestLock(newRequest);
    this.renderRequestTimeout();
    this.closeDialog();
  }

  /**
   * Triggers onRequestExpired callback, clears the timeout and closes
   * the dialog.
   *
   * This triggers when the request expires without action from the owner.
   */
  requestExpired = () => {
    clearRequestTimeout();
    this.onRequestExpired();
    this.closeDialog();
  }

  /**
   * Cancels an existing request. Submits the onCancelRequest callback and
   * closes the dialog.
   *
   * This triggers when the viewer reopens the dialog and clicks cancel on
   * their own request.
   */
  cancelRequest() {
    clearRequestTimeout();
    this.onCancelRequest();
    this.closeDialog();
  }

  /**
   * Closes out the dialog and clears local data
   */
  closeDialog = () => {
    this.onRequestLock = null;
    this.requestMessageTarget.value = "";
    this.requestSummaryTarget.innerHTML = "";
    if (this.element.open) {
      this.element.close();
    }
  }

  /**
   * Shows the dialog and sets up local data for potential request submission
   *
   * @param {Object} data payload to make request
   * @param {string} data.ownerName name of current lock owner
   * @param {Function} data.onRequestLock callback to run on submission
   * @param {Function} data.onCancelRequest callback to run on cancel
   * @param {Function} data.onRequestExpired callback to run on expire
   * @param {Object} data.activeRequest current state of request
   * @param {Object} data.requester current viewer
   */
  showDialog = (data) => {
    this.ownerName = data.ownerName;
    this.onRequestLock = data.onRequestLock;
    this.onCancelRequest = data.onCancelRequest;
    this.onRequestExpired = data.onRequestExpired;

    this.activeRequest = data.activeRequest;
    this.currentViewerId = data.requester.info.id;
    this.requester = data.requester;

    this.updateDisplay();
    this.renderRequestTimeout();
    if (!this.element.open) {
      this.element.showModal();
    }
  }

  /**
   * This updates the dialog according to the current state of requests.
   *
   * If the current viewer has an active request, it delegates to
   * renderRequestInProgress
   *
   * If there is an active request by another user, it delegates to
   * renderOtherRequestInProgress
   *
   * If no active request exists it delegates to renderRequestSubmission.
   */
  updateDisplay() {
    const requesterId = this.activeRequest.requester.info.id;

    if (requesterId === this.currentViewerId) {
      this.renderRequestInProgress();
    } else if (requesterId !== 0) {
      this.renderOtherRequestInProgress();
    } else {
      this.renderRequestSubmission();
    }
  }

  /**
   * Renders the modal when the current viewer has an active request in
   * progress
   *
   * Hides the submit button and shows the cancel button. Hides the message
   * field. Displays a message that a request has already been submitted.
   */
  renderRequestInProgress() {
    const inProgressText = this.data.get("inProgressText");
    this.renderSummary(inProgressText, this.ownerName);
    this.submitButtonTarget.classList.add("super-hide");
    this.submitButtonTarget.setAttribute("disabled", "");
    this.cancelButtonTarget.classList.remove("super-hide");
    this.cancelButtonTarget.removeAttribute("disabled");
    this.requestMessageTarget.classList.add("super-hide");
  }

  /**
   * Renders the modal when another viewer has an active request in
   * progress
   *
   * Hides the submit button and the cancel button. Hides the message
   * field. Displays a message that another user has made a request.
   */
  renderOtherRequestInProgress() {
    // hide submit and cancelation buttons
    const otherInProgressText = this.data.get("otherInProgressText");
    this.renderSummary(otherInProgressText, this.ownerName);
    this.submitButtonTarget.classList.add("super-hide");
    this.submitButtonTarget.setAttribute("disabled", "");
    this.cancelButtonTarget.classList.add("super-hide");
    this.cancelButtonTarget.setAttribute("disabled", "");
    this.requestMessageTarget.classList.add("super-hide");
  }

  /**
   * Renders the modal when there are no active requests.
   *
   * Shows the submit button and hides the cancel button. Shows the message
   * field. Displays a message summarizing the request action.
   */
  renderRequestSubmission() {
    const requestText = this.data.get("requestText");
    this.renderSummary(requestText, this.ownerName);
    this.submitButtonTarget.classList.remove("super-hide");
    this.submitButtonTarget.removeAttribute("disabled");
    this.cancelButtonTarget.classList.add("super-hide");
    this.cancelButtonTarget.setAttribute("disabled", "");
    this.requestMessageTarget.classList.remove("super-hide");
  }

  /**
   * Renders string in UI summarizing action to be taken
   *
   * @param {string} prefixText message to prefix to owner name
   * @param {string} ownerName name of current owner
   */
  renderSummary(prefixText, ownerName) {
    this.requestSummaryTarget.innerHTML =`${prefixText} ${ownerName}`;
  }

  /**
   * Renders the countdown timer for a request
   *
   * If an active request is in progress then this inits the request timeout
   * and delegates to the timeout controller for managing the display of the
   * clock.
   *
   * If there is no active request, clears any existing timeout and hides the
   * timeout display.
   */
  renderRequestTimeout() {
    const requesterId = this.activeRequest.requester.info.id;

    if (requesterId !== 0) {
      this.requestTimeoutDisplayTarget.classList.remove("super-hide");
      initRequestTimeout({
        endTimeElapsed: this.activeRequest.timeout,
        onExpired: this.requestExpired,
      });
    } else {
      this.requestTimeoutDisplayTarget.classList.add("super-hide");
      clearRequestTimeout();
    }
  }
}
