import {Controller} from "stimulus";

// controls merchant grade defaults interface
export default class extends Controller {
  static targets = [
    "categories",
    "gradeDefaultsList",
  ];

  get categoriesSelector() {
    return this.categoriesTarget;
  }

  selectCategory() {
    const id = this.categoriesSelector.value;
    // refresh the page if all is selected
    if (!id) {
      window.location.reload();
      return;
    }

    const url = this.data.get("resourceUrl").replace(/\d+/, id);
    const fetchWith = window.acima.fetchInit({
      method: "GET",
      credentials: "include",
    });
    fetch(url, fetchWith)
        .then((response) => response.text())
        .then((text) => this.showGradeDefaults(text));
  }

  showGradeDefaults(html) {
    this.gradeDefaultsListTarget.innerHTML = html;
  }

  setHiddenValueFromCheckbox(event) {
    const checkBox = event.currentTarget;
    const persistedValue = checkBox.dataset.persistedValue;
    const checked = checkBox.checked;
    const hiddenField = document.getElementById(checkBox.dataset.cbname);

    hiddenField.value = checked;

    if (checked.toString() === persistedValue) {
      checkBox.parentElement.classList.remove("boolean-changed");
    } else {
      checkBox.parentElement.classList.add("boolean-changed");
    }
  }
}
