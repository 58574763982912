import { Controller } from "stimulus";
import {
  showConfirmDialog
} from "../shared/common/dialogs/confirm_dialog_controller";

export default class extends Controller {
  static target = [
    "submitBtn"
  ]
  /**
   * Sets up and shows the dialog
   */
  showDialog() {
    showConfirmDialog({
      title: this.data.get("title"),
      message: this.data.get("message"),
      cancelText: this.data.get("goBack"),
      confirmText: this.data.get("continue"),
      action: this.returnToDashboard
    });
  }

  /**
   * Redirects merchant user back to their dashboard
   */
  returnToDashboard = () => {
    window.location.href = this.data.get("url");
  }
}
