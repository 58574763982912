import {Controller} from "stimulus";

/**
 * Show and hide the check number field
 * and enable or disable the process credit button
 * based on radio button selection.
 */
export default class extends Controller {
  static targets = [
    "checkField",
    "achRadioButton",
    "wireRadioButton",
    "checkRadioButton",
    "processCreditButton",
  ]

  /**
   * If only the check radio button is rendered,
   * show the checkField
   */
  connect() {
    if (this.checkRadioButtonTarget.checked) {
      this.showFieldValidateButton();
    }
    this.setProcessCreditButtonState();
  }

  /**
   * Show the check text field and enables
   * or disables the process credit button.
   */
  showFieldValidateButton() {
    this.checkFieldTarget.classList.remove("hidden");
    this.setProcessCreditButtonState();
  }

  /**
   * Hides the check text field and enables
   * or disables the process credit button.
   */
  hideFieldValidateButton() {
    this.checkFieldTarget.classList.add("hidden");
    this.setProcessCreditButtonState();
  }

  /**
   * Checks if the ACH radio button exists
   * and whether it is checked.
   *
   * @return {bool}
   */
  validateACHRadioState() {
    if (this.hasAchRadioButtonTarget) {
      if (this.achRadioButtonTarget.checked) {
        return true;
      }
    }
  }

  /**
   * Checks if the Wire radio button exists
   * and whether it is checked.
   *
   * @return {bool}
   */
  validateWireRadioState() {
    if (this.hasWireRadioButtonTarget) {
      if (this.wireRadioButtonTarget.checked) {
        return true;
      }
    }
  }

  /**
   * Checks if the Check radio button exists
   * and whether it is checked.
   *
   * @return {bool}
   */
  validateCheckRadioState() {
    if (this.hasCheckRadioButtonTarget) {
      if (this.checkRadioButtonTarget.checked) {
        return true;
      }
    }
  }

  /**
   * Validates the state of each radio button to validate
   * if they exist and if any of them are checked.
   *
   * @return {bool}
   */
  validateRadioButtonFormGroup() {
    return this.validateACHRadioState() ||
           this.validateWireRadioState() ||
           this.validateCheckRadioState();
  }

  /**
   * Disables the Process Credit button until one of the transfer type
   * radio buttons is selected.
   */
  setProcessCreditButtonState() {
    var button = this.processCreditButtonTarget;
    if (this.validateRadioButtonFormGroup()) {
      button.classList.remove("disabled");
    } else {
      button.classList.add("disabled");
    }
  }
}
