import {Controller} from "stimulus";

/**
 * Manages SSN verification dialog for lease redesign.
 */
export default class extends Controller {
  static targets = [
    "resultMessage",
    "ssn",
  ];

  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  /**
   * Sets relevant person ID and opens dialog.
   * @param {int} personId Relevant applicant or customer ID
   * @param {string} personType Applicant or customer
   */
  launchDialog(personId, personType) {
    this.personId = personId;
    this.personType = personType;

    this.element.showModal();
  }

  /**
   * The meat and potatoes.
   */
  verify() {
    this.respondVerifying();
    this.callToVerify();
    this.maskInput();
  }

  /**
   * Fetches SSN verification and displays result.
   */
  callToVerify() {
    var controller = this;
    var params = new URLSearchParams(
      {
        person_id: this.personId,
        person_type: this.personType,
        user_input: this.ssnTarget.value,
      }
    );
    var fetchWith = window.acima.fetchInit({method: "GET"});

    fetch(`${this.data.get("url")}?${params}`, fetchWith)
      .then((response) => response.json())
      .then(function(response) {
        JSON.stringify(response);
        if (response.verified === true) {
          controller.respondSuccess(response.message);
        } else {
          controller.respondFailure(response.message);
        }
      })
      .catch(function(error) {
        controller.respondFailure(controller.errorMessage(error.message));
      });
  }

  /**
   * Resets result and clears SSN entry.
   */
  clearDialog() {
    this.clearResult();
    this.ssnTarget.value = "";
  }

  /**
   * Resets all result-based styling.
   */
  clearResult() {
    this.resultMessageTarget.classList.remove("alert-danger", "alert-success", "verifying");
    this.setResultMessage("");
  }

  /**
   * Closes and cleans up dialog for future use. Person ID and type must be
   * cleared so they can be reinjected correctly in a different
   * context.
   */
  closeDialog() {
    this.clearDialog();
    this.personId = null;
    this.personType = null;
    this.element.close();
  }

  /**
   * Interpolates a particular error message into an error template.
   * @param {string} message Error message.
   */
  errorMessage(message) {
    this.data.get("error-message").replace(/%{message}/, message);
  }

  /**
   * Masks SSN.
   */
  maskInput() {
    const mask = this.isCanadianLease() ? "XXX-XXX" : "XXX-XX";
    const regex = this.isCanadianLease() ? /^.{7}/ : /^.{6}/;

    this.ssnTarget.value = this.ssnTarget.value.replace(regex, mask);
  }

  /**
   * Sets styles for a failed verification.
   * @param {string} failureMessage Failure message.
   */
  respondFailure(failureMessage) {
    this.clearResult();
    this.resultMessageTarget.classList.add("alert-danger");
    this.resultMessageTarget.classList.remove("alert-success");
    this.setResultMessage(failureMessage);
  }

  /**
   * Sets styles for a successful verification.
   * @param {string} successMessage Success message.
   */
  respondSuccess(successMessage) {
    this.clearResult();
    this.resultMessageTarget.classList.add("alert-success");
    this.resultMessageTarget.classList.remove("alert-danger");
    this.setResultMessage(successMessage);
  }

  /**
   * Sets styles for a processing verification.
   */
  respondVerifying() {
    this.clearResult();
    this.element.classList.add("verifying");
    this.setResultMessage(this.data.get("verifying-message"));
  }

  /**
   * Displays the message related to the verification result.
   * @param {string} message Result message.
   */
  setResultMessage(message) {
    this.resultMessageTarget.textContent = message;
  }

  /**
   * Checks if current Lease is Canadian.
   * @return {boolean} true/false value
   */
  isCanadianLease() {
    let boolString = this.data.get("canadian-lease");

    return boolString.toLowerCase() === "true";
  }
}
