import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modalContainer"];
  static values = { show: Boolean };

  connect() {
    if(this.data.get("showValue") === "true") this.showModal();
  }

  showModal() {
    const endpoint = `/merchants/compliance_training_modal`;
    const fetchWith = window.acima.fetchInit({ method: "GET" });

    // fetch the modal HTML, put it in the DOM, then show it
    fetch(endpoint, fetchWith)
      .then(response => response.text())
      .then(html => this.replaceModalContainer(html))
      .then(() => $("#compliance-training-modal").modal('show'));
  }

  hideModal() {
    // remove the modal from the DOM
    this.replaceModalContainer("<div></div>")
  }

  replaceModalContainer(html) {
    const fragment = document.createRange().createContextualFragment(html);

    this.modalContainerTarget.replaceChildren(fragment);
  }
}
