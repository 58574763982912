import { Controller } from "stimulus";

/**
 * Handles Prequal application requests
 */

export default class extends Controller {
  static targets = [
    "applicationSubmitButton",
    "applicationRequestBody",
    "applicationResponseBody",
    "applicationResponseSection"
  ];

  submitApplicationRequest() {
    const body = this.applicationRequestBodyTarget.value;
    const request = window.acima.fetchInit(
      {
        method: "POST",
        body: JSON.stringify(body)
      }
    );
    const url = this.applicationSubmitButtonTarget.dataset.url;

    fetch(url, request)
      .then((response) => response.json())
      .then((jsonResponse) => this.handleResponse(jsonResponse))
      .catch(error => console.error(error));
  }

  handleResponse(jsonResponse) {
    const response = JSON.stringify(jsonResponse, null, 2);

    if (jsonResponse.result.code == "success") {
      // set text color to green
      this.applicationResponseBodyTarget.style.color = "#3fbc44";
    } else {
      // set text color to red
      this.applicationResponseBodyTarget.style.color = "#f65252";
    }

    this.applicationResponseSectionTarget.hidden = false;
    this.applicationResponseBodyTarget.innerHTML = response;
  }
}
