export const valueIsPresent = (value) => {
  return value !== "";
};

export const isValidNumber = (value) => {
  const number = Number(value);
  return !isNaN(number)
};

export const isNumberGreaterThan = (limit, value) => {
  return Number(value) > limit;
};

export const isNumberLessThanOrEqualTo = (limit, value) => {
  return Number(value) <= limit;
};

export const numberHasDecimalPlaces = (value, decimalPlaces) => {
  const regex = new RegExp(`^\\d+(\\.\\d{${decimalPlaces}})?$`);
  return regex.test(value);
};
