import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "locationId",
    "fromDate",
    "toDate",
    "applicationCount",
    "approvedCount",
    "deniedCount",
    "approvalPercentage",
    "avgApprovalAmt",
    "fundingCount",
    "fundedAmount",
    "avgFundedAmt",
  ]

  /**
   * Establish controller and request summary
   */
  connect() {
    this.requestSummary();
  }

  requestSummary() {
    this.calculatingResultsDisplay();
    const controller = this;
    const requestUrl = this.requestUrl();
    const fetchInit = window.acima.fetchInit({
      method: "GET", headers: { "Content-Type": "application/json" }
    });

    if (!requestUrl.pathname.endsWith(".json")) {
      requestUrl.pathname += ".json";
    }

    fetch(requestUrl.toString(), fetchInit).then((response) => {
      return response.json();
    }).then((data) => {
      if (data) {
        controller.applicationCountTarget.textContent = data.application_count;
        controller.approvedCountTarget.textContent = data.approved_count;
        controller.deniedCountTarget.textContent = data.denied_count;
        controller.approvalPercentageTarget.textContent = data.approval_percentage;
        controller.avgApprovalAmtTarget.textContent = data.average_approval_amt;
        controller.fundedAmountTarget.textContent = data.total_funded_amt;
        controller.fundingCountTarget.textContent = data.funded_count;
        controller.avgFundedAmtTarget.textContent = data.avg_funded_amt;
      };
    });
  }

  requestXls() {
    const requestUrl = this.requestUrl();

    if (!requestUrl.pathname.endsWith(".xls")) {
      requestUrl.pathname += ".xls";
    }

    window.open(requestUrl, "_blank");
  }

  requestPdf() {
    const requestUrl = this.requestUrl();

    if (!requestUrl.pathname.endsWith(".pdf")) {
      requestUrl.pathname += ".pdf";
    }

    window.open(requestUrl, "_blank");
  }

  calculatingResultsDisplay() {
    const calculatingText = this.data.get("calculatingText")
    this.applicationCountTarget.textContent   = calculatingText;
    this.approvedCountTarget.textContent      = calculatingText;
    this.deniedCountTarget.textContent        = calculatingText;
    this.approvalPercentageTarget.textContent = calculatingText;
    this.avgApprovalAmtTarget.textContent     = calculatingText;
    this.fundedAmountTarget.textContent       = calculatingText;
    this.fundingCountTarget.textContent       = calculatingText;
    this.avgFundedAmtTarget.textContent       = calculatingText;
  }

  requestUrl() {
    const requestUrl = new URL(new URL(window.location).origin + this.data.get("url"));
    const params = new URLSearchParams(requestUrl);

    params.set("location_id", this.locationIdTarget.value);
    params.set("from_date", this.fromDateTarget.value);
    params.set("to_date", this.toDateTarget.value);
    params.set("lang", document.documentElement.lang);

    requestUrl.search = params;
    return requestUrl;
  }
}
