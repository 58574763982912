import {Controller} from "stimulus";
import {
  showErrorDialog,
} from "../../shared/common/dialogs/error_dialog_controller";

const ASSIGN_BUTTON_SELECTOR =
  "[data-action='users--merchant-users--location-assignments#assign']";
const REMOVE_BUTTON_SELECTOR =
  "[data-action='users--merchant-users--location-assignments#remove']";

/**
 * Manages location assignments for merchant users
 */
export default class extends Controller {
  static targets = [
    "location",
  ];

  /**
   * Assigns resource url and renders location buttons
   */
  connect() {
    this.resourceUrl = this.data.get("resourceUrl");
    this.authorized = this.data.get("canModifyLocationUsers") == "true";
    this.renderAll();
  }

  /**
   * Iterates through all locations and triggers rendering of the remove and
   * assign buttons.
   */
  renderAll() {
    if (this.authorized) {
      this.locationTargets.forEach((location) => this.renderLocation(location));
    }
  }

  /**
   * Hides and shows the add and remove buttons based on current user
   * location assignment.
   *
   * @param {element} location location to render buttons for
   */
  renderLocation(location) {
    const removeButton = location.querySelector(REMOVE_BUTTON_SELECTOR);
    const assignButton = location.querySelector(ASSIGN_BUTTON_SELECTOR);

    if (location.hasAttribute("data-assigned")) {
      removeButton.classList.remove("toggle-hide");
      removeButton.removeAttribute("disabled");
      assignButton.classList.add("toggle-hide");
      assignButton.setAttribute("disabled", "");
    } else {
      removeButton.classList.add("toggle-hide");
      removeButton.setAttribute("disabled", "");
      assignButton.classList.remove("toggle-hide");
      assignButton.removeAttribute("disabled");
    }
  }

  /**
   * Handles errors in fetch, displays an error dialog
   *
   * @param {Error} err error during fetch process
   */
  handleError(err) {
    console.error("Error: ", err);
    showErrorDialog();
  }

  /**
   * Handles response from server and toggles all affected locations on success
   *
   * @param {Object} data
   * @param {Object} data.json parsed response body
   * @param {Array} locations locations to toggle after assignment/removal
   * @param {Boolean} assigned whether the action was an assignment or not
   */
  handleResponse({json, locations, assigned}) {
    if (json.success) {
      locations.forEach((location) => this.toggleAssigned(location, assigned));
    } else {
      this.handleError(new Error(json.status));
    }
  }

  /**
   * Toggles the locations assignment/removal visually after an action updates
   * the location/user assignment status.
   *
   * @param {element} location location to toggle
   * @param {Boolean} assigned whether the location was assigned or not
   */
  toggleAssigned(location, assigned) {
    if (assigned) {
      location.setAttribute("data-assigned", "");
    } else {
      location.removeAttribute("data-assigned");
    }
    this.renderLocation(location);
  }

  /**
   * Assigns a single location, builds a request payload and sends it to the
   * performFetch method
   *
   * @param {Event} e captured event
   */
  assign(e) {
    const location = e.currentTarget.closest("tr");
    const url = this.resourceUrl;
    const requestBody = {
      merchant_user_id: location.getAttribute("data-merc-user-id"),
      location_id: location.getAttribute("data-location-id"),
    };
    const request = {method: "POST", body: JSON.stringify(requestBody)};

    this.performFetch({url, request, locations: [location], assigned: true});
  }

  /**
   * Assigns all merchant locations, builds a request payload and sends it to
   * the performFetch method
   *
   * @param {Event} e captured event
   */
  assignAll(e) {
    const locations = this.locationTargets;
    const url = this.resourceUrl;
    const requestBody = {
      merchant_user_id: e.currentTarget.getAttribute("data-merc-user-id"),
      location_id: "all",
    };
    const request = {method: "POST", body: JSON.stringify(requestBody)};

    this.performFetch({url, request, locations, assigned: true});
  }

  /**
   * Removes a single location assignment, builds a request payload and sends
   * it to the performFetch method
   *
   * @param {Event} e captured event
   */
  remove(e) {
    const location = e.currentTarget.closest("tr");
    const url =
      `${this.resourceUrl}/${location.getAttribute("data-merc-user-id")}`;
    const requestBody = {
      location_id: location.getAttribute("data-location-id"),
    };
    const request = {method: "DELETE", body: JSON.stringify(requestBody)};

    this.performFetch({url, request, locations: [location], assigned: false});
  }

  /**
   * Performs a reusable fetch for the assign, assignAll and remove actions
   *
   * @param {Object} data
   * @param {string} data.url url to fetch
   * @param {Object} data.request request payload
   * @param {Array} data.locations location elements affected
   * @param {Boolean} data.assigned whether the action is an assignment or not
   */
  performFetch({url, request, locations, assigned}) {
    fetch(url, window.acima.fetchInit(request))
        .then((res) => res.json())
        .then((json) => this.handleResponse({json, locations, assigned}))
        .catch((err) => this.handleError(err));
  }
}
