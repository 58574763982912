/**
 *  Helper script to validate signatures
 **/

/**
 * Returns new set of expected characters if signature ends w/punctuation
 * Allows signature to submit with or w/o punctuation
 *
 * @param {Array} expectedCharacters characters in the application name
 * @return {Array} new array w/o final punctuation or null
 */
export function modifySignatureArray(expectedCharacters) {
  const newArray = expectedCharacters.slice(0);

  if (newArray[newArray.length - 1].match(/\W/)) {
    newArray.pop();
    return newArray;
  } else {
    return null;
  }
}

/**
 * Returns true if every character base in each string matches
 *
 * @param {string} currentCharacters the currently typed signature
 * @param {string} expectedCharacters the target signature string
 *
 * @return {boolean} the result of the match across all characters and length
 */
export function matchSignatures(currentCharacters, expectedCharacters) {
  if (currentCharacters.length !== expectedCharacters.length) {
    return false;
  }

  return currentCharacters.every((character, index) => {
    return matchCharacters(character, expectedCharacters[index]);
  });
}

/**
 * Formats the markup for a verified signature with success and error spans
 *
 * @param {string} character specific character in the current name
 * @param {number} index the index position of the character in the string
 *
 * @return {string} markup of a signature verification
 */
export function formatVerifiedSignature(character, index, expectedCharacters) {
  const space = matchCharacters(character, " ");
  const outputCharacter = space ? "&nbsp;" : character;
  const characterMatch = matchCharacters(
    character,
    expectedCharacters[index]
  );

  if (characterMatch) {
    return `<span class="signature-match">${outputCharacter}</span>`;
  } else {
    return `<span class="signature-fail">${outputCharacter}</span>`;
  }
}

/**
 * Returns true if two characters base match, regardless of accents
 *
 * @param {string} a first character
 * @param {string} b second character
 *
 * @return {boolean} the result of the character match
 */
function matchCharacters(a, b) {
  return a.localeCompare(b, undefined, {sensitivity: "base"}) === 0;
}
