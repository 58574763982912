import {Controller} from "stimulus";

/** Toggle merchant view awaiting customer delivery verification
    to update merchant delivery date form
*/
export default class extends Controller {
  static targets = [
    "awaitingBox",
    "claimNewBox",
  ];

  swapView() {
    this.awaitingBoxTarget.classList.add("hide");
    this.claimNewBoxTarget.classList.remove("hide");
  }
}
