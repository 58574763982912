import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "locationGuid",
    "merchandiseAmount",
    "retailerInvoicePriceError",
    "prepaidRentAmount",
    "prepaidRentContainer",
    "detailedPrepaidRentContainer",
    "detailPrepaidRent",
    "detailPrepaidRentLabel",
    "locationsAdditionalPaymentsData",
    "prepaidRentMoreThanRenewalPaymentMessage",
    "payFrequency",
    "itemDescription",
    "priceTagDrawer",
    "printTagButton",
    "itemDescriptionError",
    "prepaidRentError",
    "prepaymentSummaryWall",
    "firstRecurringPaymentDetails",
    "prepaidRentDetails",
    "totalRecurringPaymentDetails",
    "firstRecurringPaymentMessage",
    "firstRecurringPayment",
    "prepaidRentAmountDetail",
    "totalRecurringPaymentDetail",
    "leaseTermSelect",
    "ldwCheckBox",
    "benefitsPlusCheckBox",
    "liabilityAndBenefitsPlus",
    "liabilityOnly",
    "benefitsPlusOnly",
    "overviewLeaseAmount",
    "recurringRenewalPayment",
    "recurringLeaseDamageWaiverContainer",
    "recurringLeaseDamageWaiver",
    "recurringBenefitsPlusContainer",
    "recurringBenefitsPlus",
    "recurringPaymentTotal",
    "totalCashPrice",
    "totalOfPaymentLabel",
    "totalOfPaymentValue",
    "totalPaymentFrequency",
    "totalNoOfPayments"
  ]

  connect() {
    this.currentGuid = null;
    this.renewalPaymentAmount = "";
    this.prepaidRentAmountIsNumeric = false;
    this.locationsAdditionalPaymentsData = null;
    this.subTotalRecurringPayment = null;
    this.currentResponseData = null;

    this.hideAllLeaseTerms();
    this.togglePrepaidRentContainer();
  }

  // FORM VALIDATIONS

  /**
   * Validates lease estimates form in order to generate price tag
   * @return {bool}
   */
  validatePriceTagForm() {
    return this.validateForm() &&
           this.validateItemDescription() &&
           this.validateNoOfPayments();
  }

  /**
   * Runs all validations for form
   * @return {bool}
   */
  validateForm() {
    this.prepaidRentAmountIsNumeric = this.hasPrepaidRentAmountTarget && !isNaN(this.prepaidRentAmount) && this.prepaidRentAmount !== "";
    if (this.prepaidRentAmountIsNumeric) return false;

    return this.validateMerchandiseAmount() &&
           this.validateLocationGuid() &&
           this.validatePayFrequency();
  }

  /**
   * Validates item description length
   * @return {bool}
   */
  validateItemDescription() {
    return this.hasItemDescriptionTarget &&
           this.validateItemDescriptionLength() &&
           this.itemDescriptionTarget.value.trim() !== "";
  }

  /**
   * Validates item description length
   * @return {bool}
   */
  validateItemDescriptionLength() {
    const maxLength = 35;
    const label = this.itemDescriptionErrorTarget;

    if (this.itemDescriptionTarget.value.length > maxLength) {
      label.classList.remove("hide");
      return false;
    } else {
      label.classList.add("hide");
      return true;
    }
  }

  /**
   * Validates if there are available programs for a given
   * lease term
   * @return {bool}
   */
  validateNoOfPayments() {
    return this.totalNoOfPaymentsTarget.textContent != "--";
  }

  /**
   * Validates Retailer Invoice Price
   * @return {bool}
   */
  validateMerchandiseAmount() {
    const amount = this.merchandiseAmountTarget.value;
    const formattedAmount = this.formatMerchandiseAmount(amount);

    this.clearDisplayOnInvalidAmounts(formattedAmount);

    return this.validateMinimumAmount(formattedAmount) &&
           this.validateMaximumAmount(formattedAmount);
  }

  /**
   * Validates merchandise amount is a minimum of 100
   * @param  {float} amount float
   * @return {bool}
   */
  validateMinimumAmount(amount) {
    return amount >= parseInt(this.merchandiseAmountTarget.dataset.minimum);
  }

  /**
   * Validates merchandise amount is a maximum of 6600
   * @param  {float} amount float
   * @return {bool}
   */
  validateMaximumAmount(amount) {
    return amount <= parseFloat(this.merchandiseAmountTarget.dataset.maximum);
  }

  /**
   * Validates location guid is not blank
   * @return {bool}
   */
  validateLocationGuid() {
    return this.locationGuidTarget.value !== "";
  }

  /**
   * Validates pay frequency is not blank
   * @return {bool}
   */
  validatePayFrequency() {
    return this.payFrequencyTarget.value !== "";
  }

  /**
   * Validates prepaid rent amount is within valid range
   * @param  {string} prepaidRentAmount value of prepaid rent amount
   * @param  {string} minimum minimum value to compare
   * @param  {string} maximum maximum value to compare
   * @return {bool}
   */
  validatePrepaidRentValue(prepaidRentAmount, minimum, maximum) {
    if (prepaidRentAmount === "") return true;
    return prepaidRentAmount <= parseFloat(maximum) && prepaidRentAmount > parseFloat(minimum);
  }

  // HELPER FUNCTIONS

  /**
   * Gets applicable data for params and sends request to the
   * server side lease payment estimates controller and saves response
   */
  calculateEstimatedPayments() {
    if (!this.validateForm() && !this.prepaidRentAmountIsNumeric) {
      return;
    }

    const params = new URLSearchParams(
      {
        guid: this.locationGuidTarget.value,
        merchandise_amount: this.merchandiseAmountTarget.value,
        prepaid_rent_amount: this.prepaidRentAmount,
        renewal_payment_amount: this.renewalPaymentAmount,
        pay_frequency: this.payFrequencyTarget.value,
        ldw_enabled: this.ldwCheckBoxTarget.checked,
        benefits_plus_enabled: this.benefitsPlusCheckBoxTarget.checked
      }
    );

    const origin = new URL(window.location).origin;
    const requestUrl = new URL(origin + this.data.get("url"));
    const fetchWith = window.acima.fetchInit({ method: "GET" });

    requestUrl.search = params;

    const handleSuccessResponse = function(response) {
      if (response.success === true) {
        this.currentResponseData = response.data;
        this.displayCalculations(response.data);
      }
    }.bind(this);

    const handleErrorResponse = function(error) {
      console.error(error);
    }.bind(this);

    fetch(requestUrl.toString(), fetchWith)
      .then(response => response.json())
      .then(handleSuccessResponse)
      .catch(handleErrorResponse);
  }

  /**
   * Displays calculations on the page
   * @param {object} calculations returned from API
   */
  displayCalculations(calculations) {
    const translationPrefix = "lease_estimates_calculator";
    const isLDWSelected = this.ldwCheckBoxTarget.checked;
    const isBenefitsPlusSelected = this.benefitsPlusCheckBoxTarget.checked;

    let leaseTerm = this.leaseTermSelectTarget.value;
    let leaseTermCalculation = calculations.additional_info[leaseTerm];

    // Reset lease term if null
    if (!leaseTermCalculation) {
      this.resetLeaseTermSelection();
      leaseTerm = this.leaseTermSelectTarget.value;
      leaseTermCalculation = calculations.additional_info[leaseTerm];
    }

    // Set renewal payment amount
    this.renewalPaymentAmount = leaseTermCalculation.renewal_payment_amount;
    this.subTotalRecurringPayment = leaseTermCalculation.renewal_payment_amount;

    const payFrequency = leaseTermCalculation.renewal_payment_frequency;

    // Toggle visibility of containers based on selections
    this.toggleContainer(this.recurringLeaseDamageWaiverContainerTarget, isLDWSelected);
    this.toggleContainer(this.recurringBenefitsPlusContainerTarget, isBenefitsPlusSelected);

    this.overviewLeaseAmountTarget.textContent = accounting.formatMoney(
      leaseTermCalculation.merchandise_total, "$", 2
    );

    this.recurringRenewalPaymentTarget.textContent = accounting.formatMoney(
      leaseTermCalculation.renewal_payment_amount, "$", 2
    );

    let totalRecurringPayment = 0;
    
    // Prepaid Rent calculations
    if (this.hasValidPrepaidRentAmount()) {
      const prepaidRentAmountFloat = parseFloat(this.prepaidRentAmount);

      if (prepaidRentAmountFloat <= this.renewalPaymentAmount) {
        totalRecurringPayment = parseFloat(leaseTermCalculation.prepayment_remaining_payment_amount);
        this.showFirstRecurringPaymentDetails(prepaidRentAmountFloat, totalRecurringPayment);
      } else {
        this.showDetailedPrepaidRent(
          translationPrefix,
          prepaidRentAmountFloat,
          leaseTermCalculation.number_of_renewal_payments,
          leaseTermCalculation.pro_rate_payment_amount
        );

        totalRecurringPayment = parseFloat(leaseTermCalculation.effective_payment_amount);
        this.subTotalRecurringPayment = totalRecurringPayment;
      } 
    } else {
      this.hideFirstRecurringPaymentDetails();
      this.hideDetailedPrepaidRent();
    }

    // Benefits Plus calculations
    if (leaseTermCalculation.benefits_plus_fee_amount == null) {
      this.recurringBenefitsPlusTarget.textContent = "--";
    } else {
      this.recurringBenefitsPlusTarget.textContent = accounting.formatMoney(
        leaseTermCalculation.benefits_plus_fee_amount, "$", 2
      );

      totalRecurringPayment += leaseTermCalculation.benefits_plus_fee_amount;
      this.subTotalRecurringPayment += leaseTermCalculation.benefits_plus_fee_amount;

      if (this.prepaidRentAmount !== "") {
        const prepaidRentAmountFloat = parseFloat(this.prepaidRentAmount);

        if (prepaidRentAmountFloat <= this.renewalPaymentAmount) {
          this.showFirstRecurringPaymentDetails(prepaidRentAmountFloat, totalRecurringPayment);
        }
        else {
          this.showDetailedPrepaidRent(
            translationPrefix,
            prepaidRentAmountFloat,
            leaseTermCalculation.number_of_renewal_payments,
            leaseTermCalculation.pro_rate_payment_amount
          );
        }
      }
    }

    // LDW calculations
    if (leaseTermCalculation.ldw_fee_amount == null) {
      this.recurringLeaseDamageWaiverTarget.textContent = "--";
    } else {
      this.recurringLeaseDamageWaiverTarget.textContent = accounting.formatMoney(
        leaseTermCalculation.ldw_fee_amount, "$", 2
      );

      totalRecurringPayment += leaseTermCalculation.ldw_fee_amount;
      this.subTotalRecurringPayment += leaseTermCalculation.ldw_fee_amount;

      if (this.prepaidRentAmount !== "") {
        const prepaidRentAmountFloat = parseFloat(this.prepaidRentAmount);

        if (prepaidRentAmountFloat <= this.renewalPaymentAmount) {
          this.showFirstRecurringPaymentDetails(prepaidRentAmountFloat, totalRecurringPayment);
        }
        else {
          this.showDetailedPrepaidRent(
            translationPrefix,
            prepaidRentAmountFloat,
            leaseTermCalculation.number_of_renewal_payments,
            leaseTermCalculation.pro_rate_payment_amount
          );
        }
      }
    }

    // Totals
    this.recurringPaymentTotalTarget.textContent = accounting.formatMoney(this.subTotalRecurringPayment, "$", 2);
    this.totalCashPriceTarget.textContent = accounting.formatMoney(leaseTermCalculation.acima_cash_price, "$", 2);
    this.totalOfPaymentValueTarget.textContent = accounting.formatMoney(leaseTermCalculation.total_of_payments, "$", 2);
    this.totalPaymentFrequencyTarget.textContent = acima.i18n.t(`${translationPrefix}.pay_frequency.${payFrequency}`);
    this.totalNoOfPaymentsTarget.textContent = leaseTermCalculation.number_of_renewal_payments;

    this.setPrintButtonState();
    this.hideUnsupportedPrograms(calculations);
  }

  hasValidPrepaidRentAmount() {
    if (!this.locationsAdditionalPaymentsData.additional_payments_allowed) return false;
    if (this.prepaidRentAmountTarget.value === "") return false;
    if (isNaN(this.prepaidRentAmountTarget.value)) return false;

    return this.validatePrepaidRentValue(this.prepaidRentAmount, 0, this.merchandiseAmountTarget.value * 0.4);
  }

  /**
   * Toggles prepaid rent container based on additional payments allowed
   */
  togglePrepaidRentContainer() {
    if (!this.hasLocationsAdditionalPaymentsDataTarget) return;

    const locationsAdditionalPaymentsData = JSON.parse(this.locationsAdditionalPaymentsDataTarget.textContent);
    const additionalPaymentsAllowed = locationsAdditionalPaymentsData[0]?.additional_payments_allowed;

    this.currentGuid = locationsAdditionalPaymentsData[0]?.id;
    this.locationsAdditionalPaymentsData = locationsAdditionalPaymentsData[0];

    if (additionalPaymentsAllowed) {
      if (this.hasPrepaidRentContainerTarget) this.toggleContainer(this.prepaidRentContainerTarget, true);
    } else {
      if (this.hasPrepaidRentContainerTarget) this.toggleContainer(this.prepaidRentContainerTarget, false);
    }
  }

  /**
   * Handles location change event
   */
  onLocationChange() {
    const selectedLocation = this.locationGuidTarget.value;
    const locationsAdditionalPaymentsData = JSON.parse(this.locationsAdditionalPaymentsDataTarget.textContent);
    const locationData = locationsAdditionalPaymentsData.find(
      (location) => location.id === selectedLocation
    );
    
    this.locationsAdditionalPaymentsData = locationData;

    if (locationData && locationData.additional_payments_allowed) {
      if (this.hasPrepaidRentContainerTarget) this.toggleContainer(this.prepaidRentContainerTarget, true);
    } else {
      if (this.hasPrepaidRentContainerTarget) this.toggleContainer(this.prepaidRentContainerTarget, false);
    }

    if (this.hasPrepaidRentAmountTarget) this.prepaidRentAmountTarget.value = null;
    this.hideFirstRecurringPaymentDetails();
    this.hideDetailedPrepaidRent();
    this.calculateEstimatedPayments();
  }

  /**
   * Calculates estimated payments when payment frequency or lease term changes
   */
  onPaymentFrequencyOrLeaseTermChange() {
    if (this.currentResponseData) this.displayCalculations(this.currentResponseData);

    this.calculateEstimatedPayments();
  }

  /**
   * Clears results when amount is invalid
   * @param  {float} amount float
   */
  clearDisplayOnInvalidAmounts(amount) {
    if (!this.validateMinimumAmount(amount) || !this.validateMaximumAmount(amount)) {
      this.resetOverviewTargets();
      this.hideAllLeaseTerms();
    }
  }

  /**
   * Clears results when amount is invalid
   */
  clearDisplayOnInvalidAmount() {
    const amount = this.merchandiseAmountTarget.value;
    const invalidAmount = !this.validateMinimumAmount(amount) || !this.validateMaximumAmount(amount);
    const emptyField = amount == "";

    // shows error when field is not empty and invalid
    if (invalidAmount) {
      this.toggleContainer(this.retailerInvoicePriceErrorTarget, true);
    }

    // hides error when field is empty
    if (emptyField) {
      this.toggleContainer(this.retailerInvoicePriceErrorTarget, false);
    }

    // reset form when field is empty or invalid
    if (emptyField || invalidAmount) {
      this.resetOverviewTargets();
      this.leaseTermSelectTarget.value = "";
      this.hideAllLeaseTerms();
    } else {
      this.leaseTermSelectTarget.value = "12_months";
      this.calculateEstimatedPayments();
      this.toggleContainer(this.retailerInvoicePriceErrorTarget, false);
    }

    this.setPrintButtonState();
  }

  /**
   * Clears results when prepaid rent is invalid
   */
  clearDisplayOnInvalidPrepaidRent() {
    const merchandiseAmount = this.merchandiseAmountTarget.value;

    if (!this.validateMerchandiseAmount()) {
      this.prepaidRentErrorHandler();

      return;
    }

    if (this.validatePrepaidRentValue(this.prepaidRentAmount, 0, merchandiseAmount * 0.4)) {
      if (this.hasPrepaidRentErrorTarget) this.toggleContainer(this.prepaidRentErrorTarget, false);
      this.calculateEstimatedPayments();
    } else {
      this.prepaidRentErrorHandler();
    }

    this.setPrintButtonState();
  }

  /**
   * Hides unsupported programs
   * @param {object} calculations
   */
  hideUnsupportedPrograms(calculations) {
    const leaseTermSelectOptions = [...this.leaseTermSelectTarget.options].map(o => o.text);

    for (const leaseTerm of leaseTermSelectOptions) {
      const formattedTerm = leaseTerm.toLowerCase().replace(" ", "_");
      const isLeaseTermEmpty = calculations.additional_info[formattedTerm] == null;
      this.hideExtendedLeaseTerm(this.leaseTermSelectTarget, formattedTerm, isLeaseTermEmpty);
    }
  }

  /**
   * enables and disables the print tag button depending on whether
   * enough data is present.
   */
  setPrintButtonState() {
    const button = this.printTagButtonTarget;

    if (
      this.hasPrepaidRentContainerTarget &&
      this.prepaidRentAmountTarget.value &&
      !isNaN(this.prepaidRentAmountTarget.value)
    ) {
      button.classList.add("disabled");
    } else if (this.validatePriceTagForm()) {
      button.classList.remove("disabled");
    } else {
      button.classList.add("disabled");
    }
  }

  /**
   * Formats merchandise amount
   * @param  {string} amount string
   * @return {float} float
   */
  formatMerchandiseAmount(amount) {
    const strippedAmount = amount.replace(/[^0-9.]+/g, "");
    this.merchandiseAmountTarget.value = strippedAmount;

    return parseFloat(strippedAmount);
  }

  /**
   * Resets overview targets
   */
  resetOverviewTargets() {
    const translationPrefix = "lease_estimates_calculator";
    const leaseTerm = this.leaseTermSelectTarget.value;

    // Retailer Invoice Price
    this.overviewLeaseAmountTarget.textContent = "--";

    // Recurring Payments
    this.recurringRenewalPaymentTarget.textContent = "--";
    this.recurringLeaseDamageWaiverTarget.textContent = "--";
    this.recurringBenefitsPlusTarget.textContent = "--";
    this.recurringPaymentTotalTarget.textContent = "--";

    this.hideDetailedPrepaidRent();
    this.hideFirstRecurringPaymentDetails();
    this.prepaidRentErrorHandler();

    // Totals
    this.totalCashPriceTarget.textContent = "--";
    this.totalOfPaymentValueTarget.textContent = "--";
    this.totalPaymentFrequencyTarget.textContent = "--";
    this.totalNoOfPaymentsTarget.textContent = "--";
  }

  /**
   * Hides all lease term options by default except for a blank option
   */
  hideAllLeaseTerms() {
    const leaseTermSelectOptions = [...this.leaseTermSelectTarget.options];
    for (const termOption of leaseTermSelectOptions) {
      if (termOption.value != "") {
        this.hideExtendedLeaseTerm(this.leaseTermSelectTarget, termOption.value, true);
      }
    }
  }

  /**
   * Hides extended lease term from select list.
   * @param {Object} select target.
   * @param {String} value of the option.
   * @param {Boolean} true/false.
   */
  hideExtendedLeaseTerm(selectTarget, optionValue, hideTerm) {
    const targetOption = selectTarget.querySelector("option[value='" + optionValue + "']");
    hideTerm ? targetOption.classList.add("hide") : targetOption.classList.remove("hide");
  }

  /**
   * Hides first recurring payment details
   */
  hideFirstRecurringPaymentDetails() {
    if (this.hasPrepaymentSummaryWallTarget) this.toggleContainer(this.prepaymentSummaryWallTarget, false);
    if (this.hasFirstRecurringPaymentDetailsTarget) this.toggleContainer(this.firstRecurringPaymentDetailsTarget, false);
    if (this.hasPrepaidRentDetailsTarget) this.toggleContainer(this.prepaidRentDetailsTarget, false);
    if (this.hasTotalRecurringPaymentDetailsTarget) this.toggleContainer(this.totalRecurringPaymentDetailsTarget, false);
    if (this.hasFirstRecurringPaymentMessageTarget) this.toggleContainer(this.firstRecurringPaymentMessageTarget, false);

    if (this.hasFirstRecurringPaymentTarget) this.firstRecurringPaymentTarget.textContent = "--";
    if (this.hasPrepaidRentAmountDetailTarget) this.prepaidRentAmountDetailTarget.textContent = "--";
    if (this.hasTotalRecurringPaymentDetailTarget) this.totalRecurringPaymentDetailTarget.textContent = "--";
  }

  /**
   * Shows first recurring payment details
   * @param {float} prepaidRentAmountFloat
   * @param {float} totalRecurringPayment
   */
  showFirstRecurringPaymentDetails(prepaidRentAmountFloat, totalRecurringPayment) {
    if (this.hasPrepaymentSummaryWallTarget) this.toggleContainer(this.prepaymentSummaryWallTarget, true);
    if (this.hasFirstRecurringPaymentDetailsTarget) this.toggleContainer(this.firstRecurringPaymentDetailsTarget, true);
    if (this.hasPrepaidRentDetailsTarget) this.toggleContainer(this.prepaidRentDetailsTarget, true);
    if (this.hasTotalRecurringPaymentDetailsTarget) this.toggleContainer(this.totalRecurringPaymentDetailsTarget, true);
    if (this.hasFirstRecurringPaymentMessageTarget) this.toggleContainer(this.firstRecurringPaymentMessageTarget, true);

    this.hideDetailedPrepaidRent();
    if (this.hasTotalRecurringPaymentDetailTarget) this.totalRecurringPaymentDetailTarget.textContent = accounting.formatMoney(this.subTotalRecurringPayment, "$", 2);
    if (this.hasPrepaidRentAmountDetailTarget) this.prepaidRentAmountDetailTarget.textContent = `-${accounting.formatMoney(prepaidRentAmountFloat, "$", 2)}`;
    if (this.hasFirstRecurringPaymentTarget) this.firstRecurringPaymentTarget.textContent = accounting.formatMoney(totalRecurringPayment, "$", 2);
  }

  /**
   * Hides detailed prepaid rent
   */
  hideDetailedPrepaidRent() {
    if (this.hasDetailedPrepaidRentContainerTarget) this.toggleContainer(this.detailedPrepaidRentContainerTarget, false);
    if (this.hasPrepaidRentMoreThanRenewalPaymentMessageTarget) this.toggleContainer(this.prepaidRentMoreThanRenewalPaymentMessageTarget, false);

    if (this.hasDetailPrepaidRentLabelTarget) this.detailPrepaidRentLabelTarget.textContent = "--";
    if (this.hasDetailPrepaidRentTarget) this.detailPrepaidRentTarget.textContent = "--";
  }

  /**
   * Shows detailed prepaid rent
   * @param {string} translationPrefix
   * @param {float} prepaidRentAmountFloat
   * @param {int} numberOfRenewalPayments
   * @param {float} proratedPaymentAmount
   */
  showDetailedPrepaidRent(translationPrefix, prepaidRentAmountFloat, numberOfRenewalPayments, proratedPaymentAmount) {
    if (this.hasDetailedPrepaidRentContainerTarget) this.toggleContainer(this.detailedPrepaidRentContainerTarget, true);
    if (this.hasPrepaidRentMoreThanRenewalPaymentMessageTarget) this.toggleContainer(this.prepaidRentMoreThanRenewalPaymentMessageTarget, true);
    this.hideFirstRecurringPaymentDetails();

    if (this.hasDetailPrepaidRentLabelTarget) {
      this.detailPrepaidRentLabelTarget.textContent = acima.i18n.t(
        `${translationPrefix}.overview.recurring_payments.detailed_prepaid_rent`,
        {
          amount: accounting.formatMoney(prepaidRentAmountFloat, "$", 2),
          payments_number: numberOfRenewalPayments,
        }
      );
    }

    if (this.hasDetailPrepaidRentTarget) {
      this.detailPrepaidRentTarget.textContent = `-${accounting.formatMoney(
        proratedPaymentAmount, "$", 2
      )}`;
    }
  }

  /**
   * Resets lease term selection
   */
  resetLeaseTermSelection() {
    this.leaseTermSelectTarget.value = "12_months";
    this.hideAllLeaseTerms();
  }

  /**
   * Checks if prepaid rent amount is within valid range and shows/hides container
   */
  prepaidRentErrorHandler() {
    const merchandiseAmount = this.merchandiseAmountTarget.value;
    const prepaidRentAmount = this.hasPrepaidRentAmountTarget ? this.prepaidRentAmountTarget.value : "";

    const validAmount = this.validatePrepaidRentValue(prepaidRentAmount, 0, merchandiseAmount * 0.4);

    if (validAmount) {
      if (this.hasPrepaidRentErrorTarget) this.toggleContainer(this.prepaidRentErrorTarget, false);
    } else {
      if (this.hasPrepaidRentErrorTarget) this.toggleContainer(this.prepaidRentErrorTarget, true);
      this.hideDetailedPrepaidRent();
    }
  }

  /**
   * Shows/hides optional service message based on checkbox states
   */
  optionalServicesToggled() {
    let ldwEnabled = this.ldwCheckBoxTarget.checked;
    let benefitsPlusEnabled = this.benefitsPlusCheckBoxTarget.checked;

    if (ldwEnabled && benefitsPlusEnabled) {
      this.toggleContainer(this.liabilityAndBenefitsPlusTarget, true);
      this.toggleContainer(this.liabilityOnlyTarget, false);
      this.toggleContainer(this.benefitsPlusOnlyTarget, false);
    } else {
      if (ldwEnabled) {
        this.toggleContainer(this.liabilityAndBenefitsPlusTarget, false);
        this.toggleContainer(this.liabilityOnlyTarget, true);
        this.toggleContainer(this.benefitsPlusOnlyTarget, false);
      }
      if (benefitsPlusEnabled) {
        this.toggleContainer(this.liabilityAndBenefitsPlusTarget, false);
        this.toggleContainer(this.liabilityOnlyTarget, false);
        this.toggleContainer(this.benefitsPlusOnlyTarget, true);
      }
      if (!ldwEnabled && !benefitsPlusEnabled) {
        this.toggleContainer(this.liabilityAndBenefitsPlusTarget, false);
        this.toggleContainer(this.liabilityOnlyTarget, false);
        this.toggleContainer(this.benefitsPlusOnlyTarget, false);
      }
    }
  }

  /**
   * Toggles visibility of a container.
   */
  toggleContainer(container, isVisible) {
    if (isVisible) {
      $(container).removeClass("hide");
    } else {
      $(container).addClass("hide");
    }
  }

  /**
   * Prevents form submission unless Price Tag form is valid
   * @param {event} event checks for Enter keydown
   */
  preventFormSubmission(event) {
    if (event.keyCode === 13) {
      if (!this.validatePriceTagForm()) {
        event.preventDefault()
      }
    }
  }

  // GETTERS

  /**
   * Determines prepaid rent amount, returns empty string if target not present
   * @return {string}
   */
  get prepaidRentAmount() {
    return this.hasPrepaidRentAmountTarget ? this.prepaidRentAmountTarget.value : "";
  }
}
