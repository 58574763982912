import {Controller} from "stimulus";
import {
  showConfirmDialog,
} from "../../shared/common/dialogs/confirm_dialog_controller";
import {
  showErrorDialog,
} from "../../shared/common/dialogs/error_dialog_controller";
import {
  showSpinnerDialog,
  closeSpinnerDialog,
} from "../../shared/common/dialogs/spinner_dialog_controller";

/** Refund initial payment */
export default class extends Controller {
  /**
    * Connect the controller
    */
  connect() {
    this.refundUrl = this.data.get("refundUrl");
    this.successUrl = this.data.get("successUrl");
    this.message = this.data.get("message");
    this.errorMessage = this.data.get("errorMessage");
    this.cancelText = this.data.get("cancelText");
    this.confirmText = this.data.get("confirmText");
  }

  /**
   * Handles exceptional responses in resend fetch
   *
   * @param {Object} error the caught error
   */
  handleError(error) {
    console.error("Error: ", error);
    closeSpinnerDialog();
    showErrorDialog(this.errorMessage);
  }

  /**
   * Handles success and failure response in fetch
   *
   * @param {Object} response The fetch response as parsed json
   */
  handleResponse(response) {
    if (response.success) {
      window.location.href = this.successUrl;
    } else {
      closeSpinnerDialog();
      showErrorDialog(response.message);
    }
  }

  /**
   * Simple post to refunds endpoint
   */
  refundPayment = () => {
    showSpinnerDialog();
    fetch(this.refundUrl, window.acima.fetchInit({method: "POST"}))
        .then((res) => res.json())
        .then((res) => this.handleResponse(res))
        .catch((err) => this.handleError(err));
  }

  /**
   * Sets up and shows the confirmation dialog
   */
  showDialog() {
    showConfirmDialog({
      message: this.message,
      cancelText: this.cancelText,
      confirmText: this.confirmText,
      action: this.refundPayment,
    });
  }
}
