const DIALOG_SPINNER_MARKUP = `
<div class="dialog-loading-spinner">
  <div class="loading-spinner loading-spinner-lg"><div>
</div>
`;

/**
 *
 * @param {element} target Target to render spinner markup in
 */
export function renderDialogBodySpinner(target) {
  target.innerHTML = DIALOG_SPINNER_MARKUP;
}
