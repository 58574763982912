import {Controller} from "stimulus";

/**
 * Controller used to open the site alerts (megaphone)
 */
export default class extends Controller {
  /**
   * Launch the site alerts window
   * @return {boolean} false prevent default action
   */
  launchSiteAlerts() {
    const path = this.data.get("path");
    const styles = "width=395, height=750";
    const alertsWindow = window.open(path, "", styles);
    alertsWindow.focus();
    return false;
  }
}
