import {Controller} from "stimulus";

/**
 * Launches owners funding account dialogs.
 */
export default class extends Controller {
    static targets = [
      "addCardModal",
      "deleteCardModal",
    ];

    /** Launch Add Card modal */
    launchAddCard() {
      const controller = this.application.getControllerForElementAndIdentifier(
          document.querySelector("#add-instant-funding-card-modal"),
          "owners--add-instant-funding-card",
      );
      controller.loadForm();
      this.addCardModalTarget.showModal();
    }

    /** launch delete card modal controller
     *
     * @param {Object} event - triggering event
     */
    launchDeleteCard(event) {
      const modalController =
            this.application.getControllerForElementAndIdentifier(
                document.querySelector("#delete-instant-funding-card-modal"),
                "owners--delete-instant-funding-card",
            );
      const cardGuid = event.currentTarget.dataset["cardGuid"];
      const deleteUrl = event.currentTarget.dataset["cardDeleteUrl"];
      modalController.openDialog(cardGuid, deleteUrl);
    }
}
