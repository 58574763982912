import {now} from "lodash";

/**
 * Helper with reusable time related utilities
 */
class TimeHelper {
  /**
   * Calculates the current time as milliseconds elapsed since unix epoch
   *
   * @return {number} current time represented as milliseconds
   */
  currentTimeElapsed() {
    return now();
  }

  /**
   * Calculates a future time as milliseconds elapsed since unix epoch based
   * on minutes from current time for use in custom timeouts
   *
   * @param {number} minutes amount of minutes into the future for timeout
   *
   * @return {number} current time represented as milliseconds
   */
  calculateTimeoutElapsed(minutes) {
    const startTimeMilliseconds = this.currentTimeElapsed();
    const durationMilliseconds = minutes * 60 * 1000;

    return startTimeMilliseconds + durationMilliseconds;
  }

  /**
   * Converts date time string into unix timestamp
   *
   * @param {string} dateTime server side date time string as extended iso8601
   *
   * @return {number} date as unix timestamp/milliseconds elapsed since epoch
   */
  convertDateTimeElapsed(dateTime) {
    const date = new Date(dateTime);

    return date.getTime();
  }
}

export const timeHelper = new TimeHelper;
