import { Controller } from "stimulus";
import { publish, subscribe, unsubscribe } from "../../../../helpers/pub_sub";
import {
  closeSpinnerDialog
} from "../../../../shared/common/dialogs/spinner_dialog_controller";

const SHOW_REFUND_CONFIRMATION_DIALOG_EVENT =
  "showRefundConfirmationDialogEvent";

export function showRefundConfirmationDialog() {
  publish(SHOW_REFUND_CONFIRMATION_DIALOG_EVENT);
}

export default class extends Controller {
  static targets = [
    "printButton"
  ];

  connect() {
    dialogPolyfill.registerDialog(this.targetDialog);
    subscribe(SHOW_REFUND_CONFIRMATION_DIALOG_EVENT, this.showDialog);
  }

  disconnect() {
    unsubscribe(SHOW_REFUND_CONFIRMATION_DIALOG_EVENT, this.showDialog);
  }

  showDialog = () => {
    closeSpinnerDialog();

    if (this.targetDialog.open) {
      return;
    } else {
      this.targetDialog.showModal();
    }
  }

  printConfirmation = () => {
    const url = this.printButtonTarget.getAttribute("data-url") + ".pdf";
    window.open(url, "_blank");

    this.closeDialog();
    history.scrollRestoration = "manual";
    window.reload();
  }

  closeDialog() {
    this.targetDialog.close();
    window.location.reload();
  }

  get targetDialog() {
    return document.getElementById("refund-confirmation-modal");
  }
}
