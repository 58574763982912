import { Controller } from "stimulus";

/** Generates a unique merchant feedback survey url based on
  * the current merchant users selection in location dropdown
  */

export default class extends Controller {
  static targets = [
    "locationId",
    "surveyUrl",
    "merchantId",
    "formattedSurveyUrl"
  ]

  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  proceedToSurvey() {
    const locationId = JSON.parse(this.locationIdTarget.value)[1];
    const locationDba = JSON.parse(this.locationIdTarget.value)[0];
    const merchantId = this.element.dataset.merchantId;
    const surveyUrl = this.element.dataset.surveyUrl;
    const formattedUrl = `${surveyUrl}location_id=${locationId}&location_dba=${locationDba}&merchant_id=${merchantId}`;

    this.element.dataset.formattedSurveyUrl = formattedUrl;

    this.closeDialog();

    const modalController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("#feedback-survey-dialog"),
      "merchants--dashboard--feedback-survey-dialog"
    );

    modalController.launchDialog();
  }

  closeDialog() {
    this.element.close();
  }
}
