import {Controller} from "stimulus";

/**
 * Manages override approval dialog for user contracts
 */
export default class extends Controller {
  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  closeDialog() {
    this.element.close();
  }

  showDialog() {
    this.element.showModal();
  }
}
