import { Controller } from "stimulus";
import {
  showErrorDialog
} from "../../../shared/common/dialogs/error_dialog_controller";

/**
 * Manages revert action for user contracts
 */
export default class extends Controller {
  static targets = [
    "reason",
    "revertButton"
  ];

  connect() {
    dialogPolyfill.registerDialog(this.element);
    this.url = this.data.get("url");
    this.errorMessage = this.data.get("errorMessage");
  }

  handleResponse(jsonResponse) {
    if (!jsonResponse.success) {
      throw new Error(jsonResponse.message);
    }

    window.location.reload();
  }

  handleError(err) {
    console.error(err);
    this.closeDialog();
    showErrorDialog(`${this.errorMessage} ${err.message}`);
  }

  revert() {
    const request = window.acima.fetchInit({ method: "POST" });

    fetch(this.url, request)
      .then((response) => response.json())
      .then((jsonResponse) => this.handleResponse(jsonResponse))
      .catch((err) => this.handleError(err));
  }

  closeDialog() {
    this.element.close();
  }

  showDialog() {
    this.element.showModal();
  }
}
