/**
 * Alert Helpers
 *
 * These helpers are for controllers that own an alert section in their own
 * scope that is more localized than the page level flash alerts. If you want
 * page level flash alerts on the client use flash_alerts_controller.js
 *
 */


/**
 * Public Interface
 *
 * To use, know your alert type and call that with a target element in your
 * controller and a message
 *
 * markup ex:
 *
 * .row
 *   .col-xs-12
 *     .alert[
 *       data-target="your--controller--path.alertNotice"
 *     ]
 *
 * js ex:
 *
 * import {alertHelper} from "packs/controllers/helpers/alert_helpers";
 *
 * alertHelper.error(this.alertNoticeTarget, "uh oh something went wrong");
 * alertHelper.success(this.alertNoticeTarget, "yay you did it");
 * alertHelper.info(this.alertNoticeTarget, "just so you know");
 *
 * This will clear the alert
 * alertHelper.clear(this.alertNoticeTarget);
 */
export const alertHelper = {
  clear: (target) => clearAlert(target),
  error: (target, message) => setAlert(target, message, "error"),
  info: (target, message) => setAlert(target, message, "info"),
  success: (target, message) => setAlert(target, message, "success"),
};

/**
 * Private implementation
 *
 * Maps message states to css class names
 */
const MESSAGE_CLASSES = {
  info: "alert-info",
  error: "alert-danger",
  success: "alert-success",
};

/**
 * Private implementation
 *
 * Removes all alert classes from the target element
 *
 * @param {element} target Element to remove alert classes from
 */
function clearAlertClasses(target) {
  target.classList.remove(...Object.values(MESSAGE_CLASSES));
}

/**
 * Private implementation
 *
 * Adds alert class based on message key to an element, falls back to `info`
 *
 * @param {element} target Element to add the class to
 * @param {string} alertKey Type of alert to determine visual display
*/
function addAlertClass(target, alertKey) {
  target.classList.add(MESSAGE_CLASSES[alertKey]);
}

/**
 * Private implementation
 *
 * Clears alert message and classes
 *
 * @param {element} target Alert element that will be cleared
 */
function clearAlert(target) {
  clearAlertClasses(target);
  target.innerHTML = "";
}

/**
 * Private implementation
 *
 * Clears existing alert, sets alert target class and message body
 *
 * @param {element} target Alert element to populate with message and class
 * @param {string} message Message to display
 * @param {string} alertKey Type of alert to determine visual display
 */
function setAlert(target, message, alertKey) {
  clearAlert(target);
  addAlertClass(target, alertKey);
  target.innerHTML = message;
}
