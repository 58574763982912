import { Controller } from "stimulus";
import { staticTargets, isPositiveNumberGreaterThanZero, isNotEmptyField, validateStatus }
  from "./helpers/foreign_taxes_helper";

/**
 * Used to handle foreign taxes fields
 */

export default class extends Controller {
  static targets = staticTargets();

  /** Calls validation function and check validation status
   * @param {Object} event
   * @param {Function} validationFunction name of validation function
   * @param {String} errorMessageKey error message key
   */
  validateField(event, validationFunction, errorMessageKey) {
    const { target } = event;
    const status = validationFunction(target.value);
    const errorMessage = status ? "" : this.errorMessagesTarget.dataset[errorMessageKey];

    validateStatus(this, target, errorMessage, status);

    if (!status) {
      event.stopImmediatePropagation();
    }
  };

  /** Validates required field value
   * @param {Object} event
   */
  validateRequiredField(event) {
    this.validateField(event, isNotEmptyField, "blankError");
  };

  /** Validates if field value is positive number
   * @param {Object} event
   */
  validateDecimalPositiveNumber(event) {
    this.validateField(event, isPositiveNumberGreaterThanZero, "positiveNumber");
  };

  get requiredFieldsReady() {
    return this.requiredFieldTargets.every(element => isNotEmptyField(element.value));
  };

  get positiveNumberReady() {
    return this.requiredFieldTargets.every(element => isPositiveNumberGreaterThanZero(element.value));
  };
}
