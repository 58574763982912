import {Controller} from "stimulus";
import {alertHelper} from "../../helpers/alert_helpers";
import {showAlertListDialog} from "../common/navigation/alert_bar/alert_list_dialog_controller";

/** Controls Dismissing of Unread Messages */
export default class extends Controller {
  static targets = [
    "count",
    "alertNotice",
  ];

  /**
   * Connect controller
   */
  connect() {
    this.initialCount = parseInt(this.data.get("messageCount"));
    this.failureMessage = this.data.get("failureMessage");
    this.successMessage = this.data.get("successMessage");
    this.resourceUrl = "";
  }

  /**
   * Handles error responses and sets notification alert
   *
   * @param {Error} err caught error
   */
  handleError(err) {
    alertHelper.error(this.alertNoticeTarget, this.failureMessage);
  }

  /**
   * Handles successful responses and updates with results
   *
   * @param {object} responseJson parsed response json
   */
  handleResponse(responseJson) {
    if (!responseJson.success) {
      throw new Error(responseJson.message);
    }

    this.removeMessage(this.messageRow);
    alertHelper.success(this.alertNoticeTarget, this.successMessage);
  }

  /**
   * Removes the message row and updates message count
   *
   * @param {element} messageRow table row of dismissed message
   */
  removeMessage(messageRow) {
    this.initialCount -= 1;
    this.countTarget.textContent = this.initialCount;

    messageRow.classList.add("unread-message-dismissed");
    setTimeout(function() {
      messageRow.parentNode.removeChild(messageRow);
    }, 200);
  }

  /**
   * Marks message as read
   *
   * @param {Event} e click event for dismissed message
   */
  dismiss(e) {
    alertHelper.clear(this.alertNoticeTarget);
    this.messageRow = e.currentTarget.closest("tr");
    const url = this.messageRow.getAttribute("data-resource-url");
    const requestBody = {is_read: true};
    const request = {method: "PATCH", body: JSON.stringify(requestBody)};

    fetch(url, window.acima.fetchInit(request))
        .then((res) => res.json())
        .then((json) => this.handleResponse(json))
        .catch((err) => this.handleError(err));
  }

  /**
   * Opens modal to upload condition image
   *
   * @param {Event} e click event for open new upload image to condition modal
   */
  openUploadConditionImageModal(e) {
      showAlertListDialog({
        url: e.target.dataset.uploadConditionImageUrl,
        title: window.acima.i18n.t("users.upload_condition_image.modal_title"),
        dialogClassname: "upload-image-conditions-dialog",
      });
    }
}
