import { Controller } from "stimulus";
import { alertHelper } from "../helpers/alert_helpers";

/** Purchase confirmation handling */
export default class extends Controller {
  static targets = [
    "resendRequestButton",
    "mobileNotice"
  ]

  /**
  * Register the controller and set initial state
  */
  connect() {
    this.busyMessage = this.data.get("busyMessage");
    this.contractId = this.data.get("contractId");
    this.failureMessage = this.data.get("failureMessage");
    this.resendUrl = this.data.get("resendUrl");
    this.successMessage = this.data.get("successMessage");
    this.conditionSatisfied = this.data.get("conditionSatisfied");

    this.toggleResendButton(this.conditionSatisfied);
    alertHelper.success(this.mobileNoticeTarget, this.successMessage);
  }

  /**
   * Handles hard errors in resendSms fetch and shows alert message
   *
   * @param {Error} err
   */
  handleError(err) {
    console.error("Error: ", err);

    this.toggleResendButton(true);
    alertHelper.error(this.mobileNoticeTarget, this.failureMessage);
  }

  /**
   * Handles success and failure response in resend fetch and shows
   * corresponding alerts
   *
   * @param {Object} response
   */
  handleResponse(response) {
    this.toggleResendButton(true);
    if (response.success) {
      alertHelper.success(this.mobileNoticeTarget, this.successMessage);
    } else {
      alertHelper.error(this.mobileNoticeTarget, this.failureMessage);
    }
  }

  /**
   * Action for sending the mobile signature request
   */
  resendSms = () => {
    const requestBody = { id: this.contractId };
    const request = { method: "POST", body: JSON.stringify(requestBody) };

    this.toggleResendButton(false);

    fetch(this.resendUrl, window.acima.fetchInit(request))
      .then(res => res.json())
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err));
  }

  /**
   * Enables and disables the resend button along with triggering the
   * busyMessage alert
   *
   * @param {boolean} buttonEnabled Whether the button is currently enabled
   */
  toggleResendButton(buttonEnabled) {
    if (buttonEnabled) {
      this.resendRequestButtonTarget.removeAttribute("disabled");
    } else {
      this.resendRequestButtonTarget.setAttribute("disabled", true);
      alertHelper.info(this.mobileNoticeTarget, this.busyMessage);
    }
  }
}
