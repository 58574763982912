import {Controller} from "stimulus";
import CountdownTimer from "../../../helpers/countdown_timer";
import {subscribe, publish} from "../../../helpers/pub_sub";
import formatCountdown from "./format_countdown";
import {REQUEST_TIMEOUT_MINUTES} from "./lock_requester_dialog_controller";

const INIT_REQUEST_TIMEOUT_EVENT = "initLockRequestTimeoutEvent";
const CLEAR_REQUEST_TIMEOUT_EVENT = "clearLockRequestTimeoutEvent";

/**
 * Publish init event that will set the clock inside the timeout controller
 *
 * @param {Object} timeoutData data payload for setting clock
 * @param {number} timeoutData.endTimeElapsed time to countdown to
 * @param {Function} timeoutData.onExpired callback to trigger when time ends
 */
export function initRequestTimeout(timeoutData) {
  publish(INIT_REQUEST_TIMEOUT_EVENT, timeoutData);
}

/**
 * Publish clear event that will clear the clock inside the timeout controller
 */
export function clearRequestTimeout() {
  publish(CLEAR_REQUEST_TIMEOUT_EVENT, {});
}

/**
 * Controller used to manage countdown timers
 */
export default class extends Controller {
  static targets = [
    "countdownTimerDisplay",
  ];

  /**
   * connect controller and subscribe to events
   */
  connect() {
    subscribe(INIT_REQUEST_TIMEOUT_EVENT, this.initTimeout);
    subscribe(CLEAR_REQUEST_TIMEOUT_EVENT, this.clearTimeout);
    this.countdownTimer = null;
  }

  /**
   * Creates a countdown timer and starts it, the timer handles the clock
   * ticking and rendering
   *
   * @param {Object} timeoutData data payload for setting clock
   * @param {number} timeoutData.durationMinutes original duration in minutes
   * @param {number} timeoutData.endTimeElapsed time to countdown to
   * @param {Function} timeoutData.onExpired callback to trigger when time ends
   */
  initTimeout = ({endTimeElapsed, onExpired}) =>{
    this.clearTimeout();
    this.countdownTimer = new CountdownTimer(
        {
          endTimeElapsed,
          onExpired,
          durationMinutes: REQUEST_TIMEOUT_MINUTES,
          onTick: this.render,
        }
    );
    this.countdownTimer.start();
  }

  /**
   * Stops and clears the timer if it exists
   */
  clearTimeout = () => {
    if (this.countdownTimer) {
      this.countdownTimer.stop();
      this.countdownTimer = null;
    }
  }

  /**
   * Formats the clock every tick and renders it into the countdown timer
   * display. Renders the clock in 0:00 format. The parameter is provided
   * by the countdown timer every tick.
   *
   * @param {Object} clockValues current state of the countdown timer
   */
  render = (clockValues) => {
    this.countdownTimerDisplayTarget.innerHTML = formatCountdown(clockValues);
  }
}
