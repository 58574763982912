import { Controller } from "stimulus";
import {
  showErrorDialog
} from "../../../shared/common/dialogs/error_dialog_controller";

/**
 * Manages phone number verification dialog action used in applicant Edit form
 */

export default class extends Controller {
  static targets = ["applicantIdentityConfirmationModal"]

  connect() {
    dialogPolyfill.registerDialog(this.element);
    this.url = this.data.get("url");
    this.errorMessage = this.data.get("errorMessage");
    this.reason = null;
  }

  /**
   * Launch dialog
   */

  launchDialog() {
    this.updateTurboFrameSrc();
    this.element.showModal();
  }

  /**
   * Update the src attribute for the turbo frame
   */
  updateTurboFrameSrc() {
    const mainPhone = document.querySelector("#applicant_main_phone");
    this.applicantIdentityConfirmationModalTarget.src +=
      `?new_phone_number=${mainPhone.value.replace(/\D/g, "")}`;
  }

  /**
   * Submit the edit applicant form
   */

  submitForm() {
    const modalController =
      this.application.getControllerForElementAndIdentifier(
        document.querySelector("#edit-applicant-container"),
        "users--contracts--actions--edit-applicant-details"
      );

    this.closeDialog();
    modalController.requestFormSubmit();
  }

  /**
   * Handles the fetch response by reloading the page on success or throwing
   * an error that is caught by handleError.
   */

  handleResponse(jsonResponse) {
    if (!jsonResponse.success) {
      throw new Error(jsonResponse.message);
    }

    this.closeDialog();
  }

  /**
   * Handles errors in fetch
   */

  handleError(err) {
    this.closeDialog();
    showErrorDialog(`${err.message}`);
  }

  closeDialog() {
    this.element.close();
    window.location.reload();
  }
}
