import {Controller} from "stimulus";
import {
  showConfirmDialog,
} from "../../../shared/common/dialogs/confirm_dialog_controller";

/**
 * Manages edit application action for user contracts
 */
export default class extends Controller {
  connect() {
    this.title = this.data.get("title");
    this.message = this.data.get("message");
    this.confirmText = this.data.get("confirmText");
    this.cancelText = this.data.get("cancelText");
    this.url = this.data.get("url");
  }


  /**
   * Navigates to edit application url
   */
  editApplication = () => {
    window.location = this.url;
  }

  /**
   * Sets up and shows the edit application confirmation dialog
   *
   * @param {event} e event that triggered action
   */
  showDialog(e) {
    if (e.currentTarget.hasAttribute("disabled")) {
      return;
    }

    showConfirmDialog({
      title: this.title,
      message: this.message,
      confirmText: this.confirmText,
      cancelText: this.cancelText,
      action: this.editApplication,
    });
  }
}
