import {Controller} from "stimulus";
import {
  showErrorDialog,
} from "../../../shared/common/dialogs/error_dialog_controller";

/**
 * Manages deny application action for user contracts
 */
export default class extends Controller {
  static targets = [
    "reasonOption",
    "denyApplicationButton",
  ];

  connect() {
    dialogPolyfill.registerDialog(this.element);
    this.url = this.data.get("url");
    this.errorMessage = this.data.get("errorMessage");
    this.reason = null;
  }

  /**
   * Handles the fetch response by reloading the page on success or throwing
   * an error that is caught by handleError.
   *
   * @param {Object} jsonResponse fetch response object
   */
  handleResponse(jsonResponse) {
    if (!jsonResponse.success) {
      throw new Error(jsonResponse.message);
    }
    this.closeDialog();
    window.location.reload();
  }

  /**
   * Handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    console.error(err);
    this.closeDialog();
    showErrorDialog(`${this.errorMessage} ${err.message}`);
  }

  /**
   * Enables deny application button when a reason is selected and sets
   * the active reason.
   *
   * @param {Object} e event that triggered the function
   */
  selectReason(e) {
    const selectedReason = e.currentTarget;
    this.reason = selectedReason.dataset.reason;
    this.reasonOptionTargets.forEach((option) => {
      option.classList.remove("btn-selected");
    });
    selectedReason.classList.add("btn-selected");
    this.denyApplicationButtonTarget.removeAttribute("disabled");
  }

  denyApplication() {
    if (!this.reason) {
      return;
    }

    const params = JSON.stringify({
      deny_application: {reason: this.reason},
      lease: {
        // These values are hardcoded here because they are required on the
        // rails side but are being dropped from the form on the UI side.
        // It may be that they are actually still in use and need to be
        // restored to the form at views/users/contracts/actions/deny_dialog
        underwriter_verified_recent_nsf_count: 0,
        underwriter_verified_recent_protected_nsf_count: 0,
      },
    });
    const request = window.acima.fetchInit({method: "POST", body: params});

    this.preventSubmitWhileFetching(true);
    fetch(this.url, request)
        .then((response) => response.json())
        .then((jsonResponse) => this.handleResponse(jsonResponse))
        .catch((err) => this.handleError(err))
        .finally(() => this.preventSubmitWhileFetching(false));
  }

  preventSubmitWhileFetching(fetching) {
    if (fetching) {
      this.denyApplicationButtonTarget.setAttribute("disabled", "");
    } else {
      this.denyApplicationButtonTarget.removeAttribute("disabled");
    }
  }

  closeDialog() {
    this.reason = null;
    this.denyApplicationButtonTarget.setAttribute("disabled", "");
    this.reasonOptionTargets.forEach((option) => {
      option.classList.remove("btn-selected");
    });
    this.element.close();
  }

  showDialog() {
    this.denyApplicationButtonTarget.setAttribute("disabled", "");
    this.element.showModal();
  }
}
