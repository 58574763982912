/**
 *  Helper script to dynamically populate term length target names based on
 *  the number of supported lease term programs.
 **/

export default function termLengthTargets(termsSupported = 13) {
  const terms = parseInt(termsSupported);
  const targetCount = [...Array(terms).keys()];
  const targetNames = [];

  targetCount.forEach(
    value => targetNames.push("termLength"+(value+1))
  );

  return targetNames;
}
