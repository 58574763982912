import { Controller } from "stimulus";

/** Confirm the merchant delivery */
export default class extends Controller {
  static targets = [
    "calendar",
    "submitBtn"
  ];

  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  closeDialog() {
    this.element.close();
  }

  shouldBeDisabled() {
    const hasOptionalPaymentsFeatureFlagEnable = this.data.get("hasOptionalPaymentsFeatureFlagEnable");
    const hasFailedPayment = this.data.get("hasFailedPayment");
    const transactionType = this.data.get("transactionType");

    // skip disable logic if OptionalPaymentsFeatureFlagEnable is not enable or any of the following conditions
    if(hasOptionalPaymentsFeatureFlagEnable === "false" || transactionType === ""
       || hasFailedPayment === "false") {
      return false;
    }
    // if optional payment has Failed Payment then it will disable the button
    // with prepayment it will always allow the user to confirm the delivery with warnings
    return transactionType == "optional" && hasFailedPayment === "true";
  }

  changeDelivery() {
    if (this.calendarTarget.value == "") {
      this.submitBtnTarget.disabled = true;
    } else {
      this.submitBtnTarget.disabled = false;
    }

    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    const shouldBeDisabled = this.shouldBeDisabled();

    if (shouldBeDisabled) {
      this.submitBtnTarget.disabled = true;
      document.getElementById("confirm-delivery-dialog-close").focus();
    } else {
      this.submitBtnTarget.disabled = false;
      document.getElementById("confirm-delivery-dialog-submit").focus();
    }
  }

  showDialog() {
    this.calendarTarget.parentElement.classList.remove("flatpickr-wrapper");
    this.element.showModal();
    this.toggleSubmitButton();
  }

  confirmDelivery() {
    const params = JSON.stringify({
      delivery_confirmation: {
        contract_id: this.data.get("contractId"),
        selected_delivery_date: this.selectedDeliveryDate
      }
    });
    const fetchWith = window.acima.fetchInit({
      method: "POST",
      body: params
    });
    const url = this.data.get("postUrl");

    this.submitBtnTarget.disabled = true;

    fetch(url, fetchWith).then(response => {
      response.json();
    }).then(response => {
      window.location.reload();
    });
  }

  get selectedDeliveryDate() {
    return this.calendarTarget.value;
  }
}
