import {Controller} from "stimulus";

/**
 * Controller used to filter the group contracts list (datatables)
 */
export default class extends Controller {
  /**
   * Redirect the page for the 'only my leases' checkbox-filtered datatable.
   */
  redirectPage(e) {
    if (e.currentTarget.checked) {
      window.location.href = e.currentTarget.dataset.filterUrl;
    } else {
      window.location.href = e.currentTarget.dataset.url;
    }
  }
}
