import { Controller } from "stimulus";
import { publish, subscribe, unsubscribe } from "../../../helpers/pub_sub";
import {
  closeSpinnerDialog, showSpinnerDialog
} from "../../../shared/common/dialogs/spinner_dialog_controller";
import {
  showErrorDialog
} from "../../../shared/common/dialogs/error_dialog_controller";
import {
  showRefundConfirmationDialog
} from "./initial_payments/refund_confirmation_dialog_controller";

const SHOW_REFUND_INITIAL_PAYMENT_DIALOG_EVENT =
  "showRefundInitialPaymentDialogEvent";

export function showRefundInitialPaymentDialog() {
  publish(SHOW_REFUND_INITIAL_PAYMENT_DIALOG_EVENT);
}

export default class extends Controller {
  static targets = ["confirmButton", "confirmation"];

  connect() {
    dialogPolyfill.registerDialog(this.targetDialog);
    subscribe(SHOW_REFUND_INITIAL_PAYMENT_DIALOG_EVENT, this.showDialog);
  }

  disconnect() {
    unsubscribe(SHOW_REFUND_INITIAL_PAYMENT_DIALOG_EVENT, this.showDialog);
  }

  showDialog = () => {
    this.targetDialog.showModal();
  }

  confirmRefund = () => {
    this.submitRefund();
  }

  /**
   * Handles POST request to refund a cash or card initial payment
   */
  submitRefund = () => {
    const url = this.confirmButtonTarget.dataset.url;
    const contractId = this.confirmButtonTarget.dataset.contractId;
    const request = window.acima.fetchInit({
      method: "POST",
      body: JSON.stringify({ contract_id: contractId })
    });

    this.preventSubmitWhileFetching(true);
    this.closeDialog();
    showSpinnerDialog();

    fetch(url, request)
      .then(response => response.json())
      .then(jsonResponse => this.handleResponse(jsonResponse))
      .catch(err => console.error(err))
      .finally(() => this.preventSubmitWhileFetching(false));
  }

  /**
   * Handles the fetch response by reloading the page on success or throwing
   * an error that is caught by handleError.
   *
   * @param {Object} jsonResponse fetch response object
   */
  handleResponse(jsonResponse) {
    closeSpinnerDialog();

    if (jsonResponse.success) {
      this.openRefundConfirmationDialog();
    } else {
      this.handleError(jsonResponse);
    }
  }


  /**
   * Handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    showErrorDialog(`${err.message}`);
  }

  closeDialog() {
    this.targetDialog.close();
  }

  preventSubmitWhileFetching(fetching) {
    if (fetching) {
      this.confirmButtonTarget.disabled = true;
    } else {
      this.confirmButtonTarget.disabled = false;
    }
  }

  // Show confirmation modal:
  getThenRenderNextView(modalDiv, url) {
    const request = { method: "GET" };

    return fetch(url, window.acima.fetchInit(request))
      .then(response => response.text())
      .then(text => modalDiv.innerHTML = text)
      .catch(err => console.error(err));
  }

  openRefundConfirmationDialog() {
    const confirmationUrl = this.confirmationTarget.dataset.refundConfirmationUrl;
    const render = this.getThenRenderNextView(
      document.getElementById("refund-confirmation-modal"),
      confirmationUrl
    );

    render.then(() => showRefundConfirmationDialog())
      .catch(err => console.error(err));
  }

  get targetDialog() {
    return document.getElementById("refund-initial-payment-modal");
  }
}
