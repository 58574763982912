import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "closeButton"
  ]

  closeDialog() {
    if (this.targetInitialPaymentRefundDialog) {
      this.targetInitialPaymentRefundDialog.close();
    }
    if (this.targetOptionalPaymentRetryDialog) {
      this.targetOptionalPaymentRetryDialog.close();
    }
    if (this.targetRefundConfirmationDialog) {
      this.targetRefundConfirmationDialog.close();
    }
    if (this.targetViewReceiptDialog) {
      this.targetViewReceiptDialog.close();
    };
    if (this.targetInitialPaymentDialog) {
      this.targetInitialPaymentDialog.close();
    }
    this.takePaymentsInner.remove();
  }

  refreshBrowser() {
    this.closeButtonTarget.classList.add("disabled");
    window.location.reload();
  }

  get targetInitialPaymentDialog() {
    return document.getElementById("take-initial-payment-modal");
  }

  get targetInitialPaymentRefundDialog() {
    return document.getElementById("refund-initial-payment-modal");
  }

  get targetOptionalPaymentRetryDialog() {
    return document.getElementById("retry-optional-payment-modal");
  }

  get targetRefundConfirmationDialog() {
    return document.getElementById("refund-confirmation-modal");
  }

  get takePaymentsInner() {
    return document.getElementById("take-payments-inner");
  }

  get targetViewReceiptDialog() {
    return document.getElementById("view-receipt-modal");
  }
}
