import {Controller} from "stimulus";

/**
 * Only loads the quick lease filters when not on mobile views
 */
export default class extends Controller {
  static targets = ["bucket"]

  /**
   * Checks if the partial should get displayed
   * then loads the partial, else returns
   */
  connect() {
    if (this.shouldDisplayPartial) {
      this.loadLeaseBucketPartial();
    } else {
      return;
    }
  }

  /**
   * Renders the partial in the target div
   * after a fetch to the LeaseBucketsController
   */
  loadLeaseBucketPartial() {
    const url = this.data.get("url");
    const fetchWith = window.acima.fetchInit({method: "GET"});

    fetch(url, fetchWith)
        .then(function(response) {
          if (response.ok) {
            return response.text();
          }
        })
        .then((html) => this.bucketTarget.innerHTML = html);
  }

  /**
   * Checks to see if the element has a diplay property
   * returns the result as a boolean
   * display property set by CSS for different screen sizes
   * @return {boolean} display property not "none"
   */
  get shouldDisplayPartial() {
    const computedStyle = window.getComputedStyle(this.bucketTarget);
    return computedStyle.getPropertyValue("display") != "none";
  }
}
