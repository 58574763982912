import { Controller } from "stimulus";
import {
  showErrorDialog
} from "../../../shared/common/dialogs/error_dialog_controller";

/**
 * Manages bundle dialog action used in applicant Edit form
 */

export default class extends Controller {
  connect() {
    dialogPolyfill.registerDialog(this.element);
    this.url = this.data.get("url");
    this.errorMessage = this.data.get("errorMessage");
    this.reason = null;
  }

  /**
   * Launch bundle dialog
   */

  launchDialog() {
    this.element.showModal();
  }

  /**
   * Cancel lease application if applicant is bundling.
   * Bundling is when an Applicant is using multiple leasing companies.
   */

  confirmBundled() {
    const modalController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("#edit-applicant-container"),
      "users--contracts--actions--edit-applicant-details"
    );
    modalController.requestFormSubmit("bundle-dialog");

    this.closeDialog();
  }

  /**
   * Handles the fetch response by reloading the page on success or throwing
   * an error that is caught by handleError.
   */

  handleResponse(jsonResponse) {
    if (!jsonResponse.success) {
      throw new Error(jsonResponse.message);
    }

    this.closeDialog();
    window.location.reload();
  }

  /**
   * Handles errors in fetch
   */

  handleError(err) {
    this.closeDialog();
    showErrorDialog(`${err.message}`);
  }

  closeDialog() {
    this.element.close();
  }
}
