/**
 *  Helper script to handle foreign tax validations
 **/

export function staticTargets() {
  return [
    "goodsAndServicesTax",
    "goodsAndServicesTaxStatus",
    "goodsAndServicesTaxInvalid",
    "provincialSalesTax",
    "provincialSalesTaxStatus",
    "provincialSalesTaxInvalid",
    "harmonizedSalesTax",
    "harmonizedSalesTaxStatus",
    "harmonizedSalesTaxInvalid",
    "puertoRicanSalesTax",
    "puertoRicanSalesTaxStatus",
    "puertoRicanSalesTaxInvalid",
    "requiredField",
    "errorMessages"
  ];
};

/** Check if field value is a positive number.
 * @param {String} value of current field.
 * @return {Boolean} true/false.
 */
export function isPositiveNumberGreaterThanZero(value) {
  return /^\d*\.?\d*$/.test(value) && parseFloat(value) >= 0;
};

/** Check if field value is not empty.
 * @param {String} value of current field.
 * @return {Boolean} true/false.
 */
export function isNotEmptyField(value) {
  return value !== "" || value.length > 0;
}

/** Display error message
 * @param {Object} target reference of element
 * @param {String} message error
 */
function displayErrorMessage(target, message) {
  target.classList.remove("hidden");
  target.textContent = message;
};

/** Toggle class error message
 * @param {Object} target reference of element
 * @param {Boolean} status of class error
 */
function toggleClassError(target, status) {
  if (status) {
    target.classList.remove("has-error");
    target.classList.add("has-success");
  } else {
    target.classList.remove("has-success");
    target.classList.add("has-error");
  }
};

/** Validates general condition
 * @param {Object} controller reference of controller
 * @param {Object} target reference of element
 * @param {String} message of condition
 * @param {Boolean} status is the result of condition
 */
export function validateStatus(controller, target, message, status) {
  const fieldName = target.dataset.fieldname;
  const fieldNameStatusTarget = controller[fieldName + "StatusTarget"];
  const fieldNameInvalidTarget = controller[fieldName + "InvalidTarget"];

  displayErrorMessage(fieldNameInvalidTarget, message);
  toggleClassError(fieldNameStatusTarget, status);
};

/** Returns array of foreign tax targets if present
 * @return {Array} foreign tax fields
 */
export function foreignTaxesFields(controller) {
  const foreignTaxes = [];
  if (controller.hasGoodsAndServicesTaxTarget) {
    foreignTaxes.push(controller.goodsAndServicesTaxTarget);
  }
  if (controller.hasHarmonizedSalesTaxTarget) {
    foreignTaxes.push(controller.harmonizedSalesTaxTarget);
  }
  if (controller.hasProvincialSalesTaxTarget) {
    foreignTaxes.push(controller.provincialSalesTaxTarget);
  }
  if (controller.hasPuertoRicanSalesTaxTarget) {
    foreignTaxes.push(controller.puertoRicanSalesTaxTarget);
  }
  return foreignTaxes;
};

/** Validates foreign taxes
 * @param {Object} controller reference of controller
 * @return {Boolean} true/false
 */
export function validForeignTaxes(controller) {
  const foreignTaxFields = foreignTaxesFields(controller);

  return foreignTaxFields.length === 0 ||
    foreignTaxFields.every(target =>
      isPositiveNumberGreaterThanZero(target.value) && isNotEmptyField(target.value)
    );
};

