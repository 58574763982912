import { Controller } from "stimulus";
import {
  showDownloadableAttachmentsDialog
} from "../../text_messages/downloadable_attachments_dialog_controller";

export default class extends Controller {
  /*
   * Show the attachments dialog
   *
   * @param {Event} e event that triggered the action
   */
  showAttachmentsDialog(e) {
    const textMessageId = e.currentTarget.getAttribute("data-text-message-id");
    showDownloadableAttachmentsDialog(textMessageId);
  }
}
