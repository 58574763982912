import { Controller } from "stimulus";
import {
  showConfirmDialog
} from "../../../shared/common/dialogs/confirm_dialog_controller";
import {
  showErrorDialog
} from "../../../shared/common/dialogs/error_dialog_controller";
import {
  flash
} from "../../../shared/common/notices/flash_alerts_controller";

/**
 * Deletes a text message in the users contract communications tab
 */
export default class extends Controller {
  connect() {
    this.message = this.element.dataset.message;
    this.url = this.element.dataset.url;
    this.successMessage = this.element.dataset.successMessage;
  }

  /**
   * Handles the fetch response by showing a flash success and reloading
   * the page or throwing an error that is caught by handleError
   */
  handleResponse(jsonResponse) {
    if (jsonResponse.success) {
      this.showSuccessMessage();
    } else {
      this.handleError(new Error(jsonResponse.message));
    }

    window.location.reload();
  }

  /**
   * handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    showErrorDialog(err);
  }

  /**
   * Makes request to delete a text message
   */
  deleteMessage = () => {
    const request = window.acima.fetchInit({ method: "DELETE" });

    fetch(this.url, request)
      .then(response => response.json())
      .then(jsonResponse => this.handleResponse(jsonResponse))
      .catch(err => this.handleError(err));
  }

  /**
   * Sets up and shows the dialog
   */
  showDialog() {
    showConfirmDialog({
      message: this.message,
      action: this.deleteMessage
    });
  }

  /**
   * Display flash success to user
   */
  showSuccessMessage() {
    flash.success(`${this.successMessage}`);
  }
}
