import {Controller} from "stimulus";

// Polls for session expiration and triggers forced logout when session expires
export default class extends Controller {
  static targets = [
    "details",
    "forceLogoutOwnerSessionBtn",
  ]

  connect() {
    this.pollForExpiration(this.expiration());
  }

  pollForExpiration(expiration) {
    const exp = expiration;
    const btn = this.forceLogoutOwnerSessionBtnTarget;
    const logout = this.logout;

    setInterval(function() {
      const now = new Date();
      if (now >= exp) {
        logout(btn);
      }
    }, 10000); // every 10 seconds
  }

  expiration() {
    return new Date(this.detailsTarget.dataset.expiration);
  }

  logout(btn) {
    btn.click();
  }
}
