import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["syncPartial"];

  syncToOracle(event) {
    const url = event.target.dataset.url;
    const fetchWith = window.acima.fetchInit({ method: "PATCH" })

    fetch(url, fetchWith)
      .then(response => response.text())
      .then(html => this.replaceDOM(html))
  }

  replaceDOM(html) {
    const fragment = document.createRange().createContextualFragment(html)

    this.syncPartialTarget.replaceChildren(fragment)
  }
}
