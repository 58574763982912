function clickToCopy() {
  const clickToCopy = document.querySelectorAll("clipboard-copy");
  if (clickToCopy.length > 0) {
    clickToCopy.forEach(function(elem) {
      elem.addEventListener("copy", function() {
        document.querySelectorAll(".click-to-copy").forEach(function(elem) {
          elem.dataset.originalTitle = "Click to copy";
        });

        this.dataset.originalTitle = "Copied to clipboard!";
      });
      elem.addEventListener("click", function() {
        event.preventDefault();
      });
    });
  }
}

export default clickToCopy();
