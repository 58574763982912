import {Controller} from "stimulus";

/** Handle Edit Account modal functionality */
export default class extends Controller {
  static targets = [
    "maskedAccountNumber",
    "hiddenAccountNumber",
    "updateAccountBtn",
    "bankName",
    "errors",
    "routingNumber",
    "ownerId",
    "ownerType",
  ]

  /** Register dialog */
  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  /**
   * Watch for account number change.
   */
  changeAccountNumber() {
    const accountNum = this.maskedAccountNumberTarget.value;
    this.hiddenAccountNumberTarget.value = accountNum;
  }

  /**
   * Watch for account number focus.
   */
  focusAccountNumber() {
    this.maskedAccountNumberTarget.value = "";
  }

  /**
   * Get account from launch controller, open modal & fill with data
   * @param  {object} fundingAccount The funding account instance
   */
  openDialog(fundingAccount) {
    this.fundingAccount = fundingAccount;
    this.element.showModal();
    this.fillForm();
  }

  /** Close the dialog */
  closeDialog() {
    this.clearForm();
    this.element.close();
  }

  /** Fill form with selected account current data */
  fillForm() {
    this.bankNameTarget.value = this.fundingAccount.bank_name;
    this.routingNumberTarget.value = this.fundingAccount.routing_number;
    this.maskedAccountNumberTarget.value = this.fundingAccount.masked_account_number;
    this.hiddenAccountNumberTarget.value = this.fundingAccount.account_number;
  }

  /** Clear input fields when modal closed */
  clearForm() {
    this.maskedAccountNumberTarget.value = "";
    this.hiddenAccountNumberTarget.value = "";
    this.routingNumberTarget.value = "";
    this.bankNameTarget.value = "";
    this.clearErrors();
  }

  /** Clear error messages */
  clearErrors() {
    this.errorsTarget.classList.add("hide");
    this.errorsTarget.textContent = "";
  }

  /**
   * Disable submit button after click
   * @param  {event} event JS Event
   * @return {null} null Early return if already disabled
   */
  disableSubmitButton(event) {
    if ( event.currentTarget.disabled == true) return null;
    event.currentTarget.disabled = true;
    event.currentTarget.classList.add("disabled");
  }

  /** Enable submit button */
  enableSubmitButton() {
    this.updateAccountBtnTarget.disabled = false;
    this.updateAccountBtnTarget.classList.remove("disabled");
  }

  /** List errors at top of form
  * @param {string} message Error message from invalid form.
  */
  addError(message) {
    const item = document.createElement("LI");
    item.textContent = message;
    this.errorsTarget.classList.remove("hide");
    this.errorsTarget.appendChild(item);
  }

  /** Handle form submission
  * @param {event} event Action that submits form.
  */
  submitForm(event) {
    const controller = this;
    const url = this.data.get("url");
    const requestHash = {method: "PATCH", body: this.params()};
    const fetchWith = window.acima.fetchInit(requestHash);

    controller.disableSubmitButton(event);

    fetch(url, fetchWith).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.success == false && data.redirect) {
        window.location = data.redirect;
        return false;
      }

      if (data.success == true) {
        controller.clearForm();
        window.location.reload();
      } else {
        controller.clearErrors();
        data.message.forEach((message) => {
          controller.addError(message);
        });
        controller.enableSubmitButton();
      }
    });
  }

  /**
   * Define params for Post request
   * @return {JSON} params JSON string
   */
  params() {
    const ownerId = this.data.get("ownerId");
    const ownerType = this.data.get("ownerType");
    const accountId = this.fundingAccount.id;

    return JSON.stringify({
      id: accountId,
      account_number: this.hiddenAccountNumberTarget.value,
      routing_number: this.routingNumberTarget.value,
      bank_name: this.bankNameTarget.value,
      owner_id: ownerId,
      owner_type: ownerType,
    });
  }
}
