import {Controller} from "stimulus";
import cable from "../../channels/helpers/cable";
import ContractPresenceChannel from "../../channels/contract_presence_channel";

/** Contract locking presence controller */
export default class extends Controller {
  /**
   * Establishes a Web Socket connection to verify merchant presence on a
   * specific contract
   */
  connect() {
    this.contractPresenceChannel = new ContractPresenceChannel(this.params);
    cable.subscribe(this.contractPresenceChannel);
    this.contractPresenceChannel.perform("populate_subscribers");
  }

  get params() {
    return {
      leaseId: this.data.get("contractId"),
      userId: this.data.get("merchantId"),
      currentView: "merchant"
    };
  }
}
