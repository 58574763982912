import {Controller} from "stimulus";

/** Code Signature dialog */
export default class extends Controller {
  static targets = []

  /**
    * Connect the controller and trigger redirect
    */
  connect() {
    this.delaySeconds = this.data.get("delaySeconds");
    this.redirectUrl = this.data.get("redirectUrl");
    this.redirect();
  }

  /**
   * Sets timeout to redirect window location
   */
  redirect() {
    setTimeout(
        () => (window.location.href = this.redirectUrl),
        this.delaySeconds * 1000,
    );
  }
}
