import {Controller} from "stimulus";

/**
 * Launches owners funding account dialogs.
 */
export default class extends Controller {
    static targets = [
      "editLocationModal",
    ]

    /** Launch edit location modal
     * @param {event} event Click action on the Edit Button
     */
    launchEditLocation(event) {
      const modalController =
            this.application.getControllerForElementAndIdentifier(
                document.querySelector("#edit-location-modal"),
                "owners--edit-location",
            );
      const cardData = this.cardData(event);
      modalController.openDialog(this.fundingAccount(event),
          this.locationData(event), cardData);
    }

    /**
     * Get the funding account instance hash
     * @param {event} event Click action on the Edit Button
     * @return {object} event Object hash
     */
    fundingAccount(event) {
      return JSON.parse(event.currentTarget.dataset["bankAccount"]);
    }

    /**
     * Get the location instance hash
     * @param {event} event Click action on the Edit Button
     * @return {object} event Object hash
     */
    locationData(event) {
      return JSON.parse(event.currentTarget.dataset["locationData"]);
    }

    /**
     * Get the instant funding card instance hash
     * @param {event} event Click action on the Edit Button
     * @return {object} event Object hash
     */
    cardData(event) {
      return JSON.parse(event.currentTarget.dataset["cardData"]);
    }
}
