import { Controller } from "stimulus"

// controls new merchant form and merchant settings edit form industry selectors
export default class extends Controller {
  static targets = [ "industries", "primaryIndustry" ]

  getIndustries() {
    const selectedIndustries = [];
    [...this.industriesSelector].forEach(function(option) {
      if (option.selected) {
        selectedIndustries.push(option)
      }
    });

    this.aggregatePrimaryIndustry(selectedIndustries)
    if (this.industriesSelector.selectedOptions.length == 1 ){
      this.selectOnlyOptionForPrimary();
    }
  }

  aggregatePrimaryIndustry(selectedIndustries){
    const primaryIndustrySelector = this.primaryIndustrySelector;
    this.clearPrimaryIndustries()

    selectedIndustries.forEach(function(option) {
      const newOption = new Option(option.text, option.value);

      primaryIndustrySelector.appendChild(newOption);
    });
  }

  clearPrimaryIndustries(){
    this.primaryIndustrySelector.innerHTML = null;
    this.primaryIndustrySelector.appendChild(new Option('',''));
  }

  selectOnlyOptionForPrimary(){
    this.primaryIndustrySelector.options[1].selected = true;
  }

  get industriesSelector() {
    return this.industriesTarget;
  }

  get primaryIndustrySelector() {
    return this.primaryIndustryTarget;
  }
}
