import { Controller } from "stimulus";
import { alertHelper } from "../../../../helpers/alert_helpers";
import { renderDialogBodySpinner } from "../../../../helpers/spinner_helpers";

export default class extends Controller {
  static targets = [
    "submitButton",
    "initialPaymentAmount",
    "fromAmount",
    "toAmount",
    "alertNotice",
    "dialogBody"
  ]

  connect() {
    this.url = this.data.get("url");
    this.amountRangeWarning = this.data.get("amount-range-warning");
    this.handleFromAmount();
    dialogPolyfill.registerDialog(this.element);
  }

  handleInitialPaymentAmount() {
    this.validateAmount();
    const amount = this.initialPaymentAmountTarget.value;
    if (!(this.fromAmountTarget.value == 0)) {
      this.toAmountTarget.value = amount;
    }
    if (amount > 0 && amount <= 100) {
      alertHelper.clear(this.alertNoticeTarget, this.amountRangeWarning);
      this.enableSubmit();
    } else if (parseInt(amount) == 0) {
      alertHelper.clear(this.alertNoticeTarget, this.amountRangeWarning);
      this.enableSubmit();
    } else {
      if (amount > 100) {
        alertHelper.error(this.alertNoticeTarget, this.amountRangeWarning);
      }
      this.disableSubmit();
    }
  }

  handleFromAmount() {
    if (this.fromAmountTarget.value == 0) {
      this.fromAmountTarget.parentNode.classList.add("hidden");
      this.toAmountTarget.parentNode.classList.add("hidden");
    }
  }

  enableSubmit() {
    this.submitButtonTarget.classList.remove("disabled");
  }

  disableSubmit() {
    this.submitButtonTarget.classList.add("disabled");
  }

  showDialog() {
    this.element.showModal();
  }

  closeDialog() {
    this.element.close();
  }

  requestBody() {
    return JSON.stringify({
      override: {
        initial_payment: this.initialPaymentAmountTarget.value
      }
    });
  }

  submit() {
    const request = {
      method: "POST",
      body: this.requestBody()
    };
    const fetchWith = window.acima.fetchInit(request);
    renderDialogBodySpinner(this.dialogBodyTarget);
    fetch(this.url, fetchWith)
      .then(response => response.json())
      .then(data => this.handleResponse(data))
      .catch(error => this.handleError(error));
  }

  validateAmount() {
    if (this.initialPaymentAmountTarget.value == "") {
      alertHelper.clear(this.alertNoticeTarget, this.amountRangeWarning);
      return;
    } else {
      return this.initialPaymentAmountTarget.value =
        accounting.toFixed(this.initialPaymentAmountTarget.value, 0);
    }
  }

  handleResponse(responseData) {
    this.dialogBodyTarget.innerHTML = "";
    if (responseData.success) {
      alertHelper.success(this.alertNoticeTarget, responseData.message);
      setTimeout(location.reload(), 1000);
    } else {
      alertHelper.error(this.alertNoticeTarget, responseData.message);
      setTimeout(location.reload(), 1000);
    }
  }

  handleError(error) {
    this.dialogBodyTarget.innerHTML = "";
    alertHelper.error(this.alertNoticeTarget, error);
    console.error(error);
    setTimeout(location.reload(), 1000);
  }
}
