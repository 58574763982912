import {Controller} from "stimulus";

/**
 * Manages a simple file upload field
 *
 * This allows us to have a cleaner looking file picker without using a third
 * party library like bootstrap-fileupload.
 *
 * Instead of using bootstrap-fileupload, use the helper method for rendering
 * this component. If you have a specific form object(from form_for) you can
 * pass it along as the `form:` option or just pass the field_name for basic
 * forms and it won't worry about associating with a form object
 *
 * = form_for(...) do |f|
 *   .form-group
 *     label Document
 *     = file_uploader_field(field_name: :attachment, form: f)
 *
 * If you have an external controller that needs to monitor changes to the file
 * uploader you can pass a change action that will be called for the field and a
 * target that will be attached to the field.
 *
 * = form_for(...) do |f|
 *   .form-group
 *     label Document
 *     = file_uploader_field(\
 *        field_name: :attachment,
 *        form: f,
 *        file_action: "change->some--other--controller#changeFile",
 *        file_target: "some--other--controller.fileField",
 *      )
 **/
export default class extends Controller {
  static targets = [
    "displayField",
    "selectButton",
    "clearButton",
    "actualFileField",
  ]

  changeFile(e) {
    // Isolate just the last part(the file name) of the full file path
    const fileName = e.currentTarget.value.split("\\").pop();

    this.toggleDisplayField(fileName);

    if (fileName.length) {
      // If a filename is selected, show clear button
      this.selectButtonTarget.classList.add("hide");
      this.clearButtonTarget.classList.remove("hide");
    } else {
      // If a filename is NOT selected, show select button
      this.selectButtonTarget.classList.remove("hide");
      this.clearButtonTarget.classList.add("hide");
    }
  }

  /**
   * Clears the actual file field value and dispatches a change event so that
   * this controller and any other listening controllers can recognize the file
   * field changed and run their actions.
   *
   * Causes changeFile() to trigger
   */
  clearFile() {
    this.actualFileFieldTarget.value = "";
    this.actualFileFieldTarget.dispatchEvent(new Event("change"));
  }

  /**
   * This toggles the fake file upload display field out of readonly so that
   * we can set the value of the field and then turns readonly back on.
   *
   * @param {string} value filename to display
   */
  toggleDisplayField(value) {
    this.displayFieldTarget.removeAttribute("readonly");
    this.displayFieldTarget.value = value;
    this.displayFieldTarget.setAttribute("readonly", "");
  }
}
