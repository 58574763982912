import {Controller} from "stimulus";
import {isNil} from "lodash";
import {showAlertListDialog} from "./alert_list_dialog_controller";

/**
 * Shared controller for navbar alert lists
 * Fetches alert counts and renders a badge if any alerts are present
 * launches the alert dialog on click
*/
export default class extends Controller {
  static targets = [
    "alertLink",
  ];

  /**
   * Connect controller
   */
  connect() {
    this.resourceUrl = this.data.get("resourceUrl");
    this.fetch();
  }

  /**
   * Sets the alert badge class when alerts exist
   *
   * @param {number} count number of alerts found
   */
  setAlertClass(count) {
    if (isNil(count) || count === 0) {
      return;
    }

    this.alertLinkTarget.classList.add(this.data.get("alertClassname"));
  }

  /**
   * Handles error responses, logs error to console
   *
   * @param {Error} err
   */
  handleError(err) {
    console.error("Error: ", err);
  }

  /**
   * Handles success responses
   *
   * @param {object} responseJson parsed json response
   */
  handleResponse(responseJson) {
    if (!responseJson.success) {
      throw new Error(responseJson.message);
    }

    this.setAlertClass(responseJson.count);
  }

  /**
   * Fetch alert list count
   */
  fetch() {
    const request = {method: "GET"};

    fetch(`${this.resourceUrl}.json`, window.acima.fetchInit(request))
        .then((res) => res.json())
        .then((json) => this.handleResponse(json))
        .catch((err) => this.handleError(err));
  }

  /**
   * Show alert list dialog
   */
  showDialog() {
    showAlertListDialog({
      url: this.resourceUrl,
      title: this.data.get("alertListTitle"),
      dialogClassname: this.data.get("alertDialogClassname"),
    });
  }
}
