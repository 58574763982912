import { Controller } from "stimulus";

export default class extends Controller {
  print() {
    const url = event.target.dataset.url + ".pdf?" + new URLSearchParams({
      payment_params: event.target.dataset.params
    });
    window.open(url, "_blank");
  }
}
