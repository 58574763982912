import { Controller } from "stimulus";

/** controller for quick links menu item */
export default class extends Controller {
  static targets = ["switchBavDcp"]

  /** @return {fetch} user choice from popup */
  selectCondition() {
    return this.doFetch(this.fetchUrl);
  }

  /** @return {fetch} result */
  switchCondition(event) {
    event.preventDefault();
    const fetchIt = this.doFetch;
    const url = this.fetchUrl;
    const selectedType = this.switchBavDcpTarget.dataset.bavdcpTypeSelected;

    if ( selectedType == "voided_check" ) {
      const msg = "By switching to BAV, completed lease" +
      " agreements and payments will be cancelled and refunded." +
      " A new lease agreement will need to be signed and payment collected." +
      " Are you sure you want to continue?";
      App.confirmDialog( msg, function() {
        return fetchIt(url);
      });
    } else if ( selectedType == "debit_card_payment" ) {
      return fetchIt(url);
    }
  }

  /** @param {url} url*/
  doFetch(url) {
    const fetchWith = window.acima.fetchInit({method: "GET"});
    fetch(url, fetchWith
    ).then((res) => res.json()
    ).then(function(res) {
      if (res.success) {
        location.reload();
      } else {
        show_common_error_dialog("An unexpected error has occurred.");
        console.error(res);
      }
    }).catch((error) => console.error("Error:", error));
  }

  /** returns {url} for ajax fetch*/
  get fetchUrl() {
    const requestUrl = new URL(new URL(window.location).origin
      + this.switchBavDcpTarget.dataset.conditionUrl);
    const params = new URLSearchParams(requestUrl.search);
    params.append("selected_type",
      this.switchBavDcpTarget.dataset.bavdcpTypeSelected);
    requestUrl.search = params;

    return requestUrl.toString();
  }
}
