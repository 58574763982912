import {Controller} from "stimulus";
import {
  showErrorDialog,
} from "../../../shared/common/dialogs/error_dialog_controller";

/**
 * Manages override approval action for user contracts
 */
export default class extends Controller {
  connect() {
    const controllerName = "users--contracts--actions--override-dialog";
    this.targetDialog = this.application.getControllerForElementAndIdentifier(
        document.querySelector(`[data-controller="${controllerName}"]`),
        controllerName,
    );
    this.outstandingConditionsUrl = this.data.get("outstandingConditionsUrl");
    this.disabledMessage = this.data.get("disabledMessage");
  }

  /**
   * Handles the initial outstanding conditions fetch response by rendering an
   * error dialog if there are outstanding conditions or showing the override
   * dialog with rails form.
   *
   * @param {Object} jsonResponse fetch response object
   */
  handleResponse(jsonResponse) {
    if (jsonResponse.outstanding_conditions) {
      showErrorDialog(this.disabledMessage);
    } else {
      this.targetDialog.showDialog();
    }
  }

  /**
   * Handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    console.error(err);
    showErrorDialog(`${this.errorMessage} ${err.message}`);
  }

  /**
   * We first have to check if there are any oustanding conditions and show an
   * error dialog if there are before showing the actual dialog which is just
   * a rails form. This is done in fetch so the sequence is async and needs
   * to be chained together.
   *
   * @param {event} e event that triggered action
   */
  showDialog(e) {
    if (e.currentTarget.hasAttribute("disabled")) {
      return;
    }

    const request = window.acima.fetchInit({method: "GET"});

    fetch(this.outstandingConditionsUrl, request)
        .then((response) => response.json())
        .then((jsonResponse) => this.handleResponse(jsonResponse))
        .catch((err) => this.handleError(err));
  }
}
