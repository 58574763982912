import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["formattedSurveyUrl"]

  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  launchDialog() {
    const surveyUrl = document.querySelector("#feedback-dialog").dataset.formattedSurveyUrl;
    document.getElementById("feedback-survey-iframe").setAttribute("src", surveyUrl);
    this.element.showModal();
  }

  closeDialog() {
    this.element.close();
  }
}
