import {Controller} from "stimulus";

/**
 * Launches arbitrary dialogs
 *
 * The button you want to launch a dialog with should trigger the launch action
 * and should have a `data-dialog` attribute with the name of the specific
 * dialog controller.
 *
 * It is common to need to launch dialogs from buttons that are not within
 * the scope of the dialog itself, this controller handles that for the basic
 * case where no addiitional context is needed to launch a dialog beyond its
 * name.
 *
 * The specific dialog controller should have a `showDialog` method that
 * shows the dialog.
 *
 * .section data-controller="shared--common--dialogs--dialog-launcher"
 *   .btn[
 *     data-action="shared--common--dialogs--dialog-launcher#launch"
 *     data-dialog="some--specific--dialog--controller"
 *   ] Launch
 */
export default class extends Controller {
  static targets = []

  launch(e) {
    if (e.currentTarget.hasAttribute("disabled")) {
      return;
    }

    const controllerName = e.currentTarget.dataset.dialog;
    const dialogController =
      this.application.getControllerForElementAndIdentifier(
          document.querySelector(`[data-controller="${controllerName}"]`),
          controllerName,
      );
    dialogController.showDialog(e);
  }
}
