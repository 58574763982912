import { Controller } from "stimulus";
import Clusterize from "clusterize.js";
import { buildPagination } from "../helpers/advanced_paging";
import { debounce } from "lodash";

export default class extends Controller {
  static targets = [
    "table",
    "tableParent",
    "pagination",
    "searchTermQuery",
  ];

  connect() {
    this.pageNumber = 1;
    this.merchantLeadsUrl = this.data.get("url");
    this.merchantApplicantContactedUrl = this.data.get("contactedUrl");
    this.debounceFetchLeads = debounce(this.fetchLeads, 1000);
    this.sortBy = null;
    this.sortDirection = null;
    this.fetchLeads();
  }

  renderSpinner() {
    this.tableTarget.innerHTML = `
      <tr>
        <td colspan="7">${acima.i18n.t("merchants.merchant_leads.loading")}...</td>
      </tr>
      <tr>
        <td colspan="7"><div class="loading-spinner loading-spinner-lg"></div></td>
      </tr>
    `;
  }

  renderRow(rowData) {
    return `
      <tr>
        <td>
          <input
            type="checkbox"
            ${rowData.contacted ? "checked" : ""}
            ${rowData.contacted ? "disabled" : ""}
            data-applicant-id="${rowData.applicant_id}"
            data-action="change->merchants--merchant-leads#handleCheckboxClick"
          >
        </td>
        <td>${rowData.full_name}</td>
        <td>${rowData.approval_amount}</td>
        <td>${rowData.opportunity_type}</td>
        <td>${rowData.approval_expiration}</td>
        <td>${rowData.phone}</td>
        <td>${rowData.email}</td>
      </tr>
    `;
  }

  renderList(contacts) {
    new Clusterize({
      rows: contacts.map((rowData) => this.renderRow(rowData)),
      scrollId: this.tableParentTarget.id,
      contentId: this.tableTarget.id,
    });
  }

  handleError(error) {
    return show_common_error_dialog(acima.i18n.t("merchants.merchant_leads.errors.fetching_contacts"));
  }

  handleResponse(responseJson) {
    this.renderPagination(responseJson.pagination);
    this.renderList(responseJson.rows);
  }

  formatQueryUrl() {
    const queryParams = {
      page: this.pageNumber,
      search_term: this.searchTermQueryTarget.value,
      sort_by: this.sortBy || "",
      sort_direction: this.sortDirection || "",
    };

    const url = new URL(this.merchantLeadsUrl, window.location);

    Object.keys(queryParams).forEach((key) => url.searchParams.append(key, queryParams[key]));

    return url;
  }

  fetchLeads(currentTarget) {
    this.renderSpinner();
    const fetchWith = window.acima.fetchInit({ method: "GET" });

    fetch(this.formatQueryUrl(), fetchWith)
      .then((res) => res.json())
      .then((json) => this.handleResponse(json))
      .catch((error) => this.handleError(error));
  }

  renderPagination(paginationData) {
    this.paginationTarget.innerHTML = buildPagination({
      ...paginationData,
      action: "data-action='merchants--merchant-leads#changePage'",
    });
  }

  changePage(e) {
    if (e.currentTarget.parentElement.classList.contains("disabled")) {
      return;
    }

    this.pageNumber = e.currentTarget.getAttribute("data-page");
    this.fetchLeads();
  }

  handleSorting(e) {
    const attribute = e.currentTarget.getAttribute("data-sort-by");

    if (this.sortBy === attribute) {
    } else {
      this.sortBy = attribute;
    }

    this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    this.updateSortArrow(e, this.sortDirection);
    this.fetchLeads();
  }

  markAsContacted(applicantId, element) {
    const fetchWith = window.acima.fetchInit({
      method: "POST",
      body: JSON.stringify({
        applicant_id: applicantId,
      })
    });

    fetch(this.merchantApplicantContactedUrl, fetchWith)
      .then((res) => res.json())
      .then((json) => element.setAttribute("disabled", "disabled"))
      .catch((error) => show_common_error_dialog(acima.i18n.t("merchants.merchant_leads.errors.mark_as_contacted")));
  }

  handleCheckboxClick(e) {
    if (!e.currentTarget.checked) {
      return;
    }

    const applicantId = e.currentTarget.getAttribute("data-applicant-id");

    this.markAsContacted(applicantId, e.currentTarget);
  }

  updateSortArrow(e, sortDirection) {
    const icon = e.currentTarget.querySelector("i");
    icon.classList.remove("fa-sort");

    if (sortDirection === "asc") {
      icon.classList.remove("fa-angle-up");
      icon.classList.add("fa-angle-down");
    } else {
      icon.classList.remove("fa-angle-down");
      icon.classList.add("fa-angle-up");
    }
  }
}
