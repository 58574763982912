import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "invoiceContainer"
  ]

  invoiceBreakdownHtml = "";

  requestInvoiceNew() {
    const url = this.data.get("invoiceNewUrl");
    const container = this.invoiceContainerTarget;

    this.getThenRenderForm(url, container);
  }

  requestInvoiceEdit() {
    this.invoiceBreakdownHtml = this.invoiceContainerTarget.innerHTML;

    const url = this.invoiceContainerTarget.dataset["invoiceEditUrl"];
    const container = this.invoiceContainerTarget;

    this.getThenRenderForm(url, container);
  }

  requestPrefundedInvoiceAdjustment() {
    this.invoiceBreakdownHtml = this.invoiceContainerTarget.innerHTML;
    this.prefundedAdjustmentFormHtml = this.invoiceContainerTarget.innerHTML;

    const url = this.invoiceContainerTarget.dataset["prefundedAdjustmentUrl"];
    const container = this.invoiceContainerTarget;

    this.getThenRenderForm(url, container);
  }

  getThenRenderForm(url, container) {
    const request = { method: "GET" };

    fetch(url, window.acima.fetchInit(request))
      .then(response => response.text())
      .then(text => container.innerHTML = text)
      .catch(err => console.error(err));
  }

  cancelEdit() {
    this.invoiceContainerTarget.innerHTML = this.invoiceBreakdownHtml;
  }
}
