import { Controller } from "stimulus";
import CoworkerSitewideNotificationChannel
  from "~/channels/coworker_sitewide_notification_channel";
import cable from "~/channels/helpers/cable";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.subscribeToCoworkerSitewideNotifications();
  }

  subscribeToCoworkerSitewideNotifications() {
    const userGuid = this.containerTarget.dataset.userGuid;
    this.channel = new CoworkerSitewideNotificationChannel({
      user_guid: userGuid,
    });

    cable.subscribe(this.channel).ensureSubscribed();
    this.channel.on("message", msg => this.displayNotification(msg));
  }

  displayNotification(msg) {
    if (msg.event_name == "optional_services_upsell_opportunity") {
      const notificationElement = document.createElement("div");
      notificationElement.innerHTML = msg.content.notification_content;
      notificationElement.classList.add("coworker-notification");

      if (this.notificationPresent(notificationElement)) {
        return;
      }
      this.containerTarget.appendChild(notificationElement);

      // Remove the notification after 30 seconds
      setTimeout(() => {
        notificationElement.remove();
      }, 30000);
    }
  }

  notificationPresent(notificationElement) {
    const containerNotifications = this.containerTarget.querySelector(".coworker-notification")
    return containerNotifications && containerNotifications.innerHTML === notificationElement.innerHTML;
  }

  dismissNotification(event) {
    this.containerTarget.removeChild(event.target.closest(".coworker-notification"));
  }

  disconnect() {
    if (this.channel) {
      cable.unsubscribe(this.channel);
      this.channel = null;
    }
  }
}
