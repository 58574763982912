import {Controller} from "stimulus";

/**
 * Controller for handling lease OFAC condtions
 * TODO: refactor this file and the _lease_conditions_list
 * to use more stimulus like features
 */
export default class extends Controller {
  static targets = ["radio"];
  /**
   * Setup needed targets and add some event listeners
   */
  connect() {
    // Defining targets due to no data attribute on the
    // bootbox dialog
    this.cancelTarget = document.querySelector(".ofac-cancel-button");
    this.submitTarget = document.querySelector(".ofac-clear-button");
    this.submitTarget.disabled = true;
    this.submitTarget.addEventListener("click", () => this.clearOfac());
  }

  /**
   * Render the form ready to submit
   * @param {event} event
   */
  changeSelection(event) {
    if (this.selectedRadio) {
      this.submitTarget.disabled = false;
      this.submitTarget.classList.remove("disabled");
    }
  }

  /**
   * Submit and clear the OFAC condtion
   */
  clearOfac() {
    if (this.selectedRadio && this.submitTarget.disabled == false) {
      this.submitTarget.disabled = true;

      const fetchWith = window.acima.fetchInit({method: "POST"});
      const params = new URLSearchParams({
        ofac_condition: this.selectedRadio[0],
      }).toString();
      const url = this.data.get("url");
      fetch(`${url}?${params}`, fetchWith).then((response) => {
        return response.json();
      }).then((data) => {
        if (data.success) {
          window.page_reload_submission_helper();
        } else {
          show_common_error_dialog(data.message);
        }
      }).catch((req, status, error) => {
        show_common_error_dialog(
            `An unexpected error has occurred (${error}). Try again.`,
        );
      });
    }
  }

  /**
   * Provides the value from the selected raio
   */
  get selectedRadio() {
    return this.radioTargets.map((elem) => {
      if (elem.checked) {
        return elem.value;
      }
    }).filter(Boolean);
  }
}
