import {Controller} from "stimulus";
import {publish, subscribe} from "../../helpers/pub_sub";
import {
  showErrorDialog,
} from "../../shared/common/dialogs/error_dialog_controller";
import {renderDialogBodySpinner} from "../../helpers/spinner_helpers";

const EVENT_NAME = "showDownloadableAttachmentsDialogEvent";

/**
 * Publishes the show dialog event
 *
 * @param {number} textMessageId mp ID to fetch
 */
export function showDownloadableAttachmentsDialog(textMessageId) {
  publish(EVENT_NAME, {textMessageId});
}

/** Text Message Downloadable Attachments dialog */
export default class extends Controller {
  static targets = [
    "attachments",
  ]

  /**
   * Register the HTML dialog
   */
  connect() {
    this.baseResourceUrl = this.data.get("baseResourceUrl");
    this.subResourcePath = this.data.get("subResourcePath");
    renderDialogBodySpinner(this.attachmentsTarget);
    dialogPolyfill.registerDialog(this.element);
    subscribe(EVENT_NAME, this.showDialog);
  }

  /**
   * Handles hard errors in fetch
   *
   * @param {Error} err
   */
  handleError(err) {
    console.error("Error: ", err);
    this.closeDialog();
    showErrorDialog(this.data.get("errorMessage"));
  }

  /**
   * Handles success and failure response in fetch
   *
   * @param {string} responseBody parsed response body string containing markup
   */
  handleResponse(responseBody) {
    this.renderDetails(responseBody);
  }

  /**
   * Fetch text message downloadable attachments
   *
   * @param {number} textMessageId mp ID to fetch
   */
  fetch(textMessageId) {
    const url = [
      this.baseResourceUrl,
      textMessageId,
      this.subResourcePath,
    ].join("/");
    const request = {method: "GET"};

    fetch(url, window.acima.fetchInit(request))
        .then((res) => {
          if (res.status == 200) {
            return res.text();
          } else {
            throw new Error(res.status);
          }
        })
        .then((body) => this.handleResponse(body))
        .catch((err) => this.handleError(err));
  }

  /**
   * Renders the result of fetch
   *
   * @param {string} attachmentsMarkup markup string from fetch to render
   */
  renderDetails(attachmentsMarkup) {
    this.attachmentsTarget.innerHTML = attachmentsMarkup;
  }

  /**
   * Close the dialog and reset body markup to spinner
   */
  closeDialog() {
    renderDialogBodySpinner(this.attachmentsTarget);
    this.element.close();
  }

  /**
   * Trigger data fetch and show dialog
   *
   * @param {Object} data
   * @param {number} data.textMessageId ID to display attachments of
   */
  showDialog = ({textMessageId}) => {
    this.fetch(textMessageId);
    this.element.showModal();
  }
}
