import { Controller } from "stimulus";
import { LeaseWorkflowChannel } from "../../channels/lease_workflow_channel";
import cable from "../../channels/helpers/cable";

/** Lease condition poller replacement */
export default class extends Controller {
  /**
   * Establishes a Web Socket connection used to refresh a merchant page
   * when certain events are triggered in the workflow
   */

  connect() {
    this.leaseWorkflowChannel = new LeaseWorkflowChannel(this.params);
    cable.subscribe(this.leaseWorkflowChannel);
  }

  disconnect() {
    cable.unsubscribe(this.leaseWorkflowChannel);
  }

  /**
   * Build out the params for fetch request
   */
  get params() {
    return {
      contract_guid: this.data.get("leaseGuid"),
      workflow_state: this.data.get("leaseWorkflowState")
    };
  }
}
