/**
 * Helper script to handle foreign tax calculations
 **/

function getTaxPercentages(controller) {
  return {
    puertoRicanSalesTaxPercentage: controller.hasPuertoRicanSalesTaxTarget ? Number(controller.puertoRicanSalesTaxTarget.dataset.taxpercentage): 0,
    provincialSalesTaxPercentage: controller.hasProvincialSalesTaxTarget ? Number(controller.provincialSalesTaxTarget.dataset.taxpercentage) : 0,
    goodsAndServicesTaxPercentage: controller.hasGoodsAndServicesTaxTarget ? Number(controller.goodsAndServicesTaxTarget.dataset.taxpercentage) : 0,
    harmonizedSalesTaxPercentage: controller.hasHarmonizedSalesTaxTarget ? Number(controller.harmonizedSalesTaxTarget.dataset.taxpercentage) : 0,
  }
};

function fillTaxAmountValues(controller, taxAmounts) {
  // Rework all of the above commented code to use the controller object
  if (controller.hasHarmonizedSalesTaxTarget) {
    const harmonizedSalesTaxAmount = taxAmounts.harmonizedSalesTaxAmount.toFixed(2);

    controller.harmonizedSalesTaxTarget.value = harmonizedSalesTaxAmount;
    controller.harmonizedSalesTaxValueTarget.textContent = accounting.formatMoney(harmonizedSalesTaxAmount, "$", 2);
  }

  if (controller.hasProvincialSalesTaxTarget) {
    const provincialSalesTaxAmount = taxAmounts.provincialSalesTaxAmount.toFixed(2);

    controller.provincialSalesTaxTarget.value = provincialSalesTaxAmount;
    controller.provincialSalesTaxValueTarget.textContent = accounting.formatMoney(provincialSalesTaxAmount, "$", 2);
  }

  if (controller.hasGoodsAndServicesTaxTarget) {
    const goodsAndServicesTaxAmount = taxAmounts.goodsAndServicesTaxAmount.toFixed(2);

    controller.goodsAndServicesTaxTarget.value = goodsAndServicesTaxAmount;
    controller.goodsAndServicesTaxValueTarget.textContent = accounting.formatMoney(goodsAndServicesTaxAmount, "$", 2);
  }

  if (controller.hasPuertoRicanSalesTaxTarget) {
    controller.puertoRicanSalesTaxTarget.value = taxAmounts.puertoRicanSalesTaxAmount.toFixed(2);
  }
};

function calculateTaxAmounts(taxPercentages, invoiceTotal) {
  return {
    puertoRicanSalesTaxAmount: invoiceTotal * taxPercentages.puertoRicanSalesTaxPercentage,
    provincialSalesTaxAmount: invoiceTotal * taxPercentages.provincialSalesTaxPercentage,
    goodsAndServicesTaxAmount: invoiceTotal * taxPercentages.goodsAndServicesTaxPercentage,
    harmonizedSalesTaxAmount: invoiceTotal * taxPercentages.harmonizedSalesTaxPercentage,
  }
};

function calculateNewInvoiceTotal(taxesAmounts, invoiceTotal) {
  return Object.values(taxesAmounts).reduce((accumulator, currentValue) => {
    return accumulator + Number(currentValue);
  }, invoiceTotal);
};

export function setTaxAmount(controller){
  const taxPercentages = getTaxPercentages(controller);
  const newInvoiceTotal = Number(controller.merchandiseTotalInputTarget.value);

  if (isNaN(newInvoiceTotal) || controller.previousRetailerInvoiceTotal === newInvoiceTotal) {
    return;
  }

  const taxesAmounts = calculateTaxAmounts(taxPercentages, newInvoiceTotal);
  controller.previousRetailerInvoiceTotal = newInvoiceTotal;

  fillTaxAmountValues(controller, taxesAmounts);
  const leaseAmount = calculateNewInvoiceTotal(taxesAmounts, newInvoiceTotal);

  controller.acimaLeaseAmountTarget.textContent = accounting.formatMoney(leaseAmount, "$", 2);
}
