import {Controller} from "stimulus";
import {publish, subscribe} from "../../helpers/pub_sub";

const EVENT_NAME = "showPastUpwardDeadlineDialogEvent";

/**
 * Publishes the show past upward deadline dialog event
 */
export function showPastUpwardDeadlineDialog() {
  publish(EVENT_NAME);
}

export default class extends Controller {
  connect() {
    dialogPolyfill.registerDialog(this.element);
    subscribe(EVENT_NAME, this.openDialog);
  }

  openDialog = () => {
    this.element.showModal();
  }

  closeDialog() {
    this.element.close();
  }
}
