import {Controller} from "stimulus";

/** controller for funding conditions modal */
export default class extends Controller {
  static targets = [
    "fundingModal",
    "submitFundingConditionsForm",
  ]

  /** register dialog */
  connect() {
    if (this.hasFundingModalTarget) {
      dialogPolyfill.registerDialog(this.fundingModalTarget);
    }
  }

  /** launch dialog */
  launchFundingModal() {
    document.querySelector("#request_funding_conditions_modal").showModal();
  }

  /** submit form from dialog */
  submitFundingConditions() {
    this.submitFundingConditionsFormTarget.submit();
  }

  /** close the dialog */
  cancelFundingConditions() {
    document.querySelector("#request_funding_conditions_modal").close();
  }
}
