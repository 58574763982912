/**
 * Controller for Coworker Invoice new and edit views
 *
 * selectedInitialPaymentAmountTarget - Select element that contains options
 * based on Initial Payment amounts. Each option's value is an array of
 * Underwriting program ids.
 */

import { Controller } from "stimulus";
import {
  closeSpinnerDialog,
  showSpinnerDialog
} from "@/controllers/shared/common/dialogs/spinner_dialog_controller";

export default class extends Controller {
  static targets = [
    "merchandiseTotalStatus",
    "merchandiseTotalInputError",
    "merchandiseTotalInput",
    "propertyDescriptionStatus",
    "propertyDescription",
    "promisedDeliveryDateStatus",
    "selectedInitialPaymentAmount",
    "selectedInitialPaymentAmountStatus",
    "promisedDeliveryDate",
    "leaseTermSelect",
    "leaseTermSelectStatus",
    "retailerInvoiceTotal",
    "acimaLeaseAmount",
    "renewalPaymentAmount",
    "ldwFeeAmount",
    "benefitsPlusFeeAmount",
    "renewalPaymentWithOptionalServices",
    "acimaCashPrice",
    "totalOfPayments",
    "renewalPaymentFrequency",
    "numberOfRenewalPayments",
    "initialPaymentAmount",
    "merchandiseConditionNew",
    "merchandiseConditionUsed",
    "merchandiseConditionStatus",
    "damagedCheckbox",
    "damagesDescriptionContainer",
    "damagesDescription",
    "damagesDescriptionStatus",
    "merchantReferenceNumber",
    "merchantReferenceNumberStatus",
    "invoiceForm",
    "submitButton"
  ];

  connect() {
    this.loadInvoice();
  }

  /**
   * Select the Initial Payment option and fetch calculations
   */
  loadInvoice() {
    if (this.data.get("invoiceMode") == "edit") {
      this.selectInitialPaymentAmount();
      this.calculateInvoiceAmounts(_);
    }
  }

  /**
   * When loading an invoice select the Initial Payment option that was chosen
   * when creating the invoice
   */
  selectInitialPaymentAmount() {
    const initialPaymentOptions =
      this.selectedInitialPaymentAmountTarget.options;
    if (initialPaymentOptions.length == 1) {
      return;
    }

    const underwritingProgramId = this.data.get("underwritingProgramId");
    for (let i = 0; i < initialPaymentOptions.length; i++) {
      if (initialPaymentOptions[i].value.includes(underwritingProgramId)) {
        this.selectedInitialPaymentAmountTarget.selectedIndex = i;
      }
    }
  }

  /**
   * Will check if the form is ready, all fields are valid and will enable or
   * disable the submit button
   */
  toggleSubmitButton() {
    if (this.formReadyForValidation()) {
      if (this.validateAll()) {
        this.submitButtonTarget.classList.remove("disabled");
        this.submitButtonTarget.disabled = false;
      } else {
        this.submitButtonTarget.classList.add("disabled");
        this.submitButtonTarget.disabled = true;
      }
    } else {
      this.submitButtonTarget.classList.add("disabled");
      this.submitButtonTarget.disabled = true;
    }
  }

  /**
   * Fetch underwriting program(s) from the server
   * and display their values in DOM
   * @param {Event} [event] - JS triggering event
   * @param {integer} [programId] - Underwriting program ID
   */
  calculateInvoiceAmounts(event, programId = null) {
    let underwritingProgramIds = null;
    if (programId == null) {
      underwritingProgramIds = this.selectedInitialPaymentAmountTarget.value;
    } else {
      underwritingProgramIds = programId;
    }
    const merchandiseTotal = accounting.unformat(
      this.merchandiseTotalInputTarget.value, "", 2
    );
    const params = new URLSearchParams({
      merchandise_total: merchandiseTotal,
      underwriting_program_ids: underwritingProgramIds
    }).toString();
    const fetchWith = window.acima.fetchInit({ method: "GET" });

    this.clearCalculations();
    showSpinnerDialog(window.acima.i18n.t("users.coworkers.contracts.invoices.fetch_message"));

    fetch(`/users/contracts/${this.data.get("contractId")}/invoice_calculations/new?${params}`, fetchWith
    ).then(response => response.json()
    ).then(function(data) {
      if (data.success) {
        // consistent across all returned programs
        const consistentData = data.details[0];
        const underwritingPrograms = data.details;

        underwritingPrograms.sort((a, b) => a.lease_term - b.lease_term);

        // Fetch all Underwriting programs available if programID is null
        if (programId == null) {
          this.leaseTermSelectTarget.innerHTML = "";
          this.addLeaseTermOptions(underwritingPrograms);
          // When editing an invoice, select correct Underwriting Program
          if (this.data.get("invoiceMode") == "edit") {
            this.selectLeaseTerm();
          }
          this.validateLeaseTermSelect();
        }

        this.setCalculations(consistentData, underwritingPrograms);
        this.leaseTermSelectTarget.disabled = false;
        this.toggleSubmitButton();
      }
      closeSpinnerDialog();
    }.bind(this));
  }

  /**
   * Iterate the returned programs,
   * add if enabled add them to lease term select element
   * @param {Object[]} underwritingPrograms - An array of Underwriting programs
   */
  addLeaseTermOptions(underwritingPrograms) {
    for (let i = 0; i < underwritingPrograms.length; i++) {
      const program = underwritingPrograms[i];

      if (program.underwriting_program_eligible == false) {
        continue;
      }

      const underwritingProgramId = program.underwriting_program_id;
      const leaseTermMonths = program.lease_term_months;
      const option = document.createElement("option");
      option.value = underwritingProgramId;
      option.innerHTML = leaseTermMonths + " " + window.acima.i18n.t("users.coworkers.contracts.invoices.months");

      this.leaseTermSelectTarget.appendChild(option);
    }
  }

  /**
   * Select the Underwriting program that exists on the invoice
   */
  selectLeaseTerm() {
    const underwritingProgramId = this.data.get("underwritingProgramId");

    if (underwritingProgramId != null) {
      for (let i = 0; i < this.leaseTermSelectTarget.options.length; i++) {
        if (this.leaseTermSelectTarget[i].value == underwritingProgramId) {
          this.leaseTermSelectTarget.selectedIndex = i;
          break;
        }
      }
    }
  }

  /**
   * Sets values in DOM for the selected Underwriting program
   * @param {Object} consistentData - An Underwriting program
   * @param {Object[]} underwritingPrograms - An array of Underwriting programs
   */
  setCalculations(consistentData, underwritingPrograms) {
    // Add consistent values to the _calculations view
    this.acimaLeaseAmountTarget.textContent = accounting.formatMoney(
      consistentData.acima_lease_amount, "$", 2
    );
    this.retailerInvoiceTotalTarget.textContent = accounting.formatMoney(
      consistentData.merchandise_total, "$", 2
    );
    this.ldwFeeAmountTarget.textContent = accounting.formatMoney(
      consistentData.ldw_fee_amount, "$", 2)
    ;
    this.benefitsPlusFeeAmountTarget.textContent = accounting.formatMoney(
      consistentData.benefits_plus_fee_amount, "$", 2
    );
    this.acimaCashPriceTarget.textContent = accounting.formatMoney(
      consistentData.acima_cash_price, "$", 2
    );
    this.renewalPaymentFrequencyTarget.textContent =
      consistentData.renewal_payment_frequency;

    // Add program specific values to the _calculations view
    const selectedUwProgramId = this.leaseTermSelectTarget.value;
    let selectedUwProgram = null;
    for (let i = 0; i < underwritingPrograms.length; i++) {
      if (
        underwritingPrograms[i].underwriting_program_id == selectedUwProgramId
      ) {
        selectedUwProgram = underwritingPrograms[i];
        break;
      }
    }
    this.renewalPaymentAmountTarget.textContent = accounting.formatMoney(
      selectedUwProgram.renewal_payment_amount, "$", 2
    );
    this.renewalPaymentWithOptionalServicesTarget.textContent =
      accounting.formatMoney(
        selectedUwProgram.renewal_payment_amount +
        consistentData.ldw_fee_amount +
        consistentData.benefits_plus_fee_amount, "$", 2
      );
    this.totalOfPaymentsTarget.textContent = accounting.formatMoney(
      selectedUwProgram.total_of_payments, "$", 2
    );
    this.numberOfRenewalPaymentsTarget.textContent =
      selectedUwProgram.number_of_renewal_payments;
    this.initialPaymentAmountTarget.textContent = accounting.formatMoney(
      selectedUwProgram.initial_payment_amount, "$", 2
    );
  }

  /**
   * Sets all the DOM calculation elements to a default value
   */
  clearCalculations() {
    this.acimaLeaseAmountTarget.textContent = "$ --";
    this.retailerInvoiceTotalTarget.textContent = "$ --";
    this.ldwFeeAmountTarget.textContent = "$ --";
    this.benefitsPlusFeeAmountTarget.textContent = "$ --";
    this.acimaCashPriceTarget.textContent = "$ --";
    this.renewalPaymentFrequencyTarget.textContent = "--";
    this.renewalPaymentAmountTarget.textContent = "$ --";
    this.renewalPaymentWithOptionalServicesTarget.textContent = "$ --";
    this.totalOfPaymentsTarget.textContent = "$ --";
    this.numberOfRenewalPaymentsTarget.textContent = "--";
    this.initialPaymentAmountTarget.textContent = "$ --";
  }

  /**
   * Validates the merchandise total and will fetch calculations
   */
  merchandiseTotalBlur() {
    if (this.validateMerchandiseTotal()) {
      let programId = Number(this.leaseTermSelectTarget.value);
      if (!programId) {
        programId = null;
      }
      this.calculateInvoiceAmounts(_, programId);
    }
  }

  /**
   * Shows or hides the damages description field
   */
  damagedChecked() {
    if (this.damagedCheckboxTarget.checked) {
      this.damagesDescriptionContainerTarget.style.display = "block";
      this.damagesDescriptionTarget.disabled = false;
      this.damagesDescriptionStatusTarget.classList.remove(
        "has-success", "has-error"
      );
    } else {
      this.damagesDescriptionContainerTarget.style.display = "none";
      this.damagesDescriptionTarget.value = null;
      this.damagesDescriptionTarget.disabled = true;
    }
  }

  /**
   * Validates the selected term length and fetches calculations
   * for the selected underwriting program id
   * @param {Event} event - JS event
   */
  selectTermLength(event) {
    const programId = event.currentTarget.value;
    this.validateLeaseTermSelect();
    this.calculateInvoiceAmounts(event, programId);
  }

  /**
   * Submit the invoice form and show a spinner dialog
   * @param {Event} e - JS event
   */
  submitInvoice(e) {
    e.preventDefault();
    const message = this.data.get("agreementGeneratingText");
    if (this.validateAll()) {
      if (this.data.get("invoiceMode") == "edit") {
        window.spinnerDialog = function() {
          showSpinnerDialog(message);
        };
        window.EditInvoiceConfirmationModal.show();
      } else {
        this.invoiceFormTarget.submit();
        showSpinnerDialog(message);
      }
    }
  }

  /**
   * Checks if all invoice fields have a value
   * @return {boolean}
   */
  formReadyForValidation() {
    return this.merchandiseTotalInputTarget.value.length > 0 &&
      this.propertyDescriptionTarget.value.trim().length > 0 &&
      this.promisedDeliveryDateTarget.value.length > 0 &&
      this.selectedInitialPaymentAmountTarget.value.length > 0 &&
      this.leaseTermSelectTarget.value.length > 0 &&
      this.damagedDescriptionReady() &&
      this.merchantReferenceNumberReady();
  }

  damagedDescriptionReady() {
    if (!this.damagedCheckboxTarget.checked) {
      return true;
    }

    return this.damagesDescriptionTarget.value.trim().length > 0;
  }

  merchantReferenceNumberReady() {
    if (this.hasMerchantReferenceNumberTarget) {
      return this.merchantReferenceNumberTarget.value.trim().length > 0;
    } else {
      return true;
    }
  }

  /**
   * Checks that all invoice fields are valid
   * @return {Boolean} valid or invalid value
   */
  validateAll() {
    return this.validateMerchandiseTotal() &&
      this.validatePropertyDescription() &&
      this.validatePromisedDeliveryDate() &&
      this.validateSelectedInitialPaymentAmount() &&
      this.validateLeaseTermSelect() &&
      this.validateMerchandiseCondition() &&
      this.validateDamagesDescription() &&
      this.validateMerchantReferenceNumber();
  }

  /**
   * Validates the merchandise total field
   * @return {Boolean} valid or invalid value
   */
  validateMerchandiseTotal() {
    if (
      this.merchandiseTotalInputTarget.value >= 100 &&
      this.merchandiseTotalInputTarget.value <= parseFloat(this.data.get("maximumMerchandiseTotal"))
    ) {
      this.addSuccess(this.merchandiseTotalStatusTarget);
      this.merchandiseTotalInputErrorTarget.textContent = "";
      return true;
    } else {
      if (this.merchandiseTotalInputTarget.value < 100) {
        this.merchandiseTotalInputErrorTarget.textContent = window.acima.i18n.t("users.coworkers.contracts.invoices.errors.greater_than_amount");
      } else if (this.merchandiseTotalInputTarget.value > parseFloat(this.data.get("maximumMerchandiseTotal"))) {
        this.merchandiseTotalInputErrorTarget.textContent = window.acima.i18n.t("users.coworkers.contracts.invoices.errors.less_than_amount");
      }
      this.addError(this.merchandiseTotalStatusTarget);
      return false;
    }
  }

  /**
   * Validates the property description field
   * @return {boolean}
   */
  validatePropertyDescription() {
    if (this.propertyDescriptionTarget.value.trim().length > 0 &&
      this.propertyDescriptionTarget.value.trim().length <= 1500) {
      this.addSuccess(this.propertyDescriptionStatusTarget);
      return true;
    } else {
      this.addError(this.propertyDescriptionStatusTarget);
      return false;
    }
  }

  /**
   * Validates the promised delivery date field
   * @return {boolean}
   */
  validatePromisedDeliveryDate() {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

    if (dateRegex.test(this.promisedDeliveryDateTarget.value)) {
      this.addSuccess(this.promisedDeliveryDateStatusTarget);
      return true;
    } else {
      this.addError(this.promisedDeliveryDateStatusTarget);
      return false;
    }
  }

  /**
   * Validates the selected initial payment amount
   * @return {boolean}
   */
  validateSelectedInitialPaymentAmount() {
    if (this.selectedInitialPaymentAmountTarget.value.length > 0) {
      this.addSuccess(this.selectedInitialPaymentAmountStatusTarget);
      return true;
    } else {
      this.addError(this.selectedInitialPaymentAmountStatusTarget);
      return false;
    }
  }

  /**
   * Validates the selected lease term
   * @return {boolean}
   */
  validateLeaseTermSelect() {
    if (this.leaseTermSelectTarget.value.length > 0) {
      this.addSuccess(this.leaseTermSelectStatusTarget);
      return true;
    } else {
      this.addError(this.leaseTermSelectStatusTarget);
      return false;
    }
  }

  /**
   * Validates a merchandise condition radio button is selected
   * @return {boolean}
   */
  validateMerchandiseCondition() {
    if (
      this.merchandiseConditionNewTarget.checked ||
      this.merchandiseConditionUsedTarget.checked
    ) {
      this.addSuccess(this.merchandiseConditionStatusTarget);
      return true;
    } else {
      this.addError(this.merchandiseConditionStatusTarget);
      return false;
    }
  }

  /**
   * Validates the damages description if the damaged checkbox is checked
   * @return {boolean}
   */
  validateDamagesDescription() {
    if (this.damagedDescriptionReady()) {
      this.addSuccess(this.damagesDescriptionStatusTarget);
      return true;
    } else {
      this.addError(this.damagesDescriptionStatusTarget);
      return false;
    }
  }

  /**
   * Validates merchant reference number if it's present in the form
   * @return {boolean}
   */
  validateMerchantReferenceNumber() {
    if (this.hasMerchantReferenceNumberTarget) {
      if (this.merchantReferenceNumberReady()) {
        this.addSuccess(this.merchantReferenceNumberStatusTarget);
        return true;
      } else {
        this.addError(this.merchantReferenceNumberStatusTarget);
        return false;
      }
    } else {
      return true;
    }
  }

  addSuccess(target) {
    target.classList.remove("has-error");
    target.classList.add("has-success");
  }

  addError(target) {
    target.classList.remove("has-success");
    target.classList.add("has-error");
  }
}
