import {Controller} from "stimulus";

/**
 * Make table rows clickable to navigate to leases on mobile views
 */
export default class extends Controller {
  static targets = ["url", "leaseNumCell"]

  /**
   * Navigate the browser to the lease if the devices
   * view is a mobile device.
   */
  goToLease() {
    if (this.displayStatus == "none") {
      window.location.href = this.leaseURL;
    }
  }

  /** Get the URL for the lease from the html element
   *
   * @return {string} lease url
   */
  get leaseURL() {
    return this.urlTarget.href;
  }

  /**
   * Return the display attribute of lease num table cell which will be hidden
   * on smaller screens.
   *
   * @return {string} display property value
   */
  get displayStatus() {
    const computedStyle = window.getComputedStyle(this.leaseNumCellTarget);
    return computedStyle.getPropertyValue("display");
  }
}
