import {Controller} from "stimulus";

/** Handle Add Account modal functionality */
export default class extends Controller {
    static targets = [
      "errors",
    ];

    /** Register dialog */
    connect() {
      dialogPolyfill.registerDialog(this.element);
    }

    /** Send card object to API to create instant funding card
     * @param {Object} card - card stub info, guid, exp, last_4
     */
    createCard(card) {
      const controller = this;
      const url = this.data.get("card-url");
      const requestHash = {
        method: "POST",
        body: JSON.stringify({
          guid: card.guid,
          exp: card.expiry,
          last_4: card.last_4,
          merchant_id: this.data.get("merchantId"),
        }),
        credentials: "include",
      };
      const fetchWith = window.acima.fetchInit(requestHash);

      this.showProcessing();
      fetch(url, fetchWith).then((response) => {
        return response.json();
      }).then((data) => {
        if (data.success == false && data.redirect) {
          window.location = data.redirect;
          return false;
        }

        if (data.success == true) {
          if (data.error_message) {
            (controller.hideProcessing.bind(controller))();
            (controller.clearErrors.bind(controller))();
            (controller.addError.bind(controller))(data.error_message);
            (controller.loadForm.bind(controller))();
          } else {
            (controller.closeDialog.bind(controller))();
            window.location.reload();
          }
        } else {
          controller.hideProcessing();
          controller.clearErrors();
          controller.addError(data.message || data.error_message);
          controller.loadForm();
        }
      });
    }

    /**
     * Sets up processing dialog
     * @param {string} message or locked message
     */
    showProcessing() {
      bootbox.dialog({
        message: "<p><i class=\"fa-solid fa-spin fa-spinner\"></i> Loading...</p>",
      });
    }

    /** Hide processing views */
    hideProcessing() {
      bootbox.hideAll();
    }

    /** Close the dialog */
    closeDialog() {
      this.clearForm();
      this.hideProcessing();
      this.element.close();
    }

    /** Clear input fields when modal closed */
    clearForm() {
      this.clearErrors();
    }

    /** Clear errors in modal */
    clearErrors() {
      this.errorsTarget.classList.add("hide");
      this.errorsTarget.textContent = "";
    }

    /** Add errors into modal
     * @param {string}  error: errors from API call*/
    addError(error) {
      const item = document.createElement("LI");
      item.textContent = error;
      this.errorsTarget.classList.remove("hide");
      this.errorsTarget.appendChild(item);
    }


    /** @return{string} styling for card library.  Applies to input elements */
    iFrameCssBase() {
      return "color: #6B7C93;\n" +
            "display: block;\n" +
            "font-size: 14px;\n" +
            "position: relative;\n" +
            "border: 2px solid #DDE9F5;\n" +
            "-webkit-box-shadow: 0 0 0;\n" +
            "box-shadow: 0 0 0;\n" +
            "padding: 6px 12px;\n" +
            "margin: 12px 0px;" +
            "height: 34px;" +
            "font-family: \"Sofia Pro\";" +
            "font-weight: 600;" +
            "width: 90%;" +
            "height: auto;";
    }

    /** Load card entry form from remote service */
    loadForm() {
      if (typeof (InstantFundingCards) != 'object') {
        console.error('Could not find InstantFundingCards object ...');
        return;
      }

      const errorCallback = (error) => {
        this.addError(error);
      };
      const ifcEndpoint = this.data.get("ifcUrl");
      const ifcAuthTokenPath = this.data.get("authTokenPath");
      const ownerGuid = this.data.get("ownerGuid");
      const iframeCss = this.iFrameCssBase();
      const controller = this;
      const config = {
        form_div_id: "ccs-javascript-form",
        auth_token_endpoint: ifcAuthTokenPath,
        iframe_css_base: iframeCss,
        onComplete: (response) => {
          if (response.status === "pending") {
            controller.createCard(response);
          } else {
            const errorMessage = response.error_message || response.message;
            console.log(errorMessage);
            controller.clearErrors();
            controller.addError(errorMessage);
            controller.hideProcessing();
          }
        },
        onNetworkError: errorCallback,
        onAuthTimeout: errorCallback,
        onCCSTimeout: errorCallback,
        ifc_endpoint: ifcEndpoint,
        show_submit_button: "true",
        submit_button_text: "Submit",
        submit_button_classes: "btn simple-btn-primary simple-btn",
        debit_card_required: "true",
        debug: "true",
        merchantGuid: ownerGuid,
      };

      InstantFundingCards.form(config);
    }
}
