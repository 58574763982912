import { Controller } from "stimulus";

/** Manages the dependent country and state fields */
export default class extends Controller {
  static targets = [
    "countrySelect",
    "usStatesSelect",
    "caProvincesSelect"
  ];

  connect() {
    this.showStateFields();
  }

  /** Displays state_code field based on country-code selection.*/
  showStateFields() {
    const countryValue = this.countrySelectTarget.value;
    const usStatesSelect = this.usStatesSelectTarget;
    const caProvincesSelect = this.caProvincesSelectTarget;

    if (countryValue === "US") {
      usStatesSelect.classList.remove("hidden");
      caProvincesSelect.classList.add("hidden");
      this.usStatesSelectTarget.removeAttribute("disabled");
      this.caProvincesSelectTarget.setAttribute("disabled", "disabled");
      this.caProvincesSelectTarget.value = "";
    } else if (countryValue === "CA") {
      usStatesSelect.classList.add("hidden");
      caProvincesSelect.classList.remove("hidden");
      this.caProvincesSelectTarget.removeAttribute("disabled");
      this.usStatesSelectTarget.setAttribute("disabled", "disabled");
      this.usStatesSelectTarget.value = "";
    }
  }
}
