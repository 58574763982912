import { Controller } from "stimulus";
import {
  showSpinnerDialog, closeSpinnerDialog
} from "../../../shared/common/dialogs/spinner_dialog_controller";
import {
  showErrorDialog
} from "../../../shared/common/dialogs/error_dialog_controller";

/**
 * Manages terminating a contract
 */
export default class extends Controller {
  static targets = [
    "terminateButton"
  ];

  connect() {
    dialogPolyfill.registerDialog(this.element);
    this.url = this.data.get("url");
    this.errorMessage = this.data.get("errorMessage");
    this.waitMessage = this.data.get("waitMessage");
  }

  /**
   * Handles the fetch response by reloading the page on success or throwing
   * an error that is caught by handleError.
   *
   * @param {Object} jsonResponse fetch response object
   */
  handleResponse(jsonResponse) {
    if (!jsonResponse.success) {
      this.handleError(jsonResponse);
      return;
    }
    window.location.reload();
  }

  /**
   * Handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    closeSpinnerDialog();

    console.error(err);
    showErrorDialog(`${this.errorMessage} ${err.message}`);
  }

  closeDialog() {
    this.element.close();
  }

  showDialog() {
    this.element.showModal();
  }

  terminateContract() {
    const request = window.acima.fetchInit({ method: "POST" });

    this.preventSubmitWhileFetching(true);
    this.closeDialog();

    showSpinnerDialog(this.waitMessage);

    fetch(this.url, request)
      .then(response => response.json())
      .then(jsonResponse => this.handleResponse(jsonResponse))
      .catch(error => this.handleError(error))
      .finally(() => this.preventSubmitWhileFetching(false));
  }

  preventSubmitWhileFetching(fetching) {
    if (fetching) {
      this.disableTerminateButton();
    } else {
      this.enableTerminateButton();
    }
  }

  disableTerminateButton() {
    this.terminateButtonTarget.setAttribute("disabled", "");
  }

  enableTerminateButton() {
    this.terminateButtonTarget.removeAttribute("disabled");
  }
}
