import {Controller} from "stimulus";
import {publish, subscribe} from "../../../helpers/pub_sub";

const EVENT_NAME = "showErrorDialogEvent";

/**
 * Dispatches the showErrorDialog event
 *
 * This is the public interface into showing the error dialog
 *
 * @param {string} message - Error message to display
 */
export function showErrorDialog(message) {
  publish(EVENT_NAME, {message});
}

/**
 * Manages reusable error dialog
 */
export default class extends Controller {
  static targets = [
    "title",
    "message",
    "confirmText",
  ]

  /**
  * Register the dialog and controller
  */
  connect() {
    dialogPolyfill.registerDialog(this.element);
    this.initDefaults();
    subscribe(EVENT_NAME, this.showDialog);
  }

  /**
   * Sets default values for dialog targets
   */
  initDefaults() {
    this.title = this.data.get("defaultTitle");
    this.message = this.data.get("defaultMessage");
    this.confirmText = this.data.get("defaultConfirmText");
  }

  /**
   * Updates confirm button text if new text is provided by caller
   *
   * @param {string} newText - new text value
   */
  set confirmText(newText) {
    if (newText) {
      this.confirmTextTarget.textContent = newText;
    }
  }

  /**
   * Updates message text if new text is provided by caller
   *
   * @param {string} newText - new text value
   */
  set message(newText) {
    if (newText) {
      this.messageTarget.textContent = newText;
    }
  }

  /**
   * Updates title text if new text is provided by caller
   *
   * @param {string} newText - new text value
   */
  set title(newText) {
    if (newText) {
      this.titleTarget.textContent = newText;
    }
  }

  /**
   * Closes dialog and resets defaults
   */
  closeDialog = () => {
    this.initDefaults();
    this.element.close();
  }

  /**
   * Updates dialog data and shows dialog
   *
   * @param {Object} obj - Payload object for customizing confirm dialog
   * @param {string} obj.title - optional title text for confirm dialog
   * @param {string} obj.message - optional body text for confirm dialog
   * @param {string} obj.confirmText - optional text for confirm button
   */
  showDialog = ({title, message, confirmText}) => {
    this.title = title;
    this.message = message;
    this.confirmText = confirmText;

    this.element.showModal();
  }
}
