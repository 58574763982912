import { Controller } from "stimulus";

/** Manages the merchant settings edit form */
export default class extends Controller {
  static targets = [
    "fundingAdjustmentsMethod",
    "fundingAdjustmentsFrequency",
    "fundingAdjustmentsFrequencyRow"
  ]

  connect() {
    this.toggleFrequencySelect();
  }

  toggleFrequencySelect() {
    var fundingMethod = this.fundingAdjustmentsMethodTarget.value;

    if (["Manual Invoice", "Electronic Invoice"].includes(fundingMethod)) {
      this.showFrequencySelect();
    } else {
      this.hideFrequencySelect();
    }
  }

  showFrequencySelect() {
    this.fundingAdjustmentsFrequencyRowTarget.classList.remove("hidden");
  }

  hideFrequencySelect() {
    this.fundingAdjustmentsFrequencyRowTarget.classList.add("hidden");
  }
}
