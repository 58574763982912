/**
 * Renders the lock controls according to current lock ownership.
 *
 * Delegates to specific renderers based on lock ownership
 *
 * @param {Object} data all data required to render the controls UI
 * @param {boolean} data.lockedByCurrentViewer locked by current viewer
 * @param {boolean} data.lockedByOtherViewer locked by another viewer
 * @param {element} data.takeLockButton take lock from system
 * @param {element} data.requestLockButton request lock from other viewer
 * @param {element} data.releaseLockButton release lock as owner
 */
export function renderControls(data) {
  if (data.hasEditPermissions === "false") {
    renderNoControls(data);
    return;
  }

  if (data.lockedByCurrentViewer) {
    renderLockedByCurrentViewerControls(data);
  } else if (data.lockedByOtherViewer) {
    renderLockedByOtherViewerControls(data);
  } else {
    renderLockedBySystemControls(data);
  }
}

/**
 * Ensures no controls are rendered
 *
 * This is for when a user that does not have edit permissions views a lease.
 *
 * @param {object} data bundle of data for control rendering
 * @param {element} data.takeLockButton take lock from system
 * @param {element} data.requestLockButton request lock from other viewer
 * @param {element} data.releaseLockButton release lock as owner
 */
function renderNoControls(data) {
  disableButton(data.takeLockButton);
  disableButton(data.requestLockButton);
  disableButton(data.releaseLockButton);
}

/**
 * Renders the lock controls when owned by current viewer
 *
 * This ensures the release button is viewable and interactive so that the
 * current viewer can release the lock they own.
 *
 * @param {object} data bundle of data for control rendering
 * @param {element} data.takeLockButton take lock from system
 * @param {element} data.requestLockButton request lock from other viewer
 * @param {element} data.releaseLockButton release lock as owner
 */
function renderLockedByCurrentViewerControls(data) {
  disableButton(data.takeLockButton);
  disableButton(data.requestLockButton);
  enableButton(data.releaseLockButton);
}

/**
 * Renders the lock controls when owned by another viewer
 *
 * This ensures the request button is viewable and interactive to the current
 * viewer so they can request from the lock owner.
 *
 * @param {object} data bundle of data for control rendering
 * @param {element} data.takeLockButton take lock from system
 * @param {element} data.requestLockButton request lock from other viewer
 * @param {element} data.releaseLockButton release lock as owner
 */
function renderLockedByOtherViewerControls(data) {
  disableButton(data.takeLockButton);
  enableButton(data.requestLockButton);
  disableButton(data.releaseLockButton);
}

/**
 * Renders the lock controls when owned by the system.
 *
 * This ensures the edit button is interactive so that the current viewer
 * can take the lock from the system.
 *
 * @param {object} data bundle of data for control rendering
 * @param {element} data.takeLockButton take lock from system
 * @param {element} data.requestLockButton request lock from other viewer
 * @param {element} data.releaseLockButton release lock as owner
 */
function renderLockedBySystemControls(data) {
  enableButton(data.takeLockButton);
  disableButton(data.requestLockButton);
  disableButton(data.releaseLockButton);
}

function enableButton(button) {
  button.removeAttribute("disabled");
  button.classList.remove("super-hide", "hide");
}

function disableButton(button) {
  button.setAttribute("disabled", "");
  button.classList.add("super-hide", "hide");
}
