import {Controller} from "stimulus";
import {
  showConfirmDialog,
} from "../shared/common/dialogs/confirm_dialog_controller";

/** Confirm the merchant delivery */
export default class extends Controller {
  static targets = [
    "auditorRejection",
    "calendar",
    "damageOrRepairNeeded",
    "submitBtn",
    "undeliveredItems",
  ];

  /** Only allow delivery submission when the delivery date is present */
  changeDelivery() {
    if (this.calendarTarget.value == "") {
      this.submitBtnTarget.disabled = true;
    } else {
      this.submitBtnTarget.disabled = false;
    }
  }

  /**
   * Sets up and shows the dialog
   */
  showDialog() {
    this.confirmDelivery();
  }

  /**
   * Submit the delivery confirmation form
   */
  confirmDelivery = () => {
    const fetchWith = window.acima.fetchInit({
      method: "PUT",
      body: this.formData,
    });
    const url = this.data.get("url");
    fetch(url, fetchWith).then((response) => {
      response.json();
    }).then((response) => {
      window.location.reload();
    });
  }

  /**
    * Returns the dialog title
    */
  get dialogTitle() {
    if (Date.parse(this.selectedDeliveryDate) > Date.today()) {
      return this.data.get("deliveredFutureTitle");
    } else {
      return this.data.get("deliveredTitle");
    }
  }

  /**
   * Returns the dialog message
   */
  get dialogMessage() {
    if (Date.parse(this.selectedDeliveryDate) > Date.today()) {
      return this.data.get("deliveredFutureMessage") + " " +
        this.selectedDeliveryDate;
    } else {
      return this.data.get("deliveredMessage");
    }
  }

  /**
   * Returns JSON string of params
   * @return {string}
   */
  get formData() {
    return JSON.stringify({
      auditor_rejected: this.auditorRejectionFixed,
      damage_or_repair_needed: this.damagesOrRepairs,
      selected_delivery_date: this.selectedDeliveryDate,
      undelivered_items: this.undeliveredItemsPresent,
    }).toString();
  }

  /**
   * Returns if the form has been checked yes/no on auditor_rejected
   * @return {boolean}
   */
  get auditorRejectionFixed() {
    if (this.hasAuditorRejectionTarget) {
      return this.auditorRejectionTarget.checked == true;
    } else {
      return false;
    }
  }

  /**
   * Returns if the form has been checked yes/no on damage_or_repair_needed
   * @return {boolean}
   */
  get damagesOrRepairs() {
    if (this.hasDamageOrRepairNeededTarget) {
      return this.damageOrRepairNeededTarget.checked == true;
    } else {
      return false;
    }
  }

  /**
   * Returns the selected delivery date string
   * @return {string}
   */
  get selectedDeliveryDate() {
    return this.calendarTarget.value;
  }

  /**
   * Returns if the form has been checked yes/no on undelivered_items
   * @return {boolean}
   */
  get undeliveredItemsPresent() {
    if (this.hasUndeliveredItemsTarget) {
      return this.undeliveredItemsTarget.checked == true;
    } else {
      return false;
    }
  }
}
