import {Controller} from "stimulus";
import {isNil} from "lodash";
import {publish, subscribe} from "../../../helpers/pub_sub";
import {
  initRequestTimeout,
  clearRequestTimeout,
} from "./request_timeout_controller";

const SHOW_EVENT_NAME = "showLockOwnerDialogEvent";
const CLOSE_EVENT_NAME = "closeLockOwnerDialogEvent";
const CANCEL_LOCK_OWNER_REQUEST_EVENT_NAME = "cancelLockOwnerRequestEvent";

/**
 * Dispatches the showLockOwnerDialogEvent event and will trigger the
 * subscriber in the controller which shows the dialog.
 *
 * This is the public interface into showing the lock owner dialog
 *
 * @param {Object} dialogData - Payload object for dialog
 */
export function showLockOwnerDialog(dialogData) {
  publish(SHOW_EVENT_NAME, dialogData);
}

/**
 * Dispatches the closeLockOwnerDialogEvent event and will trigger the
 * subscriber in the controller which closes the dialog.
 *
 * This is the public interface into closing the lock owner dialog
 */
export function closeLockOwnerDialog() {
  publish(CLOSE_EVENT_NAME, {});
}

/**
 * Dispatches the cancelLockOwnerRequestEvent and will trigger the subscriber
 * in the controller which clears the timeout and closes the dialog.
 *
 * This is the public interface into handling a canceled request from the
 * owner perspective.
 */
export function lockOwnerRequestCanceled() {
  publish(CANCEL_LOCK_OWNER_REQUEST_EVENT_NAME, {});
}

/**
 * Manages request lock dialog
 * */
export default class extends Controller {
  static targets = [
    "requestMessage",
    "requestSummary",
    "responseMessage",
  ];

  /**
   * connect controller,register dialog and subscribe to publishing
   */
  connect() {
    dialogPolyfill.registerDialog(this.element);
    subscribe(SHOW_EVENT_NAME, this.showDialog);
    subscribe(CLOSE_EVENT_NAME, this.closeDialog);
    subscribe(CANCEL_LOCK_OWNER_REQUEST_EVENT_NAME, this.requestCanceled);
    this.onDenyRequest = null;
    this.onGrantRequest = null;
    this.onRequestExpired = null;
  }

  /**
   * Renders string in UI summarizing action to be taken
   *
   * @param {Object} data represents the request
   * @param {Object} data.requester details about the requester
   * @param {string} data.message message from requester
   */
  renderRequestMessage({requester, message}) {
    if (isNil(message) || message.length === 0) {
      this.requestMessageTarget.innerHTML = "";
      return;
    }

    this.requestMessageTarget.innerHTML =
        `${requester.info.name} says: ${message}`;
  }

  /**
   * Renders string in UI summarizing action to be taken
   *
   * @param {Object} data represents the request
   * @param {Object} data.requester details about the requester
   */
  renderRequestSummary({requester}) {
    const summary = this.data.get("summaryText");
    this.requestSummaryTarget.innerHTML =
        `<strong>${requester.info.name}</strong> ${summary}`;
  }

  /**
   * Triggers onRequestExpired callback, closes dialog and clears timer.
   *
   * This is called when the timer reaches zero without action from the owner.
   */
  requestExpired = () => {
    clearRequestTimeout();
    this.onRequestExpired();
    this.closeDialog();
  }

  /**
   * Triggers onGrantRequest callback, closes dialog and clears timer
   *
   * This is called when then owner gives access to the requester.
   */
  grantRequest() {
    clearRequestTimeout();
    this.onGrantRequest({message: this.responseMessageTarget.value});
    this.closeDialog();
  }

  /**
   * Triggers onDenyRequest callback, closes dialog and clears timer
   *
   * This is called when the owner denies access to the requester.
   */
  denyRequest() {
    clearRequestTimeout();
    this.onDenyRequest({message: this.responseMessageTarget.value});
    this.closeDialog();
  }

  /**
   * Triggers when a request has been canceled by the requester.
   *
   * Clears the timer and closes the dialog.
   */
  requestCanceled = () => {
    clearRequestTimeout();
    this.closeDialog();
  }

  /**
   * Closes out the dialog and clears local data
   */
  closeDialog = () => {
    this.onDenyRequest = null;
    this.onGrantRequest = null;
    this.onRequestExpired = null;
    this.requestMessageTarget.innerHTML = "";
    this.responseMessageTarget.value = "";
    this.requestSummaryTarget.innerHTML = "";
    if (this.element.open) {
      this.element.close();
    }
  }

  /**
   * Shows the dialog and sets up local callbacks for potential request
   * response
   *
   * @param {Object} data payload of data to handle request response
   * @param {string} data.request request data including requester
   * @param {Function} data.onDenyRequest when request is denied
   * @param {Function} data.onGrantRequest when request is granted
   * @param {Function} data.onRequestExpired when timer reaches zero
   */
  showDialog = (data) => {
    // Render request messaging
    this.renderRequestSummary(data.request);
    this.renderRequestMessage(data.request);
    // Assign callbacks
    this.onDenyRequest = data.onDenyRequest;
    this.onGrantRequest = data.onGrantRequest;
    this.onRequestExpired = data.onRequestExpired;
    // show modal and start countdown timer
    if (!this.element.open) {
      this.element.showModal();
    }
    initRequestTimeout({
      endTimeElapsed: data.request.timeout,
      onExpired: this.requestExpired,
    });
  }
}
