import {Controller} from "stimulus";
import {
  showSpinnerDialog,
} from "./shared/common/dialogs/spinner_dialog_controller";


export default class extends Controller {
  static targets = [
    "submitButton",
    "errors",
    "autopayAuthorization",
    "cardAutopayAuthorization",
  ]

  submitForm = () => {
    this.errors.classList.add("hide");

    if (this.missingRequiredAutopayAuth()) {
      this.displayErrors([this.autopayAuthorizationTarget.dataset.autopayAuthorizationError]);
      return;
    }
    this.disableCardAutopayAuth();
    this.submitButton.classList.add("disabled");
    this.disableCustomerPaysMerchant();

    fetch(
        this.submitButton.dataset.url,
        window.acima.fetchInit({method: "POST"}),
    ).then((resp) => {
      return resp.json();
    }).then((data) => {
      if (data.success == false) {
        this.reenableAutopayAuthorization();
        this.submitButton.classList.remove("disabled");
        this.requestUnlock();
        this.enableCustomerPaysMerchant();
      } else if (data.new_lock == true) {
        if (this.hasCardAutopayAuthorizationTarget == true) {
          this.setCardAutopayAuthorization();
        }
        const result = CreditCardServiceV2.submitForm();
        if (Array.isArray(result)) {
          this.displayErrors(result);
          this.submitButton.classList.remove("disabled");
          this.reenableAutopayAuthorization();
          this.requestUnlock();
          this.enableCustomerPaysMerchant();
        }
        trackContractStep();
      } else {
        showSpinnerDialog(data.message);
        // Time out the spinner if nothing happens (payment response).
        setTimeout(() => window.location.reload(), 60000);
      }
    });
  }

  // request an unlock only when there was a ccs failure in the first try
  requestUnlock = () => {
    fetch(
        this.submitButton.dataset.url,
        window.acima.fetchInit({method: "DELETE"}),
    );
  }

  displayErrors(errors) {
    this.errors.innerHTML = "<p>" + errors.join("</p><p>") + "</p>";
    this.errors.classList.remove("hide");
  }

  disableCustomerPaysMerchant() {
    if (!this.customerPaysMerchantController) return null;

    this.customerPaysMerchantController.disableFeature();
  }

  enableCustomerPaysMerchant() {
    if (!this.customerPaysMerchantController) return null;

    this.customerPaysMerchantController.enableFeature();
  }

  missingRequiredAutopayAuth() {
    if (this.hasAutopayAuthorizationTarget == true) {
      if (this.autopayAuthorizationTarget.checked == true) {
        this.autopayAuthorizationTarget.disabled = true;
        return false;
      } else {
        return true;
      }
    }
  }

  setCardAutopayAuthorization() {
    /**
     * Persist the customers decision on form submission. CCS responds in process_service_response.rb
     * which will handle updating the card_at column on applicant_autopay_enrollments to nil if
     * CCS returns a failure response (ie. unsuccessful payment)
     */
    if (this.cardAutopayAuthorizationTarget.checked == true) {
      this.cardAutopayAuthorizationTarget.disabled = true;
      const url = this.data.get("url");
      const request = window.acima.fetchInit(
        {
          method: "PATCH",
          body: JSON.stringify({
            enrolled_in_card_autopay_at: new Date(),
          })
        }
      );
      fetch(url, request)
        .then((response) => response.json())
        .then((jsonResponse) => this.handleResponse(jsonResponse))
        .catch((err) => console.error(err));
    }
  }

  handleResponse(jsonResponse) {
    if (!jsonResponse.success) {
      throw new Error(jsonResponse.message);
    }
  }

  disableCardAutopayAuth() {
    if (this.hasCardAutopayAuthorizationTarget == true) {
      this.cardAutopayAuthorizationTarget.disabled = true;
    }
  }

  reenableAutopayAuthorization() {
    if (this.hasAutopayAuthorizationTarget == true) {
      this.autopayAuthorizationTarget.disabled = false;
    }

    if (this.hasCardAutopayAuthorizationTarget == true) {
      this.cardAutopayAuthorizationTarget.disabled = false;
    }
  }

  get customerPaysMerchantController() {
    const element = document.getElementById("customer-pays-merchant-controller");
    return this.application.getControllerForElementAndIdentifier(
        element,
        "merchant-contracts--customer-pays-merchant-modal"
    );
  }

  get submitButton() {
    return this.submitButtonTarget;
  }

  get errors() {
    return this.errorsTarget;
  }
}
