import { Controller } from "stimulus";
import { publish, subscribe } from "../../../helpers/pub_sub";
import { submitForm } from "./form_controller";
import {
  showSpinnerDialog
} from "../../../shared/common/dialogs/spinner_dialog_controller";

const SHOW_DIALOG_EVENT = "showConfirmationDialogEvent";

/**
 * Publishes the show confirmation dialog event
 *
 * @param {String} changeAmount - amount invoice changed
 * @param {String} newInvoiceTotal - new invoice total
 * @param {String} newDescription - new invoice description
 */
export function showConfirmationDialog(
  changeAmount,
  newInvoiceTotal,
  newDescription,
) {
  publish(
    SHOW_DIALOG_EVENT,
    { changeAmount, newInvoiceTotal, newDescription },
  );
}

export default class extends Controller {
  static targets = [
    "invoiceChangeAmount",
    "newInvoiceTotal",
    "newDescription",
    "downsy"
  ]

  connect() {
    subscribe(SHOW_DIALOG_EVENT, this.openDialog);
  }

  openDialog = ({ changeAmount, newInvoiceTotal, newDescription }) => {
    this.changeAmount = changeAmount;
    this.newInvoiceTotal = newInvoiceTotal;
    this.newDescription = newDescription;

    this.initDialogValues();
    this.element.showModal();
  }

  initDialogValues() {
    this.invoiceChangeAmountTarget.innerHTML = this.changeAmount;
    this.newInvoiceTotalTarget.innerHTML = this.newInvoiceTotal;
    this.newDescriptionTarget.innerHTML = this.newDescription;
    this.downsyTarget.classList.remove("hidden");
  }

  submitForm() {
    submitForm();
    this.closeDialog();
    showSpinnerDialog(this.waitMessage);
  }

  closeDialog() {
    this.element.close();
  }
}
