import "@babel/polyfill";
import "@webcomponents/custom-elements";
import "@github/clipboard-copy-element";
import "url-search-params-polyfill";
import "whatwg-fetch";
import "classlist-polyfill";
import "flatpickr";
import "./application.css";

// Initialises function window.acima.fetchInit
import {} from "../helpers/acima/fetch_init_helper"
// This import does two things
// 1. Checks for element with HTML element clipboard-copy and binds events
import {} from "~/controllers/helpers/global_helpers";
import { Autocomplete } from "stimulus-autocomplete";
// Initializes a global acima.i18n translation object
import {} from "./i18n";

// required polyfill for "closest" that babel doesn't provide
// from https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
// BEGIN closest polyfill
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                Element.prototype.webkitMatchesSelector;
if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}
// END closest polyfill

import { Application } from "stimulus";
import { registerControllers } from "stimulus-vite-helpers";

const application = Application.start()
// Look for controllers inside app/assets/javascripts/packs/controllers/
const controllers = import.meta.globEager("~/controllers/**/*_controller.js");
registerControllers(application, controllers);
application.register("autocomplete", Autocomplete);
