import { Controller } from "stimulus"

//controller for handling two fator authentication

export default class extends Controller {
  static targets = [
    "passwordForm",
    "passwordError",
    "otpError",
    "create",
    "resend",
    "verify",
    "code",
    "token",
    "guid",
    "otpForm",
    "password",
    "passwordConfirmation",
    "otp",
    "step",
    "code"
  ]

  connect() {
    const step = this.data.get("step")
    this.showForm(step)
  }

  showForm(step){
    if (step == "password") {
      this.passwordFormTarget.style.display = ""
      this.otpFormTarget.style.display = "none"
    }
    else{
      this.resendOtp()
      this.passwordFormTarget.style.display = "none"
      this.otpFormTarget.style.display = ""
    }
  }

  showPasswordForm(event){
    event.preventDefault()
    const step = "password"
    this.showForm(step)
  }

  validatePassword(event) {
    event.preventDefault()
    const errors = [];
    const letterPattern = /[a-zA-Z]/;
    const numberPattern = /\d/;
    const password = this.passwordTarget.value;
    const confirm_password = this.passwordConfirmationTarget.value;

    if (password.length < 8) {
      errors.push(window.acima.i18n.t("merchant_users.set_password.password_too_short"));
    }
    if (!letterPattern.test(password)) {
      errors.push(window.acima.i18n.t("merchant_users.set_password.password_must_contain_letter"));
    }
    if (!numberPattern.test(password)) {
      errors.push(window.acima.i18n.t("merchant_users.set_password.password_must_contain_number"));
    }
    if (password !== confirm_password) {
      errors.push(window.acima.i18n.t("merchant_users.set_password.incorrect_password"));
    }

    this.displayErrors(errors,event);

  }

  displayErrors(errors,event) {

    const errorContainer = this.passwordErrorTarget;
    if (errors.length > 0) {
      errorContainer.innerHTML = ''; // Clear existing errors
      errors.forEach(error => {
        const errorMessage = document.createElement("p");
        errorMessage.textContent = error;
        errorContainer.appendChild(errorMessage);
      });
      errorContainer.classList.remove("toggle-hide"); // Show error messages
    }
    else{
      this.createPassword(event)
      errorContainer.classList.add("toggle-hide");
    }
  }

  createPassword(event) {
    event.preventDefault()
    const redirectPath = this.data.get("redirectPath")
    const twofactorUrl = this.data.get("twoFactorUrl")
    const password = this.passwordTarget.value
    const passwordConfirmation = this.passwordConfirmationTarget.value
    const id =  this.data.get("userId")
    const token = this.tokenTarget.value;
    const guid = this.guidTarget.value;
    const code = this.codeTarget.value;

    const requestBody = {
      password:              password,
      password_confirmation: passwordConfirmation,
      step:                  "password",
      id:                    id,
      token:                 token,
      guid:                  guid,
      code:                  code
      };

      const request = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };

      fetch(twofactorUrl, window.acima.fetchInit(request))
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
            return response.json();
        })
        .then((data) => {
            this.showForm(data.step)
            if (data.redirect == "true") {
              window.location.href = redirectPath
            }
            else {
              this.incorrectCode(data.error, data.step)
            }
        })
        .catch((error) => {
          alert(window.acima.i18n.t("merchant_users.set_password.common_error"));
        });
    }

  incorrectCode(error,step) {
    const errorMessage = this.otpErrorTarget;

    if (error == "IncorrectCode" && step == "otp") {
      errorMessage.classList.remove("toggle-hide");
      this.verifyTarget.setAttribute("disabled", true);
    } else {
      errorMessage.classList.add("toggle-hide");
    }
  }

  enableButton() {
    const password = this.passwordTarget.value;
    const confirm_password = this.passwordConfirmationTarget.value;

    if (password !== "" && confirm_password !== "") {
      this.createTarget.removeAttribute("disabled");
    } else {
      this.createTarget.setAttribute("disabled", true);
    }
  }

  validateCode() {
    const code = this.codeTarget.value;

    if (code !== "" ) {
      this.verifyTarget.removeAttribute("disabled");
    } else {
      this.verifyTarget.setAttribute("disabled", true);
    }
  }

  resendOtp() {
    // Disable resend button, start timer, etc.
    this.resendTarget.setAttribute("disabled", true);
    this.timer = 60; // Set timer to 60 seconds
    this.clearExistingTimer(); //clear existing timer
    this.startTimer();
  }
  clearExistingTimer() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
  }
  startTimer() {
    this.updateButton();
    this.timerInterval = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        clearInterval(this.timerInterval);
        this.resendTarget.removeAttribute("disabled");
        this.updateButton();
      } else {
        this.updateButton();
      }
    }, 1000);
  }

  updateButton() {
    // Updates the resend button text
    if (this.timer > 0) {
      this.resendTarget.textContent = `Resend code in ${this.timer}s`;
    } else {
      this.resendTarget.textContent = `Resend code`;
    }
  }
}
