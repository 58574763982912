import { Controller } from "stimulus";
import { isNil, isNaN } from "lodash";
import { Spanish } from "flatpickr/dist/l10n/es";

/** Date picker using Flatpickr to remove jQuery */
export default class extends Controller {
  static targets = ["calendar"]

  /**
   * Adds a date picker with a few basic needs to a given html element
   */
  connect() {
    this.datePicker = flatpickr(this.calendarTarget, this.config());
  }

  /**
   * Grabs a custom config or sets up a default config
   * @return {config} jsonObject
   */
  config() {
    if (this.data.has("config")) {
      const config = JSON.parse(this.data.get("config"));
      config.minDate = this.minDate(config);
      config.maxDate = this.maxDate(config);
      config.defaultDate = this.defaultDate(config);
      config.locale = this.locale(config);
      return config;
    } else {
      return {minDate: this.minDate(""), maxDate: this.maxDate()};
    }
  }

  /** Opens the datePicker from any html attribute */
  launchPicker() {
    this.datePicker.open();
  }

  /**
   * Remove the defaultDate if it is not within the valid dates
   * @param {object} config
   * @return {date} date
   */
  defaultDate(config) {
    const defaultDate = Date.parse(config.defaultDate);

    if (isNil(defaultDate) || isNaN(defaultDate)) {
      return config.defaultDate;
    } else if (config.minDate != "" && config.minDate > defaultDate) {
      this.calendarTarget.value = "";
      this.element.classList.remove("has-success");
      this.element.classList.add("has-error");
    } else if (config.maxDate != "" && config.maxdate < defaultDate) {
      this.calendarTarget.value = "";
      this.element.classList.remove("has-success");
      this.element.classList.add("has-error");
    } else {
      this.element.classList.add("has-success");
      this.element.classList.remove("has-error");
      return defaultDate;
    }
  }

  /**
   * This will set the latest date that is allowed to be selected by a user
   * @return {date} returns a date
   */
  maxDate(config) {
    const maxDaysInFuture = this.data.get("maxDaysInFuture");
    var resultDate = "";

    if (maxDaysInFuture != "" && maxDaysInFuture !== null && maxDaysInFuture !== "undefined") {
      if (maxDaysInFuture == 1) {
        resultDate = new Date();
        const today = new Date();

        resultDate.setDate(today.getDate() + parseInt(maxDaysInFuture));
      } else {
        resultDate = new Date(config.minDate);
        resultDate.setDate(resultDate.getDate() + parseInt(maxDaysInFuture));
      }
    }

    return resultDate;
  }

  /**
   * This will set the earliest date that is allowed to be selected by a user
   * @return {date} returns a date
   */
  minDate(config) {
    const minDate = config.minDate;
    var resultDate = "";

    if (minDate != "" && minDate !== null && typeof minDate !== "undefined") {
      resultDate = Date.parse(minDate);
    }

    return resultDate;
  }

  locale(config) {
    // When using this datepicker, add a "lang" attribute to the html element
    // and set it equal to locale.
    const lang = config.locale;

    try {
      if (lang.startsWith("es")) {
        return Spanish;
      } else {
        // Default to English if the language is not Spanish
        return flatpickr.l10ns.en
      }
    } catch (error) {
      // Default to English if locale not configured
      return flatpickr.l10ns.en
    }
  }
}
