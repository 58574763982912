import {Controller} from "stimulus";
import {
  showErrorDialog,
} from "../../../shared/common/dialogs/error_dialog_controller";

/**
 * Manages unexpire action for user contracts
 */
export default class extends Controller {
  static targets = [
    "reason",
    "unexpireButton",
  ];

  connect() {
    dialogPolyfill.registerDialog(this.element);
    this.url = this.data.get("url");
    this.errorMessage = this.data.get("errorMessage");
  }

  /**
   * Handles the fetch response by reloading the page on success or throwing
   * an error that is caught by handleError.
   *
   * @param {Object} jsonResponse fetch response object
   */
  handleResponse(jsonResponse) {
    if (!jsonResponse.success) {
      throw new Error(jsonResponse.message);
    }

    window.location.reload();
  }

  /**
   * Handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    console.error(err);
    this.closeDialog();
    showErrorDialog(`${this.errorMessage} ${err.message}`);
  }

  /**
   * Enables unexpire button when a reason is entered
   */
  changeReason() {
    if (this.reasonTarget.value.length > 1) {
      this.unexpireButtonTarget.removeAttribute("disabled");
    } else {
      this.unexpireButtonTarget.setAttribute("disabled", "");
    }
  }

  unexpire() {
    const params = JSON.stringify({reason: this.reasonTarget.value});
    const request = window.acima.fetchInit({method: "POST", body: params});

    fetch(this.url, request)
        .then((response) => response.json())
        .then((jsonResponse) => this.handleResponse(jsonResponse))
        .catch((err) => this.handleError(err));
  }

  closeDialog() {
    this.unexpireButtonTarget.setAttribute("disabled", "");
    this.reasonTarget.value = "";
    this.element.close();
  }

  showDialog() {
    this.element.showModal();
  }
}
