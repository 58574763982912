import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "bundleUrl"
  ]

  createBundle() {
    const url = this.element.dataset.bundleUrl;
    const fetchWith = window.acima.fetchInit({ method: "GET" });
    const controller = this;

    fetch(url, fetchWith).then((response) => {
      return response.json();
    }).then(function(data) {
      if (data.success == true) {
        controller.openDialog();
        controller.showBundleSuccess();
      } else {
        controller.openDialog();
        controller.showBundleFailure();
        controller.showFailureReason(data.message);
      }
    });
  }

  openDialog() {
    document.querySelector("#bundle-confirmation-modal").showModal();
  }

  showBundleSuccess() {
    document.querySelector("#bundle-success").classList.remove("hidden");
  }

  showBundleFailure() {
    document.querySelector("#bundle-failed").classList.remove("hidden");
  }

  showFailureReason(message) {
    document.querySelector("#bundle-failure-reason").append(message);
  }
}
