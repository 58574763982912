import {Controller} from "stimulus";
import {alertHelper} from "../../helpers/alert_helpers";

/** Controls the edit/add notes dialog */
export default class extends Controller {
  static targets = [
    "alertNotice",
    "messageBody",
    "title",
  ];

  /**
   * Connect controller, register dialog, set properties
   */
  connect() {
    dialogPolyfill.registerDialog(this.element);
    this.formMode = "unknown";
    this.resourceUrl = this.data.get("resourceUrl");
    this.failureMessage = this.data.get("failureMessage");
    this.emptyNoteMessage = this.data.get("emptyNote");
    this.editTitle = this.data.get("editTitle");
    this.addTitle = this.data.get("addTitle");
  }

  /**
   * Handles error responses and sets notification alert
   *
   * @param {Error} err caught error
   */
  handleError(err) {
    console.error(err);
    alertHelper.error(this.alertNoticeTarget, this.failureMessage);
  }

  /**
   * Handles successful responses, closes dialog and refreshes the page.
   *
   * @param {object} responseJson parsed response json
   */
  handleResponse(responseJson) {
    if (!responseJson.success) {
      throw new Error(responseJson.message);
    }

    this.closeDialog();
    window.location.reload();
  }

  /**
   * Closes the dialog and resets note specific properties
   */
  closeDialog() {
    this.formMode = "unknown";
    this.messageBodyTarget.value = "";
    this.comment = {};
    alertHelper.clear(this.alertNoticeTarget);

    this.element.close();
  }

  /**
   * Renders the appropriate dialog title for edit/add
   */
  renderTitle() {
    if (this.formMode === "edit") {
      this.titleTarget.textContent = this.editTitle;
    } else {
      this.titleTarget.textContent = this.addTitle;
    }
  }

  /**
   * Submits the form if it is valid otherwise noops
   */
  submit() {
    if (this.invalid()) {
      return;
    }

    if (this.formMode === "edit") {
      this.updateNote();
    } else {
      this.createNote();
    }
  }

  /**
   * Checks if the message body field has a value
   *
   * @return {boolean} form invalid or not
   */
  invalid() {
    if (this.messageBodyTarget.value.length === 0) {
      alertHelper.error(this.alertNoticeTarget, this.emptyNoteMessage);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Formats the note request body
   *
   * @return {string} request body json string
   */
  requestBody() {
    return JSON.stringify({
      comment: {
        ...this.comment,
        comment: this.messageBodyTarget.value,
      },
    });
  }

  /**
   * Updates an existing note
   */
  updateNote() {
    const url = `${this.resourceUrl}/${this.comment.id}.json`;
    const request = {method: "PATCH", body: this.requestBody()};

    fetch(url, window.acima.fetchInit(request))
        .then((res) => res.json())
        .then((json) => this.handleResponse(json))
        .catch((err) => this.handleError(err));
  }

  /**
   * Creates a new note
   */
  createNote() {
    const url = `${this.resourceUrl}.json`;
    const request = {method: "POST", body: this.requestBody()};

    fetch(url, window.acima.fetchInit(request))
        .then((res) => res.json())
        .then((json) => this.handleResponse(json))
        .catch((err) => this.handleError(err));
  }

  destroyNote({noteId}) {
    const url = `${this.resourceUrl}/${noteId}.json`;
    const request = {method: "DELETE"};

    fetch(url, window.acima.fetchInit(request))
        .then((res) => res.json())
        .then((json) => this.handleResponse(json))
        .catch((err) => this.handleError(err));
  }

  /**
   * Shows the dialog and assigns initial data
   *
   * @param {Object} comment comment object
   * @param {number} data.id existing note id
   * @param {string} data.commentable_type type of object the comment is for
   * @param {number} data.commentable_id id of object the comment is for
   */
  showDialog(comment) {
    this.formMode = comment.id ? "edit" : "create";
    this.renderTitle();
    this.comment = comment;
    this.messageBodyTarget.value = comment.comment;
    this.element.showModal();
  }
}
