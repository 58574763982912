import {Controller} from "stimulus";

/** Handle Add Account modal functionality */
export default class extends Controller {
    static targets = [
      "cardGuid", "cardDeleteUrl", "errors",
    ];

    /** Register dialog */
    connect() {
      dialogPolyfill.registerDialog(this.element);
    }

    /**
     * Get card guid and delete url from controller
     * @param {string} cardGuid
     * @param {string } cardDeleteUrl url for card resource
     */
    openDialog(cardGuid, cardDeleteUrl) {
      this.clearForm();
      this.cardGuid = cardGuid;
      this.cardDeleteUrl = cardDeleteUrl;
      this.element.showModal();
    }

    /** Close the dialog */
    closeDialog() {
      this.clearForm();
      this.element.close();
    }

    /**
     * delete card controller event callback
     * @param {object} event - triggering event
     */
    deleteCard(event) {
      const url = this.cardDeleteUrl;
      const requestHash = {
        method: "DELETE",
        body: JSON.stringify({
          guid: this.cardGuid,
        }),
      };
      const fetchWith = window.acima.fetchInit(requestHash);

      fetch(url, fetchWith).then((response) => {
        return response.json();
      }).then((data) => {
        if (data.success === false && data.redirect) {
          window.location = data.redirect;
          return false;
        }
        if (data.success === true) {
          this.clearForm();
          this.closeDialog();
          window.location.reload();
        } else {
          this.clearErrors();
          this.addError(data.message);
        }
      });
    }

    /** Close the dialog */
    closeDialog() {
      this.element.close();
    }

    /** Clear input fields when modal closed */
    clearForm() {
      this.cardGuid = null;
      this.cardDeleteUrl = null;
      this.clearErrors();
    }

    /** Clear errors in modal */
    clearErrors() {
      this.errorsTarget.classList.add("hide");
      this.errorsTarget.textContent = "";
    }

    /** Add errors into modal
     * @param {string} message Error message from invalid form.
     */
    addError(message) {
      const item = document.createElement("LI");
      item.textContent = message;
      this.errorsTarget.classList.remove("hide");
      this.errorsTarget.appendChild(item);
    }
}
