import {Controller} from "stimulus";

/** Liability damage waiver dialog launcher */
export default class extends Controller {
  static targets = ["dialog"];

  /**
   * Launch the dialog
   */
  launchDialog() {
    this.dialogTarget.showModal();
  }
}
