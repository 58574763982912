import {Controller} from "stimulus";

/** Benefits plan dialog launcher */
export default class extends Controller {
  static targets = ["dialog"];

  connect() {
    dialogPolyfill.registerDialog(this.dialogTarget);
  }
  /**
   * Launch the dialog
   */
  launchDialog() {
    this.dialogTarget.showModal();
  }
}
