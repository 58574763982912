import { Controller } from "stimulus";
import {
  showErrorDialog
} from "../shared/common/dialogs/error_dialog_controller";
import {
  flash
} from "../shared/common/notices/flash_alerts_controller";

// Controller for mobile application link and online application

export default class extends Controller {
  static targets = [
    "phoneNumber",
    "sendLinkButton",
    "box",
    "error",
    "prequalApplication"
  ];

  connect() {
  }

  get phoneNumber() {
    return this.phoneNumberTarget;
  }

  get sendLinkButton() {
    return this.sendLinkButtonTarget;
  }

  get validateUrl() {
    return this.boxTarget.dataset.validateUrl;
  }

  get submitUrl() {
    return this.boxTarget.dataset.submitUrl;
  }

  get invalidNumber() {
    return this.boxTarget.dataset.invalidNumber;
  }

  clearErrors() {
    this.errorTarget.textContent = "";
    this.errorTarget.parentElement.classList.remove("has-error");
  }

  showErrors(errorMessage) {
    this.errorTarget.textContent = errorMessage;
    this.errorTarget.parentElement.classList.add("has-error");
  }

  phoneNumberChange(event) {
    this.clearErrors();

    let number = this.phoneNumber.value;
    number = this.first10Digits(number);
    number = this.withDashes(number);
    this.phoneNumber.value = number;

    if (number.length === 12) {
      this.validateNumber(number);
    } else {
      this.sendLinkButton.disabled = true;
    }
  }

  first10Digits(number) {
    return number.replace(/\D/g, "").slice(0, 10);
  }

  withDashes(number) {
    const first = number.slice(0, 3);
    const middle = number.slice(3, 6);
    const last = number.slice(6);

    return [first, middle, last].filter((word) => word.length > 0).join("-");
  }

  /**
   * Action for validating the mobile phone number
   */

  validateNumber(number) {
    const fetchInit = window.acima.fetchInit({
      method: "GET",
      headers: { "Content-Type": "application/json" }
    });
    fetch(this.validateUrl + ".json?mobile_number=" + number, fetchInit).then(
      response => {
        return response.json();
      }).then(data => {
      if (data) {
        if (data.type == "mobile") {
          this.sendLinkButton.disabled = false;
        } else {
          this.showErrors(this.invalidNumber);
        }
      }
    });
  }

  /*
   * Action for submitting form
   */

  submitForm() {
    const locationGuid = this.prequalApplicationTarget.dataset.locationGuid;
    const mobileNumber = this.phoneNumberTarget.value;
    const url = this.prequalApplicationTarget.dataset.url;

    const params = JSON.stringify({
      location_guid: locationGuid,
      mobile_number: mobileNumber
    });

    const fetchWith = window.acima.fetchInit({ method: "POST", body: params });

    fetch(url, fetchWith)
      .then(response => response.json())
      .then(jsonResponse => this.handleResponse(jsonResponse))
      .catch(err => this.handleError(err));
  }

  /**
   * handles Json response
   *
   * @param {Object} err caught error object
   */

  handleResponse(jsonResponse) {
    if (jsonResponse.success) {
      this.showSuccessMessage(jsonResponse.successMessage);
    } else {
      this.handleError(new Error(jsonResponse.message));
    }

    // window.location.reload();
  }

  /**
   * handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    showErrorDialog(err);
  }

  /**
   * Display flash success to user
   *
   * @param {Object} successMessage display the sucessfull message
   */
  showSuccessMessage(successMessage) {
    flash.clear();
    flash.success(`${successMessage}`);
    this.phoneNumberTarget.value = "";
    this.sendLinkButtonTarget.disabled = true;
  }
};
