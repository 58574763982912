import {Controller} from "stimulus";

/** Controller for rejecting delivery confirmations */
export default class extends Controller {
  static targets = [
    "auditorRejectionNote",
    "damageOrRepairNeeded",
    "submitBtn",
    "undeliveredItems",
  ];

  /**
   * Submits the form for rejection of delivery confirmation
   * @param {event} event click event to trigger submission of the form
   */
  rejectDeliveryConfirmation(event) {
    event.currentTarget.disabled = true;

    const fetchWith = window.acima.fetchInit({method: "DELETE"});
    const url = event.currentTarget.dataset.urlPath;
    const params = this.formData;
    const controller = this;

    fetch(`${url}?${params}`, fetchWith).then(function(response) {
      return response.json();
    }).then(function(data) {
      if (data.success == true) {
        window.location.reload();
      } else {
        controller.closeModal();
        show_common_error_dialog(data.message);
      }
    });
  }

  /** Closes the HTML modal */
  closeModal() {
    this.element.close();
  }

  /**
   * Checks that the form is ready and activates/deactivates the submit button
   */
  formReady() {
    console.log("formReady check");
    if ( this.undeliveredItemsPresent ||
      this.damagesOrRepairs ||
      this.auditorRejectionNoteTarget.value != "") {
      this.submitBtnTarget.disabled = false;
    } else {
      this.submitBtnTarget.disabled = true;
    }
  }

  /**
   * Returns URLSearchParams for rejecting the DeliveryConfirmation
   * @return {string}
   */
  get formData() {
    return new URLSearchParams({
      auditor_rejection_note: this.auditorRejectionNoteTarget.value,
      auditor_rejected: this.auditorRejectionNoteTarget.value != "",
      damage_or_repair_needed: this.damagesOrRepairs,
      undelivered_items: this.undeliveredItemsPresent,
      verification_text_eligible: false,
    }).toString();
  }

  /**
   * Returns if the form has been checked yes/no on undelivered_items
   * @return {boolean}
   */
  get undeliveredItemsPresent() {
    return this.undeliveredItemsTarget.checked == true;
  }

  /**
   * Returns if the form has been checked yes/no on damage_or_repair_needed
   * @return {boolean}
   */
  get damagesOrRepairs() {
    return this.damageOrRepairNeededTarget.checked == true;
  }
}
