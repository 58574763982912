import {Controller} from "stimulus";

/**
 * Initializes and launches SSN verification modal.
 */
export default class extends Controller {
  /**
   * Injects contextually-dependent bits of data into SSN
   * verification modal and launches it.
   */
  launchDialog() {
    const modalController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("#verify-ssn-dialog"),
      "ssn-verifications-redesign"
    );

    modalController.launchDialog(
      this.data.get("applicant-id"),
      this.data.get("applicant-type"),
    );
  }
}
