import {Controller} from "stimulus";

/**
 * Controller for unmasking bank account numbers.
 */
export default class extends Controller {
  static targets = [
    "bankAccountNumber",
  ];

  toggleMask() {
    if (this.bankAccountNumberTarget.textContent == this.data.get("bankAccountNumberFull")) {
      this.bankAccountNumberTarget.textContent = this.data.get("bankAccountNumberMasked");
    }
    else {
      this.bankAccountNumberTarget.textContent = this.data.get("bankAccountNumberFull");
    }
  }
};
