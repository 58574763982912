import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "status",
    "damagesWaiverButton",
    "benefitsPlanButton",
    "optInBothButton",
  ]

  optIn(event) {
    const button = event.currentTarget;
    this.disableServiceButtons(button);
    this.submitServiceDecision(button.dataset.url, "POST");
  }

  optOut(event) {
    const button = event.currentTarget;
    button.disabled = true;
    this.submitServiceDecision(button.dataset.url, "DELETE", true);
  }

  submitServiceDecision(url, method, redirect = false) {
    const request = window.acima.fetchInit(
      { method: method }
    );
    fetch(url, window.acima.fetchInit(request))
      .then(res => res.json())
      .then(json => this.handleResponse(json))
      .then(() => {
        if (redirect) {
          window.setTimeout(location.reload(), 1000)
        }
      })
      .catch(err => console.error(err));
  }

  handleResponse(response) {
    if (response.success == true) {
      this.statusTarget.classList.add("alert-info");
    } else {
      this.statusTarget.classList.add("alert-error");
    }
    this.statusTarget.innerHTML = response.message;
  }

  disableServiceButtons(currentTarget) {
    if (currentTarget.dataset.target.includes("optInBothButton")) {
      this.damagesWaiverButtonTarget.disabled = true;
      this.benefitsPlanButtonTarget.disabled = true;

      if (this.hasOptInBothButtonTarget) {
        this.optInBothButtonTarget.disabled = true;
      }
    }

    if (currentTarget.dataset.target.includes("benefitsPlanButton")) {
      this.benefitsPlanButtonTarget.disabled = true;

      if (this.hasOptInBothButtonTarget) {
        this.optInBothButtonTarget.classList.add("hidden");
      }
    }

    if (currentTarget.dataset.target.includes("damagesWaiverButton")) {
      this.damagesWaiverButtonTarget.disabled = true;

      if (this.hasOptInBothButtonTarget) {
        this.optInBothButtonTarget.classList.add("hidden");
      }
    }
  }
}
