import {isNil} from "lodash";

/**
 * Render contract viewers
 *
 * Iterates through all viewers and yields them to the individual viewer
 * renderer.
 *
 * @param {Object} data all data required to render the viewer UI
 * @param {Object[]} data.viewers list of viewers
 * @param {number} data.lockOwnerId id of current lock owner
 * @param {element} data.viewersList element to render viewer list in
 */
export function renderViewers(data) {
  data.viewersList.innerHTML =
      data.viewers
          .sort((a, b) => a.id < b.id ? -1 : 1) // sort by ID
          .map((viewer) => renderViewer(viewer, data.lockOwnerId))
          .join("");
}

/**
 * Renders a chat link if chat url is present otherwise renders an empty
 * anchor tag
 *
 * @param {string} chatUrl possibly null url for starting a chat session
 *
 * @return {string} anchor tag for chat url or blank anchor tag
 */
function renderChatLink(chatUrl) {
  if (isNil(chatUrl)) {
    return "<a href='#' class='no-link'>";
  }

  return `<a href="${chatUrl}" target="_blank">`;
}

/**
 * Render an individual viewer, called by renderViewers()
 *
 * @param {object} viewer user/merchant representation from channel
 * @param {number} lockOwnerId id of current lock owner
 *
 * @return {string} viewer markup
 */
function renderViewer(viewer, lockOwnerId) {
  const isOwner = viewer.id === lockOwnerId;

  return `
    <div class="${renderViewerClass(viewer, lockOwnerId)}">
      ${renderChatLink(viewer.chat_url)}
        ${isOwner ? renderOwner(viewer) : renderPlainViewer(viewer)}
      </a>
    </div>
  `;
}

function renderPlainViewer(viewer) {
  const title = `${viewer.name} | ${viewer.department}`;

  return `
    <div class="viewer-thumbnail">
      <img src="${viewer.image}" title="${title}" alt="${title}">
      <svg class="progress-arc">
        <circle r="50%" cx="50%" cy="50%"></circle>
      </svg>
    </div>
  `;
}

function renderOwner(viewer) {
  const countController = "users--contracts--locking--lock-timeout";
  const countTarget = "progressArc";
  const title = `${viewer.name} | ${viewer.department}`;

  return `
    <div class="viewer-thumbnail" data-controller="${countController}">
      <img src="${viewer.image}" title="${title}" alt="${title}">
      <svg class="progress-arc">
        <circle
          data-target="${countController}.${countTarget}"
          r="50%"
          cx="50%"
          cy="50%"
        >
        </circle>
      </svg>
    </div>
  `;
}
/**
 * Renders the CSS class for a viewer
 *
 * Determines whether to highlight the viewer if they are the current lock
 * owner or if they are an absent lock owner.
 *
 * @param {Object} viewer a viewer object
 * @param {number} lockOwnerId id of current lock owner
 *
 * @return {string} CSS class for viewer
 */
function renderViewerClass(viewer, lockOwnerId) {
  const ownerClass =
      viewer.absent ? "viewer-lock-owner-absent" : "viewer-lock-owner";

  if (viewer.id === lockOwnerId) {
    return `viewer ${ownerClass}`;
  } else if (viewer.type === "merchant") {
    return "viewer viewer-merchant";
  } else {
    return "viewer";
  }
}
