import {Controller} from "stimulus";
import Clusterize from "clusterize.js";
import {debounce} from "lodash";
import {buildPagination} from "../../helpers/advanced_paging";
import {
  showErrorDialog,
} from "../../shared/common/dialogs/error_dialog_controller";
import {
  showAssignedContractsDialog,
} from "./assigned_contracts_dialog_controller";
import {
  showDownloadableAttachmentsDialog,
} from "./downloadable_attachments_dialog_controller";

const SPINNER_MARKUP = `
  <tr>
    <td colspan="7">loading...</td>
  </tr>
  <tr>
    <td colspan="7"><div class="loading-spinner loading-spinner-lg"></div></td>
  </tr>
`;

const MAX_RECORD_COUNT = 10000;

/** Text Messages List Controller */
export default class extends Controller {
  static targets = [
    "list",
    "listBody",
    "assignmentStatusQuery",
    "directionTypeQuery",
    "directionalStatusQuery",
    "recordsPerPageQuery",
    "startDateQuery",
    "endDateQuery",
    "searchTermQuery",
    "pagination",
  ]

  /**
    * Register the controller
    */
  connect() {
    this.pageNumber = 1;
    this.textMessagesResourceUrl = this.data.get("textMessagesResourceUrl");
    this.search = debounce(this.fetchList, 1000);
    this.totalRecords = 0;
    this.fetchList();
  }

  /**
   * Handles hard errors in fetch
   *
   * @param {Error} err
   */
  handleError(err) {
    console.error("Error: ", err);
    this.listBodyTarget.innerHTML = "";
    showErrorDialog(this.data.get("errorMessage"));
  }

  /**
   * Handles success and failure response in text messages fetch
   *
   * @param {Object} responseJson parsed response json
   */
  handleResponse(responseJson) {
    if (responseJson.success) {
      this.handlePagination(responseJson.pagination);
      this.renderList(responseJson.rows);
    } else {
      this.handleError(new Error(responseJson.status));
    }
  }

  /**
   * Fetch text message list
   */
  fetchList() {
    const request = {method: "GET"};
    this.renderLoading();

    fetch(this.formatQueryUrl("json"), window.acima.fetchInit(request))
        .then((res) => res.json())
        .then((json) => this.handleResponse(json))
        .catch((err) => this.handleError(err));
  }

  /**
   * Renders loading markup
   */
  renderLoading() {
    this.listBodyTarget.innerHTML = SPINNER_MARKUP;
  }

  /**
   * Renders text messages list as table
   *
   * @param {array} textMessages array of json data for texts
   */
  renderList(textMessages) {
    new Clusterize({
      rows: textMessages.map((rowData) => this.renderRow(rowData)),
      scrollId: this.listTarget.id,
      contentId: this.listBodyTarget.id,
    });
  }

  /**
   * Renders individual row of text message list data
   *
   * @param {Object} rowData
   *
   * @return {string} row markup for text message list
   */
  renderRow(rowData) {
    return `
      <tr>
        <td>${this.renderContractNumberColumn(rowData)}</td>
        <td>${rowData.date}</td>
        <td>
          ${rowData.sender.name}<br />
          ${rowData.sender.info}
        </td>
        <td class="word-break-word">${rowData.message}</td>
        <td>${this.renderMediaColumn(rowData)}</td>
        <td class="titleize text-message-status-${rowData.message_status}">
          ${rowData.message_status}
        </td>
      </tr>
    `;
  }

  /**
   * Renders the contract number column value, either a link to the contract or
   * an assign button
   *
   * @param {Object} rowData object with all data for row
   * @param {string} rowData.contract_url null or html link string
   * @param {number} rowData.id ID of the text message
   *
   * @return {string} contract number column markup
   */
  renderContractNumberColumn({id, contract_url}) {
    if (contract_url != null) {
      return contract_url;
    }

    return `
      <div
        class="btn btn-default btn-sm"
        data-text-message-id="${id}"
        data-action="click->users--text-messages--list#showAssignDialog"
      >Assign</div>
    `;
  }

  /**
   * Renders the attachments column
   *
   * @param {Object} rowData object with all data for row
   * @param {string} rowData.attachments null or html link string
   * @param {number} rowData.id ID of the text message
   *
   * @return {string} contract number column markup
   */
  renderMediaColumn({id, attached_media}) {
    if (attached_media === false) {
      return "None";
    }

    return `
      <a
        data-text-message-id="${id}"
        data-action="click->users--text-messages--list#showAttachmentsDialog"
      >Download</a>
    `;
  }

  /**
   * Show the assign dialog
   *
   * @param {Event} e event that triggered the action
   */
  showAssignDialog(e) {
    const textMessageId = e.currentTarget.getAttribute("data-text-message-id");
    showAssignedContractsDialog(textMessageId);
  }

  /**
   * Show the attachments dialog
   *
   * @param {Event} e event that triggered the action
   */
  showAttachmentsDialog(e) {
    const textMessageId = e.currentTarget.getAttribute("data-text-message-id");
    showDownloadableAttachmentsDialog(textMessageId);
  }

  /**
   * TODO move the error message into translations
   *
   * Downloads the xlsx version of the results
   */
  downloadXlsx() {
    if (this.totalRecords > MAX_RECORD_COUNT) {
      showErrorDialog(
          `The maximum records that can be exported is ${MAX_RECORD_COUNT}`
      );
      return;
    }

    const url = this.formatQueryUrl("xlsx");
    document.location.assign(url);
  }

  /**
   * Builds the request URL with query params and format
   *
   * @param {string} format the format of the request, json vs xlsx
   *
   * @return {URL} the formatted URL for requests
   */
  formatQueryUrl(format) {
    const queryParams = {
      page: this.pageNumber,
      records_per_page: this.recordsPerPageQueryTarget.value,
      assignment_status: this.assignmentStatusQueryTarget.value,
      direction_type: this.directionTypeQueryTarget.value,
      directional_status: this.directionalStatusQueryTarget.value,
      start_date: this.startDateQueryTarget.value,
      end_date: this.endDateQueryTarget.value,
      search_term: this.searchTermQueryTarget.value,
    };
    const queryUrl = new URL(`${this.textMessagesResourceUrl}.${format}`);

    Object
        .keys(queryParams)
        .forEach((key) => queryUrl.searchParams.append(key, queryParams[key]));

    return queryUrl;
  }

  // Pagination Interactions

  /**
   * Updates selected page number and fetches text messages
   *
   * @param {Event} e event that triggered changing of page action
   */
  changePage(e) {
    if (e.currentTarget.parentElement.classList.contains("disabled")) {
      return;
    }

    this.pageNumber = e.currentTarget.getAttribute("data-page");
    this.fetchList();
  }

  /**
   * Renders pagination controls
   *
   * @param {Object} paginationData
   */
  handlePagination(paginationData) {
    this.totalRecords = paginationData.total_records;
    this.paginationTarget.innerHTML = buildPagination({
      ...paginationData,
      action: "data-action='users--text-messages--list#changePage'",
    });
  }
}
