import {Controller} from "stimulus";

/** controller for income conditions modal */
export default class extends Controller {
  static targets = [
    "recurOn",
    "incomeForm",
    "submitButton",
    "firstDayOfMonthSelect",
    "secondDayOfMonthSelect",
    "weekOfMonthSelect"
  ]

  connect() {
    const buttons = this.element.querySelectorAll(".btn-boring");
    buttons.forEach((button) => {
      button.addEventListener("click", this.highlightTarget);
    });
  }

  /** launch dialog */
  launchDialog() {
    document.querySelector("#add_income_conditions_modal").showModal();
  }

  /**
   * close the dialog
   * @param {event} e
   */
  closeDialog(e) {
    e.preventDefault();
    document.getElementById("errors-row").style.display = "none";
    this.clearAllFormSelections();
    this.hideElements(".income-form-rows");
    document.querySelector("#add_income_conditions_modal").close();
  }

  /** hide income frequency rows */
  hideFormRows() {
    document.querySelectorAll(".income-form-rows").forEach((el) => el.style.display = "none");
  }

  /**
   * hide select tag
   * @param {select tag} select
   */
  hideSelect(select) {
    document.querySelectorAll(select).forEach((el) => el.style.display = "none");
  }

  /**
   * show select tag
   * @param {select tag} select
   */
  showSelect(select) {
    document.querySelectorAll(select).forEach((el) => el.style.display = "block");
  }

  /**
   * show elements
   * @param {element} element
   */
  showElements(element) {
    document.querySelectorAll(element).forEach((el) => el.style.display = "block");
  }

  /**
   * show elements inline
   * @param {element} element
   */
  showElementsInline(element) {
    document.querySelectorAll(element).forEach((el) => el.style.display = "inline-block");
  }

  /**
   * hide elements inline
   * @param {element} element
   */
  hideElements(element) {
    document.querySelectorAll(element).forEach((el) => el.style.display = "none");
  }

  /** remove error text from modal */
  removeErrorText() {
    this.hideElements("#errors-row");
    document.getElementById("error-text").innerHTML = "";
  }

  /** hide day selects */
  hideDaySelects() {
    this.showElements("#first-day-of-month-select");
    this.hideElements("#second-day-of-month-select");
    this.hideElements("#select-wrapper-row");
  }

  /** show weekly row */
  showWeeklyRow() {
    this.hideDaySelects();
    this.hideElements(".income-form-rows");
    this.clearSecondaryFormSelections();
    this.showElements("#weekly-row");
    this.recurOnTarget.value = "day_of_week";
    this.validateForm();
  }

  /** show every other week row */
  showEveryOtherWeekRow() {
    this.hideDaySelects();
    this.hideElements(".income-form-rows");
    this.clearSecondaryFormSelections();
    this.showElements("#every-other-week-row");
    this.recurOnTarget.value = "day_of_week";
    this.validateForm();
  }

  /** show twice monthly row */
  showTwiceMonthlyRow() {
    this.hideElements(".income-form-rows");
    this.hideElements("#day-of-month-title");
    this.clearSecondaryFormSelections();
    this.showElements("#select-wrapper-row");
    this.showElements("#twice-monthly-title");
    this.showElementsInline("#first-day-of-month-select");
    this.showElementsInline("#second-day-of-month-select");
    this.recurOnTarget.value = "day_of_month";
    this.validateForm();
  }

  /** show day of month row */
  showDayOfMonthRow() {
    this.hideElements(".income-form-rows");
    this.hideElements("#twice-monthly-title");
    this.hideElements("#second-day-of-month-select");
    this.clearSecondaryFormSelections();
    this.showElements("#select-wrapper-row");
    this.showElements("#day-of-month-title");
    this.showElementsInline("#first-day-of-month-select");
    this.recurOnTarget.value = "day_of_month";
    this.validateForm();
  }

  /** show week day of month row */
  showWeekdayOfMonthRow() {
    this.hideDaySelects();
    this.hideElements(".income-form-rows");
    this.clearSecondaryFormSelections();
    this.showElements("#weekday-of-the-month-row");
    this.recurOnTarget.value = "day_of_week";
    this.validateForm();
  }

  daysOfWeekElements() {
    return document.getElementsByName(
      "contract_applications_forms_income_form[schedule_day_of_week]"
    );
  }

  relativeWeekElements() {
    return document.getElementsByName(
      "contract_applications_forms_income_form[schedule_relative_week]"
    );
  }

  scheduleFrequencyElements() {
    return document.getElementsByName(
      "contract_applications_forms_income_form[schedule_frequency]"
    );
  }

  radioElement(radioElements) {
    const radios = radioElements;
    let selectedElement;
    for (const radio of radios) {
      if (radio.checked) {
        selectedElement = radio;
        break;
      }
    }
    return selectedElement;
  }

  radioIsChecked(radioElements) {
    const radios = radioElements;
    let checked = false;
    for (const radio of radios) {
      if (radio.checked) {
        checked = true;
        break;
      }
    }
    return checked;
  }

  uncheckRadios(radioElements) {
    const radios = radioElements;
    for (const radio of radios) {
      if (radio.checked) {
        radio.checked = false;
        radio.parentElement.classList.remove("checked");
        radio.parentElement.classList.remove("btn-selected");
      }
    }
  }

  clearAllFormSelections() {
    this.uncheckRadios(this.scheduleFrequencyElements());
    this.clearSecondaryFormSelections();
    this.disableSubmitButton();
  }

  clearSecondaryFormSelections() {
    this.clearSecondaryRadioSelections();
    this.clearDropdownSelections();
  }

  clearSecondaryRadioSelections() {
    this.uncheckRadios(this.daysOfWeekElements());
    this.uncheckRadios(this.relativeWeekElements());
  }

  clearDropdownSelections() {
    this.firstDayOfMonthSelectTarget.value = "";
    this.secondDayOfMonthSelectTarget.value = "";
    this.weekOfMonthSelectTarget.value = "";
  }

  dayOfWeekChecked() {
    return this.radioIsChecked(this.daysOfWeekElements());
  }

  relativeWeekChecked() {
    return this.radioIsChecked(this.relativeWeekElements());
  }

  scheduleFrequencyElement() {
    return this.radioElement(this.scheduleFrequencyElements());
  }

  firstDayOfMonthSelected() {
    return this.firstDayOfMonthSelectTarget.value !== "";
  }

  secondDayOfMonthSelected() {
    return this.secondDayOfMonthSelectTarget.value !== "";
  }

  weekOfMonthSelected() {
    return this.weekOfMonthSelectTarget.value !== "";
  }

  dayOfWeekFormIsValid() {
    const frequencyElement = this.scheduleFrequencyElement();

    if (frequencyElement.value == "weekly") {
      return true;
    } else if (frequencyElement.value == "every_other_week") {
      return this.relativeWeekChecked();
    } else if (frequencyElement.value == "monthly" &&
               frequencyElement.id == "frequency-weekday-of-month") {
      return this.weekOfMonthSelected();
    } else {
      return false;
    }
  }

  dayOfMonthFormIsValid() {
    const frequencyElement = this.scheduleFrequencyElement();

    if (frequencyElement.value == "twice_a_month") {
      return (this.firstDayOfMonthSelected() &&
              this.secondDayOfMonthSelected());
    } else if (frequencyElement.value == "monthly" &&
               frequencyElement.id == "frequency-day-of-month") {
      return this.firstDayOfMonthSelected();
    } else {
      return false;
    }
  }

  formIsValid() {
    if (this.dayOfWeekChecked()) {
      return this.dayOfWeekFormIsValid();
    } else if (this.firstDayOfMonthSelected()) {
      return this.dayOfMonthFormIsValid();
    } else {
      return false;
    }
  }

  validateForm() {
    if (this.formIsValid()) {
      this.enableSubmitButton();
    } else {
      this.disableSubmitButton();
    }
  }

  enableSubmitButton() {
    this.submitButtonTarget.classList.remove("disabled");
  }

  disableSubmitButton() {
    this.submitButtonTarget.classList.add("disabled");
  }

  highlightTarget = (e) => {
    const target = e.currentTarget;
    if (target.classList.contains("btn-boring")) {
      Array.from(target.parentNode.children).forEach((sibling) => {
        sibling.classList.remove("btn-selected");
        sibling.querySelector("input").checked = false;
      });

      target.querySelector("input").checked = true;
      target.classList.add("btn-selected");
    }
  }

  /**
   * submit applicant income asynchronously
   * @param {event} e
   */
  submitForm() {
    this.disableSubmitButton();
    this.removeErrorText();

    if (this.formIsValid()) {
      const url = this.incomeFormTarget.action;
      const form = new FormData(this.incomeFormTarget);
      const fetchWith = {method: "POST", body: form};

      fetch(url, fetchWith)
        .then((response) => response.json())
        .then(function(data) {
          if (data.success == true) {
            window.location.reload();
          } else {
            document.getElementById("errors-row").style.display = "block";
            const errorText = document.createTextNode(data.failure);
            document.getElementById("error-text").appendChild(errorText);
          }
        }
      );
    }
  }
}
