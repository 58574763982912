import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["bucket"];

  connect() {
    if (!this.shouldDisplayPartial) return;

    this.loadLeaseBucketPartial();
  }

  /**
   * Renders partial in the target div
   * after a fetch to ::Users::LeaseBucketsController
   */
  loadLeaseBucketPartial() {
    const url = this.element.dataset.url;
    const request = window.acima.fetchInit({ method: "GET" });

    fetch(url, request)
      .then(function(response) {
        if (response.ok) return response.text();
      })
      .then((html) => this.bucketTarget.innerHTML = html);
  }

  /**
   * Checks to see if element has a display property and
   * returns the result as a boolean
   * display property set by CSS for different screen sizes
   * @return {Boolean} display property not "none"
   */
  get shouldDisplayPartial() {
    const computedStyle = window.getComputedStyle(this.bucketTarget);
    return computedStyle.getPropertyValue("display") != "none";
  }
}
