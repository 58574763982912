import { Controller } from "stimulus";

/** Manages the location edit form */
export default class extends Controller {
  static targets = [
    "platformTypeToggle",
    "bigcommerceFormGroup",
    "ecomPlatformToggle",
    "ecomPlatformGroup"
  ]
  /**
   * Connects controller and toggles ecom platform type field if location is_ecom
   * is set to true. Also toggles BigCommerce fields if location ecom_platform_type
   * column is selected as "BigCommerce"
  */
  connect() {
    this.showBigCommerceFields();
    this.hideBigCommerceFields();
    this.toggleEcomPlatformType();
  }
  /**
   * displays BigCommerce fields if ecom_platform_type is selected as BigCommerce
  */
  showBigCommerceFields() {
    this.bigcommerceFormGroupTargets.forEach((formGroup) => {
      formGroup.classList.remove("toggle-hide");
    });
  }
  /**
   * hides BigCommerce fields if ecom_platform_type is NOT selected as BigCommerce
   */
  hideBigCommerceFields() {
    this.bigcommerceFormGroupTargets.forEach((formGroup) => {
      formGroup.classList.add("toggle-hide");
    });
  }
  /**
   * if is_ecom checked and BigCommerce type then show BigCommerce group fields
   * if is_ecom checked and not BigCommerce type, then don't show BigCommerce group fields
   * if is_ecom unchecked hide ecom program type and BigCommerce group fields
   */
  toggleEcomPlatformType() {
    const platformType = this.platformTypeToggleTarget;
    const bigcommerceSelected = platformType.options[platformType.options.selectedIndex].text == "BigCommerce";

    this.ecomPlatformGroupTargets.forEach((formGroup) => {
      if (this.ecomPlatformToggleTarget.checked) {
        formGroup.classList.remove("toggle-hide");
        if (bigcommerceSelected) {
          this.showBigCommerceFields();
        } else {
          this.hideBigCommerceFields();
        };
      } else if (!this.ecomPlatformToggleTarget.checked && bigcommerceSelected) {
        this.hideBigCommerceFields();
        formGroup.classList.add("toggle-hide");
      } else {
        formGroup.classList.add("toggle-hide");
      }
    });
  }
}
