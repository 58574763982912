import {Controller} from "stimulus";
import {
  showSpinnerDialog,
  closeSpinnerDialog
} from "../../shared/common/dialogs/spinner_dialog_controller";

/** ALt signing dialogs launcher */
export default class extends Controller {
  connect() {
    this.url = this.element.dataset.url;
    this.loadingMessage = this.element.dataset.waitingMessage;
  }

  /**
   * Launch the 4 Digit Code signature dialog
   * after unacepting the optional services
   */
  launchCodeDialog() {
    this.unacceptAllOptionalServices()
      .then(() => this.showCodeSignatureDialog());
  }

  unacceptAllOptionalServices() {
    showSpinnerDialog(this.loadingMessage);
    const requestBody = {
      liability_damage_waiver_accepted: false,
      benefits_plan_accepted: false
    };

    const request = { method: "PATCH", body: JSON.stringify(requestBody) };
    return fetch(this.url, window.acima.fetchInit(request))
      .then(() => closeSpinnerDialog())
      .catch(err => this.handleError(err));
  }

  handleError(error) {
    console.error(error);
  }

  showCodeSignatureDialog() {
    document
      .querySelector("[data-target='contract-signature-code-dialog']")
      .showModal();
  }
}
