import { Controller } from "stimulus";

const emailRegex = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

export default class extends Controller {
  static targets = [
    "spiffEmailStatus",
    "spiffEmailInvalid",
    "spiffEmail",
    "spiffConfirmEmailStatus",
    "spiffConfirmEmailInvalid",
    "spiffConfirmEmail",
    "spiffNameStatus",
    "spiffNameInvalid",
    "spiffName",
    "spiffInfoLink",
    "spiffInfoForm",
    "requiredField",
    "submitBtn",
    "spiffInfoHeader",
    "errorMessage"
  ];

  initialize() {
    const thead = this.spiffInfoHeaderTarget;
    // Displays spiff name
    if (thead.textContent.trim().length > 0) thead.classList.toggle("hidden");
  }

  preventPaste(e) {
    e.preventDefault();
    this.errorMessageTarget.textContent = acima.i18n.t("invoices.details.pasting_spiff_email");
    this.errorMessageTarget.style.color = 'red';
    this.errorMessageTarget.style.display = 'block';
  }

  clearErrorMessage() {
    this.errorMessageTarget.textContent = '';
    this.errorMessageTarget.style.display = 'none';
  }

  toggleSpiffInfoForm(e) {
    e.preventDefault();
    this.spiffInfoFormTarget.classList.toggle("hidden");
    this.toggleSubmitButton();
    this.clearFields();
  }

  toggleSubmitButton() {
    const form = this.spiffInfoFormTarget;
    const invalidFields = form.getElementsByClassName("has-error");
    const submitButton = this.submitBtnTarget;

    if ((this.requiredFieldTargets.some(field => field.value === "") === false) && (invalidFields.length == 0)) {
      submitButton.disabled = false;
    } else {
      submitButton.disabled = true;
    }
  }

  validateAll(e) {
    const form = this.spiffInfoFormTarget;
    this.highlightInputs();
    const invalidFields = form.getElementsByClassName("has-error");

    if (invalidFields.length > 0) {
      e.preventDefault();
    } else {
      this.toggleSpiffInfoForm(e);
      this.swapSpiffSection(e);
    }
  }

  swapSpiffSection() {
    const thead = this.spiffInfoHeaderTarget;
    thead.classList.remove("hidden");
    thead.textContent = this.spiffNameTarget.value;

    this.spiffInfoLinkTarget.textContent = this.spiffInfoLinkTarget.dataset.editLabel;
  }

  highlightInputs() {
    this.requiredFieldTargets.forEach(input => {
      this.activeInputError(input);
    });
  }

  activeInputError(input) {
    input.focus();
    input.blur();
  }

  validateEmail(e) {
    const emailFieldName = e.target.getAttribute("data-fieldName");
    const invalidTarget = this["spiff" + emailFieldName + "InvalidTarget"];

    if (this.emailReady(emailFieldName)) {
      if (this["hasSpiff" + emailFieldName + "Target"]) {
        invalidTarget.classList.add("hidden");
        this.addSuccess(this["spiff" + emailFieldName + "StatusTarget"]);
      }
    } else {
      invalidTarget.classList.remove("hidden");
      this.addError(this["spiff" + emailFieldName + "StatusTarget"]);
    }
  }

  validateEmailsMatch() {
    if (this.emailReady("ConfirmEmail") && this.emailReady("Email")) {
      if (this.spiffEmailTarget.value === this.spiffConfirmEmailTarget.value) {
        this.spiffConfirmEmailInvalidTarget.classList.add("hidden");
        this.addSuccess(this.spiffConfirmEmailStatusTarget);
      } else {
        this.spiffConfirmEmailInvalidTarget.classList.remove("hidden");
        this.addError(this.spiffConfirmEmailStatusTarget);
      }
    }
  }

  validateSpiffName() {
    if (this.spiffNameReady) {
      this.spiffNameInvalidTarget.classList.add("hidden");
      this.addSuccess(this.spiffNameStatusTarget);
    } else {
      this.spiffNameInvalidTarget.classList.remove("hidden");
      this.addError(this.spiffNameStatusTarget);
    }
  }

  addSuccess(target) {
    target.classList.remove("has-error");
    target.classList.add("has-success");
    this.toggleSubmitButton();
  }

  addError(target) {
    target.classList.remove("has-success");
    target.classList.add("has-error");
    this.toggleSubmitButton();
  }

  clearFields() {
    const form = this.spiffInfoFormTarget;
    const fields = Array.from(form.getElementsByClassName("has-success"));

    fields.forEach(field => {
      field.classList.remove("has-success");
    });
  }

  emailReady(emailFieldName) {
    if (this["hasSpiff" + emailFieldName + "Target"]) {
      return emailRegex.test(this["spiff" + emailFieldName + "Target"].value);
    } else {
      return true;
    }
  }

  get spiffNameReady() {
    return this.spiffNameTarget.value.trim().length > 0;
  }

  get spiffRecordFormReady() {
    return this.emailReady("Email")
      && this.emailReady("ConfirmEmail")
      && this.spiffNameReady;
  }
}
