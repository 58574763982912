import {Controller} from "stimulus";

/**
 * Mask and unmask sensitive information
 */
export default class extends Controller {
  static targets = [
    "eyeball",
    "value",
  ]

  /**
   * Display unmask button once relevant JS has loaded
   */
  connect() {
    if (this.hasEyeballTarget) {
      this.eyeballTarget.classList.remove("hidden");
    }
  }

  /**
   * Transition effect when masking/unmasking value
   *
   * The addition and removal of the "fade-in" class must be
   * separated by a timeout in order to achieve the desired effect.
   * Otherwise, the browser will behave as if nothing changed because
   * the class was added and removed in the same thread.
   */
  fadeInValue() {
    const controller = this;
    this.valueTarget.classList.add("fade-in");

    setTimeout(function() {
      controller.valueTarget.classList.remove("fade-in");
    }, 1);
  }

  /**
   * Reformats raw unmaskable value according to type
   * @param {str} value Unformatted value
   * @return {str} Formatted value
   */
  formatValue(value) {
    if (this.data.get("type") == "ssn") {
      const usFormat = /(\d{3})(\d{2})(\d{4})/;
      const canadianFormat = /(\d{3})(\d{3})(\d{3})/;
      const formattingRegex = this.isCanadianLease() ? canadianFormat : usFormat;

      return value.replace(formattingRegex, "$1-$2-$3");
    } else {
      return value;
    }
  }

  /**
   * Display original (masked) value
   */
  remaskValue() {
    this.valueTarget.textContent = this.data.get("masked");
    this.fadeInValue();
  }

  /**
   * Mask and unmask value
   */
  toggleValueMasking() {
    event.preventDefault();
    if (this.valueTarget.textContent == this.data.get("masked")) {
      this.unmaskValue();
    } else {
      this.remaskValue();
    }
  }

  /**
   * Display unmasked value
   */
  unmaskValue() {
    const controller = this;
    const url = this.data.get("unmask-url");
    const fetchWith = window.acima.fetchInit({method: "GET"});

    fetch(url, fetchWith).then(function(response) {
      return response.json();
    }).then(function(data) {
      const unmaskedValue = controller.formatValue(data.unmasked);
      controller.valueTarget.textContent = unmaskedValue;
      controller.fadeInValue();
    });
  }

  /**
   * Checks if current Lease is Canadian.
   * @return {boolean} true/false value
   */
  isCanadianLease() {
    const boolString = this.data.get("canadian-lease");

    return boolString.toLowerCase() === "true";
  }
}
