import BaseChannel from "./base_channel";

export default class LockRequestChannel extends BaseChannel {
  static identifier = "LockRequestChannel";

  receive(data) {
    super.receive(data);
    const { event_name, response } = data;
    if (event_name != null) {
      this.emit(event_name, response);
    }
  }
}
