export const renderHTML = (tag, content, className = "", option = {}) => {
  const optionString = optionsToHtmlAttr(option);
  return `<${tag} class="${className}" ${optionString}> ${content} </${tag}>`;
};

const optionsToHtmlAttr = option => {
  if (Object.keys(option).length === 0) return "";
  const optionStr = Object.keys(option).map(key => {
    return `${key}="${option[key]}"`;
  });
  return optionStr.join(" ");
};

export class DataTable {
  constructor(controller) {
    this.controller = controller;
  }

  renderHeaders() {
    const { tableHeaders, sortBy, order } = this.controller;
    const nextOrder = order === "ASC" ? "DESC" : "ASC";

    const rows = Object.keys(tableHeaders).map( key => {
      const orderMapping = sortBy === key ? nextOrder : "DESC";
      const attributes = {
        "data-action":
          "click->users--contracts--payments--payment-history#updateSort",
        "role": "columnheader",
        "data-sortBy": key,
        "data-order": orderMapping
      };

      const sortClass =
        sortBy === key ? `sorting_${order.toLowerCase()}` : "sorting";

      return renderHTML("th", tableHeaders[key], sortClass, attributes);
    });

    this.controller.listHeadTarget.innerHTML = renderHTML("tr", rows.join(""));
  }

  renderRow(dataRows) {
    const rows = dataRows.map(data => {
      const columns = data.map(datum => renderHTML("td", datum));
      return renderHTML("tr", columns.join(""));
    });
    this.controller.listBodyTarget.innerHTML = rows.join("");
  }

  updateSorting(target) {
    const { sortby, order } = target.dataset;
    this.controller.sortBy = sortby;
    this.controller.order = order;
  }
}
