/**
 * Dedicated pubsub for the confirm notice to allow event retention
 * Should not be reused outside of the confirm notice for PWA by the flash
 * controller.
 */

const CONFIRM_NOTICE_SUBSCRIBER_REGISTRY = {};

/**
 * Publishes event
 *
 * When called on a new event name, it will create a base subscription, add it
 * to the subscriber registry and store the event in that subscription
 * aa the most recent event.
 *
 * When called on an existing event name, it will replace the most recent event
 * with the new event. Like a stack of one.
 *
 * In both cases it will then attempt to process the event.
 *
 * @param {string} eventName - event to publish
 * @param {Object} data - data bag to act on as part of the event, shape varies
 */
export function pubConfirm(eventName, data) {
  if (!CONFIRM_NOTICE_SUBSCRIBER_REGISTRY[eventName]) {
    CONFIRM_NOTICE_SUBSCRIBER_REGISTRY[eventName] = {
      mostRecentEvent: null,
      callbacks: [],
    };
  }

  CONFIRM_NOTICE_SUBSCRIBER_REGISTRY[eventName].mostRecentEvent = data;
  processEvent(eventName);
}

/**
 * Subscribes to event
 *
 * When called on a new event name, it will create a base subscription, add it
 * to the subscriber registry.
 *
 * It will then add the callback into the list of callbacks for the suscription.
 *
 * It will then attempt to process the most recent event.
 *
 * @param {string} eventName - name of the event to subscribe to
 * @param {function} callback - function to call on event publish
 */
export function subConfirm(eventName, callback) {
  if (!CONFIRM_NOTICE_SUBSCRIBER_REGISTRY[eventName]) {
    CONFIRM_NOTICE_SUBSCRIBER_REGISTRY[eventName] = {
      mostRecentEvent: null,
      callbacks: [],
    };
  }

  CONFIRM_NOTICE_SUBSCRIBER_REGISTRY[eventName].callbacks.push(callback);
  processEvent(eventName);
}

/**
 * Processes the most recent event
 *
 * If no event or no subscribers exist, this will be a noop.
 *
 * If a most recent event exists and any subscribers exist, this will process
 * the event data through each subscriber callback. It will then clear the most
 * recent event to avoid potential duplicate processing.
 *
 * @param {string} eventName name of the event to attempt to process
 */
function processEvent(eventName) {
  const data = CONFIRM_NOTICE_SUBSCRIBER_REGISTRY[eventName].mostRecentEvent;
  const callbacks = CONFIRM_NOTICE_SUBSCRIBER_REGISTRY[eventName].callbacks;

  if (data && callbacks.length) {
    callbacks.forEach((callback) => callback(data));
    CONFIRM_NOTICE_SUBSCRIBER_REGISTRY[eventName].mostRecentEvent = null;
  }
}
