import {Controller} from "stimulus";
import {
  showErrorDialog,
} from "../../shared/common/dialogs/error_dialog_controller";

/**
 * Manages login suspensions for merchant users, this is used on the
 * MerchantUser show page and on the Merchant show page in the users tab
 * list.
 */
export default class extends Controller {
  static targets = [
    "suspendButton",
    "restoreButton",
    "loginStatus",
  ];

  /**
   * Sets up local data and renders buttons
   */
  connect() {
    this.loginSuspended = this.data.get("loginSuspended") == "true";
    this.suspendUrl = this.data.get("suspendUrl");
    this.restoreUrl = this.data.get("restoreUrl");
    this.merchantUserId = this.data.get("merchantUserId");
    this.generateRegisterUrl = this.data.get("generateRegisterUrl");
    this.render();
  }

  /**
   * Whether the current user is unauthorized to manage merchant users or not
   *
   * @return {Boolean} if the current user is unauthorized
   */
  unauthorized() {
    return this.data.get("canManageMerchantUsers") != "true";
  }

  /**
   * Hides and shows the suspend and restore buttons based on current login
   * suspension status and updates the login status field in the table.
   */
  render() {
    if (this.unauthorized()) {
      return;
    }

    if (this.loginSuspended) {
      this.suspendButtonTarget.classList.add("toggle-hide");
      this.suspendButtonTarget.setAttribute("disabled", "");
      this.restoreButtonTarget.classList.remove("toggle-hide");
      this.restoreButtonTarget.removeAttribute("disabled");
      if (this.hasLoginStatusTarget) {
        this.loginStatusTarget.textContent = "Suspended";
      } else {
        this.element.classList.add("disabled-row");
      }
    } else {
      this.suspendButtonTarget.classList.remove("toggle-hide");
      this.suspendButtonTarget.removeAttribute("disabled");
      this.restoreButtonTarget.classList.add("toggle-hide");
      this.restoreButtonTarget.setAttribute("disabled", "");
      if (this.hasLoginStatusTarget) {
        this.loginStatusTarget.textContent = "Enabled";
      } else {
        this.element.classList.remove("disabled-row");
      }
    }
  }

  /**
   * Handles errors in fetch, displays an error dialog
   *
   * @param {Error} err error during fetch process
   */
  handleError(err) {
    console.error("Error: ", err);
    showErrorDialog();
  }

  /**
   * Handles response from server, updates suspended status and rerenders
   *
   * @param {Object} json parsed response body
   */
  handleResponse(json) {
    if (json.success) {
      this.loginSuspended = !this.loginSuspended;
      this.render();
    } else {
      this.handleError(new Error(json.status));
    }
  }

  /**
   * Suspends merchant user login
   */
  suspend() {
    const requestBody = {id: this.merchantUserId};
    const request = {method: "POST", body: JSON.stringify(requestBody)};

    fetch(this.suspendUrl, window.acima.fetchInit(request))
        .then((res) => res.json())
        .then((json) => this.handleResponse(json))
        .catch((err) => this.handleError(err));
  }

  /**
   * Restores merchant login
   */
  restore() {
    const request = {method: "DELETE"};

    fetch(this.restoreUrl, window.acima.fetchInit(request))
        .then((res) => res.json())
        .then((json) => this.handleResponse(json))
        .catch((err) => this.handleError(err));
  }

  /**
   * Handles the fetch response and reloading
   * the page or throwing an error that is caught by handleError
   */
  sendLink() {
    const requestBody = {id: this.merchantUserId};
    const request = {method: "POST", body: JSON.stringify(requestBody)};

    fetch(this.generateRegisterUrl, window.acima.fetchInit(request))
        .then(() => window.location.reload())
        .catch((err) => this.handleError(err));
  }
}
