import { Controller } from "stimulus";
import termLengthTargets from "../../helpers/lease_term_targets_helper";

export default class extends Controller {
  static targets = [
    "acimaLeaseAmount",
    "feeDivider",
    "goodsAndServicesTaxValue",
    "harmonizedSalesTaxValue",
    "initialPaymentAmount",
    "initialPaymentAmountWithTax",
    "itemizedFeeContainer",
    "leaseTermsContainer",
    "leaseTermsContainerOptions",
    "markup",
    "merchandiseTotal",
    "merchandiseTotalInput",
    "merchandiseTotalStatus",
    "merchantFeeAmount",
    "merchantFeePercentage",
    "preRentBuyoutAmount",
    "preRentPurchaseTerm",
    "processingFee",
    "provincialSalesTaxValue",
    "remainingBuyoutAmountLabel",
    "remainingPreRentBuyoutAmount",
    "renewalPaymentAmount",
    "renewalPaymentFrequency",
    "rentPayment",
    "selectableInitialPaymentAmount",
    "termLengthContainer",
    "totalOfPayments",
    "totalOfRenewalPayments",
    "underwritingProgramId",
    ...termLengthTargets(),
  ];

  connect() {
    this.loadExistingInvoice();
    this.selectOnlyInitialPayment();
  }

  selectInitialPayment(event) {
    if (event.currentTarget.dataset.disabled == "true") return false;
    this.removeSelectedClass("selected-program");
    this.removeSelectedClass("selected-term-length");
    this.underwritingProgramIdTarget.value = "";

    event.currentTarget.classList.add("selected-program");
    this.leaseTermsContainerTarget.classList.add("has-success");
    this.termLengthContainerTarget.classList.remove("has-success");
    event.currentTarget.dataset.selected = true;

    this.validateAndCalculate();
    this.updateInvoiceValidation();
  }

  selectTermLength(event) {
    const programId = event.currentTarget.dataset.id;
    const eligible = event.currentTarget.dataset.eligible;
    if (programId == "" || programId == null) return false;
    if (eligible == "false") return false;

    this.removeSelectedClass("selected-term-length");
    event.currentTarget.classList.add("selected-term-length");
    this.termLengthContainerTarget.classList.add("has-success");
    event.currentTarget.dataset.selected = true;
    this.itemizedFeeContainerTargets.forEach( (elem) => {
      elem.classList.remove("selected-fee");
    });

    this.underwritingProgramIdTarget.value = programId;
    this.merchantFeePercentageTarget.textContent = "Total Fee Amount:";

    this.reCalculateAmounts(event);
    this.updateInvoiceValidation();
  }

  calculateAcimaLeaseAmount(leaseAmount){
    let totalLeaseAmount = accounting.parse(leaseAmount);

    if (this.hasGoodsAndServicesTaxValueTarget) {
      totalLeaseAmount += accounting.parse(
        this.goodsAndServicesTaxValueTarget.textContent
      );
    }

    if (this.hasProvincialSalesTaxValueTarget) {
      totalLeaseAmount += accounting.parse(
        this.provincialSalesTaxValueTarget.textContent
      );
    }

    if (this.hasHarmonizedSalesTaxValueTarget) {
      totalLeaseAmount += accounting.parse(
        this.harmonizedSalesTaxValueTarget.textContent
      );
    }

    return totalLeaseAmount;
  }

 get totalTaxAmounts(){
  let taxAmounts = 0
  if (this.hasProvincialSalesTaxValueTarget){
    taxAmounts+= accounting.parse(
        this.provincialSalesTaxValueTarget.textContent
      );
  }
  if (this.hasGoodsAndServicesTaxValueTarget){
    taxAmounts+= accounting.parse(
        this.goodsAndServicesTaxValueTarget.textContent
      );
  }
  if (this.hasHarmonizedSalesTaxValueTarget){
    taxAmounts+= accounting.parse(
        this.harmonizedSalesTaxValueTarget.textContent
      );
  }

  return taxAmounts
 }

  calculateAmounts() {
    this.leaseTermsContainerOptionsTargets.forEach( elem => {
      elem.classList.add("hide")
    });
    const leaseId = this.data.get("leaseId");
    const merchandiseTotal = accounting.unformat(this.merchandiseTotalInputTarget.value, "", 2);
    const params = new URLSearchParams({
      merchandise_total: merchandiseTotal,
      underwriting_program_ids: this.programIdsToSend,
      total_foreign_taxes: this.totalTaxAmounts
    }).toString();

    this.fetchAmounts(leaseId, params)
      .then(function(data) {
        if (data.success) {
          // Grabbing the first set for consistent values across all programs
          var consistentData = data.details[0];
          var data = data.details;

          const eligiblePrograms = [];
          data.sort((a, b) => a.lease_term - b.lease_term).forEach( (value, index) => {
            const programId = this.data.get("invoiceProgramId");
            const target = `termLength${index+1}Target`;

            this[target].textContent = accounting.formatMoney(value.renewal_payment_amount, "$", 2);
            this[target].closest("label").dataset.id = value.underwriting_program_id;
            this[target].closest("label").dataset.eligible = value.underwriting_program_eligible;
            this[target].closest("label").dataset.renewalPaymentAmount = value.renewal_payment_amount;
            this.populateRenewalPaymentAmountValue();
            this[target].closest("label").classList.remove("hide");
            if (value.underwriting_program_eligible == false) {
              this[target].closest("label").classList.add("non-eligible-program");
            } else {
              this[target].closest("label").classList.remove("non-eligible-program");
            }
            if (programId == value.underwriting_program_id.toString()) {
              this[target].click();
            } else if (!programId) { // If not editing and invoice
              if (value.underwriting_program_eligible) {
                eligiblePrograms.push(this[target]);
              }
            }
          });

          // If there is only 1 eligible program, select the term length
          if (eligiblePrograms.length == 1) {
            eligiblePrograms[0].click();
          }

          this.processingFeeTargets.forEach( elem => {
            elem.textContent = accounting.formatMoney(consistentData.processing_fee, "$", 2);
          });
          this.rentPaymentTargets.forEach( elem => {
            elem.textContent = accounting.formatMoney(consistentData.initial_rent_payment, "$", 2);
          });
          this.acimaLeaseAmountTargets.forEach( (elem) => {
            elem.textContent = accounting.formatMoney(this.calculateAcimaLeaseAmount(consistentData.acima_lease_amount), "$", 2);
          });
          this.markupTargets.forEach( (elem) => {
            elem.textContent = accounting.formatMoney(consistentData.markup, "$", 2);
          });
          this.initialPaymentAmountTargets.forEach( (elem) => {
            elem.textContent = accounting.formatMoney(consistentData.initial_payment_amount, "$", 2);
            elem.dataset.content = consistentData.initial_payment_amount;
          });
          this.initialPaymentAmountWithTaxTarget.textContent = accounting.formatMoney(consistentData.initial_payment_amount_with_tax, "$", 2);
          this.merchandiseTotalTargets.forEach( (elem) => {
            elem.textContent = accounting.formatMoney(consistentData.merchandise_total, "$", 2);
          });

          this.itemizedFeeContainerTargets.forEach( (elem) => {
            elem.classList.remove("selected-fee");
          });
          this.feeDividerTarget.classList.add("hidden");
          this.merchantFeePercentageTarget.textContent = `Retailer Fee (${consistentData.merchant_fee_percentage *100}%):`;
          this.merchantFeeAmountTarget.textContent = accounting.formatMoney(consistentData.merchant_fee_amount, "$", 2);
        }
      }.bind(this));
  }

  // we need to recalcluate the lease term programs and include the ldw_fee_amount and
  // benefits_plus_fee_amount values in the renewal_payment_amount if the service
  // has been opted into
  reCalculateRenewalPaymentAmount() {
    const leaseId = this.data.get("leaseId");
    const merchandiseTotal = accounting.unformat(this.merchandiseTotalInputTarget.value, "", 2);
    const params = new URLSearchParams({
      merchandise_total: merchandiseTotal,
      underwriting_program_ids: this.programIdsToSend
    }).toString();

    this.fetchAmounts(leaseId, params)
      .then(function(data) {
        if(data.success) {
          var data = data.details;

          const eligiblePrograms = [];
          data.sort((a, b) => a.lease_term - b.lease_term).forEach( (value, index) => {
            let displayRenewalPaymentAmount = value.renewal_payment_amount;

            if (this.isLdwCheckboxChecked) {
              displayRenewalPaymentAmount += value.ldw_fee_amount;
            }

            if (this.isBenefitsPlusCheckboxChecked) {
              displayRenewalPaymentAmount += value.benefits_plus_fee_amount;
            }

            const programId = this.data.get("invoiceProgramId");
            const target = `termLength${index+1}Target`;

            this[target].textContent = accounting.formatMoney(displayRenewalPaymentAmount, "$", 2);
            this[target].closest("label").dataset.id = value.underwriting_program_id;
            this[target].closest("label").dataset.eligible = value.underwriting_program_eligible;
            this[target].closest("label").dataset.renewalPaymentAmount = value.renewal_payment_amount;
            this.populateRenewalPaymentAmountValue();
            this[target].closest("label").classList.remove("hide");
            if (value.underwriting_program_eligible == false) {
              this[target].closest("label").classList.add("non-eligible-program");
            } else {
              this[target].closest("label").classList.remove("non-eligible-program");
            }
            if (!programId) { // If not editing an invoice
              if (value.underwriting_program_eligible) {
                eligiblePrograms.push(this[target]);
              }
            }
          });

        }
      }.bind(this));
  }

  reCalculateAmounts(event) {
    const leaseId = this.data.get("leaseId");
    const merchandiseTotal = accounting.unformat(this.merchandiseTotalInputTarget.value, "", 2);
    const params = new URLSearchParams({
      merchandise_total: merchandiseTotal,
      underwriting_program_ids: event.currentTarget.dataset.id,
      total_foreign_taxes: this.totalTaxAmounts,
    }).toString();
    this.fetchAmounts(leaseId, params)
      .then(function(data) {
        if(data.success) {
          const amounts = data.details[0]
          if (this.hasPreRentBuyoutAmountTarget) {
            this.preRentPurchaseTermTarget.textContent =
              acima.i18n.t(
                "invoices.calculations.lease_details_preview.buyout_amount",
                { term: amounts.pre_rent_buyout_days }
              );
            this.preRentBuyoutAmountTarget.textContent = accounting.formatMoney(amounts.ninety_day_buyout_amount, "$", 2);
          }

          if (this.hasRemainingPreRentBuyoutAmountTarget) {
            this.remainingBuyoutAmountLabelTarget.textContent =
              acima.i18n.t(
                "invoices.calculations.lease_details_preview.remaining_buyout_amount",
                { term: amounts.pre_rent_buyout_days }
              );
            this.remainingPreRentBuyoutAmountTarget.textContent = accounting.formatMoney(amounts.remaining_ninety_day_buyout_amount, "$", 2);
          }

          this.totalOfPaymentsTarget.textContent = accounting.formatMoney(amounts.total_of_payments, "$", 2);
          this.totalOfRenewalPaymentsTarget.textContent = accounting.formatMoney(amounts.total_of_renewal_payments, "$", 2);

          if (this.hasRenewalPaymentAmountTarget) {
            this.renewalPaymentAmountTarget.textContent = accounting.formatMoney(amounts.renewal_payment_amount, "$", 2);
            this.renewalPaymentFrequencyTarget.textContent = amounts.renewal_payment_frequency;
          }
          this.displayItemizedFeeValues(amounts);
          this.feeDividerTarget.classList.remove("hidden");
          this.merchantFeePercentageTarget.textContent = "Total Fee Amount:";
        }
      }.bind(this));

    this.reCalculateRenewalPaymentAmount();
  }

  displayItemizedFeeValues(amounts) {
    this.itemizedFeeContainerTargets.forEach( (element) => {
      if(parseInt(element.dataset.programId) === amounts.underwriting_program_id) {
        var children = element.children;
        for (var i = 0; i < children.length; i++) {
          var child = children[i];
          if (child.classList.contains("itemized-fee-value")) {
            child.textContent = accounting.formatMoney(amounts.merchandise_total * parseFloat(child.dataset.percentage));
          }
        }
        element.classList.add("selected-fee");
      }
    });
  }

  fetchAmounts(leaseId, params) {
    const fetchWith = window.acima.fetchInit({method: "GET"});
    const url = `/users/contracts/${leaseId}/invoice_calculations/new?${params}`
    return fetch(url, fetchWith).then(response => response.json());
  }

  loadExistingInvoice() {
    this.selectableInitialPaymentAmountTargets.forEach( (target) => {
      if (target.dataset.programIds.includes(this.data.get("invoiceProgramId"))) {
        return target.click();
      }
    });
  }

  selectOnlyInitialPayment() {
    const targetToClick = [];
    this.selectableInitialPaymentAmountTargets.forEach( (target) => {
      if (target.dataset.disabled == "false") {
        targetToClick.push(target);
      }
    });

    if (targetToClick.length == 1) {
      return targetToClick[0].click();
    }
  }

  merchandiseTotalBlur() {
    this.validateAndCalculate();
  }

  /** if OPE is selected, maxTotal can be up to 140% of approval amount, else can be up to 110% */
  validateAndCalculate() {
    if (this.merchandiseTotalInputTarget.value >= 100
        && this.merchandiseTotalInputTarget.value <= this.maximumMerchandiseTotal
        && this.programIdsToSend != "") {
      this.calculateAmounts();
    }
  }

  removeSelectedClass(cssClass) {
    const element = document.querySelector(`.${cssClass}`);
    if (element != null) {
      element.classList.remove(cssClass);
      delete element.dataset.selected;
    }
  }

  updateInvoiceValidation() {
    this.invoicesController.updateInvoiceFormStatus();
  }

  populateRenewalPaymentAmountValue(){
    this.invoicesController.populateRenewalPaymentAmountValue();
  }

  get programIdsToSend() {
    const targetArray = this.selectableInitialPaymentAmountTargets.map ( (target) => {
        return target.dataset.selected ? target.dataset.programIds : null;
      });

    return targetArray.filter((ids) => ids).toString();
  }

  /**
  * Returns true if OPE is selected, false otherwise
   * @return {Boolean} true/false
  */
  get isOptionalPaymentElectionSelected() {
    return this.invoicesController.isOptionalPaymentElectionSelected;
  }

  get fetchAndCalculateAmounts() {
    this.reCalculateRenewalPaymentAmount();
  }

  get isLdwCheckboxChecked() {
    return this.invoicesController.isLdwCheckboxChecked;
  }

  get isBenefitsPlusCheckboxChecked() {
    return this.invoicesController.isBenefitsPlusCheckboxChecked;
  }

  /**
   * Returns maximumMerchandiseTotal that depends on whether the user has enabled OPE or not
   * if OPE is enabled, maxTotal can be up to 140% of approval amount, else can be up to 110%
   * @return {Float}
  */
  get maximumMerchandiseTotal(){
    return this.invoicesController.maximumMerchandiseTotal;
  }

  /**
   * Returns InvoicesController reference
   * @return {Object} InvoicesController reference.
  */
  get invoicesController(){
    return this.application.controllers.find( (controller) => {
      return controller.identifier.toString() == "users--contracts--invoices";
    });
  }
}
