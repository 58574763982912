import { Controller } from "stimulus";
import {
  showErrorDialog
} from "./dialogs/error_dialog_controller";

// Controller to interact with the Prequal Application iFrame

let modalScript;
let ecomIframe;

export default class extends Controller {
  // Required
  get locationGuid() {
    return this.element.dataset.locationGuid;
  }

  // required
  get env() {
    return this.element.dataset.env;
  }

  // required
  get ecomUrl() {
    return this.element.dataset.ecomUrl;
  }

  // Optional
  get contractGuid() {
    var guid = this.element.dataset.contractGuid;
    if (guid) {
      return guid;
    } else {
      return null;
    }
  }

  // Optional
  get userGuid() {
    return this.element.dataset.userGuid;
  }

  connect() {
    modalScript = document.createElement("script");
    modalScript.src = this.determineIframeUrl();
    modalScript.class = "ecom-iframe-script";
    document.head.appendChild(modalScript);
    modalScript.addEventListener("load", () => {
      ecomIframe = new Acima.Client({ merchantId: this.locationGuid });
    });
  }

  launchPrequalApp() {
    this.fetchToken()
      .then(token => {
        ecomIframe.prequal(this.constructPayload(token))
          .then(result => {
            let msg = `Lease has been taken to full approval by coworker: ${result.impersonatedSessionUserId} - ecom session id: ${result.ecomSessionId}`
            this.consoleLogIfDev(result);
            this.addAuditEntry(msg, result.applicationId);
          }).catch(iframeError => {
            if (iframeError.code != "CheckoutInterrupted") {
              console.error(`Prequal Iframe Error: ${iframeError}`);
            }
          });
      }).catch(tokenError => {
        showErrorDialog(`Error while launching Prequal application ${tokenError}`);
        console.error(`Fetch Prequal Token Error: ${tokenError}`);
      });
  };

  determineIframeUrl() {
    switch (this.env) {
    case "development":
      return "https://ecom.preflight.acima.in/js/acima.preflight.min.js";
    case "staging":
      return `${this.ecomUrl}/js/acima.preflight.min.js`;
    case "production":
      return `${this.ecomUrl}/js/acima.min.js`;
    default:
      return `${this.ecomUrl}/js/acima.${this.env}.min.js`;
    }
  }

  addAuditEntry(description, leaseGuid) {
    const fetchWith = window.acima.fetchInit({
      method: "POST",
      body: JSON.stringify({
        name: "contract.prequalified.coworker",
        description: description,
        contract_guid: leaseGuid
      })
    });
    fetch(`/users/contracts/${leaseGuid}/audit_entries`, fetchWith);
  }

  fetchToken() {
    const fetchWith = window.acima.fetchInit({
      method: "POST",
      body: JSON.stringify({
        user_guid: this.userGuid,
        location_guid: this.locationGuid
      })
    });
    return fetch("/users/coworkers/prequal_auth_token", fetchWith)
      .then(res => {
        return res.json()
          .then(data => {
            if (res.ok) {
              return data.token
            } else {
              throw new Error(data.error);
            }
          });
      });
  }

  consoleLogIfDev(message) {
    if (this.env == "development") {
      console.log(message);
    }
  }

  constructPayload(token) {
    return {
      contractId: this.contractGuid,
      authToken: `${token}`,
      impersonatedSession:
        {
          userId: this.userGuid,
          applicationSource: "Merchant Portal"
        },
      onConditionSatisfied: c => {
        let msg = `Coworker: ${c.impersonatedSessionUserId} has satisfied condition - ${c.conditionType} - ecom session id: ${c.ecomSessionId}`
        this.consoleLogIfDev(c);
        this.addAuditEntry(msg, c.leaseId);
      },
      onPrequalDecision: e => {
        let msg = `Lease has been taken to Prequalified by coworker: ${e.impersonatedSessionUserId} - ecom session id: ${e.ecomSessionId}`
        this.consoleLogIfDev(e);
        this.addAuditEntry(msg, e.applicationId);
      }
    }
  }
};
