import { Controller } from "stimulus";
import { renderDialogBodySpinner } from "../../../helpers/spinner_helpers";

export default class extends Controller {
    static targets = [
        "addressFormContainer",
        "deliveryCheckboxGroup",
        "deliveryCheckbox",
    ]

    // Fetch the address form based on selected address type and display it
    fetchAddressForm(event) {
        let element = event.currentTarget;
        this.fetchThenRender(
            element.dataset.addressurl,
            {address_owner: element.value, address_type: element.options[element.selectedIndex].text},
        );
    }

    fetchThenRender(url, params = {}) {
        const request = { method: "GET" };
        let queryParams = new URLSearchParams(params);
        let requestUrl = `${url}?${queryParams}`;

        renderDialogBodySpinner(this.addressFormContainerTarget);
        fetch(requestUrl, window.acima.fetchInit(request))
            .then(response => response.text())
            .then(text => this.addressFormContainerTarget.innerHTML = text)
            .catch(err => console.error(err));
    }
}
