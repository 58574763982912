import {Controller} from "stimulus";
import CountdownTimer from "../../../helpers/countdown_timer";
import formatCountdown from "./format_countdown";

/**
 * External state holding the owner timeout data, allows the locking controller
 * to set the timeout data via the functions below so that the timeout
 * controller can consume it once it is connected.
 */
const OWNER_TIMEOUT = {
  data: null,
};

/**
 * Set the timeout data including the onExpired callback that the timeout
 * controller will use to set up the countdown timer.
 *
 * @param {Object} timeoutData data payload for setting clock
 * @param {number} timeoutData.durationMinutes original duration in minutes
 * @param {number} timeoutData.endTimeElapsed time to countdown to
 * @param {Function} timeoutData.onExpired callback to trigger when time ends
 */
export function initLockTimeout(timeoutData) {
  OWNER_TIMEOUT.data = timeoutData;
}

/**
 * Clear the timeout data
 */
export function clearLockTimeout() {
  OWNER_TIMEOUT.data = null;
}

/**
 * Controller used to manage countdown timers
 */
export default class extends Controller {
  static targets = [
    "countdownTimerDisplay",
    "progressArc",
  ];

  /**
   * Connect controller, clear any existing timeout as a precaution and init a
   * new timeout. This controller is connected and disconnected every time the
   * member list is rerendered due to ownership/membership changes.
   */
  connect() {
    this.clearTimeout();
    this.initTimeout(OWNER_TIMEOUT.data);
  }

  /**
   * Clear the timeout if the controller is disconnected from the DOM
   */
  disconnect() {
    this.clearTimeout();
  }

  /**
   * Creates a countdown timer and starts it, the timer handles the clock
   * ticking and calls render() on tick.
   *
   * @param {Object} timeoutData data payload for setting clock
   * @param {number} timeoutData.durationMinutes original duration in minutes
   * @param {number} timeoutData.endTimeElapsed time to countdown to
   * @param {Function} timeoutData.onExpired callback to trigger when time ends
   */
  initTimeout = ({durationMinutes, endTimeElapsed, onExpired}) => {
    if (!endTimeElapsed) {
      return;
    }

    this.countdownTimer = new CountdownTimer(
        {
          durationMinutes,
          endTimeElapsed,
          onExpired,
          onTick: this.render,
        }
    );
    this.countdownTimer.start();
  }

  /**
   * Stops and clears the timer if it exists
   */
  clearTimeout = () => {
    if (this.countdownTimer) {
      this.countdownTimer.stop();
      this.countdownTimer = null;
    }
  }

  /**
   * Formats the clock every tick and renders it into the countdown timer
   * display. Renders the clock in 0:00 format. The parameter is provided
   * by the countdown timer every tick.
   *
   * @param {Object} clockValues current state of the countdown timer
   */
  render = (clockValues) => {
    if (this.hasCountdownTimerDisplayTarget) {
      this.renderCountdownDisplay(clockValues);
    }

    if (this.hasProgressArcTarget) {
      this.renderProgressArc(clockValues);
    }
  }

  renderCountdownDisplay(clockValues) {
    this.countdownTimerDisplayTarget.innerHTML = formatCountdown(clockValues);
  }

  renderProgressArc({percentComplete}) {
    const radius = (this.progressArcTarget.getBoundingClientRect().width / 2);
    const circumference = Math.ceil(parseInt(2 * Math.PI * radius, 10));
    const percentRemaining = (100 - percentComplete) / 100;
    const circumferenceRemaining = Math.floor(percentRemaining * circumference);
    const dasharrayValues = `${circumferenceRemaining} ${circumference}`;
    this.progressArcTarget.style.strokeDasharray = dasharrayValues;
  }
}
