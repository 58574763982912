import { Controller } from "stimulus";

/** Alternate Conditions dialog launcher */
export default class extends Controller {
  static targets = ["dialog"];

  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  /**
   * Launch the dialog
   */
  launchDialog() {
    this.element.showModal();
  }

  /**
   * Close the dialog
   */
  closeDialog() {
    this.element.close();
  }
}
