import {Controller} from "stimulus";
import {
  showErrorDialog,
} from "../../shared/common/dialogs/error_dialog_controller";

/**
 * Async load the content for the conditions tab
 */
export default class extends Controller {
  static targets = [
    "content",
  ]

  connect() {
    this.conditionsUrl = this.data.get("conditionsUrl");
    this.fetchConditions();
  }

  /**
   * Handles hard errors in fetch
   *
   * @param {Error} err
   */
  handleError(err) {
    console.error("Error: ", err);
    this.contentTarget.innerHTML = "";
    showErrorDialog(this.data.get("errorMessage"));
  }

  /**
   * Handles success and failure response in email details fetch
   *
   * @param {string} responseHtml string html body
   */
  handleResponse(responseHtml) {
    this.contentTarget.innerHTML = responseHtml;
  }

  fetchConditions() {
    const request = {method: "GET"};

    fetch(this.conditionsUrl, window.acima.fetchInit(request))
        .then((res) => res.text())
        .then((html) => this.handleResponse(html))
        .catch((err) => this.handleError(err));
  }
}
