import {isNil} from "lodash";
// Example Pagination data
// {
//   first_page: 1
//   previous_page: null
//   quick_pages: [1, 2, 3, 4, 5]
//   next_page: 2
//   last_page: 6
//   current_page: 1
//   total_records: 139
//   total_pages: 6
//   action: "data-action='users--emails--list#changePage'"
// }

/**
 * Populates and returns the next controls of pagination markup
 *
 * @param {Object} paginationData information about pagination
 * @param {number} paginationData.next_page Next page after current
 * @param {number} paginationData.last_page Last page of results
 * @param {number} paginationData.current_page Current selected page of results
 * @param {string} paginationData.action Controller and action for events
 *
 * @return {string} next controls markup
 */
function renderNextControls({
  next_page,
  last_page,
  current_page,
  action,
}) {
  const nextPageClass = isNil(next_page) ? "disabled hidden-xs" : "hidden-xs";
  const lastPageClass = last_page === current_page ? "disabled" : "";

  return `
    <li class="${nextPageClass}">
      <a ${action} data-page="${next_page}">&raquo;</a>
    </li>
    <li class="${lastPageClass}">
      <a ${action} data-page="${last_page}">
        <span class="hidden-xs">Last </span>&rsaquo;
      </a>
    </li>
  `;
}

/**
 * Populates and returns the pagination markup
 *
 * @param {Object} paginationData information about pagination
 * @param {number} paginationData.first_page Previous page before current
 * @param {number} paginationData.previous_page First page of results
 * @param {array}  paginationData.quick_pages Up to 5 pages around current page
 * @param {number} paginationData.next_page Next page after current
 * @param {number} paginationData.last_page Last page of results
 * @param {number} paginationData.current_page Current selected page of results
 * @param {number} paginationData.total_records Total number of results
 * @param {number} paginationData.total_pages Total number of pages
 * @param {string} paginationData.action Controller and action for events
 *
 * @return {string} full pagination controls markup
 */
export function buildPagination(paginationData) {
  return `
    <div class="col-xs-12 col-sm-6 pagination-totals">
      ${renderTotals(paginationData)}
    </div>
    <div class="col-xs-12 col-sm-6 pagination-controls">
      <nav>
        <ul class="pagination">
          ${renderPreviousControls(paginationData)}
          ${renderQuickPages(paginationData)}
          ${renderNextControls(paginationData)}
        </ul>
      </nav>
    </div>
  `;
}

/**
 * Populates and returns the pagination markup
 *
 * @param {Object} paginationData information about pagination
 * @param {number} paginationData.first_page Previous page before current
 * @param {number} paginationData.previous_page First page of results
 * @param {number} paginationData.current_page Current selected page of results
 * @param {string} paginationData.action Controller and action for events
 *
 * @return {string} previous controls markup
 */
function renderPreviousControls({
  first_page,
  previous_page,
  current_page,
  action,
}) {
  const firstPageClass = first_page === current_page ? "disabled" : "";
  const previousPageClass =
      isNil(previous_page) ? "disabled hidden-xs" : "hidden-xs";

  return `
    <li class="${firstPageClass}">
      <a ${action} data-page="${first_page}">
        &lsaquo;<span class="hidden-xs"> First</span>
      </a>
    </li>
    <li class="${previousPageClass}">
      <a ${action} data-page="${previous_page}">&laquo;</a>
    </li>
  `;
}

/**
 * Populates and returns a single quick page for pagination markup
 *
 * @param {number} pageNumber page number to render
 * @param {number} currentPage Current selected page of results
 * @param {string} action Controller and action for events
 *
 * @return {string} quick page link markup
 */
function renderQuickPage(pageNumber, currentPage, action) {
  const quickPageClass = pageNumber === currentPage ? "active" : "";

  return `
    <li class=${quickPageClass}>
      <a ${action} data-page="${pageNumber}">${pageNumber}</a>
    </li>
  `;
}

/**
 * Populates and returns the quick pages pagination markup
 *
 * @param {Object} paginationData information about pagination
 * @param {array}  paginationData.quick_pages Up to 5 pages around current page
 * @param {number} paginationData.current_page Current selected page of results
 * @param {string} paginationData.action Controller and action for events
 *
 * @return {string} quick pages controls markup
 */
function renderQuickPages({current_page, quick_pages, action}) {
  return quick_pages.map(
      (pageNumber) => renderQuickPage(pageNumber, current_page, action)
  ).join("");
}

/**
 * Populates and returns the totals pagination markup
 *
 * @param {Object} paginationData information about pagination
 * @param {number} paginationData.total_records Total number of results
 * @param {number} paginationData.total_pages Total number of pages
 *
 * @return {string} totals markup
 */
function renderTotals({total_records, total_pages}) {
  return `<span>${total_records} records | ${total_pages} pages<span>`;
}
