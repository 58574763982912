import {Controller} from "stimulus";

/** Controller for rejecting delivery confirmations */
export default class extends Controller {
  static targets = [
    "damageOrRepairNeeded",
    "submitBtn",
    "undeliveredItems",
    "customerDeliveryDateRow",
    "customerDeliveryDateInput",
  ];

  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  /**
   * Submits the form for rejection of delivery confirmation
   * @param {event} event click event to trigger submission of the form
   */
  rejectDeliveryConfirmation(event) {
    if (event.currentTarget.disabled == true) {
      return;
    }
    event.currentTarget.disabled = true;

    const fetchWith = window.acima.fetchInit({method: "POST"});
    const url = event.currentTarget.dataset.urlPath;
    const params = this.formData;
    const controller = this;
    fetch(`${url}?${params}`, fetchWith).then(function(response) {
      return response.json();
    }).then(function(data) {
      if (data.success == true) {
        window.location.reload();
      } else {
        controller.closeModal();
        show_common_error_dialog(data.message);
      }
    });
  }

  /** Closes the HTML modal */
  closeModal() {
    this.element.close();
  }

  /**
   * Checks that the form is ready and activates/deactivates the submit button
   */
  formReady() {
    if (this.undeliveredItemsPresent && (this.customerDeliveryDateInputTarget.value != "")) {
      this.submitBtnTarget.setAttribute(
        "data-action",
        "click->user-contracts--reject-delivery-confirmations#rejectDeliveryConfirmation"
      );
      this.submitBtnTarget.removeAttribute("disabled");
      return;
    }
    if (this.damagesOrRepairs && !this.undeliveredItemsPresent) {
      this.submitBtnTarget.setAttribute(
        "data-action",
        "click->user-contracts--reject-delivery-confirmations#rejectDeliveryConfirmation"
      );
      this.submitBtnTarget.removeAttribute("disabled");
      return;
    }
    this.submitBtnTarget.removeAttribute("data-action");
    this.submitBtnTarget.setAttribute("disabled", "true");
  }

  /**
   * Toggles the custemer date row based on whether the undelivered items checkbox is checked
   */
  toggleCustomerDate() {
    if (this.undeliveredItemsPresent) {
      this.customerDeliveryDateRowTarget.classList.remove("hidden");
    } else {
      this.customerDeliveryDateRowTarget.classList.add("hidden");
    }
  }

  /**
   * Returns URLSearchParams for rejecting the DeliveryConfirmation
   * @return {string}
   */
  get formData() {
    return new URLSearchParams({
      damage_or_repair_needed: this.damagesOrRepairs,
      undelivered_items: this.undeliveredItemsPresent,
      customer_delivery_date: this.customerDeliveryDateInputTarget.value,
      verification_text_eligible: false,
      contract_id: this.submitBtnTarget.dataset.contractId
    }).toString();
  }

  /**
   * Returns if the form has been checked yes/no on undelivered_items
   * @return {boolean}
   */
  get undeliveredItemsPresent() {
    return this.undeliveredItemsTarget.checked == true;
  }

  /**
   * Returns if the form has been checked yes/no on damage_or_repair_needed
   * @return {boolean}
   */
  get damagesOrRepairs() {
    return this.damageOrRepairNeededTarget.checked == true;
  }

  showDialog() {
    this.element.showModal();
  }
}
