import {Controller} from "stimulus";
import {publish, subscribe} from "../../../helpers/pub_sub";

const EVENT_NAME = "showConfirmDialogEvent";

/**
 * Dispatches the showConfirmDialog event
 *
 * This is the public interface into showing the confirm dialog
 *
 * The params are destructured here for documentation purposes
 *
 * @param {Object} dialogData - Payload object for customizing confirm dialog
 * @param {string} dialogData.title - optional title text for confirm dialog
 * @param {string} dialogData.message - optional body text for confirm dialog
 * @param {function} dialogData.action - function to call on confirm
 * @param {string} dialogData.cancelText - optional text for cancel button
 * @param {string} dialogData.confirmText - optional text for confirm button
 */
export function showConfirmDialog(dialogData) {
  publish(EVENT_NAME, dialogData);
}

// Assigning below functions to window.acima as a hack to let the legacy
// js/coffee files call into modern stimulus for dialog controls
if (window.acima == undefined) {
  window.acima = {};
}

// When we are calling this from legacy JS, it will likely run before stimulus
// can connect the controller to the markup, so we wrap in a slight delay for
// publishing
window.acima.showConfirmDialog = function(dialogData) {
  window.setTimeout(() => showConfirmDialog(dialogData), 333);
};

/**
 * Manages reusable confirm dialog
 */
export default class extends Controller {
  static targets = [
    "title",
    "message",
    "cancelButton",
    "confirmButton",
  ]

  /**
  * Register the dialog and controller
  */
  connect() {
    dialogPolyfill.registerDialog(this.element);
    this.initDefaults();
    subscribe(EVENT_NAME, this.showDialog);
  }

  /**
   * Sets default values for dialog targets
   */
  initDefaults() {
    this.title = this.data.get("defaultTitle");
    this.message = this.data.get("defaultMessage");
    this.cancelText = this.data.get("defaultCancelText");
    this.confirmText = this.data.get("defaultConfirmText");
    this.action = this.nullAction;
  }

  /**
   * Updates cancel button text if new text is provided by caller
   *
   * @param {string} newText - new text value
   */
  set cancelText(newText) {
    this.cancelButtonTarget.textContent = newText ||
      this.data.get("defaultCancelText");
  }

  /**
   * Updates confirm button text if new text is provided by caller
   *
   * @param {string} newText - new text value
   */
  set confirmText(newText) {
    this.confirmButtonTarget.textContent = newText ||
      this.data.get("defaultConfirmText");
  }

  /**
   * Updates message if new message is provided by caller
   *
   * @param {string} newText - new text value
   */
  set message(newText) {
    this.messageTarget.innerHTML = newText ||
      this.data.get("defaultMessage");
  }

  /**
   * Updates title if new title is provided by caller
   *
   * @param {string} newText - new text value
   */
  set title(newText) {
    this.titleTarget.textContent = newText ||
      this.data.get("defaultTitle");
  }

  /**
   * calls action and closes dialog
   */
  confirm() {
    this.confirmButtonTarget.setAttribute("disabled", "");
    this.action();
    this.closeDialog();
    this.confirmButtonTarget.removeAttribute("disabled");
  }

  /**
   * Closes dialog and resets defaults
   */
  closeDialog = () => {
    this.initDefaults();
    this.element.close();
  }

  /**
   * fake action by default, should always be overridden by caller
   */
  nullAction = () => {
    // noop
  }

  /**
   * Updates dialog data and shows dialog
   *
   * @param {Object} obj - Payload object for customizing confirm dialog
   * @param {string} obj.title - optional title text for confirm dialog
   * @param {string} obj.message - optional body text for confirm dialog
   * @param {function} obj.action - function to call on confirm
   * @param {string} obj.cancelText - optional text for cancel button
   * @param {string} obj.confirmText - optional text for confirm button
   */
  showDialog = ({title, message, action, cancelText, confirmText}) => {
    this.title = title;
    this.message = message;
    this.action = action;
    this.cancelText = cancelText;
    this.confirmText = confirmText;

    this.element.showModal();
  }
}
