import {Controller} from "stimulus";

/**
 * Controls a generic notes list which wraps notes dialog interactions
 * Designed to work with `commentable` resources.
*/
export default class extends Controller {
  /**
   * Connect controller and set up dialog controller access
   */
  connect() {
    const dialogController = "users--common--notes-dialog";
    const dialogSelector = `[data-controller="${dialogController}"]`;
    this.dialogController =
      this.application.getControllerForElementAndIdentifier(
          document.querySelector(dialogSelector),
          dialogController,
      );
    this.commentableId = this.data.get("commentableId");
    this.commentableType = this.data.get("commentableType");
  }

  /**
   * Launches the notes dialog with existing note id and message body for
   * editing.
   *
   * @param {Event} e event triggering the action
   */
  editNote(e) {
    this.dialogController.showDialog({
      id: e.currentTarget.dataset.noteId,
      comment: e.currentTarget.dataset.noteMessage,
      commentable_type: this.commentableType,
      commentable_id: this.commentableId,
    });
  }

  /**
   * Launches the notes dialog with empty note id and message body for
   * creating a new note.
   *
   * @param {Event} e event triggering the action
   */
  addNote(e) {
    this.dialogController.showDialog({
      id: null,
      comment: "",
      commentable_type: this.commentableType,
      commentable_id: this.commentableId,
    });
  }

  deleteNote(e) {
    const noteId = e.currentTarget.dataset.noteId;

    this.dialogController.destroyNote({noteId});
  }
}
