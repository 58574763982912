import {Controller} from "stimulus";
import {
  showConfirmDialog,
} from "../../../shared/common/dialogs/confirm_dialog_controller";
import {
  showErrorDialog,
} from "../../../shared/common/dialogs/error_dialog_controller";

/**
 * Manages waive action on contract conditions
 */
export default class extends Controller {
  connect() {
    this.title = this.data.get("title");
    this.message = this.data.get("message");
    this.confirmText = this.data.get("confirmText");
    this.cancelText = this.data.get("cancelText");
    this.url = this.data.get("url");
    this.errorMessage = this.data.get("errorMessage");
  }

  /**
   * Handles the fetch response by reloading the page on success or throwing
   * an error that is caught by handleError.
   *
   * @param {Object} jsonResponse fetch response object
   */
  handleResponse(jsonResponse) {
    if (!jsonResponse.success) {
      throw new Error(jsonResponse.message);
    }

    window.location.reload();
  }

  /**
   * Handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    console.error(err);
    showErrorDialog(`${this.errorMessage} ${err.message}`);
  }

  /**
   * Makes request to waive condition
   */
  waive = () => {
    const request = window.acima.fetchInit({method: "POST", body: null});

    fetch(this.url, request)
        .then((response) => response.json())
        .then((jsonResponse) => this.handleResponse(jsonResponse))
        .catch((err) => this.handleError(err));
  }

  /**
   * Sets up and shows the confirmation dialog
   */
  showDialog() {
    showConfirmDialog({
      title: this.title,
      message: this.message,
      confirmText: this.confirmText,
      cancelText: this.cancelText,
      action: this.waive,
    });
  }
}
