import { Controller } from "stimulus";
import {
  flash,
} from "../../../shared/common/notices/flash_alerts_controller";
import {
  showSpinnerDialog,
} from "../../../shared/common/dialogs/spinner_dialog_controller";

/** Confirm force satisfy initial payment*/
export default class extends Controller {
  static targets = [
    "dialog",
    "submitBtn"
  ];

  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  closeDialog() {
    this.element.close();
  }

  showDialog() {
    this.element.showModal();
  }

  confirmSatsifyIP() {
    const url = this.dialogTarget.dataset.forceSatisfyUrl;
    const fetchInit = window.acima.fetchInit({ method: "POST" });

    this.submitBtnTarget.disabled = true;

    fetch(url, fetchInit)
      .then(response => response.json())
      .then(data => this.handleResponse(data))
      .catch(err => console.error(err))
      .finally(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        showSpinnerDialog("");
        window.location.reload();
      });
  }

  handleResponse(data) {
    if (data.success) {
      flash.success(data.message);
    } else {
      flash.error(data.message);
    }
  }
}
