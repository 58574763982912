import DataTableController from "../../data_table_controller";
import PaymentHistoryChannel
  from "../../../../channels/payment_history_channel";

/** Lease DataTable Controller */
export default class PaymentHistoryController extends DataTableController {
  static targets = ["dataList"]

  connect() {
    const { channelId, leaseId } = this.dataListTarget.dataset;
    this.configureChannel(PaymentHistoryChannel, {
      channel_id: channelId,
      lease_id: leaseId
    });
    this.configureDataTable({
      controllerPath: "users--contracts--payments--payment-history",
      sortBy: "date",
      tableName: "payments",
      tableHeaders: {
        date: "Date",
        type: "Type",
        status: "Status",
        amount: "Amount"
      },
      searchableFields: [
        { field: "type" },
        {
          field: "amount",
          currency: true
        }
      ]
    });
  }

  dataTableRows(result) {
    return result.map( data => {
      const { date, type, status, amount, receipt_url } = data;
      return [date, type, status, amount, this.renderViewReceiptLink(receipt_url)];
    });
  }

  renderViewReceiptLink(url) {
    const dataAttrs = {
      "data-controller": "users--contracts--payments--payment-tab",
      "data-action":
        "click->users--contracts--payments--payment-tab#openViewReceiptDialog",
      "data-url": url
    };
    let attributes = "";
    for (const key in dataAttrs) {
      if (dataAttrs.hasOwnProperty(key)) {
        attributes += `${key}="${dataAttrs[key]}" `;
      }
    }
    return `<a ${attributes} > View Receipt </a>`;
  }
}
