import {Controller} from "stimulus";
import {publish, subscribe} from "../../helpers/pub_sub";
import {
  showErrorDialog,
} from "../../shared/common/dialogs/error_dialog_controller";
import {renderDialogBodySpinner} from "../../helpers/spinner_helpers";

const EVENT_NAME = "showEmailDetailsDialogEvent";

/**
 * Publishes the show email details dialog event
 *
 * @param {number} emailId mp ID for email to fetch
 */
export function showEmailDetailsDialog(emailId) {
  publish(EVENT_NAME, {emailId});
}

/** Email Details dialog */
export default class extends Controller {
  static targets = [
    "emailDetails",
    "closeText",
  ]

  /**
   * Register the HTML dialog
   */
  connect() {
    this.emailsResourceUrl = this.data.get("emailsResourceUrl");
    this.closeTextTarget.textContent = this.data.get("closeText");
    renderDialogBodySpinner(this.emailDetailsTarget);
    dialogPolyfill.registerDialog(this.element);
    subscribe(EVENT_NAME, this.showDialog);
  }

  /**
   * Handles hard errors in email details fetch
   *
   * @param {Error} err
   */
  handleError(err) {
    console.error("Error: ", err);
    this.closeDialog();
    showErrorDialog(this.data.get("errorMessage"));
  }

  /**
   * Handles success and failure response in email details fetch
   *
   * @param {string} responseBody parsed response body string containing markup
   */
  handleResponse(responseBody) {
    this.renderDetails(responseBody);
  }

  /**
   * Fetch email details
   *
   * @param {number} emailId mp email ID to fetch
   */
  fetchEmailDetails(emailId) {
    const emailDetailsUrl = `${this.emailsResourceUrl}/${emailId}`;
    const request = {method: "GET"};

    fetch(emailDetailsUrl, window.acima.fetchInit(request))
        .then((res) => res.text())
        .then((body) => this.handleResponse(body))
        .catch((err) => this.handleError(err));
  }

  /**
   * Renders the result of fetch
   *
   * @param {string} emailDetails markup string from fetch to render in dialog
   */
  renderDetails(emailDetails) {
    this.emailDetailsTarget.innerHTML = emailDetails;
  }

  /**
   * Close the dialog and reset body markup to spinner
   */
  closeDialog() {
    renderDialogBodySpinner(this.emailDetailsTarget);
    this.element.close();
  }

  /**
   * Trigger data fetch and show dialog
   *
   * @param {Object} data
   * @param {number} data.emailId email ID to display details of
   */
  showDialog = ({emailId}) => {
    this.fetchEmailDetails(emailId);
    this.element.showModal();
  }
}
