import ListManager from './list_manager.js';

export default class LineItemManager {
  // Private variables
  static #list = new ListManager();

  // Public Methods
  static addItem(item) {
    return this.#list.add(item)
  }

  static updateItem(id, attributes) {
    this.#list.update(id, attributes)
  }

  static getById(id) {
    return this.#list.search(id)
  }

  static removeById(id) {
    return this.#list.delete(id)
  }

  static lineItems() {
    return this.#list.list()
  }

  static count() {
    return this.#list.count()
  }

  static exportItems() {
    return this.#list.stringifyList()
  }

  static creditCount() {
    return this.#filterItemsByProductType("credit").length
  }

  static leaseCount() {
    return this.#filterItemsByProductType("lease").length
  }

  static totalPrice() {
    return this.#totalPriceByProductType()
  }

  static totalCreditPrice() {
    return this.#totalPriceByProductType("credit")
  }

  static totalLeasePrice() {
    return this.#totalPriceByProductType("lease")
  }

  static summaryCreditDescription() {
    return this.#summaryDescription("credit")
  }

  static summaryLeaseDescription() {
    return this.#summaryDescription("lease")
  }

  // Private methods
  static #filterItemsByProductType(productType) {
    return this.#list.list().filter(item => item.consumer_product_type === productType)
  }

  static #totalPriceByProductType(productType = null) {
    if (productType == null) {
      return this.#list.list()
        .reduce((sum, item) => sum + Number(item.price), 0)
    } else {
      return this.#filterItemsByProductType(productType)
        .reduce((sum, item) => sum + Number(item.price), 0)
    }
  }

  static #summaryDescription(productType) {
    return this.#filterItemsByProductType(productType)
      .map(item => { return item.description }).join(", ")
  }
}
