import {Controller} from "stimulus";
import {publish, subscribe} from "../../../../helpers/pub_sub";
import {renderDialogBodySpinner} from "../../../../helpers/spinner_helpers";

const EVENT_NAME = "showAlertListDialogEvent";

/**
 * Dispatches the showAlertListDialog event
 *
 * This is the public interface into showing the alert list dialog
 *
 * @param {Object} dialogData - Payload object for customizing confirm dialog
 * @param {string} dialogData.url - url to fetch html body
 * @param {string} dialogData.title - title of dialog to render
 * @param {string} dialogData.dialogClassname - classname to add to dialog el
 */
export function showAlertListDialog(dialogData) {
  publish(EVENT_NAME, dialogData);
}

/**
 * Manages reusable alert bar list dialog
 *
 * Shows/Hides dialog and fetches HTML to render inside of it
 * */
export default class extends Controller {
  static targets = [
    "alertListBody",
    "title",
  ];

  /**
   * connect controller,register dialog and subscribe to publishing
   */
  connect() {
    dialogPolyfill.registerDialog(this.element);
    this.titleTarget.textContent = this.data.get("defaultTitle");
    renderDialogBodySpinner(this.alertListBodyTarget);
    subscribe(EVENT_NAME, this.showDialog);
  }

  /**
   * Handles error responses
   *
   * @param {Error} err an error
   */
  handleError(err) {
    console.error(err);
  }

  /**
   * Fetches html from a URL and sets it as the alert list html
   *
   * @param {string} url resource url to fetch html list from
   */
  fetch(url) {
    const request = {method: "GET"};

    fetch(url, window.acima.fetchInit(request))
        .then((res) => res.text())
        .then((text) => this.alertListBodyTarget.innerHTML = text)
        .catch((err) => this.handleError(err));
  }

  /**
   * Closes dialog and resets defaults
   */
  closeDialog = () => {
    this.titleTarget.textContent = this.data.get("defaultTitle");
    renderDialogBodySpinner(this.alertListBodyTarget);
    this.element.classList.remove(this.dialogClassname);
    this.element.close();
  }

  /**
   * Shows the dialog
   */
  showDialog = ({url, title, dialogClassname}) => {
    this.dialogClassname = dialogClassname;
    this.element.classList.add(this.dialogClassname);
    this.titleTarget.textContent = title;
    this.fetch(url);
    this.element.showModal();
  }
}
