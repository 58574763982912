import {Controller} from "stimulus";

/** Manages the merchant edit form */
export default class extends Controller {
  static targets = [
    "spiffToggle",
    "spiffFormGroup",
  ]

  /**
   * Connects controller and does initial spiff toggle based on initial spiff
   * enabled checkbox state
   */
  connect() {
    this.toggleSpiff();
  }

  /**
   * Toggles visibility of spiff form fields whenever the spiff enabled
   * checkbox is changed
   */
  toggleSpiff() {
    this.spiffFormGroupTargets.forEach((formGroup) => {
      if (this.spiffToggleTarget.checked) {
        formGroup.classList.remove("toggle-hide");
      } else {
        formGroup.classList.add("toggle-hide");
      }
    });
  }
}
