import {Controller} from "stimulus";

/**
 * Controls the upload dialog for conditions
 */
export default class extends Controller {
  static targets = [
    "documentForm",
    "submitButton",
    "fileField",
  ]

  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  changeFile(e) {
    if (e.currentTarget.value) {
      this.submitButtonTarget.removeAttribute("disabled");
    } else {
      this.submitButtonTarget.setAttribute("disabled", "");
    }
  }

  upload() {
    this.disableSubmit();
    this.documentFormTarget.submit();
  }

  closeDialog() {
    this.resetForm();
    this.element.close();
  }

  showDialog() {
    this.resetForm();
    this.element.showModal();
  }

  resetForm() {
    this.disableSubmit();
    this.fileFieldTarget.value = "";
    this.fileFieldTarget.dispatchEvent(new Event("change"));
  }

  disableSubmit() {
    this.submitButtonTarget.setAttribute("disabled", "");
  }
}
