import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["script"];

  connect() {
    const virtualCardToken = this.data.get("virtualCardToken");

    // Retrieve the card details from Marqeta's API
    const script = document.createElement("script");
    script.src = this.data.get("src");
    script.async = true;
    script.onload = () => {
      marqeta.bootstrap({
        clientAccessToken: virtualCardToken,
        integrationType: "custom",
        containerId: "virtual-card-container",
        showPan: {
          cardPan: {
            "domId": "mq-card-pan",
            "format": true,
            "styles": {
              "span": {
                "background": "#004987",
                "color": "white",
                "font-family": "monospace",
                "font-size": "26px"
              }
            }
          },
          cardExp: {
            "domId": "mq-card-exp",
            "format": true,
            "styles": {
              "span": {
                "background": "#004987",
                "color": "white",
                "font-family": "monospace",
                "font-size": "16px"
              }
            }
          },
          cardCvv: {
            "domId": "mq-card-cvv",
            "styles": {
              "span": {
                "background": "#004987",
                "color": "white",
                "font-family": "monospace",
                "font-size": "16px"
              }
            }
          }
        },
        callbackEvents: {
          onFailure: error => {
            console.error(error);
          }
        }
      });
    };

    this.scriptTarget.appendChild(script);
  }
}
