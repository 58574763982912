import { Controller } from "stimulus";
import { alertHelper } from "../../../helpers/alert_helpers";

/** Code Signature dialog */
export default class extends Controller {
  static targets = [
    "codeField",
    "codeNotice",
    "nameField",
    "signButton",
    "verificationField"
  ]

  /**
    * Register the dialog and set up data
    */
  connect() {
    dialogPolyfill.registerDialog(this.element);
    // starting data from view
    this.busyMessage = this.data.get("busyMessage");
    this.expectedName = this.data.get("applicantName");
    this.expectedCharacters = this.expectedName.split("");
    this.expectedMinusPunctuation = this.modifyArray(this.expectedCharacters);
    this.failureMessage = this.data.get("failureMessage");
    this.signatureUrl = this.data.get("signatureUrl");
    this.successMessage = this.data.get("successMessage");
    this.fetchInProgress = false;
  }

  /**
   * Returns new set of expected characters if signature ends w/punctuation
   * Allows signature to submit with or w/o punctuation
   *
   * @param {Array} expectedCharacters characters in the application name
   * @return {Array} new array w/o final punctuation or null
   */
  modifyArray(expectedCharacters) {
    const newArray = expectedCharacters.slice(0);

    if (newArray[newArray.length - 1].match(/\W/)) {
      newArray.pop();
      return newArray;
    } else {
      return null;
    }
  }

  /**
   * The current value of the 4 digit code field
   *
   * @return {string} current 4 digit code
   */
  get currentCode() {
    return this.codeFieldTarget.value;
  }

  /**
   * The current value of the applicant name field
   *
   * @return {string} current applicant name
   */
  get currentName() {
    return this.nameFieldTarget.value;
  }

  /**
   * The individual characters for the current value of the applicant name field
   *
   * @return {array} current characters for name
   */
  get currentCharacters() {
    return this.currentName.split("");
  }

  /**
   * Whether the current and expected names match
   *
   * @return {boolean} current matches expected
   */
  get signatureMatch() {
    return this.matchSignatures(
      this.currentCharacters,
      this.expectedCharacters
    );
  }

  /**
   * Whether the current and expected (modified) names match
   *
   * @return {boolean} current matches expected
   */
  get modifiedSignatureMatch() {
    if (this.expectedMinusPunctuation === null) {
      return false;
    } else {
      return this.matchSignatures(
        this.currentCharacters,
        this.expectedMinusPunctuation
      );
    }
  }

  /**
   * Whether the code is a valid 4 digit code
   *
   * @return {boolean} valid code
   */
  get validCode() {
    const correctLength = this.currentCode.length === 4;
    const allDigits = this.currentCode.match(/^[0-9]+$/) != null;

    return correctLength && allDigits;
  }

  /**
   * Handles exceptional responses in resend fetch
   *
   * @param {Object} error the caught error
   */
  handleError(error) {
    this.fetchInProgress = false;
    console.error("Error: ", error);
    alertHelper.error(this.codeNoticeTarget, this.failureMessage);
  }

  /**
   * Handles success and failure response in resend fetch
   *
   * @param {Object} response The fetch response as parsed json
   */
  handleResponse(response) {
    this.fetchInProgress = false;
    if (response.success) {
      alertHelper.success(this.codeNoticeTarget, this.successMessage);
      window.location.reload();
    } else {
      this.showErrors(response.errors);
    }
  }

  /**
   * Action that handles changes in the code field
   */
  updateCode() {
    this.toggleButton();
  }

  /**
   * Action that handles changes in the signature field
   */
  updateSignature() {
    const formattedSignature = this
      .currentCharacters
      .map(this.formatVerifiedSignature)
      .join("");

    this.verificationFieldTarget.innerHTML = formattedSignature;
    this.toggleButton();
  }

  /**
   * Toggles the resend button on and off
   */
  toggleButton() {
    const enabled = (this.signatureMatch || this.modifiedSignatureMatch)
                    && this.validCode;
    const ready = !this.fetchInProgress;

    if (enabled && ready) {
      this.signButtonTarget.removeAttribute("disabled");
    } else {
      this.signButtonTarget.setAttribute("disabled", true);
    }
  }

  /**
   * Processes the submission of a code signature
   */
  submitSignature = () => {
    const requestBody = {
      full_name: this.currentName,
      signing_code: this.currentCode
    };
    const request = { method: "POST", body: JSON.stringify(requestBody) };
    this.fetchInProgress = true;
    this.showBusyNotice();
    fetch(this.signatureUrl, window.acima.fetchInit(request))
      .then(res => res.json())
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err));
  }

  /**
   * Close the dialog and clears inputs
   */
  closeDialog() {
    this.nameFieldTarget.value = "";
    this.codeFieldTarget.value = "";
    this.verificationFieldTarget.innerHTML = "<span>&nbsp;</span>";
    this.toggleButton();
    alertHelper.clear(this.codeNoticeTarget);
    this.element.close();
  }

  showDialog() {
    this.element.showModal();
  }

  /**
   * Shows the busy message
   */
  showBusyNotice() {
    if (this.fetchInProgress) {
      alertHelper.info(this.codeNoticeTarget, this.busyMessage);
    }
  }

  /**
   * Shows the notice message with a list of errors
   *
   * @param {array} errors The list of errors to display
   */
  showErrors(errors) {
    const fail = `<p class="mb-5"><strong>${this.failureMessage}:</strong></p>`;
    let errorList;

    if (errors.length > 1) {
      errorList = errors.map((error) => `<li>${error}</li>`).join("");
      errorList = `<ul>${errorList}</ul>`;
    } else {
      errorList = errors[0];
    }

    alertHelper.error(this.codeNoticeTarget, `${fail}${errorList}`);
  }

  /**
   * Returns true if every character base in each string matches
   *
   * @param {string} currentCharacters the currently typed signature
   * @param {string} expectedCharacters the target signature string
   *
   * @return {boolean} the result of the match across all characters and length
   */
  matchSignatures = (currentCharacters, expectedCharacters) => {
    if (currentCharacters.length !== expectedCharacters.length) {
      return false;
    }

    return currentCharacters.every((character, index) => {
      return this.matchCharacters(character, expectedCharacters[index]);
    });
  }

  /**
   * Returns true if two characters base match, regardless of accents
   *
   * @param {string} a first character
   * @param {string} b second character
   *
   * @return {boolean} the result of the character match
   */
  matchCharacters(a, b) {
    return a.localeCompare(b, undefined, { sensitivity: "base" }) === 0;
  }

  /**
   * Formats the markup for a verified signature with success and error spans
   *
   * @param {string} character specific character in the current name
   * @param {number} index the index position of the character in the string
   *
   * @return {string} markup of a signature verification
   */
  formatVerifiedSignature = (character, index) => {
    const space = this.matchCharacters(character, " ");
    const outputCharacter = space ? "&nbsp;" : character;
    const characterMatch = this.matchCharacters(
      character,
      this.expectedCharacters[index]
    );

    if (characterMatch) {
      return `<span class="signature-match">${outputCharacter}</span>`;
    } else {
      return `<span class="signature-fail">${outputCharacter}</span>`;
    }
  }
}
