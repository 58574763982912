import {Controller} from "stimulus";

/** Customer information dialog */
export default class extends Controller {
  /**
    * Register the HTML dialog
    */
  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  /**
   * Close the dialog
   */
  closeDialog() {
    this.element.close();
  }
}
