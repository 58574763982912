import {Controller} from "stimulus";
import {showConfirmDialog} from "../shared/common/dialogs/confirm_dialog_controller";
import {showErrorDialog} from "../shared/common/dialogs/error_dialog_controller";

/** Merchant claims merchandise has been delivered confirmation dialog */
export default class extends Controller {
  static targets = [
    "data",
    "successMessage",
    "textLink",
  ]

  /**
    * Connect the controller and establish dialog text
    */
  connect() {
    const dataset = this.dataTarget.dataset;

    this.message = dataset.message;
    this.cancelText = dataset.cancelText;
    this.confirmText = dataset.confirmText;
    this.deliveredUrl = dataset.deliveredUrl;
    this.errorMessage = dataset.errorMessage;
  }

  /**
   * Post to merchant confirm delivered endpoint
   */
  confirmDelivered = () => {
    fetch(this.deliveredUrl, window.acima.fetchInit({method: "POST"}))
        .then((response) => response.json())
        .then((response) => this.handleResponse(response))
        .catch((error) => this.handleError(error));
  }

  /**
   * Sets up and shows the confirmation dialog
   */
  promptDeliveredDialog() {
    showConfirmDialog({
      message: this.message,
      cancelText: this.cancelText,
      confirmText: this.confirmText,
      action: this.confirmDelivered,
    });
  }

  /**
   * Handles success and failure response in fetch
   *
   * @param {Object} response The fetch response as parsed json
   */
  handleResponse(response) {
    if (response.success) {
      this.showSuccessMessage();
    } else {
      showErrorDialog(response.message);
    }
  }

  /** show success message if fetch call is successful*/
  showSuccessMessage() {
    this.successMessageTarget.classList.remove("hidden");
    this.textLinkTarget.classList.add("hidden");
  }

  /**
   * Handles exceptional responses in resend fetch
   *
   * @param {Object} error the caught error
   */
  handleError(error) {
    console.error("Error: ", error);
    showErrorDialog(this.errorMessage);
  }
}
