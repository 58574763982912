import {Controller} from "stimulus";

/**
 * Manages the login interface
 */
export default class extends Controller {
  submitLoginForm(event) {
    event.preventDefault();

    var authToken = this.data.get("authenticityToken");

    // The following is setup for Auth0 DB Connection
    const webAuth = new auth0.WebAuth({
      clientID:     this.oauth2WebappClientId,
      domain:       this.oauth2Domain,
      responseMode: "form_post",
      scope:        "openid profile email",
    });

    webAuth.authorize(
      {
        clientID:     this.oauth2WebappClientId,
        connection:   "auth0-db",
        redirectUri:  this.merchants_service_endpoint + "/auth/owners/callback",
        responseType: "token id_token",
        state:        authToken,
      },
    );
  }

  get oauth2Domain() {
    return this.data.get("oauth2-domain");
  }

  get oauth2WebappClientId() {
    return this.data.get("oauth2-webapp-client-id");
  }

  get merchants_service_endpoint() {
    return this.data.get("merchants-service-endpoint");
  }
}
