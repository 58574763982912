import {now, isFunction} from "lodash";

const ONE_SECOND_IN_MILLISECONDS = 1000;
const ONE_MINUTE_IN_MILLISECONDS = 60 * ONE_SECOND_IN_MILLISECONDS;
/**
 * It's a countdown timer, it counts down time.
 *
 * This will trigger the onTick callback every second and pass the clock values
 * to it so that the consumer can render the clock however they want. When it
 * reaches zero it will call the onExpired callback.
 *
 * The times all work off unix timestamps/elapsed milliseconds since epoch.
 *
 * Init a timer
 * const timer = new CountdownTimer({
 *  endTimeElapsed: sometimestamp,
 *  onExpired: () => console.log("times up!!!"),
 *  onTick: () => console.log("tick"),
 * });
 *
 * Start it
 * timer.start();
 *
 * Stop it if you need to, this will clear it out, this is not pause
 * timer.stop();
 *
 * otherwise it will call your onExpired callback when the timer reaches 0:00
 */
export default class CountdownTimer {
  /**
   * Build a new CountdownTimer
   *
   * @param {Object} data init payload
   * @param {number} data.durationMinutes original intended duration in mins
   * @param {number} data.endTimeElapsed time to countdown to in milliseconds
   * @param {Function} data.onExpired callback to run when time runs out
   * @param {Function} data.onTick callback to run each second(for rendering)
   */
  constructor({durationMinutes, endTimeElapsed, onExpired, onTick}) {
    this.duration = durationMinutes * ONE_MINUTE_IN_MILLISECONDS;
    this.endTimeElapsed = endTimeElapsed;
    this.startTimeElapsed = now();
    this.onExpired = onExpired;
    this.tickDuration = ONE_SECOND_IN_MILLISECONDS;
    this.active = false;
    this.onTick = onTick;
  }

  /**
   * Start the clock
   *
   * Sets the clock to active and kicks off the first tick(), returns early if
   * the clock is already active.
   */
  start() {
    if (this.active) {
      return;
    }
    this.active = true;
    this.tick();
  }

  /**
   * Ticks and updates the clock every second
   *
   * Calls itself while the clock is active, passes the updated clock values to
   * the onTick callback, calls expire() when the time runs out.
   */
  tick = () => {
    if (!this.active || this.endTimeElapsed === 0) {
      return;
    }

    const currentTimeElapsed = now();
    let timeRemaining = this.endTimeElapsed - currentTimeElapsed;

    if (!this.active || timeRemaining > 0) {
      setTimeout(this.tick, this.tickDuration);
    } else {
      timeRemaining = 0;
      this.expire();
    }

    this.updateClockValues(timeRemaining);
    if (isFunction(this.onTick)) {
      this.onTick(this.clockValues());
    }
  }

  /**
   * Updates clock values to represent current countdown progress
   *
   * Based on the time remaining and the initial clock values this calculates
   * various representations of the countdown including the minutes and
   * seconds left in the countdown and the percent progress.
   *
   * @param {number} timeRemaining time remaining in  milliseconds
   */
  updateClockValues(timeRemaining) {
    this.timeCompleted = this.duration - timeRemaining;
    this.percentComplete = Math.floor(
        this.timeCompleted / this.duration * 100
    );
    this.totalSeconds = Math.floor(
        timeRemaining / ONE_SECOND_IN_MILLISECONDS
    );
    this.minutes = Math.floor(this.totalSeconds / 60);
    this.seconds = this.totalSeconds % 60;
  }

  /**
   * Builds a small data object of the local clock values to pass to onTick.
   *
   * If active, returns the current state of the clock, if inactive, returns
   * all zeros.
   *
   * @return {Object} represents current state of clock
   */
  clockValues() {
    if (this.active) {
      return {
        duration: this.duration,
        endTimeElapsed: this.endTimeElapsed,
        totalSeconds: this.totalSeconds,
        minutes: this.minutes,
        seconds: this.seconds,
        timeCompleted: this.timeCompleted,
        percentComplete: this.percentComplete,
      };
    } else {
      return {
        duration: 0,
        endTimeElapsed: 0,
        totalSeconds: 0,
        minutes: 0,
        seconds: 0,
        timeCompleted: 0,
        percentComplete: 0,
      };
    }
  }

  /**
   * Expires the countdown, sets active to false and calls the on expired
   * callback
   */
  expire() {
    this.active = false;
    this.onExpired();
  }

  /**
   * Clears the countdown, useful for when a countdown is rendered and needs to
   * be cleared before it finishes to prevent further ticks.
   */
  stop() {
    this.active = false;
    this.endTimeElapsed = 0;
    this.onExpired = null;
    this.onTick = null;
    this.tickDuration = 0;
  }
}
