import { Controller } from "stimulus";
import { LeaseWorkflowChannel } from "../../channels/lease_workflow_channel";
import cable from "../../channels/helpers/cable";
/**
 * Used to check what state a lease is in.
 */
export default class extends Controller {
  /**
   * Post request to trigger a 'check' on the servers contract workflow state
   */
  connect() {
    // Timeout to ensure we dont trigger the request too soon
    setTimeout(() => {
      this.leaseWorkflowChannel = new LeaseWorkflowChannel(this.params);
      cable.subscribe(this.leaseWorkflowChannel);
    }, 3000);
  }

  disconnect() {
    cable.unsubscribe(this.leaseWorkflowChannel);
  }

  /**
   * Build out the params for fetch request
   */
  get params() {
    return {
      contract_guid: this.data.get("guid"),
      workflow_state: this.data.get("workflowState"),
    };
  }
}
