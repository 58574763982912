import { Controller } from "stimulus";

/** Generic data table using stimulus */
export default class extends Controller {
  static targets = [
    "table"
  ];

  initDataTable(opts) {
    const h = window.acima.escapeHtml;
    const lang = document.documentElement.lang.substring(0, 2);
    const translationUrl = new URL(window.location.origin + `/locale/datatable/${lang}.json`);

    $(this.tableTarget).dataTable( {
      "oLanguage": {
        sUrl: translationUrl.toString()
      },
      "sDom": "<'row row-fluid dataTable-control-row mr-0 ml-0'<'span6 pl-0 col-xs-12 col-sm-6 pl-0'l><'span6 col-xs-12 col-sm-6 pr-0'f>r>t<'row-fluid row well-pagination-row box-pagination ml-0 mr-0'<'span6 col-xs-12 col-md-6 ml-0'i><'span6 col-xs-12 col-md-6 pr-0'p>>",
      "bProcessing": false,
      "bPaginate": true,
      "bFilter": true,
      "bSort": true,
      "bInfo": false,
      "bServerSide": true,
      "sAjaxSource": opts.url,
      "oSearch": {"sSearch": opts.sSearch},
      "aaSorting": opts.aaSorting,
      "fnServerData": function(sSource, aoData, fnCallback) {
        $.ajax( {
          "dataType": "json",
          "type": "GET",
          "url": sSource,
          "data": aoData,
          "success": function(rs) {
            fnCallback(rs);
          },
        });
      },
      "aoColumns": [
        {"mDataProp": "lease_number", "mRender": function(data, type, full) {
          return `
            <a href="/merchants/contracts/${full.lease_id}">#${data}</a>
          `;
        }},
        { "mDataProp": "adjustment_number", "mRender": function( data, type, full ) {
          const path = `
            /merchants/contracts/${full.lease_guid}/invoice_adjustments/${full.adjustment_number}
          `;
          const adjustmentNumber = [full.lease_number, full.adjustment_number].join("-A");
          return `
            <a href="${path}">${adjustmentNumber}</a>
          `;
        }},
        { "mDataProp": "status", "mRender": function( data, type, full ) {
          return `
            <span class="status">${h(data)}</span>
          `;
        }},
        { "mDataProp": "customer_name", "mRender": function( data, type, full ) {
          return `
            <span class="customer_name">${h(data)}</span>
          `;
        }},
        { "mDataProp": "location", "mRender": function( data, type, full ) {
          return `
            <span class="location">${h(data)}</span>
          `;
        }},
        { "mDataProp": "date", "mRender": function( data, type, full ) {
          return `
            <span class="date">${h(data)}</span>
          `;
        }},
        { "mDataProp": "change", "mRender": function( data, type, full ) {
          return `
            ${full.image_indicator}<span class="change pl-5">${h(data)}</span>
          `;
        }},
      ],
      "aoColumnDefs": [
        { "sWidth": "10%", "aTargets": [0] },
        { "sWidth": "10%", "aTargets": [1] },
        { "sWidth": "15%", "aTargets": [2] },
        { "sWidth": "20%", "aTargets": [3] },
        { "sWidth": "20%", "aTargets": [4] },
        { "sWidth": "10%", "aTargets": [5] },
        { "sWidth": "10%", "aTargets": [6] }
      ],
      "iDisplayLength": opts.displayLength
    });
    $(this.tableTarget).on("init", function() {
      $(this).parent().addClass("overflow-x-auto");
    });
  }

  connect() {
    const settings = {
      iDisplayLength: 10,
      sSearch: "",
      aaSorting: [[5, "desc"]],
      url: this.element.dataset.url
    };
    this.initDataTable(settings);
  }
}
