import {Controller} from "stimulus";

/**
* Launches owners funding account dialogs.
*/
export default class extends Controller {
  static targets = [
    "addAccountModal",
    "editAccountModal",
  ];

  /** Launch Add Account modal */
  launchAddAccount() {
    this.addAccountModalTarget.showModal();
  }

  /** Launch Edit Account modal
  * @param {event} event Click action on the Edit Button
  */
  launchEditAccount(event) {
    const modalController =
      this.application.getControllerForElementAndIdentifier(
          document.querySelector("#edit-funding-account-modal"),
          "owners--edit-funding-account",
      );

    modalController.openDialog(this.fundingAccount(event));
  }

  /**
  * Get the funding account instance hash
  * @param {event} event Click action on the Edit Button
  * @return {object} event Object hash
  */
  fundingAccount(event) {
    return JSON.parse(event.currentTarget.dataset["bankAccount"]);
  }
}
