import {Controller} from "stimulus";

/** controller for resend kiq quiz buttons */
export default class extends Controller {
  static targets = ["info", "infoEmail"]

  /** Confirm submission.
   @param {event} event
  */
  confirmResend(event) {
    const t = event.currentTarget;
    App.confirmDialog(t.dataset.message, () => this.sendMessage(t));
  }

  /** Submit send message.
    @param {t} t
  * */
  sendMessage(t) {
    const target = t;
    let infoTarget = "";
    if (target.dataset.messageType == "sms") {
      infoTarget = this.infoTarget;
    } else if (target.dataset.messageType == "email") {
      infoTarget = this.infoEmailTarget;
    }
    const guid = target.dataset.guid;
    const requestBody = {
      lease_guid: guid,
    };
    const fetchWith = window.acima.fetchInit({
      method: "POST", body: JSON.stringify(requestBody)}
    );
    infoTarget.classList.add("hide");
    target.setAttribute("disabled", "disabled");
    fetch(target.dataset.url, fetchWith
    ).then((res) => res.json()
    ).then(function(res) {
      target.removeAttribute("disabled");
      infoTarget.classList.remove("hide");
      if (res.success == false) {
        infoTarget.innerHTML = infoTarget.dataset.resendError;
        infoTarget.classList.remove("alert-success");
        infoTarget.classList.add("alert-danger");
      }
    }).catch((error) => console.error("Error:", error));
  }
}
