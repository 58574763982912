import BaseChannel from "./base_channel";

export default class ContractPresenceChannel extends BaseChannel {
  static identifier = "ContractPresenceChannel";

  connected() {
    this.perform("populate_subscribers");
  }
  receive(data) {
    super.receive(data);
    this.emit(data["event"], data);
  }
}
