import {Controller} from "stimulus";

/** Dialogs controller */
export default class extends Controller {
  static targets = ["confirmNav"]

  /** confirmNav  */
  confirmNav() {
    const text = this.confirmNavTarget.dataset.message;
    const url = this.confirmNavTarget.dataset.url;

    App.confirmDialog(text, function() {
      window.location = url;
    });
  }
}
