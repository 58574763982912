import { Controller } from "stimulus";

/**
 * Manages cancel application action for user contracts
 */
export default class extends Controller {
  static targets = ["cancelResult"];

  cancelApplication() {
    const eventTarget = event.target
    const url = event.target.dataset.url;
    const request = window.acima.fetchInit({
      method: "POST",
      body: JSON.stringify({ reason: this.element.dataset.reason })
    });

    this.disableSubmitButton(eventTarget);
    fetch(url, request)
      .then((response) => response.json())
      .then((jsonResponse) => this.handleResponse(jsonResponse, eventTarget))
      .catch((err) => this.handleError(err))
  }

  handleResponse(jsonResponse, eventTarget) {
    if (jsonResponse.success) {
      this.handleSuccess(jsonResponse.message, eventTarget);
    } else {
      this.handleError(jsonResponse.message, eventTarget);
    }
  }

  handleSuccess(message, eventTarget) {
    this.disableSubmitButton(eventTarget)
    this.resultMessage.innerHTML = message;
    this.resultMessage.classList.add("alert-success");
    setTimeout(() => window.location.reload(), 2000);
  }

  handleError(err, eventTarget) {
    this.resultMessage.innerHTML = err;
    this.resultMessage.classList.add("alert-danger");
    setTimeout(() => this.resultMessage.innerHTML = "", 5000);
    this.enableSubmitButton(eventTarget);
  }

  disableSubmitButton(target) {
    target.setAttribute("disabled", "");
  }

  enableSubmitButton(target) {
    target.removeAttribute("disabled");
  }

  get resultMessage() {
    return this.cancelResultTarget;
  }
}
