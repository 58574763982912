import {Controller} from "stimulus";

/**
 * Manages add conditions dialog for user contracts
 *
 * This dialog contains a traditional rails form so this class just maintains
 * the dialog interactions
 */
export default class extends Controller {
  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  closeDialog() {
    this.element.close();
  }

  showDialog() {
    this.element.showModal();
  }
}
