import { Channel } from "@anycable/web";

export default class DataTableChannel extends Channel {
  async fetchList(controller) {
    const {
      sortBy,
      search,
      pageNumber,
      perPage,
      order
    } = controller;

    const params = {
      page: pageNumber,
      records_per_page: perPage,
      order: order.toUpperCase(),
      sortBy,
      search
    };

    return this.perform("fetch_records", params);
  }

  receive(data) {
    super.receive(data);
    this.emit("populatingTable", data);
  }
}
