import {Controller} from "stimulus";

/**
 * Handle fetch call to register owner accounts
 */
export default class extends Controller {
  static targets = [
    "registerButton",
    "passwordResetButton",
  ]

  /**
   * Grab data to register owner & send to post method
   */
  registerOwner() {
    const button = this.registerButtonTarget;
    const ownerId = button.dataset.ownerId;
    const path = button.dataset.registrationPath;
    button.classList.add("disabled");

    this.postRegistration(ownerId, path, button);
  }

  /**
   * [launchConfirmationModal description]
   * @param  {[String]} status  [success or failure to send link]
   * @param  {[String]} message [message to describe success or failure]
   */
  launchConfirmationModal(status, message) {
    const modalSelector = "[data-controller='owner-confirmation-modal']";
    const modalController =
      this.application.getControllerForElementAndIdentifier(
          document.querySelector(modalSelector),
          "owner-confirmation-modal",
      );
    modalController.openDialog(status, message);
  }

  /**
   * POST the registration
   * @param  {[Number]} ownerId owner identification
   * @param  {[String]} path    post url
   * @param  {[Element]} button button page element
   */
  postRegistration(ownerId, path, button) {
    const controller = this;
    const controllerData = controller.element.dataset;
    const params = JSON.stringify({owner_id: ownerId});
    const fetchWith = window.acima.fetchInit({method: "POST", body: params});

    fetch(path, fetchWith).then((response) => response.json()).then((data) => {
      if (data.status === "error") {
        button.classList.remove("disabled");
        controller.launchConfirmationModal(
            controllerData.modalError,
            button.dataset.registrationError,
        );
      } else {
        if (data["success"] === true) {
          if (data["message_sent_to_owner"] === false) {
            controller.launchConfirmationModal(
                controllerData.modalError,
                button.dataset.registrationSuccessNoSms,
            );
          } else {
            controller.launchConfirmationModal(
                controllerData.modalSuccess,
                button.dataset.registrationSuccess,
            );
          }
        } else {
          if (data["already_registered"] === true) {
            controller.launchConfirmationModal(
                controllerData.modalError,
                button.dataset.alreadyRegistered,
            );
          } else {
            button.classList.remove("disabled");
            controller.launchConfirmationModal(
                controllerData.modalFailure,
                button.dataset.registrationFailure,
            );
          }
        }
      }
    });
  }

  /**
   * Get data to pass to passwordReset action
   */
  passwordReset() {
    const button = this.passwordResetButtonTarget;
    const ownerId = button.dataset.ownerId;
    const path = button.dataset.passwordResetPath;

    this.postPasswordReset(ownerId, path, button);
  }

  /**
   * POST the passwordReset request
   * @param  {[Number]} ownerId owner identification
   * @param  {[String]} path    post url
   * @param  {[Element]} button button page element
   */
  postPasswordReset(ownerId, path, button) {
    const controller = this;
    const controllerData = controller.element.dataset;
    const params = JSON.stringify({owner_id: ownerId});
    const fetchWith = window.acima.fetchInit({method: "POST", body: params});

    fetch(path, fetchWith).then((response) => response.json()).then((data) => {
      if (data.status === "error") {
        controller.launchConfirmationModal(
            controllerData.modalError,
            button.dataset.passwordResetError,
        );
      } else {
        if (data["success"] === true) {
          controller.launchConfirmationModal(
              controllerData.modalSuccess,
              button.dataset.passwordResetSuccess,
          );
        } else {
          controller.launchConfirmationModal(
              controllerData.modalFailure,
              button.dataset.passwordResetFailure,
          );
        }
      }
    });
  }
}
