import BaseChannel from "./base_channel";

export default class LockToggleChannel extends BaseChannel {
  static identifier = "LockToggleChannel";

  connected(){
    this.perform('check_lock')
  }
  receive(data) {
    super.receive(data);
    const { event, response } = data;
    this.emit(event, response);
  }
}
