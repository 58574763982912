import {Controller} from "stimulus";
import {
  showConfirmDialog,
} from "../../../shared/common/dialogs/confirm_dialog_controller";
import {
  showErrorDialog,
} from "../../../shared/common/dialogs/error_dialog_controller";

/**
 * Manages fraud condition requests action for user contracts
 */
export default class extends Controller {
  connect() {
    this.title = this.data.get("title");
    this.message = this.data.get("message");
    this.errorMessage = this.data.get("errorMessage");
    this.confirmText = this.data.get("confirmText");
    this.cancelText = this.data.get("cancelText");
    this.url = this.data.get("fraudRequestUrl");
    this.conditions = this.data.get("fraudRequestConditions");
  }

  /**
   * Handles the fetch response by reloading the page on success or throwing
   * an error that is caught by handleError.
   *
   * @param {Object} response fetch response object
   */
  handleResponse(response) {
    if (response.status != 200) {
      throw new Error(response.headers.get("warning"));
    }

    window.location.reload();
  }

  /**
   * Handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    console.error(err);
    showErrorDialog(`${this.errorMessage} ${err.message}`);
  }

  /**
   * Makes request to add fraud conditions
   */
  requestFraud = () => {
    const params = JSON.stringify({conditions: this.conditions});
    const request = window.acima.fetchInit({method: "POST", body: params});

    fetch(this.url, request)
        .then((response) => this.handleResponse(response))
        .catch((err) => this.handleError(err));
  }

  /**
   * Sets up and shows the request fraud confirmation dialog
   *
   * @param {event} e event that triggered action
   */
  showDialog(e) {
    if (e.currentTarget.hasAttribute("disabled")) {
      return;
    }

    showConfirmDialog({
      title: this.title,
      message: this.message,
      confirmText: this.confirmText,
      cancelText: this.cancelText,
      action: this.requestFraud,
    });
  }
}
