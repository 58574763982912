export default class ListManager {
  // Private variables
  #list;
  #incr;

  // Public Methods
  constructor(){
    this.#list = []
    this.#incr = 0
  }

  add(item) {
    item.id = this.#increment()
    this.#list.push(item)

    return this.list()
  }

  update(id, attributes) {
    const index = this.#findIndexByID(id)

    if (index == null) return null

    this.#list[index].update(attributes)
  }

  search(id) {
    const index = this.#findIndexByID(id)

    if (index == null) return null

    return this.#list[index]
  }

  delete(id) {
    const index = this.#findIndexByID(id)

    if (index == null) return null

    this.#list.splice(index, 1)

    return this.list()
  }

  list() {
    return [...this.#list]
  }

  count() {
    return this.#list.length
  }

  stringifyList() {
    return JSON.stringify(this.#list)
  }

  // Private methods
  #increment() {
    return this.#incr += 1
  }

  #findIndexByID(id) {
    id = Number(id)
    const index = this.#list.findIndex(item => { return item.id === id })
    if (index === -1) return null
    return index
  }
}
