import { Controller } from "stimulus";
/**
 * Handles requests to claim a lease condition alert
 */
export default class extends Controller {
  handleResponse(jsonResponse) {
    const { success, message } = jsonResponse;
    if (!success) {
      Rollbar.error(message);
      return false;
    }
    window.open(this.data.get("contract-url"));
  }

  claimViaFetch() {
    const eventTarget = this.element;
    const lockUrl = this.data.get("lock-url");
    const request = window.acima.fetchInit({
      method: "POST",
      body: JSON.stringify(this.params)
    });

    eventTarget.setAttribute("disabled", "");

    fetch(lockUrl, request)
      .then((response) => response.json())
      .then((jsonResponse) => this.handleResponse(jsonResponse))
      .catch((err) => console.log(err));
  }

  claimAlert() {
    // TODO: Will implement this without fetch
    this.claimViaFetch();
  }

  cleanStalledAlerts() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    document.getElementById("refreshAlertsButton").setAttribute("disabled", "");
    document.getElementById("btnRefresh").innerText = "Cleaning...";

    $.ajax({
      url: this.data.get("clean-stalled-alerts-url"),
      type: "POST",
      headers: {
        "Content-Type": 'application/json',
        'Accept': 'text/javascript', // Ensuring the server responds with JS
        "X-CSRF-Token": csrfToken  // Include the CSRF token in the headers
      },
      success: function(data) {
        document.getElementById("refreshCopy").classList.remove('display-none');

        setTimeout(function(){
          document.getElementById("refreshCopy").classList.add('display-none');
        },5000)
      },
      error: function() {
        console.log('error')
        document.getElementById("ErrorCopy").classList.remove('display-none');

        setTimeout(function(){
          document.getElementById("ErrorCopy").classList.add('display-none');
        },5000)
      },
      complete: function(){
        document.getElementById("btnRefresh").innerText = "Clean Stalled Alerts";
        document.getElementById("refreshAlertsButton").removeAttribute("disabled");
      }
    });
  }

  get params() {
    return {
      leaseId: this.data.get("contract-id"),
      leaseConditionId: this.data.get("lease-condition-id"),
      minutes: this.data.get("minutes"),
      contractId: this.data.get("contract-id")
    };
  }
}
