import { Controller } from "stimulus";
import { alertHelper } from "../../../helpers/alert_helpers";
import { modifySignatureArray, matchSignatures, formatVerifiedSignature }
  from "../../../helpers/signature_validator_helper";

/** Launches merchant leads confirmation of terms and condition dialog code */
export default class extends Controller {
  static targets = [
    "dialog",
    "signatureField",
    "alertNotice",
    "confirmButton",
    "signatureContainer",
  ];

  /**
  * Register the dialog and set up data
  */
  connect() {
    dialogPolyfill.registerDialog(this.dialogTarget);

    // starting data from view
    this.expectedMerchantUserName = this.data.get("expectedMerchantUserName");
    this.expectedCharacters = this.expectedMerchantUserName.split("");
    this.expectedMinusPunctuation = modifySignatureArray(this.expectedCharacters);
    this.signatureUrl = this.data.get("signatureUrl");
    this.successMessage = this.data.get("successMessage");
    this.failureMessage = this.data.get("failureMessage");
    this.merchantLeadsRedirectUrl = this.data.get("merchantLeadsRedirectUrl");
    this.fetchInProgress = false;
  }

  showDialog() {
    this.dialogTarget.showModal();
    alertHelper.clear(this.alertNoticeTarget);
    this.signatureFieldTarget.value = "";
    this.clearSignatureFieldStatus();
  }

  closeDialog() {
    alertHelper.clear(this.alertNoticeTarget);
    this.signatureFieldTarget.value = "";
    this.toggleValidationOfSignature();
    this.clearSignatureFieldStatus();
    this.dialogTarget.close();
  }

  /**
   * Action that handles changes in the signature field
   */
  updateSignature() {
    this.currentCharacters.map(
      (character, index) =>
        formatVerifiedSignature(character, index, this.expectedCharacters)
    ).join("");

    this.toggleValidationOfSignature();
  }

  /**
  * Handles submission of signature
  */
  submitConfirmation = () => {
    const requestBody = {
      agreement: {
        signed_name: this.signatureName,
      }
    };

    const request = { method: "POST", body: JSON.stringify(requestBody) };

    this.fetchInProgress = true;
    this.toggleValidationOfSignature();

    fetch(this.signatureUrl, window.acima.fetchInit(request))
      .then((response) => response.json())
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  /**
  * Toggles the validation of signature field and confirm button
  */
  toggleValidationOfSignature() {
    const enabled = (this.signatureMatch || this.modifiedSignatureMatch);
    const ready = !this.fetchInProgress;

    if (enabled && ready) {
      this.signatureContainerTarget.classList.add("has-success");
      this.signatureContainerTarget.classList.remove("has-error");
      this.confirmButtonTarget.removeAttribute("disabled");
    } else {
      this.signatureContainerTarget.classList.add("has-error");
      this.signatureContainerTarget.classList.remove("has-success");
      this.confirmButtonTarget.setAttribute("disabled", true);
    }
  }

  /**
  * Handles exceptional responses
  *
  * @param {Object} error the caught error
  */
  handleError(error) {
    this.fetchInProgress = false;
    console.error("Error: ", error);
    alertHelper.error(this.alertNoticeTarget, this.failureMessage);
  }

  /**
  * Handles success and failure response
  *
  * @param {Object} response The fetch response as parsed json
  */
  handleResponse(response) {
    this.fetchInProgress = false;
    if (response.success) {
      this.signatureContainerTarget.classList.add("has-success");
      alertHelper.success(this.alertNoticeTarget, this.successMessage);
      this.closeDialog();
      window.location.href = this.merchantLeadsRedirectUrl;
    } else {
      this.showErrors(response.errors);
      this.signatureContainerTarget.classList.add("has-error");
    }
  }

  /**
  * Shows the notice message with a list of errors
  *
  * @param {array} errors The list of errors to display
  */
  showErrors(errors) {
    const fail = `<p class="mb-5"><strong>${this.failureMessage}:</strong></p>`;
    let errorList;

    if (errors.length > 1) {
      errorList = errors.map((error) => `<li>${error}</li>`).join("");
      errorList = `<ul>${errorList}</ul>`;
    } else {
      errorList = errors[0];
    }

    alertHelper.error(this.alertNoticeTarget, `${fail}${errorList}`);
  }

  /** Clears displayed class into signature field */
  clearSignatureFieldStatus() {
    this.signatureContainerTarget.classList.remove("has-success");
    this.signatureContainerTarget.classList.remove("has-error");
  }

  /**
  * The current value of the merchant user name field
  *
  * @return {string} current merchant user name
  */
  get signatureName() {
    return this.signatureFieldTarget.value;
  }

  /**
  * The individual characters for the current value of the merchant user name field
  *
  * @return {array} current characters for merchant user name
  */
  get currentCharacters() {
    return this.signatureName.split("");
  }

  /**
   * Whether the current and expected names match
   *
   * @return {boolean} current matches expected
   */
  get signatureMatch() {
    return matchSignatures(this.currentCharacters, this.expectedCharacters);
  }

  /**
   * Whether the current and expected (modified) names match
   *
   * @return {boolean} current matches expected
   */
  get modifiedSignatureMatch() {
    if (this.expectedMinusPunctuation === null) {
      return false;
    } else {
      return matchSignatures(this.currentCharacters, this.expectedMinusPunctuation);
    }
  }
}
