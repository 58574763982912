import { Controller } from "stimulus";
/**
 * Refresh page after merchant is selected from autocomplete.
 */
export default class extends Controller {
  /**
   * Refreshes page to User/UsersController#edit_co_worker_locations
   * with merchant_id
   */
  connect() {
    document.addEventListener("autocomplete.change", this.autocomplete.bind(this));
  }

  autocomplete(event) {
    const params = new URLSearchParams({ merchant_id: event.detail.value });
    window.location.search = params.toString();
  }
}
