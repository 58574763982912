import { Controller } from "stimulus";
import { debounce } from "lodash";
import cable from "../../channels/helpers/cable";
import { DataTable } from "../helpers/data_table_helper";
import { buildPagination } from "../helpers/advanced_paging";

export default class DataTableController extends Controller {
  static targets = ["listHead", "listBody", "dataList", "pagination"];

  initializeDataTableParams(params) {
    const {
      pageNumber = 1,
      perPage = 5,
      order = "DESC",
      sortBy = "created_at",
      searchableFields = [],
      tableName = "",
      controllerPath = "",
      tableHeaders = {}
    } = params;

    this.pageNumber = pageNumber;
    this.perPage = perPage;
    this.order = order;
    this.sortBy = sortBy;
    this.tableHeaders = tableHeaders;
    this.searchableFields = searchableFields;
    this.tableName = tableName;
    this.controllerPath = controllerPath;
    this.search = {};
    this.handleSearch = debounce(this.handleSearch, 1000);
  }

  configureDataTable(params = {}) {
    this.initializeDataTableParams(params);
    this.dataTable = new DataTable(this);
    this.fetchList();
  }

  handleResponse(responseJson) {
    const { pagination, collection } = responseJson;
    this.handlePagination(pagination);
    this.dataTable.renderRow(this.dataTableRows(collection));
    this.dataTable.renderHeaders();
  }

  configureChannel(Channel, params = {}) {
    this.channel = new Channel(params);
    cable.subscribe(this.channel);
    this.channel.on("populatingTable", data => this.handleResponse(data));
  }

  renderLoading() {
    this.listBodyTarget.innerHTML = `
      <tr>
        <td colspan="7">
          <div class="loading-spinner loading-spinner-lg" />
        </td>
      </tr>`;
  }

  fetchList() {
    this.renderLoading();
    this.channel.fetchList(this, this.dataListTarget.dataset);
  }

  handleSearch(e) {
    this.pageNumber = 1;
    this.search[this.tableName] = this.buildSearch(e.target.value).filter(
      e => Object.keys(e).length > 1
    );
    this.fetchList();
  }

  updatePerPage(e) {
    this.perPage = parseInt(e.target.value);
    this.pageNumber = 1;
    this.fetchList();
  }

  updateSort(e) {
    this.dataTable.updateSorting(e.currentTarget);
    this.fetchList();
  }

  handlePagination(paginationData) {
    this.totalItems = paginationData.total_records;
    this.paginationTarget.innerHTML = buildPagination({
      ...paginationData,
      action: `data-action=${this.controllerPath}#changePage`
    });
  }

  changePage(e) {
    if (e.currentTarget.parentElement.classList.contains("disabled")) {
      return;
    }

    this.pageNumber = e.currentTarget.getAttribute("data-page");
    this.fetchList();
  }

  buildSearch(value) {
    return this.searchableFields.map(fieldProps => {
      const search = {};
      const { field, exact = false, id = false, currency = false } = fieldProps;
      const keyword = this.validatedKeyword(value, fieldProps);
      if (keyword) {
        search[field] = keyword;
        search["exact"] = exact || currency || id;
      }
      return search;
    });
  }

  validatedKeyword(keyword, props) {
    const { id = false, currency = false } = props;
    if (currency) return this.validateCurrency(keyword);
    if (id) return this.validateId(keyword);

    return keyword;
  }

  validateCurrency(keyword) {
    const validCurrency = parseFloat(keyword) > 0;
    if (validCurrency) return parseFloat(keyword);

    return "";
  }

  validateId(keyword) {
    const validId = parseInt(keyword) > 0;
    if (validId) return parseInt(keyword);

    return "";
  }
}
