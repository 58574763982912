import { Controller } from "stimulus";
import { flash } from "../../shared/common/notices/flash_alerts_controller";

export default class extends Controller {
  static targets = [
    "showValue",
    "editValue",
    "actionButtonsContainer",
    "merchantReferenceNumber",
  ];

  showEditForm() {
    this.showValueTarget.classList.add("hide");
    this.editValueTarget.classList.remove("hide");
    this.actionButtonsContainerTarget.classList.remove("hide");
  }

  hideEditForm() {
    this.showValueTarget.classList.remove("hide");
    this.editValueTarget.classList.add("hide");
    this.actionButtonsContainerTarget.classList.add("hide");
  }

  saveMerchantReferenceNumber(event) {
    event.preventDefault();
    const url = event.target.dataset.url;
    const request = window.acima.fetchInit({
      method: "PATCH",
      body: JSON.stringify({
        invoice: {
          merchant_reference_number: this.merchantReferenceNumberTarget.value
        }
      })
    });

    fetch(url, request)
    .then(response => response.json())
    .then(data => this.handleResponse(data))
    .catch(err => this.handleError(err));
  }

  handleResponse(data) {
    flash.clear();
    flash.success(`${data.message}`);
    window.location.reload();
  }

  handleError(err) {
    flash.clear();
    flash.error(`${err}`);
    window.location.reload();
  }
}
