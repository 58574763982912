import {Controller} from "stimulus";
import {
  flash,
} from "../../../shared/common/notices/flash_alerts_controller";
import {
  showErrorDialog,
} from "../../../shared/common/dialogs/error_dialog_controller";

/**
 * Sends signature link to customers
 */
export default class extends Controller {
  connect() {
    this.contractId = this.data.get("contractId");
    this.url = this.data.get("url");
    this.errorMessage = this.data.get("errorMessage");
  }

  /**
   * Handles the fetch response by flashing success or throwing
   * an error that is caught by handleError.
   *
   * @param {Object} jsonResponse fetch response object
   */
  handleResponse(jsonResponse) {
    if (!jsonResponse.success) {
      throw new Error(jsonResponse.message);
    }

    flash.success(jsonResponse.message);
  }

  /**
   * Handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    console.error(err);
    showErrorDialog(`${this.errorMessage} ${err.message}`);
  }

  /**
   * Makes request to send signature link
   */
  send() {
    const params = {lease_id: this.contractId};
    const request = {method: "POST", body: JSON.stringify(params)};

    fetch(this.url, window.acima.fetchInit(request))
        .then((response) => response.json())
        .then((jsonResponse) => this.handleResponse(jsonResponse))
        .catch((err) => this.handleError(err));
  }
}
