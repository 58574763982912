import {Controller} from "stimulus";

/** Customer information dialog launcher */
export default class extends Controller {
  /**
   * Launch the dialog
   */
  launchDialog() {
    document.querySelector("#contact-dialog").showModal();
  }
}
