import {Controller} from "stimulus";

export default class extends Controller {
  static targets = [
    "modal",
    "errorAlert",
    "submitButton",
    "enableFeature",
    "modalLink",
  ]

  connect() {
    dialogPolyfill.registerDialog(this.modalTarget);
  }

  showModal() {
    this.modalTarget.showModal();
  }

  closeModal() {
    this.modalTarget.close();
  }

  confirmPayment() {
    this.disableSubmitButton();

    const fetchWith = window.acima.fetchInit({method: "POST"});
    const url = this.modalTarget.dataset.url;
    const controller = this;
    fetch(url, fetchWith).then((response) => {
      return response.json();
    }).then(function(data) {
      if (data.success == true) {
        window.location.reload();
      } else {
        controller.errorAlertTarget.innerHTML = data.message;
        controller.errorAlertTarget.classList.remove("hide");
        controller.enableSubmitButton();
      }
    });
  }

  disableSubmitButton() {
    this.submitButtonTarget.classList.add("disabled");
    this.submitButtonTarget.removeAttribute("data-action");
  }

  enableSubmitButton() {
    this.submitButtonTarget.classList.remove("disabled");
    this.submitButtonTarget.setAttribute(
        "data-action",
        "click->merchant-contracts--customer-pays-merchant-modal#confirmPayment"
    );
  }

  disableModalLink() {
    this.modalLinkTarget.classList.add("disabled");
    this.modalLinkTarget.removeAttribute("data-action");
  }

  enableModalLink() {
    this.modalLinkTarget.classList.remove("disabled");
    this.modalLinkTarget.setAttribute(
        "data-action",
        "click->merchant-contracts--customer-pays-merchant-modal#showModal"
    );
  }

  disableFeature() {
    this.disableSubmitButton();
    this.disableModalLink();
  }

  enableFeature() {
    this.enableSubmitButton();
    this.enableModalLink();
  }
}
