import { Controller } from "stimulus";
import { publish, subscribe } from "../../helpers/pub_sub";

const EVENT_NAME = "showUploadItemDialogEvent";

/**
 * Publishes the show upload item dialog event
 */
export function showUploadItemDialog() {
  publish(EVENT_NAME);
}

export default class extends Controller {
  static targets = [
    "uploadItemForm",
    "submitButton",
    "fileNamePreview"
  ]

  connect() {
    dialogPolyfill.registerDialog(this.element);
    subscribe(EVENT_NAME, this.openDialog);
  }

  openDialog = () => {
    this.element.showModal();
  }

  submitDocument() {
    this.disableSubmit();
    this.uploadItemFormTarget.submit();
  }

  toggleSubmitEnabling() {
    if (this.fileNamePreviewTarget.textContent !== "") {
      this.enableSubmit();
    } else {
      this.disableSubmit();
    }
  }

  enableSubmit() {
    this.submitButtonTarget.classList.remove("disabled");
  }

  disableSubmit() {
    this.submitButtonTarget.classList.add("disabled");
  }

  closeDialog() {
    this.element.close();
  }
}
