import { Controller } from "stimulus";
import {
  showErrorDialog
} from "../../../shared/common/dialogs/error_dialog_controller";

/**
 * Manages resubmit/kickstart action for user contracts
 */
export default class extends Controller {
  static targets = [
    "modal",
    "errorAlert",
    "submitButton",
    "enableFeature",
    "modalLink",
    "locationSelect"
  ]

  connect() {
    dialogPolyfill.registerDialog(this.modalTarget);
  }

  showModal(e) {
    if (e.currentTarget.hasAttribute("disabled")) {
      return;
    }
    this.modalTarget.showModal();
  }

  closeModal() {
    this.modalTarget.close();
  }

  confirm(e) {
    if (e.currentTarget.hasAttribute("disabled")) {
      return;
    }
    this.disableSubmitButton();
    const locationId = this.locationSelectTarget.value;
    const body = JSON.stringify({ location_id: locationId });
    const fetchWith = window.acima.fetchInit({ method: "POST", body: body });
    const url = this.modalTarget.dataset.url;
    fetch(url, fetchWith)
      .then((response) => response.json())
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  disableSubmitButton() {
    this.submitButtonTarget.classList.add("disabled");
  }

  enableSubmitButton() {
    this.submitButtonTarget.classList.remove("disabled");
  }

  /**
   * Handles the fetch response by reloading the page on success or throwing
   * an error that is caught by handleError.
   *
   * @param {Object} data fetch response object
   */
  handleResponse(data) {
    const controller = this;
    if (!data.success) {
      controller.enableSubmitButton();
      throw new Error(data.message);
    }
    controller.errorAlertTarget.innerHTML = data.message;
    controller.errorAlertTarget.classList.add("hide");

    window.location.reload();
  }

  /**
   * Handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    console.error(err);
    showErrorDialog(`${err.message}`);
  }
}
