import { Controller } from "stimulus";
import {
  showConfirmDialog
} from "../shared/common/dialogs/confirm_dialog_controller";
/**
 * Funding Groups Validations and Destroy action.
 */

export default class extends Controller {
  /**
   * Submit to request Users::DocumentsController#destroy
   * @param {event} event -> click
   */
  static targets = [
    "name",
    "nameStatus",
    "bankAccountId",
    "submitBtn",
    "deleteId"
  ];

  validateForm = () => {
    if (this.isValidName) {
      this.submitBtnTarget.disabled = false;
      this.addSuccess(this.nameStatusTarget);
    } else {
      this.submitBtnTarget.disabled = true;
      this.addError(this.nameStatusTarget);
    }
  }

  showDialog() {
    const id = this.element.dataset.id;
    const merchantId = this.element.dataset.merchantid;
    showConfirmDialog({
      action: () => this.deleteFundingGroup(id, merchantId)
    });
  }

  deleteFundingGroup = (id, merchantId) => {
    const progressUrl = `/users/merchants/${merchantId}`;
    const url = `/users/merchants/${merchantId}/funding_groups/${id}.json`;
    const fetchWith = window.acima.fetchInit({ method: "DELETE" });
    fetch(url, fetchWith).then(response => {
      response.json();
    }).then(response => {
      window.location.href = progressUrl;
    });
  }

  get isValidName() {
    return this.nameTarget.value !== "";
  }

  /**
   * Adds has-success class around text field (green)
   */

  addSuccess(target) {
    target.classList.remove("has-error");
    target.classList.add("has-success");
  }

  /**
   * Adds has-success class around text field (red)
   */

  addError(target) {
    target.classList.remove("has-success");
    target.classList.add("has-error");
  }
}
