import {Controller} from "stimulus";

/**
 * Manages reject conditions dialog for user contracts
 */
export default class extends Controller {
  static targets=[
    "reason",
  ]

  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  /**
   * Handles the fetch response by reloading the page on success or throwing
   * an error that is caught by handleError.
   *
   * @param {Object} jsonResponse fetch response object
   */
  handleResponse(jsonResponse) {
    if (!jsonResponse.success) {
      throw new Error(jsonResponse.message);
    }

    window.location.reload();
  }

  /**
   * Handles errors in fetch
   *
   * @param {Object} err caught error object
   */
  handleError(err) {
    console.error(err);
    showErrorDialog(`${this.errorMessage} ${err.message}`);
  }

  /**
   * Makes request to reject condition
   */
  reject() {
    if (this.invalid()) {
      return;
    }

    const params = {reason: this.reason};
    const request = ({method: "POST", body: JSON.stringify(params)});

    fetch(this.url, window.acima.fetchInit(request))
        .then((response) => response.json())
        .then((jsonResponse) => this.handleResponse(jsonResponse))
        .catch((err) => this.handleError(err));
  }

  /**
   * Checks if the reason body field has a value
   *
   * @return {boolean} form invalid or not
   */
  invalid() {
    if (this.reasonTarget.value.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  closeDialog() {
    this.element.close();
  }

  showDialog(e) {
    this.url = e.currentTarget.dataset.url;
    this.element.showModal();
  }
}
