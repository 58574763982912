import { Controller } from "stimulus";
import {
  randAlpha, randCity, randDomainName, randEmail, randFirstName,
  randFullName, randLastName, randNumber, randPhoneNumber,
  randProductName, randStreetName, randUrl, randZipCode
} from "@ngneat/falso";

/** New Merchant Application controller */
export default class extends Controller {
  static targets = [
    "postalCodeLabel",
    "administrativeDivisionLabel",
    "administrativeDivisionOptions",
    "caRoutingNumber",
    "usRoutingNumber",
    "languageSelection",
    "countryCode",

    "legalBusinessName", "dba", "taxIdNumber", "yearEstablished",
    "websiteUrl", "industries", "primaryIndustry", "purchasingOptions",
    "referral", "merchantSource",

    "locationName", "phoneNumber", "address",
    "address2", "city", "state", "zip",
    "primaryPerson", "position",

    "applicationEmail", "fundingEmail",

    "ownerFirstName", "ownerLastName", "ownerWorkPhone",
    "ownerMobilePhone","ownerEmail",

    "userFirstName", "userLastName", "userEmail",
    "userPhone", "userUsername", "userPassword", "userPasswordConfirm",

    "bankAccountNumber", "bankRoutingNumber", "institutionNumber", "transitNumber"
  ]

  connect() {
    this.toggleLanguageSelection();
    this.updatePostCode();
    this.updateAdministrativeDivisionLabel();
    this.updateAdministrativeDivisionOptions();
    this.updateRoutingNumberInputs();
  }

  countrySelectChanged() {
    this.toggleLanguageSelection();
    this.updatePostCode();
    this.updateAdministrativeDivisionLabel();
    this.updateRoutingNumberInputs();
    return this.updateAdministrativeDivisionOptions();
  }

  toggleLanguageSelection() {
    const languageSelection = this.languageSelectionTarget.classList;

    this.countryCodeTarget.value == "US" ? languageSelection.remove("hidden") : languageSelection.add("hidden");
  }

  updateAdministrativeDivisionLabel() {
    const countryCode = this.countryCodeTarget.value;
    const administrativeDivisions = {
      US: acima.i18n.t("merchant_application.location_info.state"),
      CA: acima.i18n.t("merchant_application.location_info.province")
    };

    const defaultAdministrativeDivision = acima.i18n.t("merchant_application.location_info.state");
    const innerHTML = administrativeDivisions[countryCode] || defaultAdministrativeDivision;
    this.administrativeDivisionLabelTarget.innerHTML = innerHTML;
  }

  updatePostCode() {
    const countryCode = this.countryCodeTarget.value;
    const postCode = {
      US: acima.i18n.t("merchant_application.location_info.zip"),
      CA: acima.i18n.t("merchant_application.location_info.postal")
    };

    const defaultPostCode = acima.i18n.t("merchant_application.location_info.zip");
    const innerHTML = postCode[countryCode] || defaultPostCode;
    this.postalCodeLabelTarget.innerHTML = innerHTML;
  }

  updateAdministrativeDivisionOptions() {
    const params = JSON.stringify({ country_code: this.countryCodeTarget.value });
    const fetchWith = window.acima.fetchInit({ method: "POST", body: params });

    return fetch("/util/country_values", fetchWith)
      .then(response => response.json())
      .then(data => {
        const states = Object.keys(data.states);
        // Clear existing options
        this.administrativeDivisionOptionsTarget.innerHTML = "";

        // Add new options
        states.forEach(option => {
          const optionElement = document.createElement("option");
          optionElement.value = optionElement.text = option;
          this.administrativeDivisionOptionsTarget.appendChild(optionElement);
        });
        document.getElementById("merchant_locations_attributes_0_state_code").selectedIndex = -1;
      });
  }

  toggleCaRoutingNumber() {
    const countryCode = this.hasCountryCodeTarget ? this.countryCodeTarget.value : "US";

    countryCode == "CA" ? this.caRoutingNumberTarget.classList.remove("hidden") : this.caRoutingNumberTarget.classList.add("hidden");

    for (const e of this.caRoutingNumberTarget.getElementsByTagName("input")) {
      e.disabled = countryCode != "CA";
    }
  }

  toggleUsRoutingNumber() {
    const countryCode = this.countryCodeTarget.value;

    countryCode == "US" ? this.usRoutingNumberTarget.classList.remove("hidden") : this.usRoutingNumberTarget.classList.add("hidden");

    for (const i of this.usRoutingNumberTarget.getElementsByTagName("input")) {
      countryCode == "US" ? i.disabled = false : i.disabled = true;
    }
  }

  updateRoutingNumberInputs() {
    this.toggleCaRoutingNumber();
    this.toggleUsRoutingNumber();
  }

  randomItem(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  randomPostalCode() {
    const validLetters = ["A","B","C","E","G","H","J","K","L","M","N","P","R","S","T","V","X","Y"];

    return this.randomItem(validLetters) + randNumber({min: 1, max: 9}) +
           this.randomItem(validLetters) + randNumber({min: 1, max: 9}) +
           this.randomItem(validLetters) + randNumber({min: 1, max: 9})
  }

  usAutofill() {
    const self = this;
    const target = this.administrativeDivisionOptionsTarget;

    this.generalAutoFill();

    this.countryCodeTarget.value = "US";
    this.countrySelectChanged().then(() => {
      target.value = self.randomItem(Array.from(target.options).map((option) => option.value));
      self.zipTarget.value = randZipCode().split("-")[0];
    });
  }

  canadaAutofill() {
    const self = this;
    const target = this.administrativeDivisionOptionsTarget;

    this.generalAutoFill();

    this.countryCodeTarget.value = "CA";
    this.countrySelectChanged().then(() => {
      target.value = self.randomItem(Array.from(target.options).map((option) => option.value));
      self.zipTarget.value = self.randomPostalCode();
      self.institutionNumberTarget.value = randNumber({min: 100, max: 999});
      self.transitNumberTarget.value = randNumber({ min: 10000, max: 99999 });
    });
  }

  generalAutoFill() {
    const entities = ["Inc.", "Co", "LLC", "Org", "Manufacturing", "Services", "Wholesale"];
    const storeType = ["Store", "Place", "Depot", "Mart", "Bazaar"];
    const routing = ["081213269", "301079617", "221381540", "263179817", "021082162", "075912712", "071111481",
      "274973390", "051409223", "124384657", "113104534", "073920395", "021082065", "243384978", "031309259"];
    const business = `${randFullName({ withAccents: false })}"s ${randProductName()} ${this.randomItem(entities)}`

    this.legalBusinessNameTarget.value = business;
    this.dbaTarget.value = business;
    this.taxIdNumberTarget.value = randNumber({min: 100000000, max: 999999999});
    this.yearEstablishedTarget.value = `19${randNumber({min: 10, max: 99})}`;
    this.websiteUrlTarget.value = randUrl();
    $(this.industriesTarget).multiselect("select", "1")
    this.primaryIndustryTarget.innerHTML = "<option value=1>Home Electronics</option>";
    $(this.purchasingOptionsTarget).multiselect("select", "Traditional (GE, Wells Fargo, etc.)");
    this.referralTarget.value = "Internet search";

    this.merchantSourceTarget.value = "Direct Sale";

    this.locationNameTarget.value = `${business} ${this.randomItem(storeType)}`;
    this.phoneNumberTarget.value = randPhoneNumber();
    this.addressTarget.value = `${randNumber() % 1000} ${randStreetName()}`;
    this.cityTarget.value = randCity();

    this.primaryPersonTarget.value = randFullName();
    this.positionTarget.value = "Business Owner";
    this.applicationEmailTarget.value = randEmail();
    this.fundingEmailTarget.value = randEmail();

    this.ownerFirstNameTarget.value = randFirstName({ withAccents: false });
    this.ownerLastNameTarget.value = randLastName({ withAccents: false });
    this.ownerWorkPhoneTarget.value = randPhoneNumber();
    this.ownerMobilePhoneTarget.value = randPhoneNumber();
    this.ownerEmailTarget.value = `${this.ownerFirstNameTarget.value}.${this.ownerLastNameTarget.value}@${randDomainName()}`

    this.userFirstNameTarget.value = randFirstName({ withAccents: false });
    this.userLastNameTarget.value = randLastName({ withAccents: false });
    this.userUsernameTarget.value = `${this.userFirstNameTarget.value}.${this.userLastNameTarget.value}`
    this.userEmailTarget.value = `${this.userUsernameTarget.value}@${randDomainName()}`;
    this.userPhoneTarget.value = randPhoneNumber();
    this.userPasswordTarget.value = "password321";
    this.userPasswordConfirmTarget.value = this.userPasswordTarget.value;

    this.bankAccountNumberTarget.value = randNumber({min: 100000000000, max: 999999999999});
    this.bankRoutingNumberTarget.value = this.randomItem(routing);
    this.bankRoutingNumberTarget.focus();
    this.bankRoutingNumberTarget.blur();
  }
}
