import { Controller } from "stimulus";
import LeaseOnboardingAlertsChannel
  from "~/channels/lease_onboarding_alerts_channel";
import cable from "~/channels/helpers/cable";

export default class extends Controller {
  connect() {
    const contractGuid = this.data.get("lease-guid");
    const canInvoiceAdjust = this.data.get("can-invoice-adjust");
    if (!canInvoiceAdjust) {
      return;
    }

    this.channel = new LeaseOnboardingAlertsChannel({
      contract_guid: contractGuid
    });
    cable.subscribe(this.channel).ensureSubscribed();
    this.channel.on("message", msg => {
      if (msg == "success") {
        window.location.reload();
      }
    });
  }

  disconnect() {
    if (this.channel) {
      cable.unsubscribe(this.channel);
    }
  }
}
