import { Controller } from "stimulus";
import { publish, subscribe, unsubscribe } from "@/controllers/helpers/pub_sub";

const SHOW_RETRY_OPTIONAL_PAYMENT_DIALOG_EVENT =
  "showRetryOptionalPaymentDialogEvent";

export function showRetryOptionalPaymentDialog() {
  publish(SHOW_RETRY_OPTIONAL_PAYMENT_DIALOG_EVENT);
}

export default class extends Controller {
  static targets = [
    "error",
    "ipData",
    "submitButton",
    "paymentBreakdown",
    "paymentForm",
    "dueToday",
    "optionalPayment",
    "optionalPaymentTax",
  ];

  connect() {
    subscribe(SHOW_RETRY_OPTIONAL_PAYMENT_DIALOG_EVENT, this.showDialog);

    if (typeof(CreditCardServiceV2) === "undefined") {
      setTimeout(this.formLoadingErrorMessage, 1000);
      Rollbar.error("CreditCardServiceV2 is undefined.");
    } else {
      setTimeout(this.buildForm.bind(this), 1000);
    }
  }

  disconnect() {
    unsubscribe(SHOW_RETRY_OPTIONAL_PAYMENT_DIALOG_EVENT, this.showDialog);
  }

  showDialog = () => {
    if (!this.targetDialog.hasAttribute("open")) {
      this.targetDialog.showModal();
    }
  }

  formLoadingErrorMessage = () => {
    document.getElementById("form-loading-error").classList.remove("hidden");
  }

  buildForm = () => {
    const ipData = this.ipDataTarget.dataset;
    CreditCardServiceV2.form({
      onComplete: response => {
        this.logResponse(response, ipData.auditUrl);
        if (!response) {
          // prevent rapid resubmissions in the case of network issues
          setTimeout(this.reEnableSubmit(this.submitButtonTarget), 5000);
        } else if (response.status === "Success") {
          // If confirmation url is present, charge occurs on the backend
          if (ipData.confirmPaymentUrl.length > 0) {
           this.fetchThenRender(
              ipData.confirmPaymentUrl,
              this.targetDialog,
              "POST",
              response,
            );
          }
        } else if (
          response.code === "cvv_validation_failure_allow_retry" ||
          response.code === "address_validation_failure_allow_retry" ||
          this.preAuthorizationError(response)
        ) {
          CreditCardServiceV2.showAddressFields();
        } else {
          setTimeout(this.reEnableSubmit(this.submitButtonTarget), 2000);
        }
      },
      ...this.creditCardParams(ipData)
    });
  }

  creditCardParams = ipData => {
    let authTokenEndpoint = ipData.authTokenEndpoint;

    return {
      auth_token_endpoint: authTokenEndpoint,
      respond_to: ipData.respondTo,
      applicant_name: ipData.applicantFullName,
      require_check_box_auth: ipData.requireCheckBoxAuth,
      require_check_box_auth_text: ipData.debitCardReqText,
      debit_card_required: ipData.debitCardRequired,
      debit_card_auth_text: ipData.debitCardReqText,
      ccs_endpoint: ipData.ccsEndpoint,
      submit_button_text: ipData.submitButtonText,
      iframe_css_base: ipData.ccnumFieldCss,
      submit_button_classes:
        ipData.buttonClasses + " " + ipData.submitButtonClasses,
      clear_button_classes: ipData.buttonClasses,
      address: ipData.address,
      city: ipData.city,
      state: ipData.state,
      zip: ipData.zip,
      iframe_width: "100%",
      iframe_height: "60px",
      payment_source: ipData.paymentSource,
      hide_fields: { address: true, city: true, state: true, zip: true },
      kount_enabled: false,
      payment_context: ipData.paymentContext,
      phone_number: ipData.phoneNumber,
      customer_email: ipData.customerEmail,
      invoice_total: ipData.invoiceTotal,
      debug: true,
      fake_ccs: ipData.fakeCcs,
      paymentAmount: ipData.paymentAmount,
      business_entity: ipData.businessEntity,
      country: ipData.country,
      currency_code: ipData.currencyCode
    };
  }

  logResponse = (response, auditUrl) => {
    let description = [];

    Object.keys(response).forEach(property => {
      description.push(property + ":" + response[property]);
    });

    const params = [
      "name=lease.initial_payment.service_response",
      "description=Initial payment service response " + description.join("|")
    ].join("&");
    let xhr = new XMLHttpRequest();
    xhr.open("POST", auditUrl);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("X-CSRF-Token", $("meta[name=\"csrf-token\"]").attr("content"));
    xhr.send(params);
  };

  reEnableSubmit = element => {
    element.classList.remove("disabled");
  }

  submitForm = async () => {
    this.preSubmissionActions();
    await this.submitCreditCardForm();
  }

  preSubmissionActions = () => {
    this.submitButtonTarget.classList.add("disabled");
  }

  submitCreditCardForm = async () => {
    try {
      const res = await fetch(
        this.submitButtonTarget.dataset.url,
        window.acima.fetchInit({ method: "POST" }),
      );
      const data = await res.json();

      if (data.success == false) {
        this.handleErrors([data.message]);
      } else if (data.new_lock == true) {
        const result = CreditCardServiceV2.submitForm();
        if (Array.isArray(result)) {
          this.handleErrors(result);
          setTimeout(() => this.clearErrors(), 5000);
        }
      } else {
        this.handleErrors([data.message]);
        setTimeout(() => window.location.reload(), 60000);
      }
    } catch (e) {
      Rollbar.error(e);
    }
  }

  handleResponse = jsonResponse => {
    if (!jsonResponse.success) {
      throw new Error(jsonResponse.message);
    }
  }

  handleErrors = data => {
    this.submitButtonTarget.classList.remove("disabled");
    this.displayErrors(data);
    this.requestUnlock();
  }

  displayErrors = errors => {
    const el = this.errorTarget;
    el.innerHTML = "<p>" + errors.join("</p><p>") + "</p>";
    el.classList.remove("hide");
  }

  clearErrors = () => {
    const el = this.errorTarget;
    el.innerHTML = "";
    el.classList.add("hide");
  }

  requestUnlock = () => {
    fetch(
      this.submitButtonTarget.dataset.url,
      window.acima.fetchInit({ method: "DELETE" }),
    );
  }

  fetchThenRender(url, modalDiv, requestType = "GET", params = {}) {
    const request = { method: `${requestType}` };

    if (Object.keys(params).length === 0) {
      var requestUrl = url;
    } else {
      var queryParams = new URLSearchParams(params);
      var requestUrl = `${url}?${queryParams}`;
    }

    fetch(requestUrl, window.acima.fetchInit(request))
      .then(response => response.text())
      .then(text => modalDiv.innerHTML = text)
      .then(text => this.initialPaymentDialog.innerHTML = text) // This element contains the optional payment retry form when the Optional Payment fails during the Initial Payment process.
      .catch(err => console.error(err));
  }

  preAuthorizationError = response => {
    return response.charge &&
      response.charge.pre_authorization &&
      (
        response.charge.pre_authorization.error_code === "cvv_validation_failure_allow_retry" ||
        response.charge.pre_authorization.error_code === "address_validation_failure_allow_retry"
      );
  }

  get targetDialog() {
    return document.getElementById("retry-optional-payment-modal");
  }

  get initialPaymentDialog() {
    return document.getElementById("take-initial-payment-modal");
  }
}
