import {Controller} from "stimulus";
import {publish, subscribe} from "../../helpers/pub_sub";
import {renderDialogBodySpinner} from "../../helpers/spinner_helpers";

const SHOW_EVENT_NAME = "showFundingDialogEvent";
const CLOSE_EVENT_NAME = "closeFundingDialogEvent";

// Assigning below functions to window.acima as a hack to let the legacy
// coffeescript funding list call into modern stimulus for dialog controls
if (window.acima == undefined) {
  window.acima = {};
}

/**
 * Dispatches the show event
 *
 * This is the public interface into showing the funding dialog
 *
 * @param {Object} dialogData - Payload object for customizing confirm dialog
 * @param {string} dialogData.url - url to fetch html body
 * @param {string} dialogData.title - title of dialog to render
 */
window.acima.showFundingDialog = function showFundingDialogEvent(dialogData) {
  publish(SHOW_EVENT_NAME, dialogData);
};

/**
 * Dispatches the close event
 *
 * This is the public interface into closing the funding dialog
 */
window.acima.closeFundingDialog = function closeFundingDialogEvent() {
  publish(CLOSE_EVENT_NAME, {});
};

/**
 * Manages reusable funding dialog for the user side funding list
 *
 * Shows/Hides dialog and fetches HTML to render inside of it
 * */
export default class extends Controller {
  static targets = [
    "dialogBody",
    "title",
  ];

  /**
   * connect controller,register dialog and subscribe to publishing
   */
  connect() {
    dialogPolyfill.registerDialog(this.element);
    this.titleTarget.textContent = this.data.get("defaultTitle");
    renderDialogBodySpinner(this.dialogBodyTarget);
    subscribe(SHOW_EVENT_NAME, this.showDialog);
    subscribe(CLOSE_EVENT_NAME, this.closeDialog);
  }

  /**
   * Handles error responses
   *
   * @param {Error} err an error
   */
  handleError(err) {
    console.error(err);
  }

  /**
   * Fetches html from a URL and sets it as the dialog body
   *
   * @param {string} url resource url to fetch html list from
   */
  fetch(url) {
    const request = {method: "GET"};

    fetch(url, window.acima.fetchInit(request))
        .then((res) => res.text())
        .then((text) => this.dialogBodyTarget.innerHTML = text)
        .catch((err) => this.handleError(err));
  }

  /**
   * Closes dialog and resets defaults
   */
  closeDialog = () => {
    this.titleTarget.textContent = this.data.get("defaultTitle");
    this.element.classList.remove(this.className);
    renderDialogBodySpinner(this.dialogBodyTarget);
    this.element.close();
  }

  /**
   * Shows the dialog
   */
  showDialog = ({url, title, className}) => {
    this.titleTarget.textContent = title;
    this.className = className;
    this.element.classList.add(this.className);
    this.fetch(url);
    this.element.showModal();
  }
}
