import {Controller} from "stimulus";
import {publish, subscribe} from "../../../helpers/pub_sub";
import {pubConfirm, subConfirm} from "../../../helpers/pub_sub_confirm_notice";

const CLEAR_FLASH_EVENT_NAME = "clearFlashAlert";
const CLEAR_NOTICE_EVENT_NAME = "clearNoticeAlert";
const FLASH_EVENT_NAME = "showFlashAlert";
const NOTICE_EVENT_NAME = "showNoticeAlert";


/**
 * Flash Alerts/Confirm Notices
 *
 * These interfaces are for client side page level flash and confirm notices.
 * If you are lookign to manage a more localized alert within the scope of a
 * stimulus controller try the alert_helpers.js file.
 *
 */


/**
 * Public interface
 *
 * Creates client side flash alerts, modeled after our use of rails flash
 *
 * To use, know your alert type and call that with a message
 *
 * import {
 *   flash,
 * } from "packs/controllers/shared/common/notices/flash_alerts_controller";
 *
 * flash.error("uh oh something went wrong");
 * flash.success("yay you did it");
 * flash.info("just so you know");
 *
 * This will clear out any flashes that currently exist or noop
 * flash.clear();
 */
export const flash = {
  clear: () => clearFlashAlerts(),
  error: (message) => flashAlert("alert-danger", message),
  info: (message) => flashAlert("alert-info", message),
  success: (message) => flashAlert("alert-success", message),
};

/**
 * Public interface
 *
 * Creates confirm notice alerts, modeled after flash but with an
 * additional onclick callback and optional buttonText param.
 *
 * There can only be one confirmNotice at a time and they are fixed in the view
 * and require user interaction to dismiss or confirm them.
 *
 * To use, know your alert type and call that with a message and callback.
 * button text is optional and defaults to "Confirm"
 *
 * import {
 *   confirmNotice,
 * } from "packs/controllers/shared/common/notices/flash_alerts_controller";
 *
 * confirmNotice.error("uh oh something went wrong", myFunc);
 * confirmNotice.success("yay you did it", myFunc, "Update");
 * confirmNotice.info("just so you know", myFunc, "Reload");
 *
 * This will clear out any confirmNotice that currently exists or noop
 * confirmNotice.clear();
 */
export const confirmNotice = {
  clear: () => clearNoticeAlerts(),
  error: (message, onClick, buttonText = "Confirm") => {
    noticeAlert("alert-danger", message, buttonText, onClick);
  },
  info: (message, onClick, buttonText = "Confirm") => {
    noticeAlert("alert-info", message, buttonText, onClick);
  },
  success: (message, onClick, buttonText = "Confirm") => {
    noticeAlert("alert-success", message, buttonText, onClick);
  },
};

/**
 * Private Implementation
 *
 * Dispatches the clearFlashAlert event, which will cause the client side
 * flash alerts to be cleared out
 */
function clearFlashAlerts() {
  publish(CLEAR_FLASH_EVENT_NAME, {});
  console.log("- clearFlashAlerts");
}

/**
 * Private Implementation
 *
 * Dispatches the clearNoticeAlert event, which will cause the client side
 * confirmNotice alert to be cleared out
 */
function clearNoticeAlerts() {
  pubConfirm(CLEAR_NOTICE_EVENT_NAME, {});
  console.log("- clearNoticeAlerts");
}

/**
 * Private Implementation
 *
 * Dispatches the showFlashAlert event
 *
 * @param {string} alertClass - string for class to use in alert markup
 * @param {string} message - message to display
 */
function flashAlert(alertClass, message) {
  publish(FLASH_EVENT_NAME, {alertClass, message});
  console.log("- flashAlert: ", alertClass);
}

/**
 * Private Implementation
 *
 * Dispatches the showNoticeAlert event
 *
 * @param {string} alertClass the class string for the type of alert
 * @param {string} message the message to display in the alert
 * @param {string} buttonText the text to display in the confirm button
 * @param {Function} onClick the function to trigger on confirm click
 */
function noticeAlert(alertClass, message, buttonText, onClick) {
  pubConfirm(NOTICE_EVENT_NAME, {alertClass, message, buttonText, onClick});
  console.log("- noticeAlert: ", alertClass);
}

/**
 * Manages reusable client side flash alerts
 */
export default class extends Controller {
  static targets = [
    "flashList",
    "notice",
  ]

  /**
   * Register controller, connect to event
   */
  connect() {
    subscribe(FLASH_EVENT_NAME, this.showFlash);
    subscribe(CLEAR_FLASH_EVENT_NAME, this.clearFlash);
    subConfirm(NOTICE_EVENT_NAME, this.showNotice);
    subConfirm(CLEAR_NOTICE_EVENT_NAME, this.dismissNotice);
  }

  /**
   * Private implementation
   *
   * Clears all client side flash alerts
   */
  clearFlash = () => {
    this.flashListTarget.innerHTML = "";
  }

  /**
   * Private implementation
   *
   * Renders the flash message alert
   *
   * @param {Object} data
   * @param {string} data.alertClass the class string for the type of alert
   * @param {string} data.message the message to display in the alert
   */
  showFlash = ({alertClass, message}) => {
    const alert = `
      <div class="alert alert-dismissible ${alertClass}">
        <button class="close" data-dismiss="alert"><span>&times;</span></button>
        <p>${message}</p>
      </div>
    `;
    this.flashListTarget.innerHTML += alert;
  }

  /**
   * Private Implementation
   *
   * Renders the confirm notice, only 1 confirm notice at a time, any
   * subsequent confirm notices replace it
   *
   * @param {Object} data
   * @param {string} data.alertClass the class string for the type of alert
   * @param {string} data.message the message to display in the alert
   * @param {string} data.buttonText the text to display in the confirm button
   * @param {Function} data.onClick the function to trigger on confirm click
   */
  showNotice = ({alertClass, message, buttonText, onClick}) => {
    const alert = `
      <div class="alert-notice">
        <div class="alert ${alertClass}">
          <p>${message}</p>
          <a class="alert-notice-button alert-notice-action">
            <span>${buttonText}</span>
          </a>
          <a class="alert-notice-button"><span>Dismiss</span></a>
        </div>
      </div>
    `;
    this.noticeTarget.innerHTML = alert;

    // Adjust dom to show notice and attach event listeners
    this.navbar().style.marginTop = `${this.noticeTarget.offsetHeight}px`;
    this.noticeTarget
        .querySelector(".alert-notice-action")
        .addEventListener("click", onClick);
    this.noticeTarget
        .querySelectorAll(".alert-notice-button")
        .forEach((el) => el.addEventListener("click", this.dismissNotice));
  }

  /**
   * Private Implementation
   *
   * Dismisses the confirm notice by removing any markup, only 1 confirm
   * notice at a time
   */
  dismissNotice = () => {
    this.noticeTarget.innerHTML = "";
    this.navbar().style.marginTop = "0px";
  }

  /**
   * Private Implementation
   *
   * Simple accessor for the document level navigation bar so that we can adjust
   * the margin on it to allow for the fixed confirmNotice
   *
   * @return {element} navbar element
   */
  navbar() {
    return document.querySelector("nav.navbar");
  }
}
