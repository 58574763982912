import {Controller} from "stimulus";
import {publish, subscribe} from "../../helpers/pub_sub";

const EVENT_NAME = "showEmailResendStatusDialogEvent";

/**
 * Publishes the show email details dialog event
 *
 * @param { status } status success or failure of email to send
 * @param { message } message to display for status
 */
export function showEmailResendStatusDialog(status, message) {
  publish(EVENT_NAME, {status, message});
}

/**
 * Handles operations inside confirmation modal
*/
export default class extends Controller {
  static targets = [
    "title",
    "description",
  ]

  /**
   * Register Dialog
  */
  connect() {
    dialogPolyfill.registerDialog(this.element);
    subscribe(EVENT_NAME, this.openDialog);
  }

  /**
   * Open dialog & save status & message
   * @param  {[String]} status  [success or failure to send link]
   * @param  {[String]} message [message to describe success or failure]
  */
  openDialog = ({status, message}) => {
    this.status = status;
    this.message = message;
    this.addTextToModal();
    this.element.showModal();
  }

  /**
   * Close Dialog
  */
  closeDialog() {
    this.element.close();
  }

  /**
   * Assign status & message to target elements
  */
  addTextToModal() {
    this.titleTarget.textContent = this.status;
    this.descriptionTarget.textContent = this.message;
  }
}
