import BaseChannel from "./base_channel";

class LeaseWorkflowChannel extends BaseChannel {
  static identifier = "LeaseWorkflowChannel";

  receive(code) {
    if (code === "valid_state") {
      setTimeout(() => window.location.reload(), 3000);
    }
  }
};

export { LeaseWorkflowChannel };
