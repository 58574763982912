import {Controller} from "stimulus";
import {publish, subscribe} from "../../../helpers/pub_sub";

const SHOW_EVENT_NAME = "ShowSpinnerDialogEvent";
const CLOSE_EVENT_NAME = "CloseSpinnerDialogEvent";

/**
 * Publishes the ShowSpinnerDialogEvent which triggers the spinner dialog
 *
 * This is the public interface into showing the spinner dialog
 *
 * @param {string} message - message to display in body of spinner
 */
export function showSpinnerDialog(message) {
  publish(SHOW_EVENT_NAME, {message});
}

/**
 * Publishes the CloseSpinnerDialogEvent which triggers the spinner dialog
 *
 * The dialog will normally be closed implicitly via a location reload or
 * redirect after a long running process. This is available in the event that
 * more manual control is required to close.
 */
export function closeSpinnerDialog() {
  publish(CLOSE_EVENT_NAME);
}

/** Dialog for displaying a spinner and message for long running processes */
export default class extends Controller {
  static targets = [
    "message",
  ]

  /**
    * Register the dialog, set initial state and subscribe to event
    */
  connect() {
    dialogPolyfill.registerDialog(this.element);
    this.defaultMessage = this.data.get("defaultMessage");
    subscribe(SHOW_EVENT_NAME, this.showDialog);
    subscribe(CLOSE_EVENT_NAME, this.closeDialog);
  }

  /**
   * Sets the message from the event or default and displays the spinner dialog
   *
   * @param {Object} obj -  The published event data payload
   * @param {string} obj.message - optional message body text
   */
  showDialog = ({message}) => {
    this.messageTarget.textContent = message || this.defaultMessage;
    this.element.showModal();
  }

  /**
   * Close the dialog and reset message text
   */
  closeDialog = () => {
    this.messageTarget.textContent = this.defaultMessage;
    this.element.close();
  }
}
