import {Controller} from "stimulus";
import {publish, subscribe} from "../../helpers/pub_sub";

const EVENT_NAME = "showConfirmationDialogEvent";

// Publishes the show confirmation dialog event
export function showConfirmationDialog() {
  publish(EVENT_NAME, {});
}

/** Dialogs controller */
export default class extends Controller {
  static targets = [
    "alertSuccess",
    "alertFailure",
    "body",
    "modal",
    "invoiceChangeAmount",
    "invoiceTotalBefore",
    "newInvoiceTotal",
    "downsy",
    "submitButton",
    "continueButton",
    "paymentNotice",
    "virtualCardRefundNotice",
    "virtualCardRefundAck",
    "continueButton2",
  ]

  connect() {
    dialogPolyfill.registerDialog(this.modalDialog);
    subscribe(EVENT_NAME, this.showConfirmationModal);
  }

  showConfirmationModal = () => {
    this.getInvoiceTotal();
    this.modalDialog.showModal();
  }

  closeDialog() {
    this.modalDialog.close();
  }

  getInvoiceTotal() {
    const controller = this;
    const fetchWith = window.acima.fetchInit({method: "GET"});

    fetch(
        `${controller.data.get("amountsUrl")}`,
        fetchWith
    )
        .then((response) => response.json())
        .then(function(jsonResponse) {
          controller.populateBreakdown(jsonResponse.merchandise_total);
        }).catch(function(error) {
          console.log(error.message);
        });
  }

  populateBreakdown(merchantInvoiceTotal) {
    const currentInvoiceTotal = `$${merchantInvoiceTotal.toFixed(2)}`;
    this.invoiceTotalBeforeTarget.innerHTML = currentInvoiceTotal;
    this.invoiceChangeAmountTarget.innerHTML = currentInvoiceTotal;
    this.newInvoiceTotalTarget.innerHTML = "$0.00";
    this.downsyTarget.classList.remove("hidden");
  }

  submitTermination() {
    // DONOT SUBMIT if merchantsfunding_adjustments_method
    // is virtual card refund
    if (this.data.get("virtualCardRefund") == "true") {
      this.handleSuccess({});
      return;
    }
    this.submitButtonTarget.classList.add("disabled");
    const controller = this;
    const fetchWith = window.acima.fetchInit({method: "POST"});
    const handleSuccessFn = function(jsonResponse) {
      this.responseData = jsonResponse;
      if (jsonResponse.result == "success") {
        this.handleSuccess(jsonResponse);
      } else {
        this.handleFailure(jsonResponse.message);
      }
    }.bind(this);
    fetch(
      `${controller.data.get("submitUrl")}`,
      fetchWith
    )
    .then((response) => response.json())
    .then(handleSuccessFn)
    .catch(function(error) {
      controller.handleError(error);
    });
  }

  handleSuccess(responseData) {
    this.alertSuccessTarget.classList.remove("hidden");
    this.alertFailureTarget.classList.add("hidden");

    if (this.data.get("customerPaidMerchant") == "true") {
      this.modalBody.classList.add("hidden");
      this.paymentNotice.classList.remove("hidden");
    }
    else {
      if (this.data.get("virtualCardRefund") == "true") {
        this.showVirtualCardTermintionNotice();
        return;
      }
      window.location.href = responseData.redirect_path;
    }
  }

  showVirtualCardTermintionNotice() {
    this.modalBody.classList.add("hidden");
    this.paymentNotice.classList.add("hidden");
    if (this.hasVirtualCardRefundNotice) {
      this.virtualCardRefundNotice.classList.remove("hidden");
    }
  }

  handleFailure(message) {
    this.alertFailureTarget.innerHTML = message;
    this.alertFailureTarget.classList.remove("hidden");
    this.submitButtonTarget.classList.remove("disabled");
  }

  handleError(error) {
    console.log(error);
    this.alertFailureTarget.classList.remove("hidden");
    this.submitButtonTarget.classList.remove("disabled");
  }

  continue() {
    if (this.data.get("virtualCardRefund") == "true") {
      this.showVirtualCardTermintionNotice();
      return;
    }

    this.continueButtonTarget.classList.add("disabled");
    window.location.href = this.responseData.redirect_path;
  }

  confirmVirtualCardRefund() {
    if (!this.virtualCardRefundAck.checked) {
      // disable continue/submit
      this.confirmTerminationButton.classList.add("disabled");
      return;
    }

    window.location.reload();
  }

  toggleConfirmationBtn() {
    if (!this.virtualCardRefundAck.checked) {
      // disable continue/submit
      this.confirmTerminationButton.classList.add("disabled");
    }
    else {
      this.confirmTerminationButton.classList.remove("disabled");
    }
  }

  get modalDialog() {
    return this.modalTarget;
  }

  get modalBody() {
    return this.bodyTarget;
  }

  get paymentNotice() {
    return this.paymentNoticeTarget;
  }

  get virtualCardRefundNotice() {
    return this.virtualCardRefundNoticeTarget;
  }

  get virtualCardRefundAck() {
    return this.virtualCardRefundAckTarget;
  }

  get confirmTerminationButton() {
    return this.continueButton2Target;
  }

  get hasVirtualCardRefundNotice() {
    return this.hasVirtualCardRefundNoticeTarget;
  }
}
