import {Controller} from "stimulus";
import Clusterize from "clusterize.js";

/** Controller used to mask and unmask the CRA report details */
export default class extends Controller {
  static targets = ["table", "tableParent"];

  /**
   * Toggle between masking and unmasking the CRA report data.
   * @param {htmlevent} event
   */
  toggle(event) {
    const currentTarget = event.currentTarget;
    if (currentTarget.dataset.masked == "true") {
      this.fetchReport(currentTarget);
    } else {
      this.removeReport(currentTarget);
    }
  }

  /**
   * Get the last decision report from the server.
   * @param {htmlDomTarget} currentTarget
   */
  fetchReport(currentTarget) {
    const controller = this;
    const leaseGuid = this.data.get("lease-guid");
    const fetchWith = window.acima.fetchInit({method: "GET"});
    const url = new URL(this.data.get("url"), window.location);
    url.searchParams.set("guid", leaseGuid);
    fetch(url, fetchWith).then((resp) => {
      return resp.json();
    }).then((data) => {
      if (data.status === "error") {
        return show_common_error_dialog(data.message);
      } else {
        currentTarget.textContent = "Mask";
        currentTarget.dataset.masked = "false";
         new Clusterize({
          rows: controller.processData(data),
          scrollId: controller.tableParentTarget.id,
          contentId: controller.tableTarget.id,
        });
      }
    }).catch((error) => {
      return show_common_error_dialog(
          "Unknown error trying to obtain underwriting details."
      );
    });
  }

  /**
   * Remove all data in the CRA report details
   * @param {htmlDomTarget} currentTarget
   */
  removeReport(currentTarget) {
    while (this.tableTarget.childElementCount != 0) {
      this.tableTarget.children[0].remove();
    }
    currentTarget.textContent = "Unmask";
    currentTarget.dataset.masked = "true";
  }

  /**
   * Build the data table html
   * @param {Array} data
   * @return {Array} array of html strings
   */
  processData(data) {
    if (data.length == 1) {
      return [`<pre>${data}</pre>`];
    } else {
      return data.map((item, index, array) => {
        if (index == array.length - 1) {
          return `<pre>${item}</pre>`;
        } else {
          return "<tr>" +
            `<th>${Object.keys(item)[0]}</th>` +
            `<td>${Object.values(item)[0]}</td>` +
            "</tr>";
        }
      });
    }
  }
}
