import {Controller} from "stimulus";
import {publish, subscribe} from "../../helpers/pub_sub";
import {submitForm} from "./form_controller";

const SHOW_DIALOG_EVENT = "showConfirmationDialogEvent";

/**
 * Publishes the show confirmation dialog event
 *
 * @param {String} changeAmount - amount invoice changed
 * @param {String} newInvoiceTotal - new invoice total
 * @param {Boolean} changeIsUpsy - true if invoice amount increased
 */
export function showConfirmationDialog(
    changeAmount,
    newInvoiceTotal,
    changeIsUpsy,
) {
  publish(
      SHOW_DIALOG_EVENT,
      {changeAmount, newInvoiceTotal, changeIsUpsy},
  );
}

export default class extends Controller {
  static targets = [
    "invoiceChangeAmount",
    "newInvoiceTotal",
    "upsy",
    "downsy",
    "confirmationText",
    "disclosure",
    "disclosureSection",
    "acknowledgmentCheckBox",
    "submitButton",
  ]

  connect() {
    dialogPolyfill.registerDialog(this.element);
    subscribe(SHOW_DIALOG_EVENT, this.openDialog);
  }

  openDialog = ({changeAmount, newInvoiceTotal, changeIsUpsy}) => {
    this.changeAmount = changeAmount;
    this.newInvoiceTotal = newInvoiceTotal;
    this.changeIsUpsy = changeIsUpsy;
    this.acknowledgmentCheckBoxTarget.checked = false;
    this.disclosureSectionTarget.classList.remove("hidden");
    this.submitButtonTarget.classList.add("disabled");

    this.initDialogValues();
    this.element.showModal();
  }

  initDialogValues() {
    this.invoiceChangeAmountTarget.innerHTML = this.changeAmount;
    this.newInvoiceTotalTarget.innerHTML = this.newInvoiceTotal;

    if (this.changeIsUpsy) {
      this.upsyTarget.classList.remove("hidden");
      this.downsyTarget.classList.add("hidden");
      this.confirmationTextTarget.innerHTML = window.acima.i18n.t(
        "merchants.contracts.invoice_adjustments.invoice_step.doing.dialog.upward_confirmation_text"
      );
      this.disclosureTarget.innerHTML = window.acima.i18n.t(
        "merchants.contracts.invoice_adjustments.invoice_step.doing.dialog.upward_disclosure"
      );
    } else {
      this.upsyTarget.classList.add("hidden");
      this.downsyTarget.classList.remove("hidden");
      this.confirmationTextTarget.innerHTML = window.acima.i18n.t(
        "merchants.contracts.invoice_adjustments.invoice_step.doing.dialog.downward_confirmation_text"
      );
      const parsedAmount = parseFloat(this.changeAmount.replace(/[^\d.]/g, ""));
      if (parsedAmount === 0) {
        this.disclosureSectionTarget.classList.add("hidden");
        this.submitButtonTarget.classList.remove("disabled");
      } else {
        this.disclosureTarget.innerHTML = window.acima.i18n.t(
          "merchants.contracts.invoice_adjustments.invoice_step.doing.dialog.downward_disclosure"
        );
      }
    }
  }

  toggleSubmittability() {
    if (this.acknowledgmentCheckBoxTarget.checked == true) {
      this.submitButtonTarget.classList.remove("disabled");
    } else {
      this.submitButtonTarget.classList.add("disabled");
    }
  }

  submitForm() {
    submitForm();
    this.closeDialog();
  }

  closeDialog() {
    this.element.close();
  }
}
