import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["boxBody", "errorMessage", "userList", "locationUserType",
    "location", "user", "add"]

  connect() {
    if (this.hasLocationUserTypeTarget) {
      this.populateUsers(this.locationUserTypeTarget.value)
    }
  }

  remove(event) {
    const element = event.target
    const message = this.errorMessageTarget
    const endpoint = `/users/location_users/${element.dataset.location_user_id}`
    const fetchWith = window.acima.fetchInit({ method: "DELETE" })

    fetch(endpoint, fetchWith)
      .then(response => response.text())
      .then(html => this.replaceBody(html))
      .catch(error => message.innerText = `An error occured while removing this user: ${error}`)
  }

  add(_event) {
    const locationID = Number(this.locationTarget.value)
    const userID = Number(this.userTarget.value)
    const locationUserTypeID = Number(this.locationUserTypeTarget.value)
    const message = this.errorMessageTarget
    const endpoint = "/users/location_users"
    const payload = JSON.stringify({
      location_id: locationID,
      user_id: userID,
      location_user_type_id: locationUserTypeID
    })
    const fetchWith = window.acima.fetchInit({
      method: "POST",
      body: payload
    })

    // if these are zero it means they were an empty string or null coming in
    if (locationID === 0 || userID === 0 || locationUserTypeID === 0) return

    fetch(endpoint, fetchWith)
      .then(response => response.text())
      .then(html => this.replaceBody(html))
      .catch(error => message.innerText = `An error occured while adding a user: ${error}`)
  }

  locationTypeSelected(event) {
    this.populateUsers(event.target.value)
  }

  populateUsers(locationUserTypeID) {
    const select = this.userTarget
    const message = this.errorMessageTarget
    const endpoint = "/users/users/valid_users_for_location_user_type"
    const payload = JSON.stringify({ location_user_type_id: locationUserTypeID })
    const fetchWith = window.acima.fetchInit({ method: "POST", body: payload })

    if (Number.isNaN(Number.parseInt(locationUserTypeID))) {
      select.innerHTML = null
      return
    }

    fetch(endpoint, fetchWith)
      .then(response => response.json())
      .then(users => select.replaceChildren(this.buildOptionsFragment(users)))
      .catch(error => message.innerText = `An error occured while loading users: ${error}`)
  }

  buildOptionsFragment(users) {
    const fragment = document.createDocumentFragment()

    users.forEach(user => {
      const option = document.createElement("option")
      option.value = user.id
      option.text = user.full_name

      fragment.appendChild(option)
    })

    return fragment
  }

  replaceBody(html) {
    const fragment = document.createRange().createContextualFragment(html)

    this.boxBodyTarget.replaceChildren(fragment)
  }
}
