import {Controller} from "stimulus";

/** Controller to launch request fraud conditions modal */
export default class extends Controller {
  static targets = ["conditionsDialog"];

  /** Launches a dialog modal */
  launchDialog() {
    document.querySelector("#request-fraud-conditions-dialog").showModal();
  }
}
