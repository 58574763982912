import { Controller } from "stimulus";
import QRCode from "qrcode";

// Controller for generating the qr code for applications

export default class extends Controller {
  static targets = [
    "qrCode",
    "qrCodeLabel",
    "dialog",
    "locationName"
  ];

  connect() {
    dialogPolyfill.registerDialog(this.dialogTarget);
  }

  generateQrCode() {
    const params = JSON.stringify({ location_id: this.locationId() });
    const fetchWith = window.acima.fetchInit({ method: "POST", body: params });

    fetch(this.qrCodeTarget.dataset.qrUrl, fetchWith).then(response => {
      return response.json();
    }).then(response => {
      if (response.status === "ok") {
        QRCode.toString(
          response.application_url,
          { type: "svg", height: 175, width: 175 }
        ).then(url => {
          const qrLabel = this.qrCodeLabelTarget.dataset.qrLabel;
          this.qrCodeTarget.innerHTML = url;
          this.qrCodeLabelTarget.innerHTML = qrLabel;
        }).catch(err => {
          console.error(err);
          this.qrCodeTarget.innerHTML = this.qrCodeTarget.dataset.error;
          this.qrCodeLabelTarget.innerHTML = "";
        });
      } else {
        console.error(`QR Code failed to generate: ${response.message}`);
        this.qrCodeTarget.innerHTML = this.qrCodeTarget.dataset.error;
        this.qrCodeLabelTarget.innerHTML = "";
      }
      this.locationNameTarget.innerHTML = this.locationName();
      this.dialogTarget.showModal();
    });
  }

  locationId() {
    const selectedLocation = document.getElementById("location_id");

    if (document.getElementById("location_id").length >= 2) {
      return selectedLocation.options[selectedLocation.selectedIndex].value;
    } else {
      return selectedLocation.value;
    }
  }

  locationName() {
    if (document.getElementById("location_id").length >= 2) {
      const selectedLocation = document.getElementById("location_id");
      return selectedLocation.options[selectedLocation.selectedIndex].innerHTML;
    } else {
      const selectedLocation = document.getElementById("location_name");
      return selectedLocation.value;
    }
  }

  closeDialog() {
    this.dialogTarget.close();
  }
};
