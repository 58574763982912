import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "option1",
    "option2"
  ];

  toggle(e) {
    this.option1Target.classList.toggle("active");
    this.option2Target.classList.toggle("active");
  }
}
