import { Controller } from "stimulus";
import { publish, subscribe, unsubscribe } from "../../../helpers/pub_sub";

const SHOW_RECEIPT_DIALOG_EVENT = "showReceiptDialogEvent";

export function showReceiptDialog() {
  publish(SHOW_RECEIPT_DIALOG_EVENT);
}

export default class extends Controller {
  connect() {
    dialogPolyfill.registerDialog(this.targetDialog);
    subscribe(SHOW_RECEIPT_DIALOG_EVENT, this.showDialog);
  }

  disconnect() {
    unsubscribe(SHOW_RECEIPT_DIALOG_EVENT, this.showDialog);
  }

  showDialog = () => {
    this.targetDialog.showModal();
  }

  closeDialog() {
    this.targetDialog.close();
  }

  get targetDialog() {
    return document.getElementById("view-receipt-modal");
  }
}
